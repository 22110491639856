import {deduplication} from './constants';
import {isEmpty} from 'lodash';
import React from 'react';

/** Получаем значение фильтра статус */
export const statusFilter = (protocolsJobDeduplication) => {
  if (!isEmpty(protocolsJobDeduplication)) {
    const title = protocolsJobDeduplication
      ?.map((status) => {
        return status?.status;
      })
      .map((el) => el?.title);
    const uniqueCatalogTitle = [...new Set(title)];
    return uniqueCatalogTitle?.map((el, index) => {
      return {key: index, value: el};
    });
  }
};
/** Получаем значение фильтра - статус подключения */
export const statusConnectionFilter = (protocolsJobDeduplication) => {
  if (!isEmpty(protocolsJobDeduplication)) {
    const title = protocolsJobDeduplication
      ?.map((statusConnection) => {
        return statusConnection?.status;
      })
      .map((el) => el?.title);
    const uniqueStatusConnection = [...new Set(title)];
    return uniqueStatusConnection.map((el, index) => {
      return {key: index, value: el};
    });
  }
};
/** Получаем значение фильтра - пользователь,подтвердивший связь */
export const userConfirmConnectFilter = (protocolsJobDeduplication) => {
  if (!isEmpty(protocolsJobDeduplication)) {
    const title = protocolsJobDeduplication?.map((userConfirmConnect) => {
      return userConfirmConnect?.username;
    });
    const uniqueUserConfirmConnect = [...new Set(title)];
    return uniqueUserConfirmConnect.map((el, index) => {
      return {key: index, value: el};
    });
  }
};
/** Получаем значение фильтра - пользователь */
export const authorUserNameFilter = (protocolsJobDeduplication) => {
  if (!isEmpty(protocolsJobDeduplication)) {
    const author = protocolsJobDeduplication?.map((userName) => {
      return userName?.username;
    });
    const uniqueAuthor = [...new Set(author)];
    return uniqueAuthor.map((el, index) => {
      return {key: index, value: el};
    });
  }
};
/** Получаем значение фильтра - справочник */
export const catalogFilter = (protocols) => {
  if (!isEmpty(protocols)) {
    const title =
      !isEmpty(protocols) &&
      protocols?.map((catalog) => {
        return catalog?.catalogDeduplicationName;
      });
    const uniqueCatalogTitle = [...new Set(title)];
    return uniqueCatalogTitle.map((el, index) => {
      return {key: index, value: el};
    });
  }
};
/** Получаем значение фильтра - название задания дедупликации */
export const jobTitleFilter = (protocols) => {
  if (!isEmpty(protocols)) {
    if (!isEmpty(protocols)) {
      const title = protocols?.map((name) => {
        return name?.name;
      });
      const uniqueJobTitle = [...new Set(title)];
      return uniqueJobTitle.map((name, index) => {
        return {key: index, value: name};
      });
    }
  }
};
/** Получаем значение статуса для отображения  */
export const renderStatusDeduplicationColor = (status) => {
  switch (status) {
    case 1:
      return 'deduplicationJob__btn-blue';
    case 2:
      return 'deduplicationJob__btn-darkBlue';
    case 3:
      return 'deduplicationJob__btn-yellow';
    case 4:
      return 'deduplicationJob__btn-red';
    case 5:
      return 'deduplicationJob__btn-green';
    default:
      return null;
  }
};
/** Функция для отображения текста кнопки */
export const renderButtonDeduplicationTextSave = (status, formStatus) => {
  switch (status || formStatus) {
    case formStatus === 'editStatus' && 1:
      return deduplication.edit;
    case formStatus === 'editStatus' && 4:
      return deduplication.edit;
    case 'createNewDeduplication':
      return deduplication.save;
    default:
      return null;
  }
};
/** Функция для отображения золотой записи  */
export const renderDeduplicationGoldRecord = (goldRecord, deduplicationGroupProtocolInfo) => {
  switch (goldRecord || deduplicationGroupProtocolInfo) {
    case deduplicationGroupProtocolInfo?.mainItem.attributes &&
      !isEmpty(deduplicationGroupProtocolInfo?.mainItem.attributes):
      return deduplicationGroupProtocolInfo?.mainItem.attributes?.map((item) => (
        <div key={item.recordUuid} className="deduplicationGroupTable__box--block-right--item">
          {item.value}
        </div>
      ));
    case !isEmpty(goldRecord):
      return goldRecord?.map((item) => (
        <div key={item.recordUuid} className="deduplicationGroupTable__box--block-right--item">
          {item.value}
        </div>
      ));
    default:
      return deduplicationGroupProtocolInfo?.deduplicationFields?.map(() => (
        <div className="deduplicationGroupTable__box--block-right--item"></div>
      ));
  }
};
/** Функция для отображения текста кнопки  */
export const renderButtonDeduplicationTextCancel = (status, formStatus) => {
  switch (status || formStatus) {
    case formStatus === 'editStatus' && 2:
      return deduplication.close;
    case formStatus === 'editStatus' && 3:
      return deduplication.close;
    case formStatus === 'editStatus' && 5:
      return deduplication.close;
    case formStatus === 'editStatus' && 1:
      return deduplication.cancel;
    case formStatus === 'editStatus' && 4:
      return deduplication.cancel;
    default:
      return deduplication.cancel;
  }
};
/** Функция для отображения алгоритма дедупликации  */
export const renderAlgorithmDeduplication = (algorithm) => {
  switch (algorithm) {
    case 'FULL_NAME':
      return 'ФИО';
    case 'EMAIL':
      return 'E-mail';
    case 'ADDRESS':
      return 'Адрес';
    case 'COMPANY_NAME':
      return 'Наименование организации';
    case 'PHONE':
      return 'Телефон';
    case 'NUMERIC_STRING':
      return 'Последовательность цифр';
    case 'STEMMING':
      return 'Произвольный текст';
    default:
      return null;
  }
};
/** Функция для отображения алгоритма дедупликации для отправки */
export const renderAlgorithmDeduplicationPayload = (algorithm) => {
  switch (algorithm) {
    case 'ФИО':
      return 'FULL_NAME';
    case 'E-mail':
      return 'EMAIL';
    case 'Адрес':
      return 'ADDRESS';
    case 'Наименование организации':
      return 'COMPANY_NAME';
    case 'Телефон':
      return 'PHONE';
    case 'Последовательность цифр':
      return 'NUMERIC_STRING';
    case 'Произвольный текст':
      return 'STEMMING';
    default:
      return null;
  }
};
