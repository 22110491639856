import {
  ADD_VERIFY_VALUE,
  SET_ALL_VERIFY_VALUE,
  SET_CHANGED_DETAIL_NAME,
  SET_CHANGED_DETAIL_FIELD,
  SET_CHANGED_DETAIL_MODE,
  SET_CONTEXT_SEARCH_VALUE,
  SET_FILTER_SEARCH_VALUE,
  SET_HISTORY_SEARCH_VALUE,
  SET_FILTER_RESET,
  SET_FILTER_ERROR,
  SET_RECORD_LOADING,
  SET_MENU_ACCESS,
  SET_BREADCRUMB_NAMES,
  SET_ACTIVE_MENU_ITEM,
  SET_CATALOG_EDIT_CAPTION,
  ADD_DEDUPLICATION_PROTOCOL,
  SET_DEDUPLICATION_FORM_PROTOCOL,
  SET_DEDUPLICATION_JOB_PROTOCOL,
  ADD_RULES_DEDUPLICATION,
  ADD_ALGORITHM_DEDUPLICATION,
  ADD_DEGREE_PROXIMITY_DEDUPLICATION,
  ADD_START_DEDUPLICATION_PROTOCOL,
  SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE,
  SET_DEDUPLICATION_PROTOCOLS,
  EDIT_JOB_DEDUPLICATION_PROTOCOL,
  SET_DEDUPLICATION_PROTOCOL_INFO,
  SET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  RESET_RULES_DEDUPLICATION,
  SET_DEDUPLICATION_BLOCK_LINK_GROUP,
  SET_DEDUPLICATION_SET_LINK_GROUP,
  SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP,
  SET_SYSTEM_INFO_PROTOCOL,
  SET_DEDUPLICATION_CANCEL_LINK_GROUP,
  SET_DEDUPLICATION_PROTOCOLS_DELETE,
  RESET_DEDUPLICATION_PROTOCOL_INFO,
  RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  COLOR,
  SET_NOTIFICATION_LIST,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_TEMPLATE,
  SET_NOTIFICATION_TEMPLATE_LIST,
  DELETE_NOTIFICATION_TEMPLATE,
  SET_NOTIFICATION_TEMPLATE,
  EDIT_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION,
  SET_NOTIFICATION_ACTIVATE,
  SET_NOTIFICATION_DEACTIVATE,
  SET_NOTIFICATION_ID,
  CAPTION,
  TEST_NOTIFICATION,
  SEND_SUCCESS_MESSAGE,
  SET_FILTERED_SUBSCRIPTION,
  SET_NOTIFICATION_SUBSCRIPTION_LIST,
  SET_RECORD_ID,
  SET_REFERENCE_CATALOG_PATTERN_ORIGIN,
  RESET_NOTIFICATION_TEMPLATE,
  SET_SCHEDULE_EXECUTION,
  GET_USER_NAME,
  GET_HELP_INFO,
  SET_DATA_OPTIONS,
  SET_USER,
  SET_SUBMIT_REQUEST,
  SET_USER_ROLES,
  LOADING_JOBS_DEDUPLICATION,
  LOADING_PROTOCOLS_DEDUPLICATION,
  LOADING_NOTIFICATION_LIST,
  LOADING_NOTIFICATION_TEMPLATES,
  SET_CURRENT_CATALOG,
  SET_DATA_OPTIONS_RESET,
  GET_FIELDS_TYPE,
  SET_EMAILS_FOR_TEST,
  SET_PHONE_NUMBER_FOR_TEST,
  SET_VALIDATOR_LENGTH,
  SET_NUMERIC_COMPARISON,
  SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS,
  LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION,
  GET_USERS_ROLES,
  SET_FILTER_ERROR_KEY_ATTRIBUTE,
} from './actionTypes';

import {fetchFunc} from '../../Utils/security/http/mdm';

export const getMenuAccess = () => {
  return async (dispatch) => {
    const fetchData = await fetchFunc({
      url: `/api/v1/catalogs/menu/fact/access`,
      method: 'get',
    });
    dispatch(setMenuAccess(fetchData));
  };
};
export const getUserNameAC = (value) => {
  return {
    type: GET_USER_NAME,
    payload: value,
  };
};
export const setValidatorLengthAC = (value) => {
  return {
    type: SET_VALIDATOR_LENGTH,
    payload: value,
  };
};
export const setNumericComparisonAC = (value) => {
  return {
    type: SET_NUMERIC_COMPARISON,
    payload: value,
  };
};
export const getHelpInfoAC = (value) => {
  return {
    type: GET_HELP_INFO,
    payload: value,
  };
};
export const setMenuAccess = (roles) => {
  return {
    type: SET_MENU_ACCESS,
    payload: roles,
  };
};

export const setCurrentCatalog = (payload) => ({
  type: SET_CURRENT_CATALOG,
  payload,
});

export const setCatalogEditCaption = (value) => {
  return {
    type: SET_CATALOG_EDIT_CAPTION,
    payload: value,
  };
};

export const setActiveMenuItem = (payload) => {
  return {
    type: SET_ACTIVE_MENU_ITEM,
    payload: payload,
  };
};
export const setDataForOptionsTypeSelect = (values) => {
  return {
    type: SET_DATA_OPTIONS,
    payload: values,
  };
};
export const setBreadcrumbsNames = (payload) => {
  return {
    type: SET_BREADCRUMB_NAMES,
    payload: payload,
  };
};

export const addVerify = (name, value) => {
  return {
    type: ADD_VERIFY_VALUE,
    payload: {
      name,
      value,
    },
  };
};

export const setVerify = (values) => {
  return {
    type: SET_ALL_VERIFY_VALUE,
    payload: values,
  };
};

export const setChangedDetailName = (values) => {
  return {
    type: SET_CHANGED_DETAIL_NAME,
    payload: values,
  };
};

export const setChangedDetailField = (values) => {
  return {
    type: SET_CHANGED_DETAIL_FIELD,
    payload: values,
  };
};

export const setReferenceCatalogPatternOriginAC = (value) => {
  return {
    type: SET_REFERENCE_CATALOG_PATTERN_ORIGIN,
    payload: value,
  };
};

export const setNotificationEmailsForTestAC = (emails) => {
  return {
    type: SET_EMAILS_FOR_TEST,
    payload: emails,
  };
};

export const setNotificationPhoneNumberForTestAC = (phoneNumbers) => {
  return {
    type: SET_PHONE_NUMBER_FOR_TEST,
    payload: phoneNumbers,
  };
};

export const setChangedDetailMode = (values) => {
  return {
    type: SET_CHANGED_DETAIL_MODE,
    payload: values,
  };
};
export const searchScheduleExecutionAC = (value) => {
  return {
    type: SET_SCHEDULE_EXECUTION,
    payload: value,
  };
};
export const getUsersAC = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};
export const getFieldsTypeAC = (types) => {
  return {
    type: GET_FIELDS_TYPE,
    payload: types,
  };
};
export const getUsersRolesAC = (roles) => {
  return {
    type: GET_USERS_ROLES,
    payload: roles,
  };
};
export const setUserRoles = (roles) => {
  return {
    type: SET_USER_ROLES,
    payload: roles,
  };
};
export const setContextSearchValue = (value) => {
  return {
    type: SET_CONTEXT_SEARCH_VALUE,
    payload: value,
  };
};
export const setFilterSearchValue = (values) => {
  return {
    type: SET_FILTER_SEARCH_VALUE,
    payload: values,
  };
};
export const setHistorySearchValue = (values) => {
  return {
    type: SET_HISTORY_SEARCH_VALUE,
    payload: values,
  };
};
export const setFilterReset = (flag) => {
  return {
    type: SET_FILTER_RESET,
    payload: flag,
  };
};

export const setDataOptionsReset = (flag) => {
  return {
    type: SET_DATA_OPTIONS_RESET,
    payload: flag,
  };
};
export const setFilterError = (flag) => {
  return {
    type: SET_FILTER_ERROR,
    payload: flag,
  };
};

export const setRecordLoading = (flag) => {
  return {
    type: SET_RECORD_LOADING,
    payload: flag,
  };
};

export const addDeduplicationProtocolAC = (value) => {
  return {
    type: ADD_DEDUPLICATION_PROTOCOL,
    payload: value,
  };
};
export const setFilterErrorKeyAttribute = (flag) => {
  return {
    type: SET_FILTER_ERROR_KEY_ATTRIBUTE,
    payload: flag,
  };
};

export const addStartDeduplicationProtocolAC = (value, uuid) => {
  return {
    type: ADD_START_DEDUPLICATION_PROTOCOL,
    payload: {
      value,
      uuid,
    },
  };
};

export const editJobDeduplicationAC = (value, uuid) => {
  return {
    type: EDIT_JOB_DEDUPLICATION_PROTOCOL,
    payload: {
      value,
      uuid,
    },
  };
};

export const getDeduplicationFormProtocolAC = (values) => {
  return {
    type: SET_DEDUPLICATION_FORM_PROTOCOL,
    payload: values,
  };
};

export const getDeduplicationJobProtocolAC = (values) => {
  return {
    type: SET_DEDUPLICATION_JOB_PROTOCOL,
    payload: values,
  };
};

export const getDeduplicationProtocolsAC = (values) => {
  return {
    type: SET_DEDUPLICATION_PROTOCOLS,
    payload: values,
  };
};

export const getDeduplicationUnarchivedProtocolsAC = (values) => {
  return {
    type: SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS,
    payload: values,
  };
};

export const getDeduplicationProtocolDeleteAC = (value, protocolId) => {
  return {
    type: SET_DEDUPLICATION_PROTOCOLS_DELETE,
    payload: {value, protocolId},
  };
};
export const addRulesDeduplication = (values) => {
  return {
    type: ADD_RULES_DEDUPLICATION,
    payload: values,
  };
};

export const resetRulesDeduplication = (values) => {
  return {
    type: RESET_RULES_DEDUPLICATION,
    payload: values,
  };
};

export const addAlgorithmDeduplication = (value, comboBoxOptions, rulesDeduplication, key) => {
  return {
    type: ADD_ALGORITHM_DEDUPLICATION,
    payload: {
      value,
      comboBoxOptions,
      rulesDeduplication,
      key,
    },
  };
};

export const addDegreeProximityDeduplication = (value, key) => {
  return {
    type: ADD_DEGREE_PROXIMITY_DEDUPLICATION,
    payload: {
      value,
      key,
    },
  };
};
export const loadingJobsAC = (value) => {
  return {
    type: LOADING_JOBS_DEDUPLICATION,
    payload: value,
  };
};
export const loadingProtocolsAC = (value) => {
  return {
    type: LOADING_PROTOCOLS_DEDUPLICATION,
    payload: value,
  };
};
export const loadingProtocolsUnarchivedAC = (value) => {
  return {
    type: LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION,
    payload: value,
  };
};
export const loadingNotificationListAC = (value) => {
  return {
    type: LOADING_NOTIFICATION_LIST,
    payload: value,
  };
};
export const loadingPNotificationTemplatesAC = (value) => {
  return {
    type: LOADING_NOTIFICATION_TEMPLATES,
    payload: value,
  };
};
export const getSubmitRequestAC = (value) => {
  return {
    type: SET_SUBMIT_REQUEST,
    payload: value,
  };
};
export const getDeduplicationProtocolInfoAC = (values, protocolId) => {
  return {
    type: SET_DEDUPLICATION_PROTOCOL_INFO,
    payload: {values, protocolId},
  };
};
export const getDeduplicationGroupProtocolInfoAC = (value, groupId) => {
  return {
    type: SET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
    payload: {value, groupId},
  };
};

export const getDeduplicationJobProtocolInfoDeleteAC = (uuid) => {
  return {
    type: SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE,
    payload: uuid,
  };
};

export const getBlockLinkGroupAC = (value, groupId, itemGroupId) => {
  return {
    type: SET_DEDUPLICATION_BLOCK_LINK_GROUP,
    payload: {value, groupId, itemGroupId},
  };
};

export const getBlockLinkFullGroupAC = (value, groupId) => {
  return {
    type: SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP,
    payload: {value, groupId},
  };
};

export const getCancelLinkGroupAC = (value, groupId) => {
  return {
    type: SET_DEDUPLICATION_CANCEL_LINK_GROUP,
    payload: {value, groupId},
  };
};

export const getSetLinkGroupAC = (value, groupId) => {
  return {
    type: SET_DEDUPLICATION_SET_LINK_GROUP,
    payload: {value, groupId},
  };
};

export const setSystemInfoProtocolAC = (value) => {
  return {
    type: SET_SYSTEM_INFO_PROTOCOL,
    payload: value,
  };
};

export const resetDeduplicationProtocolInfo = () => {
  return {
    type: RESET_DEDUPLICATION_PROTOCOL_INFO,
  };
};

export const resetDeduplicationProtocolInfoGroup = () => {
  return {
    type: RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  };
};

export const color = (color) => {
  return {
    type: COLOR,
    payload: color,
  };
};
export const setCaption = (caption, value) => {
  return {
    type: CAPTION,
    payload: {caption, value},
  };
};
export const setRecordId = (value) => {
  return {
    type: SET_RECORD_ID,
    payload: value,
  };
};
export const deleteNotificationAC = (uuid) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: uuid,
  };
};
export const getNotificationListAC = (values) => {
  return {
    type: SET_NOTIFICATION_LIST,
    payload: values,
  };
};
export const getNotificationIdAC = (uuid) => {
  return {
    type: SET_NOTIFICATION_ID,
    payload: uuid,
  };
};
export const setActivateNotificationAC = (value, notifyConditionId) => {
  return {
    type: SET_NOTIFICATION_ACTIVATE,
    payload: {value, notifyConditionId},
  };
};
export const setDeactivateNotificationAC = (value, notifyConditionId) => {
  return {
    type: SET_NOTIFICATION_DEACTIVATE,
    payload: {value, notifyConditionId},
  };
};
export const getNotificationTemplateListAC = (values) => {
  return {
    type: SET_NOTIFICATION_TEMPLATE_LIST,
    payload: values,
  };
};
export const deleteNotificationTemplateAC = (uuid) => {
  return {
    type: DELETE_NOTIFICATION_TEMPLATE,
    payload: uuid,
  };
};
export const getNotificationTemplateAC = (uuid) => {
  return {
    type: SET_NOTIFICATION_TEMPLATE,
    payload: uuid,
  };
};
export const resetNotificationTemplateAC = (value) => {
  return {
    type: RESET_NOTIFICATION_TEMPLATE,
    payload: value,
  };
};
export const addNotificationTemplateAC = (value) => {
  return {
    type: ADD_NOTIFICATION_TEMPLATE,
    payload: value,
  };
};
export const addNotificationAC = (value) => {
  return {
    type: ADD_NOTIFICATION,
    payload: value,
  };
};
export const editNotificationTemplateAC = (value, uuid) => {
  return {
    type: EDIT_NOTIFICATION_TEMPLATE,
    payload: {value, uuid},
  };
};
export const testNotificationAC = (value) => {
  return {
    type: TEST_NOTIFICATION,
    payload: value,
  };
};
export const sendSuccessSendMessageAC = (value) => {
  return {
    type: SEND_SUCCESS_MESSAGE,
    payload: value,
  };
};
export const getNotificationListSubscriptionAC = (values) => {
  return {
    type: SET_NOTIFICATION_SUBSCRIPTION_LIST,
    payload: values,
  };
};
export const getFilteredSubscriptionAC = (values) => {
  return {
    type: SET_FILTERED_SUBSCRIPTION,
    payload: values,
  };
};
