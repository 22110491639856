import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {isEmpty} from 'lodash';
import {Switch} from 'antd/es';
import {Button, Spin} from 'antd';

import DeduplicationGroupModalValue from '../DeduplicationGroupModalValue/DeduplicationGroupModalValue';
import {ReactComponent as DeleteDeduplication} from '../../../../../assets/images/delete.svg';
import {getBlockLinkGroup, getDeduplicationProtocolInfo} from '../../../../../redux/thunk/deduplicationThunk';
import {deduplication} from '../../../helpers/constants';
import {color, setCaption, setRecordId} from '../../../../../redux/actions/actions';

import './DeduplicationGroupTable.scss';

/**
 * Таблица гпупп дедупликации
 * @param groupId - uuid группы
 * @param setPayloadData - функция для подготовки данных к отправке
 * @param payloadData  - данные для отпраки
 * @param status  - статус группы
 * @param deduplicationProtocolInfo - данные по протоколу
 * @param deduplicationGroupProtocolInfo - данные по группам дедупликации
 * @param goldRecord - состояние золотой записи
 * @param setGoldRecord -функция изменения золотой записи
 * @param setIsGoldRecord - функция изменения состояния выделения  золотой записи
 * @param isGoldRecord - состояние золотой записи
 * @param match - данные для загрузки id при перезагрузки страницы
 */
const DeduplicationGroupTable = ({
  groupId,
  setPayloadData,
  payloadData,
  status,
  deduplicationGroupProtocolInfo,
  goldRecord,
  setGoldRecord,
  setIsGoldRecord,
  isGoldRecord,
  match,
}) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Используем хранилище */
  const caption = useSelector((state) => state?.deduplication.caption);
  /** Uuid группы записей */
  const [groupRecordUuid, setGroupRecordUuid] = useState('');
  /** Uuid выбранной записи не подтвержденной */
  const [goldRecordUuidLocal, setGoldRecordUuidLocal] = useState('');
  /** Золотая запись подвежденная */
  const goldRecordData = deduplicationGroupProtocolInfo ? deduplicationGroupProtocolInfo?.mainItem?.attributes : null;
  /** Используем хранилище */
  const deduplicationProtocolInfo = useSelector((state) => state?.deduplication.deduplicationProtocolInfo);

  const catalogDeduplicationId =
    !isEmpty(deduplicationProtocolInfo) && deduplicationProtocolInfo[0]?.catalogDeduplicationId;
  /** Добавляем в золотую запись полученные даннные */
  useEffect(() => {
    setGoldRecord(goldRecordData);
  }, [goldRecordData]);
  useEffect(() => {
    isEmpty(deduplicationProtocolInfo) && dispatch(getDeduplicationProtocolInfo(match.params.id));
  }, [deduplicationProtocolInfo]);

  /** Функция для переключения записи в золотую */
  const goldRecordHandler = (event, checked, value, id) => {
    event.preventDefault();
    if (checked) {
      setPayloadData({
        ...payloadData,
        catalogDeduplicationId: catalogDeduplicationId,
        mainItemUuid: id,
      });
      setIsGoldRecord(true);
      setGoldRecordUuidLocal(id);
      setGoldRecord(value);
    } else {
      setGoldRecord(null);
      setIsGoldRecord(false);
    }
  };
  /** Функция для добавления значений в золотую запись*/
  const copyValueToGoldRecordHandler = (event, value, caption, recordUuid, origin, active) => {
    event.preventDefault();
    let updateGoldRecord = goldRecord?.map((item) => {
      if (item.caption === caption) {
        dispatch(color('red'));
        dispatch(setCaption(item.caption, item.recordUuid));
        return {...item, value: value};
      } else {
        dispatch(color('gray'));
        dispatch(setRecordId(recordUuid));
        return item;
      }
    });
    const data = payloadData?.updatedMainItemFields?.filter((item) => item.attribute !== origin);
    active === false
      ? setPayloadData({
          ...payloadData,
          updatedMainItemFields: !isEmpty(data)
            ? [{attribute: origin, value: value}, ...data]
            : [{attribute: origin, value: value}],
        })
      : setPayloadData({
          ...payloadData,
          updatedMainItemFields: payloadData?.updatedMainItemFields?.filter((item) => item.attribute !== origin),
        });
    setGroupRecordUuid(groupRecordUuid);
    setGoldRecord(updateGoldRecord);
  };
  if (isEmpty(deduplicationGroupProtocolInfo)) return <Spin size="large" className="deduplicationGroupTable__spin" />;
  return (
    <div className={cn('deduplicationGroupTable')}>
      <div className="deduplicationGroupTable__box">
        <div className="deduplicationGroupTable__box--block-left">
          <div className="deduplicationGroupTable__box--block-left--column">
            <div className="deduplicationGroupTable__box--block-left">
              <div className="deduplicationGroupTable__box--block-left--item"></div>
              {deduplicationGroupProtocolInfo?.deduplicationFields?.map((attribute, index) => (
                <div className="deduplicationGroupTable__box--block-left--item" key={index}>
                  {attribute}{' '}
                </div>
              ))}
            </div>
            <div className="deduplicationGroupTable__box--block-left--column">
              <div className="deduplicationGroupTable__box--block-left">
                <div className="deduplicationGroupTable__box--block-left--head">
                  {!isEmpty(deduplicationGroupProtocolInfo?.mainItem)
                    ? deduplicationGroupProtocolInfo?.mainItem?.title
                    : deduplication.goldRecord}
                </div>
                {!isEmpty(goldRecord)
                  ? goldRecord?.map((goldRecord, key) => (
                      <div
                        className={cn('deduplicationGroupTable__box--block-right--item', {
                          'deduplicationGroupTable__box--block-right--item--goldRecord': isGoldRecord,
                        })}
                        key={key}
                      >
                        {goldRecord.value}
                      </div>
                    ))
                  : deduplicationGroupProtocolInfo?.deduplicationFields?.map((key) => (
                      <div className="deduplicationGroupTable__box--block-right--item" key={key}></div>
                    ))}
              </div>
            </div>
          </div>
        </div>

        <div className="deduplicationGroupTable__box--block-right">
          <div className="deduplicationGroupTable__box--block-right__up">
            {deduplicationGroupProtocolInfo?.records?.map((record) => {
              return (
                <div className="deduplicationGroupTable__box--block-right__down">
                  <div className="deduplicationGroupTable__box--block-right--item">
                    <div
                      className={cn('deduplicationGroupTable__box--block-right__head', {
                        'deduplicationGroupTable__box--block-right__head-hidden-toggle': status === 'LINK_SET',
                      })}
                      key={record.groupRecordUuid}
                    >
                      <div> {record.title}</div>
                      {status !== 'LINK_SET' && (
                        <div
                          className={cn('deduplicationGroupTable__switch-box', {
                            ' deduplicationGroupTable__switch-box-hidden': status !== 'LINK_SET',
                          })}
                        >
                          <div className="deduplicationGroupTable__switch-box--title">{deduplication.gold}</div>
                          <Switch
                            size="small"
                            checked={isGoldRecord && goldRecordUuidLocal === record.recordUuid}
                            onChange={(checked, event) =>
                              goldRecordHandler(event, checked, record.attributes, record.recordUuid)
                            }
                          />
                          <Button
                            id={record?.groupRecordUuid}
                            className="deduplicationGroupTable__switch-box--button"
                            onClick={() => {
                              dispatch(getBlockLinkGroup(groupId, record?.groupRecordUuid));
                            }}
                            disabled={
                              status === 'LINK_SET' ||
                              (isGoldRecord && goldRecordUuidLocal === record?.recordUuid) ||
                              deduplicationGroupProtocolInfo?.records.map((item) => item?.attributes).length <= 2
                            }
                          >
                            <DeleteDeduplication className="deduplicationGroupTable__switch-box--delete-icon" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  {deduplicationGroupProtocolInfo?.deduplicationFields?.map((attribute) => {
                    return deduplicationGroupProtocolInfo.records?.map((item) => {
                      return (
                        <>
                          {item.attributes?.map(
                            (recordsValue, index) =>
                              recordsValue?.caption === attribute &&
                              record.recordUuid === recordsValue.recordUuid && (
                                <DeduplicationGroupModalValue
                                  recordsValue={recordsValue}
                                  goldRecord={goldRecord}
                                  rec={record?.recordUuid}
                                  copyValueToGoldRecordHandler={copyValueToGoldRecordHandler}
                                  index={index}
                                  caption={!isEmpty(caption) && caption}
                                  status={status}
                                />
                              )
                          )}
                        </>
                      );
                    });
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(DeduplicationGroupTable);
