import React, {useState, useEffect} from 'react';
import {Tooltip} from 'antd';
import {DeleteTwoTone} from '@ant-design/icons';

import useCRUD from '../../hooks/CRUD.hook';
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import SchedulerForm from '../../components/SchedulerForm/SchedulerForm';
import {onDeleteError} from './utils/utils';

const SchedulerTasks = () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: 'Тип задачи',
      dataIndex: 'type',
    },
    {
      title: '',
      key: 'del',
      align: 'center',
      render: (text, record) => (
        <Tooltip placement="topLeft" title="Удалить">
          <DeleteTwoTone onClick={() => deleteSchedule(record.uuid)} />
        </Tooltip>
      ),
    },
  ];
  const [editableItemTask, setEditableItemTask] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState('');
  const {getData, changeData, deleteData, data, loading, total} = useCRUD({
    apiUrl: `/api/v1/scheduler/job`,
    service: 'scheduler',
    onDeleteError,
  });

  useEffect(() => {
    getData({page, size, search});
  }, [page, size, search]);

  const changeSchedule = async (values, editableItemTask) => {
    try {
      await changeData({fields: values}, editableItemTask);
      getData({page, size, name_description_like: search});
    } catch (error) {}
  };

  const deleteSchedule = async (uuid) => {
    try {
      await deleteData(uuid);
      getData({page, size, name_description_like: search});
    } catch (error) {}
  };

  const handleTableChange = (paginator) => {
    setPage(--paginator.current);
    setSize(paginator.pageSize);
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  return (
    <ListWithAddForm
      editableItemTask={editableItemTask}
      setEditableItemTask={setEditableItemTask}
      isSearch={true}
      changeData={changeSchedule}
      handleTableChange={handleTableChange}
      onSearch={onSearch}
      tableConfig={{
        data,
        loading,
        columns,
        total,
      }}
      editCatalog
      addModalTitle="задач"
      FormComponent={SchedulerForm}
    />
  );
};

export default React.memo(SchedulerTasks);
