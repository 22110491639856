import React, {Component} from 'react';
import CatalogList from '../../components/CatalogList';
import './Catalogs.scss';

class Catalogs extends Component {
  constructor(props) {
    super(props);
    const {history} = this.props;
    this.state = {
      columns: [
        {
          title: 'Название',
          dataIndex: 'caption',
          key: 'caption',
          render: (text, record) => (
            <div onClick={() => history.push(`/catalog/${record.uuid}`)} style={{cursor: 'pointer'}}>
              {text}
            </div>
          ),
        },
        {
          title: 'Код',
          dataIndex: 'origin',
          key: 'origin',
        },
      ],
      displayedColumns: ['caption', 'origin'],
    };
  }

  render() {
    const {columns, displayedColumns} = this.state;

    return (
      <CatalogList
        card={true}
        editible={true}
        columns={columns}
        isContextSearch={true}
        displayedColumns={displayedColumns}
        apiUrl="/api/v1/catalogs"
        sort={'caption,asc'}
        editRulesMode={this.props.editRulesMode}
      />
    );
  }
}

export default React.memo(Catalogs);
