import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {saveAs} from 'file-saver';
import {Switch} from 'antd';
import {isEmpty} from 'lodash';

import {serviceMeta} from './Meta/meta';
import useCRUD from '../../hooks/CRUD.hook';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {setFilterReset} from '../../redux/actions/actions';
import {mainInfoStructureCreate} from './Structure/structure';
import RecordEditor from '../../components/RecordRedactor/RecordEditor';
import FormInitiator from '../../components/RecordRedactor/FormInitator';
import {metaUnifier} from '../../components/Helpers/RuleConfigurator';
import {errorModalCreate, successModalCreate} from '../../components/Helpers/Modals';
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import {getEditibleCatalogFlag} from '../../components/Helpers/Extractors';
import {renderTableDate, normalizeValues, onError, getReportStructure} from '../../components/Helpers/Utils';

const columns = [
  {
    title: 'Наименование услуги',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <Link to={`/services/${record.uuid}`}>{text}</Link>,
  },
  {
    title: 'Организация, предоставляющая услугу',
    dataIndex: ['org', 'caption'],
    key: 'org',
  },
  {
    title: 'Дата создания',
    dataIndex: 'createDate',
    key: 'createDate',
    render: (text) => renderTableDate(text),
  },
  {
    title: 'Дата архивации',
    dataIndex: 'dateEnd',
    key: 'dateEnd',
    render: (text) => renderTableDate(text),
  },
  {
    title: 'Статус',
    dataIndex: ['status', 'caption'],
    key: 'status',
  },
];

const apiUrl = '/api/v1/catalogs/service/items';

const Services = () => {
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [editCatalog, setEitCatalog] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const breadcrumbMap = useSelector((state) => state.menu.breadcrumbMap);
  const location = useLocation();
  const dispatch = useDispatch();
  const [hiddenFields, setHiddenFields] = useState([]);
  const [isTree, setIsTree] = useState(false);
  const [displayedColumns, setDisplayedColumns] = useState(['name', 'createDate', 'dateEnd', 'org', 'status']);
  const [treeData, setTreeData] = useState([]);
  const history = useHistory();

  const {getData, getCatalogFields, data, loading, total, fields} = useCRUD({
    apiUrl,
  });

  const contextString = useSelector((state) => state.filter.contextString);
  const filterBody = useSelector((state) => state.filter.filterBody);
  const filterResetFlag = useSelector((state) => state.filter.filterResetFlag);

  useEffect(() => {
    if (location) {
      let active = breadcrumbMap.find((elem) => elem.route === location.pathname);
      dispatch({
        type: 'SET_ACTIVE_MENU_ELEMENT',
        data: active,
      });
    }
    getCatalogFields('service');
    getEditFlagService();
  }, []);

  useEffect(() => {
    if (filterResetFlag) {
      if (isEmpty(filterBody)) setFilter({});
      dispatch(setFilterReset(false));
    }
  }, [filterResetFlag]);

  useEffect(() => {
    const body = makeFilterBody(filter, search, isTree);
    getData({page, size, type: 'search', body, params: {showRefs: 1}});
  }, [page, size, filter, search, isTree]);

  const makeFilterBody = (filter, search, isTree) => {
    let body = {
      and: [],
    };
    if (filter && !isEmpty(filter)) body.and.push(filter);
    if (search) {
      body.and.push({
        ['%context_filter']: search,
      });
    }
    if (isTree) {
      body.and.forEach((item) => (item.parentId = null));
    }
    return body;
  };

  useEffect(() => {
    setPage(0);
  }, [isTree]);

  useEffect(() => {
    if (isTree) {
      data.forEach((item) => {
        item.children = [];
        item.key = item.uuid;
      });
      setTreeData(data);
    }
  }, [data, isTree]);

  const getEditFlagService = async () => {
    setEitCatalog(await getEditibleCatalogFlag('service'));
  };

  const getChildren = async (parentId, totalElements = 100) => {
    try {
      const data = await fetchFunc({
        url: `${apiUrl}/search`,
        method: 'post',
        data: {
          parentId,
        },
      });
      if (data.totalElements > totalElements) {
        getChildren(parentId, totalElements);
      } else {
        let parent = treeData.find((elem) => elem.uuid === parentId);
        if (parent) {
          parent.children = data.content;
        }
        setTreeData([...treeData]);
      }
    } catch (error) {}
  };

  const onExport = async () => {
    if (!displayedColumns?.length)
      return errorModalCreate(
        'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
      );
    applyFilter();
    try {
      setExportLoading(true);
      const data = await fetchFunc({
        url: `/api/v1/catalogs/service/items/report?showRefs=1`,
        method: 'post',
        data: {
          filter: makeFilterBody(filterBody, contextString, isTree),
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: [],
        },
        responseType: 'blob',
      });
      saveAs(data, `services.csv`);
      setExportLoading(false);
    } catch (error) {
      setExportLoading(false);
    }
  };

  const handleTableChange = (paginator) => {
    setPage(--paginator.current);
    setSize(paginator.pageSize);
  };

  const applyFilter = () => {
    setSearch(contextString);
    setFilter(filterBody);
    setPage(0);
  };

  const onCreate = async (values) => {
    try {
      const response = await fetchFunc(
        {
          url: '/api/v1/catalogs/service/items',
          method: 'post',
          data: values,
        },
        onError
      );
      history.push(`services/${response.uuid}`);
      successModalCreate('', `Услуга ${response.name} успешно создана`);
    } catch (error) {}
  };

  const changeRecord = (values) => {
    values = normalizeValues({values, fields: fields});
    onCreate({fields: values});
  };

  const resultMetaFields = metaUnifier(fields, serviceMeta);

  const CreateForm = (props) => {
    return (
      <FormInitiator
        formName={'service-create-form'}
        fieldsMeta={resultMetaFields}
        onCancel={props.onCancel}
        structure={mainInfoStructureCreate}
        changeRecord={changeRecord}
        children={(form) => (
          <RecordEditor
            form={form}
            fieldsMeta={resultMetaFields}
            structure={mainInfoStructureCreate}
            changeRecord={changeRecord}
            mode="create"
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields}
          />
        )}
        mode="create"
      />
    );
  };

  const expandable = {
    onExpand: (expanded, record) => {
      if (expanded) {
        getChildren(record.uuid);
      }
    },
  };

  return (
    <ListWithAddForm
      handleColumnsChange={(clmns) => setDisplayedColumns(() => [...clmns])}
      handleTableChange={handleTableChange}
      tableConfig={{
        data: isTree ? treeData : data,
        loading,
        columns,
        total,
        displayedColumns,
        expandable,
        current: page + 1,
      }}
      additionalActions={[
        <div key={'wood'} className="ml-3 d-flex justify-content-center align-items-center">
          <p className="mb-1 mr-2">Дерево:</p>
          <Switch loading={false} checked={isTree} onChange={(checked) => setIsTree(checked)} />
        </div>,
      ]}
      applyFilter={applyFilter}
      onFilterSearch={true}
      onContextSearch={true}
      onExport={onExport}
      editCatalog={editCatalog}
      exportLoading={exportLoading}
      addModalTitle="услугу"
      fields={fields}
      contextSearchValue={search}
      FormComponent={(props) => CreateForm(props)}
      modalWidth={1200}
    />
  );
};

export default Services;
