import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {isEmpty} from 'lodash';
import {
  BellFilled,
  BellTwoTone,
  EyeFilled,
  EyeTwoTone,
  MailOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {MaskedInput} from 'antd-mask-input';
import TextArea from 'antd/es/input/TextArea';
import {Button, Collapse, Form, Input, message, Modal, notification, Popover, Select} from 'antd';

import {
  checkIsEmptyEmailTemplates,
  checkIsEmptyHttpTemplates,
  checkIsEmptyTelephone,
  checkTransportParams,
  renderEmails,
  renderEmailsToPayload,
  renderInitValuesTelephone,
  renderInitValuesTelephoneForPayload,
  renderPhoneNumber,
  renderSchedule,
  renderScheduleToPayload,
  renderTemplateToPayload,
  replaceEmailNotificationValueToPayload,
  replaceEmailPayloadToNotificationValue,
  setStylesForNotificationText,
} from '../helpers/utils';
import {
  notificationMethodOptions,
  notifications,
  notificationScheduleOptions,
} from '../../../components/NotificationPage/helpers/constant';
import {
  resetNotificationTemplateAC,
  setNotificationEmailsForTestAC,
  setNotificationPhoneNumberForTestAC,
} from '../../../redux/actions/actions';
import {requiredRule} from '../../../components/EditFieldForm/helpers/constants';
import {ReactComponent as ExclamationPoint} from '../../../assets/images/coolicon.svg';
import {addNotificationTemplate, editNotification} from '../../../redux/thunk/notificationThunk';
import NotificationPageModalTestTelephone from '../../../components/NotificationPage/NotificationPageModalTestTelephone';

import './NotificationPageTemplateForm.scss';

/**
 * Форма создания уведомления
 * @param templateTypeHttp - данные для шаблона http
 * @param templateTypeEmail - данные для шаблона email
 * @param templateTypeSms - данные для шаблона sms
 * @param status - статус для отображения формы создания шаблона/редактирования формы
 * @param notificationTemplate - данные шаблона
 * @param setNotificationModalVisible - функция изменения состояния модального окна
 * @param uuid - uuid шаблона */
const NotificationPageTemplateForm = ({
  setNotificationModalVisible,
  templateTypeHttp,
  templateTypeEmail,
  templateTypeSms,
  status,
  notificationTemplate,
  uuid,
}) => {
  /** Данные формы Sms */
  const [formSms] = Form.useForm();
  /** Данные формы Email */
  const [formEmail] = Form.useForm();
  /** Данные формы Http */
  const [formHttp] = Form.useForm();
  /** Данные общей формы для отправки запроса  */
  const [form] = Form.useForm();
  /** Отправка данных для хранилища */
  const dispatch = useDispatch();
  /** Компонент для отображения */
  const {Panel} = Collapse;
  /** Начальное состоние номера телефона */
  const [telephones, setTelephones] = useState([]);
  /** Начальное состоние  номера телефона при создании */
  const [phoneNumber, setPhoneNumber] = useState({});
  /** Начальное состоние ключа для сброса данных с шаблона sms*/
  const [clearKeySms, setClearKeySms] = useState('');
  /** Начальное состоние ключа для сброса данных с шаблона email*/
  const [clearKeyEmail, setClearKeyEmail] = useState('');
  /** Начальное состоние ключа для сброса данных с шаблона http*/
  const [clearKeyHttp, setClearKeyHttp] = useState('');
  /** Начальное состоние email */
  const [email, setEmail] = useState([]);
  /** Начальное состоние email дефолтное*/
  const [emailFirst, setEmailFirst] = useState({});
  /** Начальное состояние темы email */
  const [subjectEmail, setSubjectEmail] = useState('');
  /** Начальное состояние url Http */
  const [url, setUrl] = useState('');
  /** Начальное состояние расписания для отправки */
  const [schedule, setSchedule] = useState({
    sms: '',
    email: '',
    http: '',
  });
  /** Начальное состояние метода отправки http */
  const [methodHttp, setMethodHttp] = useState('');
  /** Начальное состояние текста уведомления  */
  const [notificationText, setNotificationText] = useState({
    sms: '',
    email: '',
    http: '',
  });
  /** Начальное состояние переключателя уведомлений */
  const [isActiveSms, setIsActiveSms] = useState(false);
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [isActiveHttp, setIsActiveHttp] = useState(false);
  /** Начальное состояние панелей шаблона */
  const [openCollapseSms, setOpenCollapseSms] = useState('');
  const [openCollapseEmail, setOpenCollapseEmail] = useState('');
  const [openCollapseHttp, setOpenCollapseHttp] = useState('');
  /** Состояние модального окна для тестирования уведомления */
  const [isVisibleModalForPhone, setIsVisibleModalForPhone] = useState(false);
  /** Состояние для подготовки данных sms */
  const [methodNotificationsSms, setMethodNotificationsSms] = useState({});
  /** Состояние для подготовки данных email */
  const [methodNotificationsEmail, setMethodNotificationsEmail] = useState({});
  /** Состояние для подготовки данных http */
  const [methodNotificationsHttp, setMethodNotificationsHttp] = useState({});
  /** Состояние ключ для тестирования уведомлений */
  const [testKey, setTestKey] = useState('');
  /** Состояние для всплывающего уведомления */
  const [api, contextHolder] = notification.useNotification();
  /** Состояние для всплывающего сообщения сохранения шаблона */
  const [messageApi, contextHolderMessage] = message.useMessage();
  /** Функция настройки всплывающего уведомления при заполнении формы*/
  const openNotificationWithIconForm = (type) => {
    api[type]({
      message: notifications.attention,
      description: notifications.attentionDescriptionForm,
    });
  };
  /** Функция для отображения сообщения при сохранении шаблона */
  const success = () => {
    messageApi
      .open({
        type: 'success',
        content: notifications.changesSave,
      })
      .then();
  };
  /** Данные для отображения информации общей формы */
  const initialValues = {
    name:
      status === 'edit' && !isEmpty(notificationTemplate)
        ? notificationTemplate.nameNotification
        : formSms.getFieldValue('name'),
  };
  /** Данные для отображения информации о шаблоне при редактировании Sms */
  const initialValuesSms = {
    telephone:
      status === 'edit' && !isEmpty(templateTypeSms)
        ? renderInitValuesTelephone(templateTypeSms)
        : formSms.getFieldValue('telephone'),
    scheduleSms:
      status === 'edit' &&
      !isEmpty(templateTypeSms) &&
      templateTypeSms?.transportType === 'SMS' &&
      !isEmpty(templateTypeSms?.transportParams)
        ? renderSchedule(templateTypeSms?.scheduleType)
        : formSms.getFieldValue('scheduleSms'),
    textSms: status === 'edit' && !isEmpty(templateTypeSms) ? templateTypeSms?.body : formSms.getFieldValue('textSms'),
  };
  /** Данные для отображения информации о шаблоне при редактировании Email */
  const initialValuesEmail = {
    emails:
      status === 'edit' && !isEmpty(templateTypeEmail?.transportParams)
        ? templateTypeEmail.transportParams?.email?.split(',')
        : formEmail.getFieldValue('emails'),
    scheduleEmail:
      status === 'edit' && templateTypeEmail?.transportType === 'EMAIL' && !isEmpty(templateTypeEmail.transportParams)
        ? renderSchedule(templateTypeEmail?.scheduleType)
        : formEmail.getFieldValue('scheduleEmail'),
    textEmail:
      status === 'edit' && templateTypeEmail?.transportType === 'EMAIL' && !isEmpty(templateTypeEmail.transportParams)
        ? replaceEmailPayloadToNotificationValue(templateTypeEmail.body)
        : formEmail.getFieldValue('textEmail'),
    subjectEmail:
      status === 'edit' && templateTypeEmail?.transportType === 'EMAIL' && !isEmpty(templateTypeEmail.transportParams)
        ? templateTypeEmail?.transportParams.topic
        : formEmail.getFieldValue('subjectEmail'),
  };
  /** Данные для отображения информации о шаблоне при редактировании Email */
  const initialValuesHttp = {
    scheduleHttp:
      status === 'edit' && templateTypeHttp?.transportType === 'HTTP' && !isEmpty(templateTypeHttp.transportParams)
        ? renderSchedule(templateTypeHttp.scheduleType)
        : formHttp.getFieldValue('scheduleHttp'),
    url:
      status === 'edit' && !isEmpty(templateTypeHttp?.transportParams?.url)
        ? templateTypeHttp?.transportParams?.url
        : formHttp.getFieldValue('url'),
    methodsHttp:
      status === 'edit' && templateTypeHttp?.transportType === 'HTTP'
        ? templateTypeHttp?.transportParams?.method
        : formHttp.getFieldValue('methodsHttp'),
    textHttp:
      status === 'edit' && templateTypeHttp?.transportType === 'HTTP'
        ? templateTypeHttp?.body
        : formHttp.getFieldValue('textHttp'),
  };
  /** Валидация для сбора данных с шаблона sms */
  const validateTemplateSms = isEmpty(
    (formSms.getFieldValue('telephone') || formSms.getFieldValue('telephoneFirst')) &&
      formSms.getFieldValue('scheduleSms') &&
      formSms.getFieldValue('textSms')
  );
  /** Валидация для сбора данных с шаблона Email */
  const validateTemplateEmail = isEmpty(
    (formEmail.getFieldValue('emails') || formEmail.getFieldValue('emailsFirst')) &&
      formEmail.getFieldValue('textEmail') &&
      formEmail.getFieldValue('scheduleEmail') &&
      formEmail.getFieldValue('subjectEmail')
  );
  /** Валидация для сбора данных с шаблона Http */
  const validateTemplateHttp = isEmpty(
    formHttp.getFieldValue('url') &&
      formHttp.getFieldValue('methodsHttp') &&
      formHttp.getFieldValue('scheduleHttp') &&
      formHttp.getFieldValue('textHttp')
  );
  /**  Загружаем данные формы для редактирования шаблона Sms */
  useEffect(() => {
    !isEmpty(templateTypeSms) && formSms.setFieldsValue(initialValuesSms);
  }, [formSms, status, templateTypeSms]);
  /**  Загружаем данные формы для редактирования шаблона  */
  useEffect(() => {
    !isEmpty(notificationTemplate) && form.setFieldsValue(initialValues);
  }, [form, notificationTemplate]);
  /**  Загружаем данные формы для редактирования шаблона Email */
  useEffect(() => {
    !isEmpty(templateTypeEmail) && formEmail.setFieldsValue(initialValuesEmail);
  }, [formEmail, templateTypeEmail]);
  /**  Загружаем данные формы для редактирования шаблона Http */
  useEffect(() => {
    !isEmpty(templateTypeHttp) && formHttp.setFieldsValue(initialValuesHttp);
  }, [formHttp, templateTypeHttp]);
  /**  Загружаем данные для редактирования шаблона Sms */
  useEffect(() => {
    status === 'edit' &&
      clearKeySms !== 'clear' &&
      !isEmpty(templateTypeSms) &&
      checkIsEmptyTelephone(
        formSms,
        status,
        schedule,
        templateTypeSms,
        telephones,
        initialValuesSms,
        setMethodNotificationsSms
      );
  }, [formSms, status, templateTypeSms, clearKeySms]);
  /**  Загружаем данные для редактирования шаблона Email */
  useEffect(() => {
    status === 'edit' &&
      clearKeySms !== 'clear' &&
      !isEmpty(templateTypeEmail) &&
      checkIsEmptyEmailTemplates(
        formEmail,
        status,
        schedule,
        initialValuesEmail,
        setMethodNotificationsEmail,
        subjectEmail,
        templateTypeEmail,
        notificationText
      );
  }, [formEmail, status, templateTypeEmail, clearKeySms]);
  /**  Загружаем данные для редактирования шаблона Http */
  useEffect(() => {
    status === 'edit' &&
      clearKeySms !== 'clear' &&
      !isEmpty(templateTypeHttp) &&
      checkIsEmptyHttpTemplates(
        formHttp,
        status,
        schedule,
        initialValuesHttp,
        notificationText,
        methodHttp,
        templateTypeHttp,
        setMethodNotificationsHttp,
        url
      );
  }, [formHttp, templateTypeHttp, status, clearKeySms]);
  /**  Функция сеттер номеров телефона при редактировании */
  useEffect(() => {
    !isEmpty(methodNotificationsSms) &&
      isEmpty(telephones) &&
      isEmpty(phoneNumber) &&
      isEmpty(clearKeySms) &&
      status === 'edit' &&
      setTelephones(renderInitValuesTelephoneForPayload(methodNotificationsSms));
  }, [methodNotificationsSms, telephones, phoneNumber, clearKeySms, status]);
  /** Функция для разворачивания панелей при редактрировании */
  useEffect(() => {
    !isEmpty(methodNotificationsSms) && setOpenCollapseSms('sms');
  }, [methodNotificationsSms]);
  useEffect(() => {
    !isEmpty(methodNotificationsEmail) && setOpenCollapseEmail('email');
  }, [methodNotificationsEmail]);
  useEffect(() => {
    !isEmpty(methodNotificationsHttp) && setOpenCollapseHttp('http');
  }, [methodNotificationsHttp]);
  /** Функция для включения шаблона при редактрировании */
  useEffect(() => {
    !isEmpty(methodNotificationsSms) && !isEmpty(status) && setIsActiveSms(true);
  }, [methodNotificationsSms, status]);
  useEffect(() => {
    !isEmpty(methodNotificationsEmail) && !isEmpty(status) && setIsActiveEmail(true);
  }, [methodNotificationsEmail, status]);
  useEffect(() => {
    !isEmpty(methodNotificationsHttp) && !isEmpty(status) && setIsActiveHttp(true);
  }, [methodNotificationsHttp, status]);
  /** Функция сеттер названия уведомления
   * @param value - название уведомления
   * */
  const handleTitleNotificationChange = (value) => {
    formSms.setFieldsValue({
      name: value,
    });
  };
  /**
   * Функция сеттер номера телефона
   * @param value - номер телефона
   * @param key - уникальный идентификатор
   * */
  const handleTelephoneNumberChange = useCallback(
    (value, key) => {
      const telephone = formSms
        .getFieldValue('telephone')
        ?.map((item, index) => ({value: item?.match(/\d/g)?.join(''), key: index}));
      const checkNumberInTelephone = telephone.find((item) => item.key === key);
      if (!isEmpty(checkNumberInTelephone)) {
        setTelephones([...telephone]);
      } else {
        setTelephones([...telephone, {value, key}]);
      }
    },
    [formSms]
  );
  /** Функция сеттер email */
  const handleEmailChange = () => {
    const fields = formEmail.getFieldsValue();
    const {email} = fields;
    formEmail.setFieldsValue({email});
    setEmail([...formEmail.getFieldValue('emails')?.map((item, index) => ({value: item, key: index}))]);
  };
  /** Функция удаления элемента */
  const removeItemTelephones = (key) => {
    const filterTelephones = telephones
      ?.filter((item) => item.key !== key && item)
      ?.map((item, index) => ({value: item.value, key: index}));
    setTelephones(filterTelephones);
  };
  /** Функция удаления элемента */
  const removeItemEmails = (key) => {
    setEmail(email.filter((item) => item.key !== key && item).map((item, index) => ({value: item.value, key: index})));
  };
  /**
   * Функция сеттер расписание отправки Sms
   * @param value - значение графика расписания
   * */
  const handleScheduleSmsChange = (value) => {
    setSchedule({...schedule, sms: renderScheduleToPayload(value)});
  };
  /**
   * Функция сеттер расписание отправки Email
   * @param value - значение графика расписания
   * */
  const handleScheduleEmailChange = (value) => {
    setSchedule({...schedule, email: renderScheduleToPayload(value)});
  };
  /** Функция сеттер расписание отправки Http
   * @param value - значение графика расписания
   * */
  const handleScheduleHttpChange = (value) => {
    setSchedule({...schedule, http: renderScheduleToPayload(value)});
  };
  /**
   * Функция сеттер url
   * @param value - значение url
   * */
  const handleUrlChange = (value) => {
    setUrl(value);
  };
  /**
   * Функция сеттер темы email
   * @param value - значение темы email */
  const handleChangeSubjectEmail = (value) => {
    setSubjectEmail(value);
    formEmail.setFieldsValue({subjectEmail: value});
  };
  /**
   *  Функция сеттер метод отправки Http
   * @param value - значение темы email */
  const handleMethodChange = (value) => {
    form.setFieldsValue({method: value});
    setMethodHttp(value);
  };
  /**
   *  Функция сеттер текст Email
   * @param value - текст email */
  const handleChangeTextEmail = (value) => {
    setNotificationText({email: value});
  };
  /**
   *  Функция сеттер текст Sms
   * @param value - текст Sms */
  const handleChangeTextSms = (value) => {
    setNotificationText({sms: value});
  };
  /** Функция изменения состояния переключателя Sms */
  const handleActiveChangeSms = () => {
    setIsActiveSms((prev) => !prev);
    const arr = [...telephones, phoneNumber];
    if (status === 'edit') {
      if (!isEmpty(templateTypeSms)) {
        methodNotificationsSms.methodUpdateUuid = templateTypeSms?.uuid;
      }
      setMethodNotificationsSms({
        body: !isEmpty(notificationText.sms) ? notificationText.sms : initialValuesSms.textSms,
        scheduleType: !isEmpty(schedule.sms) ? schedule.sms : renderScheduleToPayload(initialValuesSms.scheduleSms),
        transportType: notifications.sms,
        transportParams: checkTransportParams(
          clearKeySms,
          phoneNumber,
          telephones,
          initialValuesSms,
          status,
          templateTypeSms,
          arr
        ),
      });
    } else {
      setMethodNotificationsSms({
        body: formSms.getFieldValue('textSms'),
        scheduleType: schedule.sms,
        transportType: notifications.sms,
        transportParams:
          !isEmpty(telephones) && !isEmpty(phoneNumber)
            ? {
                ...arr.map((item) => {
                  return {phonenumber: item.value?.match(/\d/g)?.join('')};
                }),
              }
            : {
                phonenumber: phoneNumber.value?.match(/\d/g)?.join(''),
              },
      });
    }
    success();
  };
  /** Функция изменения состояния переключателя Email */
  const handleActiveChangeEmail = () => {
    setIsActiveEmail((prev) => !prev);
    if (status === 'edit') {
      if (!isEmpty(templateTypeEmail)) {
        methodNotificationsEmail.methodUpdateUuid = templateTypeEmail?.uuid;
      }
      setMethodNotificationsEmail({
        body: !isEmpty(notificationText.email) ? notificationText.email : initialValuesEmail.textEmail,
        scheduleType: !isEmpty(schedule.email)
          ? schedule.email
          : renderScheduleToPayload(initialValuesEmail.scheduleEmail),
        transportParams: {
          email: renderEmailsToPayload(formEmail),
          topic: !isEmpty(subjectEmail) ? subjectEmail : initialValuesEmail.subjectEmail,
        },
        transportType: notifications.email,
        methodUpdateUuid: templateTypeEmail?.uuid,
      });
    } else {
      setMethodNotificationsEmail({
        body: setStylesForNotificationText(
          replaceEmailNotificationValueToPayload(formEmail.getFieldValue('textEmail'))
        ),
        scheduleType: schedule.email,
        transportParams: {
          email:
            !isEmpty(formEmail.getFieldValue('emailsFirst')) && !isEmpty(formEmail.getFieldValue('emails'))
              ? [formEmail.getFieldValue('emailsFirst'), ...formEmail.getFieldValue('emails')].toString()
              : formEmail.getFieldValue('emailsFirst').toString(),
          topic: subjectEmail,
        },
        transportType: notifications.email,
      });
      success();
    }
  };
  /** Функция изменения состояния переключателя Http */
  const handleActiveChangeHttp = () => {
    setIsActiveHttp((prev) => !prev);
    if (status === 'edit') {
      if (!isEmpty(templateTypeHttp)) {
        methodNotificationsHttp.methodUpdateUuid = templateTypeHttp?.uuid;
      }
      setMethodNotificationsHttp({
        body: !isEmpty(notificationText.http) ? notificationText.http : initialValuesHttp.textHttp,
        scheduleType: !isEmpty(schedule.http) ? schedule.http : renderScheduleToPayload(initialValuesHttp.scheduleHttp),
        transportParams: {
          url: url,
          method: !isEmpty(methodHttp) ? methodHttp : initialValuesHttp.methodsHttp,
        },
        transportType: notifications.http,
        methodUpdateUuid: templateTypeHttp?.uuid,
      });
    } else {
      setMethodNotificationsHttp({
        body: notificationText.http,
        scheduleType: schedule.http,
        transportParams: {
          url: url,
          method: methodHttp,
        },
        transportType: notifications.http,
      });
    }
    success();
  };
  /** Функция очистки шаблона sms */
  const clearTemplateSmsHandler = () => {
    formSms.resetFields(['scheduleSms', 'telephoneFirst', 'telephone', 'textSms']);
    setClearKeySms('clear');
    setMethodNotificationsSms({});
    setTelephones([]);
    setPhoneNumber({});
    setIsActiveSms(false);
  };
  /** Функция очистки шаблона email */
  const clearTemplateEmailHandler = () => {
    formEmail.resetFields(['scheduleEmail', 'emails', 'emailsFirst', 'textEmail', 'subjectEmail']);
    setMethodNotificationsEmail({});
    setClearKeyEmail('clear');
    setIsActiveEmail(false);
  };
  /** Функция очистки шаблона http */
  const clearTemplateHttpHandler = () => {
    formHttp.resetFields(['scheduleHttp', 'url', 'methodsHttp', 'textHttp']);
    setMethodNotificationsHttp({});
    setClearKeyHttp('clear');
    setIsActiveHttp(false);
  };
  /** Информация для отправки  */
  let payloadData = useMemo(() => {
    return {
      retryCount: 0,
      active: true,
    };
  }, []);
  /** Формируем объект для payloadData */
  const createCurrentNotificationTemplate = () => {
    if (status === 'edit') {
      return {
        ...payloadData,
        nameNotification: form.getFieldValue('name'),
        methodNotificationsUpdate: renderTemplateToPayload(
          methodNotificationsSms,
          methodNotificationsHttp,
          methodNotificationsEmail
        ),
      };
    }
    return {
      ...payloadData,
      nameNotification: form.getFieldValue('name'),
      methodNotifications: renderTemplateToPayload(
        methodNotificationsSms,
        methodNotificationsHttp,
        methodNotificationsEmail
      ),
    };
  };
  /** Функция протестировать уведомление для Sms  */
  const testNotificationHandlerSms = (event) => {
    event.stopPropagation();
    setTestKey('telephone');
    dispatch(setNotificationPhoneNumberForTestAC(renderPhoneNumber(phoneNumber, telephones, templateTypeSms)));
    setIsVisibleModalForPhone(true);
  };
  /** Функция протестировать уведомление для Email  */
  const testNotificationHandlerEmail = (event) => {
    event.stopPropagation();
    setTestKey('emails');
    dispatch(setNotificationEmailsForTestAC(renderEmails(emailFirst, email, methodNotificationsEmail)));
    setIsVisibleModalForPhone(true);
  };
  /** Функция протестировать уведомление для Http */
  const testNotificationHandlerHttp = (event) => {
    event.stopPropagation();
    setTestKey('http');
    setIsVisibleModalForPhone(true);
  };
  /** Функция submit в случае ошибки валидации */
  const onFinishFailedForm = () => {
    if (isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
      openNotificationWithIconForm('warning');
    } else {
      createCurrentNotificationTemplate();
    }
  };
  /** Функция сеттер activeKey для шаблона sms */
  const isOpenCollapseHandlerSms = (key) => {
    setOpenCollapseSms(key);
  };
  /** Функция сеттер activeKey для шаблона email */
  const isOpenCollapseHandlerEmail = (key) => {
    setOpenCollapseEmail(key);
  };
  /** Функция сеттер activeKey для шаблона http */
  const isOpenCollapseHandlerHttp = (key) => {
    setOpenCollapseHttp(key);
  };
  /** Отправка данных формы */
  const submitHandler = () => {
    if (isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
      onFinishFailedForm();
    } else {
      status === 'edit'
        ? dispatch(editNotification(createCurrentNotificationTemplate(), uuid))
        : dispatch(addNotificationTemplate(createCurrentNotificationTemplate()));
      form.resetFields();
      formSms.resetFields();
      formEmail.resetFields();
      formHttp.resetFields();
      dispatch(resetNotificationTemplateAC({}));
      setMethodNotificationsSms({});
      setMethodNotificationsEmail({});
      setMethodNotificationsHttp({});
      setTelephones([]);
      setEmail([]);
      isOpenCollapseHandlerSms('');
      isOpenCollapseHandlerEmail('');
      isOpenCollapseHandlerHttp('');
      setPhoneNumber({});
      setClearKeySms('');
      setClearKeyEmail('');
      setIsActiveSms(false);
      setIsActiveEmail(false);
      setIsActiveHttp(false);
      setNotificationModalVisible(false);
    }
  };
  /** Очистка данных формы */
  const resetHandler = () => {
    form.resetFields();
    formSms.resetFields();
    formEmail.resetFields();
    formHttp.resetFields();
    setMethodNotificationsSms({});
    setMethodNotificationsEmail({});
    setMethodNotificationsHttp({});
    isOpenCollapseHandlerSms('');
    isOpenCollapseHandlerEmail('');
    isOpenCollapseHandlerHttp('');
    setClearKeySms('');
    setClearKeyEmail('');
    setTelephones([]);
    setEmail([]);
    setPhoneNumber({});
    setIsActiveSms(false);
    setIsActiveEmail(false);
    setIsActiveHttp(false);
    setNotificationModalVisible(false);
  };
  /** Функция отмены disabled полей шаблона Email при редактировании */
  const editMethodNotificationsEmailHandler = () => {
    setIsActiveEmail((prev) => !prev);
  };
  /** Функция отмены disabled полей шаблона Sms при редактировании */
  const editMethodNotificationSmsHandler = () => {
    setIsActiveSms((prev) => !prev);
  };
  /** Функция отмены disabled полей шаблона Http при редактировании */
  const editMethodNotificationHttpHandler = () => {
    setIsActiveHttp((prev) => !prev);
  };
  /** Отрисовка подсказки для заполнения текста уведомлений */
  const renderPopoverContent = (
    <div>
      {notifications.hintContentsTextForTextNotification?.map((content) => (
        <p key={content.index}>{content}</p>
      ))}
    </div>
  );
  return (
    <div className="notificationPageForm">
      <>
        {contextHolder}
        {contextHolderMessage}
      </>
      <h5 className="notificationPageForm__title">{notifications.templateForm}</h5>
      <Form
        layout={'vertical'}
        form={form}
        name="notification_form"
        scrollToFirstError
        onFinishFailed={onFinishFailedForm}
        onFinish={submitHandler}
      >
        <div className="notificationPageForm__block-one">
          <Form.Item label={notifications.title} name="name" rules={requiredRule}>
            <Input
              className="notificationPageForm__name-box"
              placeholder={notifications.addTitle}
              onChange={(event) => handleTitleNotificationChange(event.target.value)}
            />
          </Form.Item>
        </div>
        <div className="notificationPageForm__method-subtitle">{notifications.formMethods}</div>
        <Form layout={'vertical'} form={formSms} name="notification_form" scrollToFirstError>
          <Collapse
            collapsible="header"
            className="notificationPageForm__collapse"
            onChange={(key) => isOpenCollapseHandlerSms(key)}
            activeKey={openCollapseSms}
          >
            <Panel
              header={notifications.sms}
              extra={
                <div className="notificationPageForm__button-box">
                  <Button
                    id="sms"
                    type="link"
                    icon={!isActiveSms ? <BellFilled /> : <BellTwoTone />}
                    onClick={(event) => testNotificationHandlerSms(event)}
                    disabled={!isActiveSms && true}
                  >
                    {notifications.test}
                  </Button>
                </div>
              }
              key="sms"
            >
              <div className="notificationPageForm__block-telephone">
                <div className="notificationPageForm__block-telephone--box-add">
                  {(isEmpty(status) || clearKeySms === 'clear' || (!isEmpty(status) && isEmpty(templateTypeSms))) && (
                    <Form.Item
                      name={'telephoneFirst'}
                      label={notifications.telephone}
                      rules={[
                        {
                          required: !isEmpty(openCollapseSms) && true,
                          message: notifications.rulesMessage,
                        },
                        {
                          pattern: /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/,
                          message: notifications.patternNumber,
                        },
                      ]}
                    >
                      <MaskedInput
                        mask={'+7(000)000-00-00'}
                        type="tel"
                        className="notificationPageForm__row--input-add"
                        onChange={(value) => {
                          setPhoneNumber({value: `+7${value.unmaskedValue}`});
                        }}
                        disabled={(isEmpty(openCollapseSms) || isActiveSms) && true}
                      />
                    </Form.Item>
                  )}
                  <Form.List name="telephone">
                    {(fields, {add, remove}) => (
                      <div>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <Form.Item
                              {...field}
                              label={
                                index === 0 && status === 'edit' && isEmpty(clearKeySms) && !isEmpty(templateTypeSms)
                                  ? notifications.telephone
                                  : ''
                              }
                              key={field.key}
                            >
                              <div className="notificationPageForm__row">
                                <Form.Item
                                  {...field}
                                  rules={[
                                    {
                                      required: !isEmpty(openCollapseSms) && true,
                                      message: notifications.rulesMessage,
                                    },
                                    {
                                      pattern: /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/,
                                      message: notifications.patternNumber,
                                    },
                                  ]}
                                  key={isEmpty(templateTypeSms) ? field.key : field.key++}
                                >
                                  <MaskedInput
                                    mask={'+7(000)000-00-00'}
                                    type="tel"
                                    className="notificationPageForm__row--input-add"
                                    onChange={(value) => {
                                      handleTelephoneNumberChange(`+7${value.unmaskedValue}`, index);
                                    }}
                                    disabled={isEmpty(openCollapseSms) || isActiveSms}
                                  />
                                </Form.Item>
                                {(isEmpty(status) && index >= 0) ||
                                (!isEmpty(status) && !isEmpty(clearKeySms) && index >= 0) ||
                                (!isEmpty(status) && index >= 1) ||
                                (!isEmpty(status) && index >= 0 && isEmpty(templateTypeSms)) ? (
                                  <Button
                                    disabled={isActiveSms}
                                    className="notificationPageForm__row--minus"
                                    onClick={() => {
                                      remove(field.name);
                                      removeItemTelephones(isEmpty(templateTypeSms) ? field.name : field.name++);
                                    }}
                                    icon={<MinusCircleOutlined />}
                                  />
                                ) : null}
                              </div>
                            </Form.Item>
                          </div>
                        ))}
                        <Form.Item className="notificationPageForm__block-telephone--add">
                          <Button
                            type="link"
                            disabled={isActiveSms}
                            onClick={() => {
                              add();
                            }}
                            icon={<PlusOutlined />}
                          >
                            {notifications.addNumber}
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </div>
                <Form.Item
                  label={notifications.schedule}
                  className="notificationPageForm__block-telephone--schedule"
                  name="scheduleSms"
                  rules={[
                    {
                      required: !isEmpty(openCollapseSms) && true,
                      message: notifications.rulesMessage,
                    },
                  ]}
                >
                  <Select
                    options={notificationScheduleOptions}
                    placeholder={notifications.selectSchedule}
                    onChange={(value) => handleScheduleSmsChange(value)}
                    disabled={(isEmpty(openCollapseSms) || isActiveSms) && true}
                  />
                </Form.Item>
              </div>
              <Form.Item
                label={
                  <div className="notificationPageForm__popover">
                    <span>{notifications.text}</span>{' '}
                    <Popover content={renderPopoverContent} className="notificationPageForm__popover--exclamationPoint">
                      <ExclamationPoint />
                    </Popover>
                  </div>
                }
                name="textSms"
                className="notificationPageForm__text-notification"
                rules={[
                  {
                    required: !isEmpty(openCollapseSms) && true,
                    message: notifications.rulesMessage,
                  },
                ]}
              >
                <TextArea
                  className="notificationPageForm__textAndButtonEmail--text-notification"
                  rows={4}
                  onChange={(value) => handleChangeTextSms(value.currentTarget.value)}
                  disabled={(isEmpty(openCollapseSms) || isActiveSms) && true}
                />
              </Form.Item>
              <div className="notificationPageForm__buttonForSetValue">
                <Button
                  type="danger"
                  className="notificationPageForm__buttonForSetValue--btn"
                  onClick={clearTemplateSmsHandler}
                >
                  {notifications.fresh}
                </Button>
                <Button
                  type="primary"
                  htmlType={!isActiveSms ? 'submit' : 'button'}
                  disabled={validateTemplateSms || (!isEmpty(clearKeySms) && validateTemplateSms)}
                  onClick={!isActiveSms ? handleActiveChangeSms : editMethodNotificationSmsHandler}
                >
                  {!isActiveSms ? notifications.save : notifications.edit}
                </Button>
              </div>
            </Panel>
          </Collapse>
        </Form>
        <Form name="notificationFormEmail" layout="vertical" form={formEmail} scrollToFirstError>
          <Collapse
            collapsible="header"
            className="notificationPageForm__collapse"
            activeKey={openCollapseEmail}
            onChange={(key) => isOpenCollapseHandlerEmail(key)}
          >
            <Panel
              header={notifications.email}
              extra={
                <div className="notificationPageForm__button-box">
                  <Button
                    type="link"
                    icon={!isActiveEmail ? <BellFilled /> : <BellTwoTone />}
                    onClick={(event) => testNotificationHandlerEmail(event)}
                    disabled={!isActiveEmail && true}
                  >
                    {notifications.test}
                  </Button>
                </div>
              }
              key="email"
            >
              <div className="notificationPageForm__block-telephone">
                <div className="notificationPageForm__block-telephone--box-add">
                  {(isEmpty(status) ||
                    clearKeyEmail === 'clear' ||
                    (!isEmpty(status) && isEmpty(templateTypeEmail))) && (
                    <Form.Item
                      name="emailsFirst"
                      label={notifications.emails}
                      rules={[
                        {
                          required: !isEmpty(openCollapseEmail) && true,
                          message: notifications.rulesMessage,
                        },
                        {type: 'email', message: 'Введенный email некорректный!'},
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined className="notificationPageForm__row--prefix" />}
                        placeholder={notifications.emails}
                        className="notificationPageForm__row--input-add"
                        type="email"
                        onChange={(value) => setEmailFirst({value: value.currentTarget.value})}
                        disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
                      />
                    </Form.Item>
                  )}
                  <Form.List name="emails" className="notificationPageForm__block-telephone--add">
                    {(fields, {add, remove}) => (
                      <div>
                        {fields.map((field, index) => {
                          return (
                            <>
                              <Form.Item
                                {...field}
                                key={field.key}
                                label={
                                  index === 0 &&
                                  status === 'edit' &&
                                  isEmpty(clearKeyEmail) &&
                                  !isEmpty(templateTypeEmail)
                                    ? notifications.emails
                                    : ''
                                }
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: !isEmpty(openCollapseEmail) && true,
                                  },
                                  {type: 'email', message: 'Введенный email некорректный!'},
                                ]}
                              >
                                <div className="notificationPageForm__row">
                                  <Form.Item {...field} key={field.key}>
                                    <Input
                                      prefix={<MailOutlined className="notificationPageForm__row--prefix" />}
                                      placeholder={notifications.emails}
                                      className="notificationPageForm__row--input-add"
                                      type="email"
                                      onChange={(value) => {
                                        handleEmailChange(value.currentTarget.value);
                                      }}
                                      disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
                                    />
                                  </Form.Item>
                                  {(isEmpty(status) && index >= 0) ||
                                  (!isEmpty(status) && !isEmpty(clearKeyEmail) && index >= 0) ||
                                  (!isEmpty(status) && index >= 1) ||
                                  (!isEmpty(status) && isEmpty(templateTypeEmail)) ||
                                  (!isEmpty(status) && index >= 0 && isEmpty(templateTypeEmail)) ? (
                                    <Button
                                      disabled={isActiveEmail}
                                      className="notificationPageForm__row--minus"
                                      onClick={() => {
                                        remove(field.name);
                                        removeItemEmails(isEmpty(templateTypeEmail) ? field.name : field.name++);
                                      }}
                                      icon={<MinusCircleOutlined />}
                                    />
                                  ) : null}
                                </div>
                              </Form.Item>
                            </>
                          );
                        })}
                        <Form.Item className="notificationPageForm__block-telephone--add">
                          <Button
                            type="link"
                            disabled={isActiveEmail}
                            onClick={() => {
                              add();
                            }}
                            icon={<PlusOutlined />}
                          >
                            {notifications.addEmail}
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </div>
                <Form.Item
                  label={notifications.schedule}
                  className="notificationPageForm__block-telephone--schedule"
                  name="scheduleEmail"
                  rules={[
                    {
                      required: !isEmpty(openCollapseEmail) && true,
                      message: notifications.rulesMessage,
                    },
                  ]}
                >
                  <Select
                    options={notificationScheduleOptions}
                    placeholder={notifications.selectSchedule}
                    onChange={(value) => handleScheduleEmailChange(value)}
                    disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
                  />
                </Form.Item>
              </div>

              <Form.Item
                label={notifications.subject}
                className="notificationPageForm__subjectEmail"
                rules={[
                  {
                    required: !isEmpty(openCollapseEmail) && true,
                    message: notifications.rulesMessage,
                  },
                ]}
                name="subjectEmail"
              >
                <Input
                  type="string"
                  onChange={(event) => handleChangeSubjectEmail(event.target.value)}
                  disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div className="notificationPageForm__popover">
                    <span>{notifications.text}</span>{' '}
                    <Popover content={renderPopoverContent} className="notificationPageForm__popover--exclamationPoint">
                      <ExclamationPoint />
                    </Popover>
                  </div>
                }
                name="textEmail"
                rules={[
                  {
                    required: !isEmpty(openCollapseEmail) && true,
                    message: notifications.rulesMessage,
                  },
                ]}
              >
                <TextArea
                  className="notificationPageForm__textAndButtonEmail--text-notification"
                  rows={4}
                  onChange={(event) => {
                    handleChangeTextEmail(event.target.value);
                  }}
                  disabled={(isEmpty(openCollapseEmail) || isActiveEmail) && true}
                />
              </Form.Item>
              <div className="notificationPageForm__buttonForSetValue">
                <Button
                  type="danger"
                  className="notificationPageForm__buttonForSetValue--btn"
                  onClick={clearTemplateEmailHandler}
                >
                  {notifications.fresh}
                </Button>
                <Button
                  className="notificationPageForm__buttonForSetValue--btn"
                  icon={isEmpty(formEmail.getFieldValue('textEmail')) ? <EyeFilled /> : <EyeTwoTone />}
                  hidden={isEmpty(formEmail.getFieldValue('textEmail'))}
                  onClick={() => {
                    setIsVisibleModalForPhone(true);
                    setTestKey('view');
                  }}
                >
                  {notifications.preview}
                </Button>
                <Button
                  type="primary"
                  htmlType={!isActiveEmail ? 'submit' : 'button'}
                  disabled={validateTemplateEmail || (!isEmpty(clearKeyEmail) && validateTemplateEmail)}
                  onClick={!isActiveEmail ? handleActiveChangeEmail : editMethodNotificationsEmailHandler}
                >
                  {!isActiveEmail ? notifications.save : notifications.edit}
                </Button>
              </div>
            </Panel>
          </Collapse>
        </Form>
        <Form name="notificationFormHttp" layout="vertical" form={formHttp} scrollToFirstError>
          <Collapse
            collapsible="header"
            className="notificationPageForm__collapse"
            onChange={(key) => isOpenCollapseHandlerHttp(key)}
            activeKey={openCollapseHttp}
          >
            <Panel
              header={notifications.http}
              extra={
                <div className="notificationPageForm__button-box">
                  <Button
                    type="link"
                    icon={!isActiveHttp ? <BellFilled /> : <BellTwoTone />}
                    onClick={(event) => testNotificationHandlerHttp(event)}
                    disabled={!isActiveHttp && true}
                  >
                    {notifications.test}
                  </Button>
                </div>
              }
              key="http"
            >
              <div className="notificationPageForm__block-telephone">
                <Form.Item
                  label={notifications.methodsHttp}
                  name="methodsHttp"
                  className="notificationPageForm__block-telephone--method"
                  rules={[
                    {
                      required: !isEmpty(openCollapseHttp) && true,
                      message: notifications.rulesMessage,
                    },
                  ]}
                >
                  <Select
                    options={notificationMethodOptions}
                    placeholder={notifications.selectMethod}
                    onChange={(value) => handleMethodChange(value)}
                    disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true}
                  />
                </Form.Item>
                <Form.Item
                  label={notifications.schedule}
                  className="notificationPageForm__block-telephone--schedule"
                  name="scheduleHttp"
                  rules={[
                    {
                      required: !isEmpty(openCollapseHttp) && true,
                      message: notifications.rulesMessage,
                    },
                  ]}
                >
                  <Select
                    options={notificationScheduleOptions}
                    placeholder={notifications.selectSchedule}
                    onChange={(value) => handleScheduleHttpChange(value)}
                    disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true}
                  />
                </Form.Item>
              </div>
              <Form.Item
                label={notifications.url}
                className="notificationPageForm__block-telephone--url"
                name="url"
                rules={[
                  {
                    required: !isEmpty(openCollapseHttp) && true,
                    message: notifications.rulesMessage,
                  },
                ]}
              >
                <Input
                  onChange={(value) => {
                    handleUrlChange(value.currentTarget.value);
                  }}
                  disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div className="notificationPageForm__popover">
                    <span>{notifications.text}</span>{' '}
                    <Popover content={renderPopoverContent} className="notificationPageForm__popover--exclamationPoint">
                      <ExclamationPoint />
                    </Popover>
                  </div>
                }
                name="textHttp"
                className="notificationPageForm__text-notification"
                rules={[
                  {
                    required: !isEmpty(openCollapseHttp) && true,
                    message: notifications.rulesMessage,
                  },
                ]}
              >
                <TextArea
                  className="notificationPageForm__textAndButtonEmail--text-notification"
                  rows={4}
                  onChange={(event) => setNotificationText({http: event.target.value})}
                  disabled={(isEmpty(openCollapseHttp) || isActiveHttp) && true}
                />
              </Form.Item>
              <div className="notificationPageForm__buttonForSetValue">
                <Button
                  type="danger"
                  className="notificationPageForm__buttonForSetValue--btn"
                  onClick={clearTemplateHttpHandler}
                >
                  {notifications.fresh}
                </Button>
                <Button
                  type="primary"
                  htmlType={!isActiveHttp ? 'submit' : 'button'}
                  disabled={validateTemplateHttp || (!isEmpty(clearKeyHttp) && validateTemplateHttp)}
                  onClick={!isActiveHttp ? handleActiveChangeHttp : editMethodNotificationHttpHandler}
                >
                  {!isActiveHttp ? notifications.save : notifications.edit}
                </Button>
              </div>
            </Panel>
          </Collapse>
        </Form>
        <div className="notificationPageForm__buttonForSetValue">
          <Button
            type="danger"
            htmlType="button"
            className="notificationPageForm__buttonForSetValue--btn"
            onClick={resetHandler}
          >
            {notifications.cancel}
          </Button>
          <Button type="primary" className="notificationPageForm__buttonForSetValue--btn" htmlType="submit">
            {notifications.addTemplate}
          </Button>
        </div>
      </Form>
      <Modal open={isVisibleModalForPhone} width={500} footer={null} closable={false}>
        <NotificationPageModalTestTelephone
          textEmail={formEmail.getFieldValue('textEmail')}
          testKey={testKey}
          templateTypeSms={templateTypeSms}
          templateTypeEmail={templateTypeEmail}
          templateTypeHttp={templateTypeHttp}
          notificationText={notificationText}
          methodNotificationsSms={!isEmpty(methodNotificationsSms) && methodNotificationsSms}
          methodNotificationsEmail={!isEmpty(methodNotificationsEmail) && methodNotificationsEmail}
          methodNotificationsHttp={!isEmpty(methodNotificationsHttp) && methodNotificationsHttp}
          schedule={schedule}
          setIsVisibleModalForPhone={setIsVisibleModalForPhone}
        />
      </Modal>
    </div>
  );
};
export default React.memo(NotificationPageTemplateForm);
