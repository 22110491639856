import dayjs from 'dayjs';
import {isEmpty} from 'lodash';

export const deduplicationJobsFilter = (deduplicationData, filter) => {
  let listTmp = deduplicationData;
  let start = filter.launchDate && dayjs(filter.launchDate[0]).format('DD.MM.YYYY');
  let end = filter.launchDate && dayjs(filter.launchDate[1]).format('DD.MM.YYYY');

  if (
    filter.name &&
    filter.author &&
    filter.launchDate &&
    filter.status &&
    filter.catalog &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }

  if (
    filter.name &&
    isEmpty(filter.author) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.status) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name && deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1
    );
  }

  if (
    filter.author &&
    isEmpty(filter.status) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.username && deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.catalog &&
    isEmpty(filter.author) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.status) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.status &&
    isEmpty(filter.author) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData?.status?.title &&
        deduplicationData?.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1
    );
  }
  if (
    filter.launchDate &&
    isEmpty(filter.author) &&
    isEmpty(filter.status) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        (deduplicationData.launchDate || deduplicationData.dateReport) &&
        !(
          start > dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY')
        )
    );
  }
  if (
    filter.status &&
    filter.catalog &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.author) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.status &&
    filter.author &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.catalog &&
    filter.author &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.status) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.author &&
    filter.status &&
    filter.catalog &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }

  if (
    filter.launchDate &&
    filter.catalog &&
    isEmpty(filter.status) &&
    isEmpty(filter.author) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        (deduplicationData.launchDate || deduplicationData.dateReport) &&
        !(
          start > dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY')
        ) &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }

  if (
    filter.launchDate &&
    filter.author &&
    isEmpty(filter.status) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }

  if (
    filter.launchDate &&
    filter.status &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.author) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1
    );
  }

  if (
    filter.launchDate &&
    filter.catalog &&
    filter.status &&
    isEmpty(filter.author) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1
    );
  }

  if (
    filter.launchDate &&
    filter.catalog &&
    filter.author &&
    isEmpty(filter.status) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }

  if (
    filter.launchDate &&
    filter.status &&
    filter.author &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.catalog &&
    isEmpty(filter.status) &&
    isEmpty(filter.author) &&
    isEmpty(filter.launchData) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.status &&
    isEmpty(filter.author) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1
    );
  }
  if (
    filter.launchDate &&
    filter.name &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.author) &&
    isEmpty(filter.status) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        (deduplicationData.launchDate || deduplicationData.dateReport) &&
        !(
          start > dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY')
        ) &&
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1
    );
  }
  if (
    filter.author &&
    filter.name &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.status) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.catalog &&
    filter.launchDate &&
    isEmpty(filter.author) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.status)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1 &&
        (deduplicationData.launchDate || deduplicationData.dateReport) &&
        !(
          start > dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate || deduplicationData.dateReport).format('DD.MM.YYYY')
        )
    );
  }
  if (
    filter.name &&
    filter.catalog &&
    filter.status &&
    isEmpty(filter.author) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.launchDate)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.catalog &&
    filter.author &&
    isEmpty(filter.status) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.launchDate)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.status &&
    filter.author &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.launchDate)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.status &&
    filter.launchDate &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.author)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        )
    );
  }
  if (
    filter.name &&
    filter.launchDate &&
    filter.author &&
    isEmpty(filter.status) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.catalog)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.author &&
    filter.launchDate &&
    filter.status &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.author &&
    filter.launchDate &&
    filter.catalog &&
    isEmpty(filter.status) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.author &&
    filter.status &&
    filter.catalog &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.launchDate &&
    filter.status &&
    filter.catalog &&
    isEmpty(filter.author) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.name &&
    filter.launchDate &&
    filter.author &&
    filter.catalog &&
    isEmpty(filter.status) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.name &&
        deduplicationData.name.toUpperCase().indexOf(filter.name.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.status &&
    filter.launchDate &&
    filter.author &&
    filter.catalog &&
    isEmpty(filter.name) &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.userConfirmConnect)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.status.toUpperCase()) + 1 &&
        deduplicationData.launchDate &&
        !(
          start > dayjs(deduplicationData.launchDate).format('DD.MM.YYYY') ||
          end < dayjs(deduplicationData.launchDate).format('DD.MM.YYYY')
        ) &&
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.author.toUpperCase()) + 1 &&
        deduplicationData.catalogDeduplicationName &&
        deduplicationData.catalogDeduplicationName.toUpperCase().indexOf(filter.catalog.toUpperCase()) + 1
    );
  }
  if (
    filter.statusConnection &&
    isEmpty(filter.userConfirmConnect) &&
    isEmpty(filter.status) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.author) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.statusConnection.toUpperCase()) + 1
    );
  }

  if (
    filter.userConfirmConnect &&
    isEmpty(filter.statusConnection) &&
    isEmpty(filter.status) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.author) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.userConfirmConnect.toUpperCase()) + 1
    );
    console.log(listTmp, 'usern');
  }

  if (
    filter.userConfirmConnect &&
    filter.statusConnection &&
    isEmpty(filter.status) &&
    isEmpty(filter.launchDate) &&
    isEmpty(filter.author) &&
    isEmpty(filter.catalog) &&
    isEmpty(filter.name)
  ) {
    listTmp = deduplicationData.filter(
      (deduplicationData) =>
        deduplicationData.username &&
        deduplicationData.username.toUpperCase().indexOf(filter.userConfirmConnect.toUpperCase()) + 1 &&
        deduplicationData.status?.title &&
        deduplicationData.status?.title.toUpperCase().indexOf(filter.statusConnection.toUpperCase()) + 1
    );
  }

  return listTmp;
};
