import React from 'react';
import {Input, Checkbox, Button, Select, Divider, Form} from 'antd';
import {layout, CBLayout} from '../../components/Helpers/constants';
import {checkBdNameFormat} from '../CatalogItem/helpers/utils';
import {createDraftItem} from '../CatalogItem/effects/actions';

const CatalogItemAddForm = ({history}) => {
  const [form] = Form.useForm();

  const createDraft = async (values) => {
    let resultValues = {...values};
    if (values.options) resultValues.options = JSON.parse(values.options);
    else resultValues.options = null;
    const data = await createDraftItem(resultValues);
    history.push(`/catalog-draft/${data.uuid}`);
  };

  const requiredRule = {required: true, message: 'Поле обязательно для заполнения'};

  return (
    <Form
      {...layout}
      form={form}
      name="catalog-item-add"
      onFinish={createDraft}
      initialValues={{
        validateRecords: false,
        prioritySupport: false,
        inputType: 'mixed',
      }}
    >
      <h5>Создание справочника</h5>
      <Divider />
      <Form.Item label="Наименование справочника" name="catalogCaption" rules={[requiredRule]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Наименование в БД"
        name="origin"
        extra="Допустимо использовать английские буквы, цифры, нижние подчеркивания. На первом месте буква"
        rules={[requiredRule, {validator: checkBdNameFormat}]}
      >
        <Input />
      </Form.Item>
      <Form.Item {...CBLayout} name="validateRecords" valuePropName="checked">
        <Checkbox>Требуется верификация записей справочника</Checkbox>
      </Form.Item>
      <Form.Item {...CBLayout} name="prioritySupport" valuePropName="checked">
        <Checkbox>Включен механизм приоритетности источников данных</Checkbox>
      </Form.Item>
      <Form.Item label="Способ введения" name="inputType" rules={[requiredRule]}>
        <Select
          options={[
            {value: 'manual', label: 'Вручную'},
            {value: 'external', label: 'Во внешних источниках данных'},
            {value: 'mixed', label: 'В смешанном режиме'},
          ]}
        />
      </Form.Item>
      <div className="d-flex justify-content-end">
        <Button className="mr-3" type="danger" onClick={() => history.push('/catalog-draft')}>
          Отмена
        </Button>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </div>
    </Form>
  );
};

export default CatalogItemAddForm;
