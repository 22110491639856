import {ADD_VERIFY_VALUE, SET_ALL_VERIFY_VALUE} from '../actions/actionTypes';

const initialState = {
  verifyValues: {},
};

export const VerifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VERIFY_VALUE:
      return {
        ...state,
        verifyValues: {
          ...state.verifyValues,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_ALL_VERIFY_VALUE:
      return {
        ...state,
        verifyValues: action.payload,
      };
    default:
      return state;
  }
};
