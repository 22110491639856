import React, {useState} from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {Button, Upload, Form} from "antd";
import { warningModalCreate } from '../../components/Helpers/Modals';
import './CatalogItemImport.scss';

const CatalogImportForm = (props) => {
    const [form] = Form.useForm();
    const { catalogImport } = props;

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    const [fileList, setFileList] = useState([]);

    const submitForm = values => {
        const { type } = values.upload.file;
        if (type !== 'application/json') {
            warningModalCreate('Неверный тип файла', 'Импорт файлов возможен только в формате .json')
        } else {
            let reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    const catalogData = await JSON.parse(reader.result);
                    catalogImport(catalogData);
                } catch (error) {
                    console.log(error)
                }
            };
            reader.readAsText(values.upload.file);
        }
    };

    return (
        <Form
            {...layout}
            form={form}
            name="basic"
            onFinish={submitForm}
        >
            <Form.Item
                label="Файл"
                name='upload'
                rules = {
                    [
                        {
                            required: true,
                            message: 'Выберите файл для импорта'
                        }
                    ]
                }
            >
                <Upload
                    name="file"
                    listType="text"
                    multiple = {false}
                    accept = '.json'
                    beforeUpload={() => { return false }}
                    fileList = {fileList}
                    onChange={(e) => setFileList(e.fileList)}
                >
                    {fileList && !fileList.length && <Button>
                        <UploadOutlined /> Загрузить
                    </Button>}
                </Upload>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Импортировать
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CatalogImportForm;
