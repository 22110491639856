
export const MainInfoStructure = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'general', label: 'Общая информация', items: [

                    {type:'container',  name: 'general__line-1', greed: 24, items: [
                            {origin:'name', type:'item', greed: 24},
                        ]},
                    {type:'container',  name: 'general__line-2', greed: 24, items: [
                            {origin:'parentId', type:'item', greed: 24},
                        ]},
                    {type:'container',  name: 'general__line-3', greed: 24, items: [
                            {origin:'typeServiceId', type:'item', greed: 12},
                            {origin:'serviceType', type:'item', greed: 12},
                        ]},
                    {type:'container',  name: 'general__line-4', greed: 24, items: [
                            {origin:'dateBegin', type:'item', greed: 8},
                            {origin:'dateEnd', type:'item', greed: 8},
                        ]},
                    {type:'container', greed: 24,  name: 'general__line-6', items: [
                            {origin:'serviceTypesId', type:'item', greed: 8},
                            {origin:'serviceCategoryId', type:'item', greed: 8},
                            {origin:'serviceSubcategoriesId', type:'item', greed: 8},
                            // {origin:'status', type:'item', greed: 8},
                        ]},
                    {type:'container',  name: 'general__line-7', greed: 24, items: [
                            {origin:'orgId', type:'item', greed: 24},
                        ]},
                    {type:'container',  name: 'general__line-8', greed: 24, items: [
                            {origin:'productName', type:'item', greed: 24},
                        ]},
                    {type:'container',  name: 'general__line-9', greed: 24, items: [
                            {origin:'productNameTranslit', type:'item', greed: 24},
                        ]},
                    {type:'container',  name: 'general__line-10', greed: 24, items: [
                            {origin:'description', type:'item', greed: 24},
                        ]},
                    {type:'container',  name: 'general__line-11', greed: 24, items: [
                            {origin:'complexServices', type:'item', greed: 24},
                        ]},
                ],
            },
            {
                type: 'group', name: 'params', label: 'Параметры услуги', items: [
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'interfaceUrl', type:'item', greed: 24},
                            {origin:'cost', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-2', greed: 24, items: [
                            {origin:'approximateTime', type:'item', greed: 8},
                            {origin:'duration', type:'item', greed: 8},
                            {origin:'repeatPeriod', type:'item', greed: 8},
                        ]},
                    {type:'container', name: 'params__line-3', greed: 24, items: [
                            {origin:'responsibleId', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-4', greed: 24, items: [
                            {origin:'serviceMethod', type:'item', greed: 8},
                            {origin:'serviceOrder', type:'item', greed: 16},
                        ]},
                    {type:'container', name: 'params__line-5', greed: 24, items: [
                            {origin:'result', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-6', greed: 24, items: [
                            {origin:'rating', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-7', greed: 24, items: [
                            {origin:'tags', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-13', greed: 24, items: [
                            {origin:'statusForNps', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-8', greed: 24, items: [
                            {origin:'descTargetCustomer', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-9', greed: 24, items: [
                            {origin:'partnerProvided', type:'item', greed: 8},
                            {origin:'partnerRationale', type:'item', greed: 16},
                        ]},
                    {type:'container', name: 'params__line-10', greed: 24, items: [
                            {origin:'isInfoProgressService', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-11', greed: 24, items: [
                            {origin:'isPaymentsElectronic', type:'item', greed: 24},
                        ]}, 
                    {type:'container', name: 'params__line-12', greed: 24, items: [
                            {origin:'isClientSupport', type:'item', greed: 24},
                        ]},
                ],
            },
            {
                type: 'group', name: 'bpmn_processes', label: 'Параметры bpmn-процессов', items: [
                    {type:'container', name: 'bpmn_processes_line-1', greed: 24, items: [
                        {origin:'freezed', type:'item', greed: 24},
                    ]},
                    
                    {type:'container', name: 'bpmn_processes_line-2', greed: 24, items: [
                        {origin:'hidden', type:'item', greed: 24},
                    ]},
                    
                    {type:'container', name: 'bpmn_processes_line-3', greed: 24, items: [
                        {origin:'hiddenInPortal', type:'item', greed: 24},
                    ]},
                ],
            },
            {
                type: 'group', name: 'recipient', label: 'Получатель услуги', items: [
                    {
                        type: 'container', name: 'recipient__line-1', greed: 24, items: [
                            { origin: 'recipient', type: 'item', greed: 24 },
                        ]
                    },
                    {
                        type: 'container', name: 'recipient__line-1', greed: 24, items: [
                            { origin: 'maxRecipientCount', type: 'item', greed: 24 },
                        ]
                    },
                    {
                        type: 'container', name: 'recipient__line-1', greed: 24, items: [
                            { origin: 'regions', type: 'item', greed: 24 },
                        ]
                    },
                ]
            }
        ]
    }
];

export const mainInfoStructureCreate = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'general', label: 'Общая информация', items: [

                    {type:'container', name: 'general__line-1', greed: 24, items: [
                            {origin:'name', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'general__line-2', greed: 24, items: [
                            {origin:'typeServiceId', type:'item', greed: 12},
                            {origin:'serviceType', type:'item', greed: 12},
                        ]},
                    {type:'container', name: 'general__line-3', greed: 24, items: [
                            {origin:'dateBegin', type:'item', greed: 8},
                            {origin:'dateEnd', type:'item', greed: 8},
                        ]},
                    {type:'container', name: 'general__line-4', greed: 24, items: [
                            {origin:'serviceTypesId', type:'item', greed: 8},
                            {origin:'serviceCategoryId', type:'item', greed: 8},
                            {origin:'serviceSubcategoriesId', type:'item', greed: 8},
                        ]},
                    {type:'container', name: 'general__line-5', greed: 24, items: [
                            {origin:'orgId', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'general__line-6', greed: 24, items: [
                            {origin:'productName', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'general__line-7', greed: 24, items: [
                            {origin:'productNameTranslit', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'general__line-8', greed: 24, items: [
                            {origin:'description', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'general__line-9', greed: 24, items: [
                            {origin:'complexServiceId', type:'item', greed: 24},
                        ]},

                ],
            },
            {
                type: 'group', name: 'params', label: 'Параметры услуги', items: [
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'interfaceUrl', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'approximateTime', type:'item', greed: 8},
                            {origin:'duration', type:'item', greed: 8},
                            {origin:'repeatPeriod', type:'item', greed: 8},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'responsibleId', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'serviceMethod', type:'item', greed: 8},
                            {origin:'serviceOrder', type:'item', greed: 16},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'result', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'rating', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'tags', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'descTargetCustomer', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'params__line-1', greed: 24, items: [
                            {origin:'partnerProvided', type:'item', greed: 8},
                            {origin:'partnerRationale', type:'item', greed: 16},
                        ]},
                    {
                        type: 'group', name: 'recipient', label: 'Получатель услуги', items: [
                            {type:'container', name: 'recipient__line-1', greed: 24, items: [
                                    {origin:'recipient', type:'item', greed: 24},
                                ]},
                        ],
                    },
                ],
            },
        ]
    }
];

export const BaseRECStructure = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'documents', label: '', items: [
                    {type:'productDocs', name: 'documents__line-1', greed: 24, items: [
                            {origin:'retsProductDocument', type:'item', greed: 24},
                        ]},

                ],
            },
        ]
    }
];

export const TariffsStructure = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'compensation', label: 'Тарифная сетка расчета компенсаций', items: [
                    {type:'container', name: 'compensation__line-1', greed: 24, items: [
                            {origin:'tariffGrid', type:'item', greed: 24},
                        ]},
                ],
            },
            {
                type: 'group', name: 'limitations', label: 'Ограничения', items: [
                    {type:'container', name: 'limitations__line-1', greed: 24, items: [
                            {origin:'limitation', type:'item', greed: 24},
                            {origin:'accessCriteria', type:'item', greed: 24},
                        ]},
                ],
            },
        ]
    }
];

export const HistoryStructure = [
    {
        type: 'group', name: 'history', label: '', items: [
            {type:'container', name: 'history__line-1', greed: 24, items: [
                    // {origin:'reconcilingId', type:'item', greed: 24},
                ]},
        ],
    }
];

export const Specifications = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'characteristics', label: 'Характеристики услуги', items: [
                    {type:'container', name: 'characteristics__line-1', greed: 24, items: [
                        {origin:'durationStates', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'characteristics__line-1', greed: 24, items: [
                        {origin:'frequencyOfUpdating', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'characteristics__line-1', greed: 24, items: [
                        {origin:'serviceActualization', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'characteristics__line-1', greed: 24, items: [
                        {origin:'incidentMatrix', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'characteristics__line-1', greed: 24, items: [
                        {origin:'metrics', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'characteristics__line-1', greed: 24, items: [
                        {origin:'violationNotificationsSla', type:'item', greed: 24},
                    ]},
                ],
            },
        ]
    }
];

export const ProductApproval = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'approval', label: 'Согласование продукта', items: [
                    {type:'container', name: 'approval__line-1', greed: 24, items: [
                            {origin:'needPilot', type:'item', greed: 16},
                            {origin:'needModifySoftware', type:'item', greed: 24},
                            {origin:'needOpinions', type:'item', greed: 24},
                            {origin:'recProductOwnerOrg', type:'item', greed: 24},
                            {origin:'addSubdivisionsProdApproval', type:'item', greed: 24},
                        ]},
                ],
            },
        ]
    }
];
