import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {useSelector} from 'react-redux';
import {Tabs, Button} from 'antd';
import {isEmpty} from 'lodash';

import RecordRedactor from '../../components/RecordRedactor';
import Versions from '../../components/Versions';
import {SignsTable} from '../../components/SignDoc';
import {fetchFunc} from '../../Utils/security/http/mdm';

import './CatalogItemRecord.scss';

const CatalogItemRecord = () => {
  const catalogId = useSelector(({subscriptions}) => subscriptions.currentCatalog.catalogUuid);
  const {params} = useRouteMatch();
  const history = useHistory();
  const catalogUuid = params.item;
  const recordUuid = params.id;
  const [access, setAccess] = useState({});
  const [isUnverified, setIsUnverified] = useState(false);
  /** Ключ для вкладки версии */
  const [versionKey, setVersionKey] = useState('');
  /** Ключ для переключения активной панели */
  const [activeTab, setActiveTab] = useState('');
  // Восстановление версий записи
  const [isRestored, setIsRestored] = useState(false);
  useEffect(() => {
    !isEmpty(catalogUuid) && !isEmpty(recordUuid) && getCatalogRecordAccess();
  }, [catalogUuid, recordUuid]);
  /** Установление панели по дефолту */
  useEffect(() => {
    isEmpty(versionKey) && setActiveTab('1');
  }, [versionKey]);
  const getCatalogRecordAccess = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/fact/access`,
        method: 'get',
      });
      setAccess(data);
    } catch (error) {
      console.log(error);
    }
  };
  /** Функция переключения активной панели */
  const activeTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="catalogs-item-record">
      <div className="catalogs-item-record__versions container container--white">
        <div className="mb-2">
          <Button onClick={() => history.push(`/catalog/${catalogId}`)}>Назад</Button>
        </div>
        <Tabs onChange={activeTabChange} activeKey={activeTab}>
          <Tabs.TabPane tab="Общая информация" key="1">
            <RecordRedactor
              isRestored={isRestored}
              setIsUnverified={setIsUnverified}
              catalogUuid={!isEmpty(catalogUuid) && catalogUuid}
              recordUuid={!isEmpty(recordUuid) && recordUuid}
              fixedSubmitButton
              versionKey={versionKey}
              setVersionKey={setVersionKey}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Версии" key="2">
            <Versions
              isUnverified={isUnverified}
              setIsRestored={setIsRestored}
              catalogUuid={!isEmpty(catalogUuid) && catalogUuid}
              recordUuid={!isEmpty(recordUuid) && recordUuid}
              versionKey={versionKey}
              setVersionKey={setVersionKey}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Подписи" key="3">
            <SignsTable
              mainFetchProps={{
                docUuid: recordUuid,
                docTypeIdentifier: catalogUuid,
              }}
              canEdit={access.canEdit}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default React.memo(CatalogItemRecord);
