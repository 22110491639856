import React, {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import { Modal, Select } from "antd";
import { isEmpty } from 'lodash';
import { CryptoPro } from 'ruscryptojs';
import { errorModalCreate } from '../Helpers/Modals';
import { sendSignData, getSignDigest } from './effects/actions';

export const SignDocModal = ({
                     mainFetchProps,
                     showSignModal,
                     setShowSignModal,
                     filesForSign,
                     fileUuid
}) => {
    const dispatch = useDispatch();
    const [certsList, setCertsList] = useState([]);
    const [thumbprint, setThumbprint] = useState('');

    useEffect( () => {
        showSignModal && singDocumentGetCert();
    }, [showSignModal]);

    const singDocumentGetCert = () => {
        let cL = [];
        const cryptopro = new CryptoPro;

        cryptopro.init().then(function (info) {
            cryptopro.listCertificates().then(function (list) {
                list.forEach(function (cert) {
                    cL.push({ label: cert.name, value: cert.id });
                });
                setCertsList([...cL]);
            }, function (error) {
                errorModalCreate(error);
            });
        }, function (error) {
            errorModalCreate(error);
        });
    }

    const singDocument = (hash, uuid) => {
        const cryptopro = new CryptoPro;

        cryptopro.init().then(function (info) {
            cryptopro.signData(hash, thumbprint).then(function (signature) {
                dispatch(sendSignData(mainFetchProps, uuid, signature.replace(/\r?\n/g, ""), hash, fileUuid));
                setShowSignModal(false);
            }, function (error) {
                console.error(error);
            });
        }, function (error) {
            console.error(error);
        });
    }

    return (
        <Modal
            title="Выберите сертификат для подписания"
            visible={showSignModal}
            width={700}
            onOk={async () => {
                const data = await getSignDigest(mainFetchProps, fileUuid, filesForSign);
                singDocument(fileUuid ? data.hash : data.digest, data.uuid);
            }}
            okText="Подписать"
            cancelText="Отмена"
            onCancel={() => setShowSignModal(false)}
        >
            {!isEmpty(certsList) && <div>
                <Select
                    name='certForSign'
                    className='w-100'
                    options={certsList}
                    onChange={value => setThumbprint(value)}
                />
            </div>}
        </Modal>
    );
};