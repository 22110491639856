import {
    SET_DOCUMENT_SIGNS
} from './actionTypes';

const initialState = {
    docSigns: {
        content: []
    }
};

export const signReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DOCUMENT_SIGNS:
            return {
                ...state,
                docSigns: action.payload,
            };
        default:
            return state;
    }
};