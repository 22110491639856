import {
    SET_ACTIVE_MENU_ITEM,
    SET_BREADCRUMB_NAMES
} from "../actions/actionTypes";

const initialState = {
    breadcrumbNames: [],
    activeMenuItem: ''
};

export const navigateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BREADCRUMB_NAMES:
            return {
                ...state,
                breadcrumbNames: action.payload
            };
        case SET_ACTIVE_MENU_ITEM:
            return {
                ...state,
                activeMenuItem: action.payload
            };
        default:
            return state;
    }
};