import {GET_USER_NAME, SET_USER, SET_USER_ROLES} from '../actions/actionTypes';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_NAME:
      return {
        ...state,
        userName: action.payload.username,
      };
    case SET_USER:
      return {
        ...state,
        userList: action.payload,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
};
