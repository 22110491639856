import React, {useEffect, useState} from 'react';
import Records from '../../components/CatalogItemWidgets/Records';
import {useRouteMatch, useHistory} from 'react-router';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {Button} from 'antd';

const VerifyRecords = (props) => {
  const match = useRouteMatch();
  const referenceUuid = match.params && match.params.item;
  const [data, setData] = useState({});
  const history = useHistory();
  /** Проверка на наличие верификации */
  const isVerification = data.verification === true && true;
  /** Получение записей справочника */
  useEffect(() => {
    referenceUuid && getCatalog(referenceUuid);
  }, [referenceUuid]);

  const getCatalog = async (uuid) => {
    try {
      const responseData = await fetchFunc({
        url: `/api/v1/catalogs/${uuid}`,
        method: 'get',
      });
      setData(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button
        size="middle"
        className="mb-3"
        style={{cursor: 'pointer'}}
        onClick={() => history.push('/verifier-list/catalogs')}
      >
        Назад
      </Button>
      <div className="container container--white">
        <Records
          isVerification={isVerification}
          onDoubleClick={(record) => history.push(`/verifier-list/catalogs/${data.origin}/${record.uuid}`)}
          referenceOrigin={data.origin}
          referenceCaption={data.caption}
          referenceUuid={referenceUuid}
          history={props.history}
          location={props.location}
          match={match}
          isVerifier
          userRole="verificator"
        />
      </div>
    </div>
  );
};

export default VerifyRecords;
