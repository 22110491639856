import React, {useState} from 'react';
import cn from 'classnames';
import {Form, Select, Button, DatePicker} from 'antd';

import {deduplication} from '../helpers/constants';

import './DeduplicationFilters.scss';

const {RangePicker} = DatePicker;

/**
 * Фильтр дедупликации
 * @param statusDeduplication - фильтр статус задания дедупликации
 * @param authorUserName - фильтр автор задания на дедупликацию
 * @param jobTitleFilter -  фильтр наименования задания дедупликации
 * @param statusConnectionFilter - фильтр статус протокола дедупликации
 * @param userConfirmConnectFilter - фильтр пользователь,подтвердивший связь
 * @param catalogTitle - фильтр справочник
 * @param setFilter - функция сеттер фильтра
 * @param filterProtocol -ключ к фильтрам протокола дедупликации
 * @param deduplicationJobFilter -ключ к фильтрам задания дедупликации
 * @param deduplicationProtocolsFilter -ключ к фильтрам протоколов дедупликации
 */
const DeduplicationFilters = ({
  statusDeduplication,
  authorUserName,
  statusConnectionFilter,
  userConfirmConnectFilter,
  catalogTitle,
  setFilter,
  filterProtocol,
  deduplicationJobFilter,
  deduplicationProtocolsFilter,
  jobTitleFilter,
}) => {
  /** Получение данных с формы */
  const [form] = Form.useForm();
  /** Значение фильтра */
  const [filterValue, setFilterValue] = useState({
    status: null,
    author: null,
    catalog: null,
    launchDate: null,
    name: null,
    statusConnection: null,
    userConfirmConnect: null,
  });
  /** Изменение значений фильтра */
  const onChangeFilter = (field, value) => {
    switch (field) {
      case 'status':
        setFilterValue({...filterValue, status: `${value}`});
        break;
      case 'author':
        setFilterValue({...filterValue, author: `${value}`});
        break;
      case 'catalog':
        setFilterValue({...filterValue, catalog: `${value}`});
        break;
      case 'name':
        setFilterValue({...filterValue, name: `${value}`});
        break;
      case 'launchDate':
        setFilterValue({...filterValue, launchDate: value});
        break;
      case 'statusConnection':
        setFilterValue({...filterValue, statusConnection: value});
        break;
      case 'userConfirmConnect':
        setFilterValue({...filterValue, userConfirmConnect: value});
        break;
      default:
        break;
    }
  };
  /** Изменение значений фильтра - статус */
  const handleOnChangeStatus = (event) => onChangeFilter('status', event);
  /** Изменение значений фильтра - автор */
  const handleOnChangeAuthor = (event) => onChangeFilter('author', event);
  /** Изменение значений фильтра - справочник */
  const handleOnChangeCatalog = (event) => onChangeFilter('catalog', event);
  /** Изменение значений фильтра - Статус связи */
  const handleOnChangeStatusConnection = (event) => onChangeFilter('statusConnection', event);
  /** Изменение значений фильтра - Пользователь,подтвердивший связь */
  const handleOnChangeUserConfirmConnect = (event) => onChangeFilter('userConfirmConnect', event);
  /** Изменение значений фильтра - дата запуска */
  const handleOnChangeDate = (launchDate) => {
    let dateFrom = launchDate && launchDate[0];
    let dateTo = launchDate && launchDate[1];
    onChangeFilter('launchDate', [dateFrom, dateTo]);
  };
  /** Изменение значений фильтра - Наименование задания */
  const handleOnChangeName = (event) => onChangeFilter('name', event);
  /** Изменение значений фильтра при подтверждении  */
  const handleSubmit = () => {
    setFilter({
      ...filterValue,
      name: filterValue.name,
      author: filterValue.author,
      status: filterValue.status,
      catalog: filterValue.catalog,
      launchDate: filterValue.launchDate,
      statusConnection: filterValue.statusConnection,
      userConfirmConnect: filterValue.userConfirmConnect,
    });
  };

  return (
    <Form
      className={cn('deduplicationFilters', {' deduplicationFilters--filterProtocol': filterProtocol})}
      form={form}
      name="protocol_filter_form"
      onFinish={handleSubmit}
    >
      <Form.Item
        className={cn('deduplicationFilters__name-filter', {
          ' deduplicationFilters__protocols': filterProtocol || deduplicationProtocolsFilter,
        })}
        name="name"
        hidden={filterProtocol}
      >
        <Select
          placeholder="Наименование задания"
          filterOption={true}
          onChange={handleOnChangeName}
          value={filterValue.name}
          options={jobTitleFilter}
          showSearch
        />
      </Form.Item>
      <Form.Item
        className="deduplicationFilters__author-filter"
        name="author"
        hidden={filterProtocol || deduplicationProtocolsFilter}
      >
        <Select
          filterOption={true}
          placeholder="Автор "
          options={authorUserName}
          value={filterValue.author}
          onChange={handleOnChangeAuthor}
          showSearch
        />
      </Form.Item>
      <Form.Item className="deduplicationFilters__date-protocol-filter" name="launchDate" hidden={filterProtocol}>
        <RangePicker
          className="deduplicationFilters__date-protocol-filter"
          onChange={handleOnChangeDate}
          format="DD.MM.YYYY"
        />
      </Form.Item>
      <Form.Item
        className={cn('deduplicationFilters__catalog-filter', {
          deduplicationFilters__catalogs: filterProtocol || deduplicationProtocolsFilter,
        })}
        name="catalog"
        hidden={filterProtocol}
      >
        <Select
          onChange={handleOnChangeCatalog}
          value={filterValue.catalog}
          style={{
            width: 180,
          }}
          filterOption={true}
          placeholder="Справочник "
          options={catalogTitle}
          showSearch
        />
      </Form.Item>
      <Form.Item
        className="deduplicationFilters__status-filter"
        name="status"
        hidden={filterProtocol || deduplicationProtocolsFilter}
      >
        <Select
          onChange={handleOnChangeStatus}
          value={filterValue.status}
          filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
          placeholder="Статус "
          options={statusDeduplication}
          showSearch
        />
      </Form.Item>
      <Form.Item
        className={cn('deduplicationFilters__status-connection', {
          ' deduplicationFilters__status-connection--filterProtocol': filterProtocol,
        })}
        name="statusConnection"
        hidden={deduplicationProtocolsFilter || deduplicationJobFilter}
      >
        <Select
          onChange={handleOnChangeStatusConnection}
          value={filterValue.statusConnection}
          filterOption={true}
          placeholder="Статус связи "
          options={statusConnectionFilter}
          showSearch
        />
      </Form.Item>
      <Form.Item
        className={cn('deduplicationFilters__user-name', {
          ' deduplicationFilters--filterProtocol': filterProtocol,
        })}
        name="userConfirmConnect"
        hidden={deduplicationJobFilter || deduplicationProtocolsFilter}
      >
        <Select
          onChange={handleOnChangeUserConfirmConnect}
          value={filterValue.userConfirmConnect}
          filterOption={true}
          placeholder="Пользователь, подтвердивший связь"
          options={userConfirmConnectFilter}
          showSearch
        />
      </Form.Item>
      <div className="deduplicationFilters__button">
        <Button
          className="deduplicationFilters__button"
          type="danger"
          size="middle"
          onClick={() => {
            form.resetFields();
            setFilterValue({
              status: null,
              author: null,
              catalog: null,
              launchDate: null,
              name: null,
              statusConnection: null,
              userConfirmConnect: null,
            });
            setFilter({
              status: null,
              author: null,
              catalog: null,
              launchDate: null,
              name: null,
              statusConnection: null,
              userConfirmConnect: null,
            });
          }}
        >
          {deduplication.cancel}
        </Button>
        <Button type="primary" size="middle" htmlType="submit" className="deduplicationFilters__button-two">
          {deduplication.confirm}
        </Button>
      </div>
    </Form>
  );
};

export default React.memo(DeduplicationFilters);
