import React, {Component} from 'react';
import _ from 'lodash';

import {fetchFunc} from '../../Utils/security/http/mdm';
import AccessRightsTable from './AccessRightsTable';
import AccessRuleForm from './AccessRuleForm';
import {Modal} from 'antd';
import {BaseDiv, StyledButton, StyledModal} from '../Themes/Components';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {onError} from '../Helpers/Utils';

class AccessRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      size: 10,
      loading: false,
      modalShow: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      () => this.getAccessRights()
    );
  }

  showDeleteRuleConfirm = (ruleName, id) => {
    const {confirm} = Modal;
    const _this = this;
    confirm({
      title: `Вы действительно хотите удалить права для ${ruleName}?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        _this.deleteAccessRules(id);
      },
    });
  };

  getAccessRights = async () => {
    const {referenceOrigin, store, rulesRecordUuid} = this.props;
    const {page, size} = this.state;

    let pageParam = `?page=${page}`;
    let sizeParam = `&size=${size}`;
    let urlBody;
    switch (store) {
      case 'items':
      case 'fields':
        urlBody = `/api/v1/catalogs/${referenceOrigin}/${store}/${rulesRecordUuid}/access`;
        break;
      case 'status-matrix-action':
      case 'status-matrix':
        urlBody = `/api/v1/${store}/${rulesRecordUuid}/access`;
        break;
      default:
        urlBody = `/api/v1/catalogs/${referenceOrigin}/access`;
    }
    let url = `${urlBody}${pageParam}${sizeParam}`;
    try {
      const data = await fetchFunc(
        {
          url,
          method: 'get',
        },
        onError
      );
      this.setState({
        data: data,
        loading: false,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  updateAccessRules = (newRights, mode) => {
    const {editableUserRules} = this.state;
    if (mode === 'edit') {
      this.setState(
        (state) => {
          const {editableUserRules} = state;
          _.assign(editableUserRules, newRights);
          return {editableUserRules};
        },
        () => this.putAccessRules(editableUserRules)
      );
    } else {
      const {store, rulesRecordUuid} = this.props;
      if (store === 'fields') {
        newRights.field = {
          uuid: rulesRecordUuid,
        };
      }
      this.postAccessRules(newRights);
    }
  };

  putAccessRules = async (rules) => {
    const {referenceOrigin, rulesRecordUuid, store} = this.props;
    let url = `/api/v1/catalogs/${referenceOrigin}/access/${rules.id}`;
    if (store === 'items') url = `/api/v1/catalogs/${referenceOrigin}/${store}/${rulesRecordUuid}/access/${rules.id}`;
    else if (store === 'fields') url = `/api/v1/catalogs/${referenceOrigin}/${store}/access/${rules.id}`;
    else if (store === 'status-matrix-action' || store === 'status-matrix') url = `/api/v1/${store}/access/${rules.id}`;
    try {
      await fetchFunc(
        {
          url,
          method: 'put',
          data: rules,
        },
        onError
      );
      this.setState({loading: true, modalShow: false}, () => this.getAccessRights());
    } catch (error) {
      console.log(error.response);
    }
  };

  postAccessRules = async (rules) => {
    const {referenceOrigin, rulesRecordUuid, store} = this.props;
    let url = `/api/v1/catalogs/${referenceOrigin}/access`;
    if (store === 'items') url = `/api/v1/catalogs/${referenceOrigin}/${store}/${rulesRecordUuid}/access`;
    else if (store === 'fields') url = `/api/v1/catalogs/${referenceOrigin}/${store}/access`;
    else if (store === 'status-matrix-action' || store === 'status-matrix')
      url = `/api/v1/${store}/${rulesRecordUuid}/access`;

    try {
      await fetchFunc(
        {
          url,
          method: 'post',
          data: rules,
        },
        onError
      );
      this.setState({loading: true, modalShow: false}, () => this.getAccessRights());
    } catch (error) {
      console.log(error.response);
    }
  };

  deleteAccessRules = async (id) => {
    const {referenceOrigin, rulesRecordUuid, store} = this.props;
    let url = `/api/v1/catalogs/${referenceOrigin}/access/${id}`;
    if (store === 'items') url = `/api/v1/catalogs/${referenceOrigin}/${store}/${rulesRecordUuid}/access/${id}`;
    else if (store === 'fields') url = `/api/v1/catalogs/${referenceOrigin}/${store}/access/${id}`;
    else if (store === 'status-matrix-action' || store === 'status-matrix') url = `/api/v1/${store}/access/${id}`;

    try {
      await fetchFunc(
        {
          url,
          method: 'delete',
        },
        onError
      );
      this.setState({loading: true}, () => this.getAccessRights());
    } catch (error) {
      console.log(error.response);
    }
  };

  editRule = async (id) => {
    const {referenceOrigin, rulesRecordUuid, store} = this.props;
    let url = `/api/v1/catalogs/${referenceOrigin}/access/${id}`;
    if (store === 'items') url = `/api/v1/catalogs/${referenceOrigin}/${store}/${rulesRecordUuid}/access/${id}`;
    else if (store === 'fields') url = `/api/v1/catalogs/${referenceOrigin}/${store}/access/${id}`;
    else if (store === 'status-matrix-action' || store === 'status-matrix') url = `/api/v1/${store}/access/${id}`;

    try {
      const data = await fetchFunc(
        {
          url,
          method: 'get',
        },
        onError
      );
      this.setState({
        modalShow: true,
        editableUserRules: data,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  handlePaginationChange = (current) => {
    this.setState(
      {
        page: current - 1,
        loading: true,
      },
      () => this.getAccessRights()
    );
  };

  render() {
    const {data, loading, modalShow, editableUserRules} = this.state;
    const {store} = this.props;
    return (
      <BaseDiv>
        <StyledButton
          sName="primaryBtn"
          type="primary"
          className="mb-3"
          onClick={() =>
            this.setState({
              modalShow: true,
              editableUserRules: null,
            })
          }
        >
          Добавить
        </StyledButton>
        <AccessRightsTable
          data={data}
          handlePaginationChange={this.handlePaginationChange}
          loading={loading}
          editRule={this.editRule}
          showDeleteRuleConfirm={this.showDeleteRuleConfirm}
        />
        <StyledModal
          title={(editableUserRules ? 'Изменение' : 'Добавление') + ' объекта прав'}
          visible={modalShow}
          footer={null}
          onCancel={() => this.setState({modalShow: false})}
          destroyOnClose={true}
          width={800}
        >
          <AccessRuleForm
            store={store || 'dictionary'}
            editableUserRules={editableUserRules}
            updateAccessRules={this.updateAccessRules}
          />
        </StyledModal>
      </BaseDiv>
    );
  }
}

export default AccessRights;
