import React, {useEffect} from 'react';
import {Button, Table, Tooltip, Card} from 'antd';
import {getSignData, checkSign, deleteSign, saveSignData} from './effects/actions';
import {useDispatch, useSelector} from 'react-redux';
import {saveTextToFile} from '../Helpers/Utils';
import {DeleteOutlined, CheckCircleOutlined} from '@ant-design/icons';
import {showConfirm} from '../Helpers/Modals';
import moment from 'moment';
import {get} from 'lodash';

export const SignsTable = ({mainFetchProps, uuidFile, canEdit}) => {
  const signData = useSelector(({sign}) => sign.docSigns);
  //const canEdit = useSelector(({ document }) => get(document, 'access.canEdit', false));
  const dispatch = useDispatch();
  useEffect(() => {
    mainFetchProps?.docUuid && mainFetchProps?.docTypeIdentifier && dispatch(getSignData(mainFetchProps, uuidFile));
  }, []);

  return (
    <Card title="Электронные подписи" bordered={false}>
      <Table
        columns={[
          {
            title: 'Описание',
            dataIndex: 'signDescription',
          },
          {
            title: 'Подписант',
            dataIndex: 'authorId',
            render: (text) => text,
          },
          {
            title: 'Дата подписи',
            dataIndex: 'dateFrom',
            render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
          },
          {
            title: 'Подпись',
            dataIndex: 'signData',
            render: (text, record) => (
              <Button type="link" onClick={() => saveTextToFile(text, record.uuid, 'sig')}>
                Скачать
              </Button>
            ),
          },
          {
            title: 'Алгоритм подписи',
            dataIndex: 'signAlgorithm',
          },
          {
            title: 'Подписанные данные',
            dataIndex: 'signData',
            render: (text, record) => (
              <Button
                sName="linkBtn"
                type="link"
                onClick={() => dispatch(saveSignData(mainFetchProps, record.uuid, `signData_${record.uuid}`))}
              >
                Скачать
              </Button>
            ),
          },
          {
            title: '',
            key: 'action',
            render: (text, record) => {
              return (
                <div className="text-right">
                  <Tooltip className="mr-2" placement="top" title="Проверить">
                    <CheckCircleOutlined onClick={() => dispatch(checkSign(mainFetchProps, record.uuid, uuidFile))} />
                  </Tooltip>
                  {canEdit && (
                    <Tooltip placement="top" title="Удалить">
                      <DeleteOutlined
                        onClick={() => {
                          showConfirm({
                            title: 'Удаление подписи',
                            content: 'Вы действительно хотите удалить подпись?',
                            action: () => dispatch(deleteSign(mainFetchProps, record.uuid, uuidFile)),
                          });
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
        ]}
        dataSource={signData.content}
        rowKey={(record) => record.uuid}
      />
    </Card>
  );
};
