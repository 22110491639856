import { fetchFunc } from "../../../Utils/security/http/mdm";

export const createDraftItem = async (data) => {
    return await fetchFunc({
        url: '/api/v1/catalog-draft',
        method: 'post',
        data,
    });
};

