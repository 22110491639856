export const initialMenuItems = [
  {
    id: 1,
    route: '',
    title: 'Справочники',
    icon: 'ReadOutlined',
    items: [
      {
        id: 2,
        route: '/catalog',
        title: 'Все справочники',
        icon: null,
        items: [],
      },
    ],
  },
  {
    id: 24,
    route: '',
    title: 'Дедупликация',
    icon: 'SwapOutlined',
    items: [
      {
        id: 25,
        route: '/deduplication/protocols',
        title: 'Протоколы дедупликации',
        icon: null,
        items: [],
      },
    ],
  },
];
