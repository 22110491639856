import React, {useState, useEffect, useMemo} from 'react';
import {get, isEmpty} from 'lodash';
import RecordReader from '../../../components/RecordRedactor/RecordReader';
import RecordEditor from '../../../components/RecordRedactor/RecordEditor';
import {useHistory, useRouteMatch} from 'react-router';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import {Button, Alert} from 'antd';
import {normalizeValues} from '../../../components/Helpers/Utils';
import {useDispatch} from 'react-redux';
import {setVerify} from '../../../redux/actions/actions';
import FormInitiator from '../../../components/RecordRedactor/FormInitator';
import SystemInformation from '../../../components/SystemInformation';

const VerifyCard = () => {
  const match = useRouteMatch();
  const [fields, setFields] = useState([]);
  const [data, setData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const uuid = match.params.id;
  const referenceOrigin = match.params.item;

  useEffect(() => {
    referenceOrigin && getFields();
    uuid && referenceOrigin && getUnverifiedItem();
  }, [referenceOrigin, uuid]);

  const getFields = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/fields?size=1000`,
      });
      setFields(data.content);
    } catch (error) {}
  };

  const getUnverifiedItem = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/`,
        params: {
          showDetail: 2,
        },
      });
      setData(data);
      dispatch(setVerify(data.sysVerifyInfo.result));
    } catch (error) {}
  };

  const saveRecord = async (values) => {
    try {
      values = normalizeValues({values, fields});
      await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/`,
        method: 'put',
        data: {
          fields: values,
        },
      });
      setIsEdit(false);
      getUnverifiedItem();
    } catch (error) {}
  };

  const deleteRecord = async () => {
    try {
      await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/`,
        method: 'delete',
      });
      history.push(`/catalog/${referenceOrigin}`);
    } catch (error) {}
  };

  const updateRecord = async (updateType) => {
    try {
      await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/${uuid}/unverified/${updateType}`,
        method: 'post',
      });
      getUnverifiedItem();
    } catch (error) {
      console.log(error);
    }
  };

  const changeType = useMemo(() => get(data, 'sysVerifyInfo.changeType'), [data]);

  return (
    <div className="container container--white">
      <div className="mb-4">
        {!isEmpty(data) && (
          <SystemInformation data={data} referenceOriginLocal={referenceOrigin} recordUuid={uuid} validateCatalog />
        )}
        {get(data, 'sysVerifyInfo.remark') && (
          <Alert
            message="Замечания по результатам верификации"
            description={get(data, 'sysVerifyInfo.remark')}
            type="error"
          />
        )}
      </div>
      <div className="d-flex mb-3">
        {(get(data, 'sysVerifyInfo.status') === 'draft' || get(data, 'sysVerifyInfo.status') === 'rejected') && (
          <>
            {!isEdit ? (
              <>
                {changeType !== 'delete' && (
                  <Button type="default" onClick={() => setIsEdit(true)}>
                    Изменить
                  </Button>
                )}
                <Button type="danger" className="ml-2" onClick={deleteRecord}>
                  Удалить
                </Button>
                <Button type="primary" className="ml-auto" onClick={() => updateRecord('verify')}>
                  На верификацию
                </Button>
              </>
            ) : (
              <Button onClick={() => setIsEdit(false)}>Отмена</Button>
            )}
          </>
        )}
        {get(data, 'sysVerifyInfo.status') === 'verification' && (
          <Button type="danger" className="ml-auto" onClick={() => updateRecord('reject')}>
            Вернуть для уточнения
          </Button>
        )}
      </div>
      {isEdit ? (
        <FormInitiator
          formName={'verify-form'}
          fieldsMeta={!isEmpty(fields) && fields}
          data={!isEmpty(data) && data}
          changeRecord={saveRecord}
          children={(form) => (
            <RecordEditor
              form={form}
              isEdit={isEdit}
              fieldsMeta={!isEmpty(fields) && fields}
              data={!isEmpty(data) && data}
              mode={'edit'}
              changeType={changeType}
              referenceOrigin={referenceOrigin}
              recordUuid={uuid}
              isUnverified
            />
          )}
        />
      ) : (
        <RecordReader
          fieldsMeta={!isEmpty(fields) && fields}
          referenceOrigin={referenceOrigin}
          data={!isEmpty(data) && data}
          isUnverified
        />
      )}
    </div>
  );
};

export default VerifyCard;
