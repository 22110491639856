import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {Button, Modal, Spin} from 'antd';

import {
  getBlockLinkFullGroup,
  getCancelLinkGroup,
  getDeduplicationGroupProtocolInfo,
  getSetLinkGroup,
} from '../../../../../redux/thunk/deduplicationThunk';
import {deduplication} from '../../../helpers/constants';
import DeduplicationGroupTable from '../DeduplicationGroupTable/DeduplicationGroupTable';
import {resetDeduplicationProtocolInfoGroup} from '../../../../../redux/actions/actions';

import './DeduplicationGroupModal.scss';

/**
 * Данные   гпупп дедупликации
 * @param setIsDeduplicationModalVisible - функция для подготовки данных к отправке
 * @param groupId  - данные для отпраки
 * @param status  - статус группы
 * @param deduplicationProtocolInfo - данные по протоколу
 */
const DeduplicationGroupModal = ({setIsDeduplicationModalVisible, groupId, status, deduplicationProtocolInfo}) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Используем хранилище */
  const deduplicationGroupProtocolInfo = useSelector((state) => state?.deduplication.deduplicationGroupProtocolInfo);

  /** Начальное состояние модального окна групп дедупликации */
  const [disabledGroupsModalIsVisible, setDisabledGroupsModalIsVisible] = useState(false);
  /** Данные для отправки на изменение связи в группе */
  const [payloadData, setPayloadData] = useState(deduplication.preparePayloadData);
  /** Состояние для переключения золотой записи */
  const [isGoldRecord, setIsGoldRecord] = useState(false);
  /** Состояние золотой записи */
  const [goldRecord, setGoldRecord] = useState(null);
  /** Золотая запись подвежденная */
  const goldRecordData = !isEmpty(deduplicationGroupProtocolInfo)
    ? deduplicationGroupProtocolInfo?.mainItem?.attributes
    : null;
  /** Uuid золотой записи */
  const mainItemUuid = !isEmpty(deduplicationGroupProtocolInfo) && deduplicationGroupProtocolInfo?.mainItem?.recordUuid;

  useEffect(() => {
    isEmpty(groupId) && dispatch(getDeduplicationGroupProtocolInfo(groupId));
  }, [groupId]);
  /** Запретить  связь у всей группы */
  const blockFullGroupsLinkHandler = () => {
    dispatch(getBlockLinkFullGroup(groupId));
    setIsDeduplicationModalVisible(false);
    setPayloadData([]);
    setGoldRecord([]);
    setIsGoldRecord(false);
    setDisabledGroupsModalIsVisible(false);
  };
  /** Отменить связь у записи группы */
  const cancelLinkGroupHandler = () => {
    dispatch(getCancelLinkGroup(groupId, mainItemUuid));
    setPayloadData([]);
    setGoldRecord([]);
    setIsGoldRecord(false);
    setIsDeduplicationModalVisible(false);
  };
  /** Установить  связь в группе*/
  const setLinkGroupHandler = () => {
    dispatch(getSetLinkGroup(payloadData, groupId));
    setPayloadData([]);
    setGoldRecord([]);
    setIsGoldRecord(false);
    setIsDeduplicationModalVisible(false);
  };
  if (isEmpty(deduplicationGroupProtocolInfo)) return <Spin size="small" className="deduplicationGroupModal__spin" />;
  return (
    <div className="deduplicationGroupModal">
      <h5 className="deduplicationGroupModal__title">{deduplication.titleFormComparison}</h5>
      {!isEmpty(deduplicationGroupProtocolInfo) && (
        <DeduplicationGroupTable
          goldRecordData={goldRecordData}
          groupId={groupId}
          isGoldRecord={isGoldRecord}
          setIsGoldRecord={setIsGoldRecord}
          setPayloadData={setPayloadData}
          status={status}
          deduplicationProtocolInfo={deduplicationProtocolInfo}
          deduplicationGroupProtocolInfo={
            !isEmpty(deduplicationGroupProtocolInfo) ? deduplicationGroupProtocolInfo : null
          }
          goldRecord={goldRecord}
          setGoldRecord={setGoldRecord}
          payloadData={payloadData}
        />
      )}
      <div className="deduplicationGroupModal__button-box">
        <Button
          type="ghost"
          className="deduplicationGroupModal__button-box-text-red"
          onClick={() => setDisabledGroupsModalIsVisible(true)}
          hidden={status === 'LINK_SET' || status === 'LINK_BLOCKED'}
        >
          {deduplication.blockGroupsLink}
        </Button>
        <Button
          type={status === 'DRAFT' ? 'primary' : 'ghost'}
          onClick={status === 'DRAFT' ? setLinkGroupHandler : cancelLinkGroupHandler}
        >
          {status === 'DRAFT' ? deduplication.setLink : deduplication.cancelLink}
        </Button>
        <Button
          type="danger"
          onClick={() => {
            setIsDeduplicationModalVisible(false);
            setGoldRecord(null);
            setPayloadData([]);
            setIsGoldRecord(false);
            dispatch(resetDeduplicationProtocolInfoGroup());
          }}
        >
          {deduplication.close}
        </Button>
      </div>
      <Modal
        visible={disabledGroupsModalIsVisible}
        onCancel={() => {
          setDisabledGroupsModalIsVisible(false);
        }}
        afterClose={() => {
          setGoldRecord(null);
          setDisabledGroupsModalIsVisible(false);
        }}
        onOk={blockFullGroupsLinkHandler}
        okText={deduplication.confirmDelete}
        cancelText={deduplication.cancel}
        width={600}
        className="deduplicationProtocol__modal"
        loading={true}
        closable={false}
        maskClosable={true}
        mask={true}
      >
        {deduplication.configModalForDelete.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Modal>
    </div>
  );
};

export default React.memo(DeduplicationGroupModal);
