import {GET_HELP_INFO} from '../actions/actionTypes';

/** Получение информации о версиях продукта */
export const helpInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HELP_INFO:
      return {
        ...state,
        helpInfo: action.payload,
      };
    default:
      return state;
  }
};
