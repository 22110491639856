import React, {Component} from 'react';
import {connect} from 'react-redux';
import _, {get, isBoolean, isEmpty, isFunction, isObject} from 'lodash';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {CheckOutlined, CloseOutlined, UnlockOutlined} from '@ant-design/icons';

import {
  convertExpoToString,
  createReferenceScriptText,
  defaultDateFormat,
  getReportStructure,
  renderChangeType,
  renderStatus,
  renderVerifierFio,
  sortFieldsByOrder,
} from '../Helpers/Utils';
import RecordTable from '../RecordTable';
import AccessRights from '../AccessRights';
import RecordRedactor from '../RecordRedactor';
import CatalogsFilters from '../CatalogsFilters';
import {setFilterReset} from '../../redux/actions/actions';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {errorModalCreate} from '../Helpers/Modals';
import {BaseDiv, StyledModal, StyledTooltip} from '../Themes/Components';

const verifyColumns = [
  {
    title: 'Статус',
    key: 'status',
    dataIndex: ['sysVerifyInfo', 'status'],
    render: (text, record) => renderStatus(record, 'sysVerifyInfo.status'),
  },
  {
    title: 'Тип изменения',
    key: 'changeType',
    dataIndex: ['sysVerifyInfo', 'changeType'],
    render: (text) => renderChangeType(text),
  },
  {
    title: 'Дата начала верификации',
    key: 'dateFrom',
    dataIndex: ['sysVerifyInfo', 'dateFrom'],
    render: (text) => moment(text).format(defaultDateFormat),
  },
  {
    title: 'Верификатор',
    key: 'verifier',
    dataIndex: ['sysVerifyInfo', 'verifier'],
    render: (text, record) => renderVerifierFio('sysVerifyInfo.verifier', record),
  },
];

class Records extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filedTypeIds: [1, 2, 3, 4, 7, 8, 10, 11, 13, 14, 15],
      columns: [],
      columnSelect: [],
      data: [],
      loading: false,
      filterAttributes: [{field: '', type: '', contains: ''}],
      modalVisible: false,
      modalRulesShow: false,
      fields: [],
      newElement: {},
      comboboxColumns: [],
      dateColumns: [],
      referenceColumns: [],
      sort: {},
      filter: {},
      historyFilter: null,
      itemData: this.props.defaultData,
      isTree: true,
      isDeduplicationRecordsAll: false,
      hierarchyFields: [],
      tableParams: {
        pagination: {
          current: 0,
          pageSize: 10,
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.referenceOrigin) {
      if (!this.props.defaultData && this.props.mode !== 'create') {
        if (this.props.tableColumns) {
          this.setState({
            sort: {
              field: 'id',
              order: 'asc',
            },
          });
        }
        this.setState(
          {
            loading: true,
          },
          () => this.getCatalogItem()
        );
      } else {
        this.getTableData();
      }
      this.getCatalogFields().then();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      referenceOrigin,
      value,
      changedDetail,
      changedField,
      detail,
      resetFilter,
      isRestored,
      setResetFlagFilter,
      detailData,
      setTableColumns,
      tableColumns,
      setTableSource,
      tableSource,
      isOriginVersion,
    } = this.props;
    const {itemData, columns, convertedItemData} = this.state;

    if (columns !== prevState.columns) {
      setTableColumns && setTableColumns([...columns, {dataIndex: 'uuid'}]);
    }

    let isAllRecords = false;
    if (tableSource) {
      tableSource.forEach((record) => {
        isAllRecords = record?.length === convertedItemData?.length;
      });
    }
    const isAllowedSetTableSource =
      tableSource &&
      (!isAllRecords || !isOriginVersion) &&
      tableSource.length < 2 &&
      !isEmpty(tableColumns) &&
      convertedItemData !== prevState.convertedItemData &&
      !isEmpty(convertedItemData);

    if (isAllowedSetTableSource) {
      const itemArray = [];

      convertedItemData.forEach((item) => {
        const requiredData = {};
        tableColumns.forEach((column) => {
          const {dataIndex} = column;
          requiredData[dataIndex] = item[dataIndex];
        });

        itemArray.push(requiredData);
      });

      setTableSource((prev) => [...prev, itemArray]);
    }

    if (
      (changedDetail === detail && changedField !== prevProps?.changedField) ||
      prevProps?.isRestored !== isRestored
    ) {
      this.setState(
        {
          loading: true,
        },
        () => this.getCatalogItem(),
        this.getCatalogFields()
      );
    }
    if (prevProps?.referenceOrigin !== referenceOrigin) {
      if (!this.props.versionId && !this.props.defaultData) {
        this.getCatalogItem().then();
      }
      this.getCatalogFields().then();
    }
    if (detailData && detailData !== prevProps?.detailData) {
      this.getCatalogItem().then();
    }
    if ((prevState?.itemData !== itemData || prevState?.columns !== columns) && columns && itemData) {
      this.getTableData();
    }
    if (prevProps?.value !== value) {
      this.setState({
        itemData: value,
        totalElements: value.length,
        loading: false,
      });
    }
    if (prevProps?.resetFilter !== resetFilter && resetFilter) {
      this.applyFilter();
      setResetFlagFilter(false);
    }
  }

  setTreeDataChildren = (parents, dataContent) => {
    parents.forEach((parent) => {
      const children = this.findChildrenItem(parent.uuid, dataContent);
      if (children.length) {
        this.setTreeDataChildren(children, dataContent);
        parent.children = children;
      }
    });
  };
  prepareTableTreeData = (dataContent) => {
    const {hierarchyFields} = this.state;
    const parents = dataContent.filter(
      (item) => !!hierarchyFields.every((hierarchyFieldsItem) => !item[hierarchyFieldsItem.origin])
    );
    this.setTreeDataChildren(parents, dataContent);
    return parents;
  };
  getCatalogFields = async () => {
    try {
      const {referenceOrigin, detail, versionId} = this.props;
      let url = `/api/v1/catalogs/${referenceOrigin}/fields?size=1000`;
      if (detail) {
        url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/fields?showDetails=0&showRefs=0&type=base&size=1000`;
      }
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      const hierarchyFields = data.content.filter((elem) => elem?.options && elem?.options?.hierarchy_field);
      this.setState(
        {
          data: data.content,
          hierarchyFields,
          isHierarchyFields: hierarchyFields,
        },
        () => {
          this.convertFields();
          this.getFieldsList();
        }
      );
      if (versionId) {
        this.setState({loading: false});
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };
  getCatalogItem = async () => {
    try {
      const {
        referenceOrigin,
        detail,
        recordUuid,
        isVerifier,
        isUnverified,
        userRole,
        maxRefsDepth,
        match,
        isHierarchy,
        changedMode,
        detailData,
        versionId,
        isOriginVersion,
        changeType,
      } = this.props;
      const {sort, isTree, tableParams, filterKey} = this.state;
      let sortParam = '';
      if (sort && sort.order) {
        sortParam = `${sort.field},${sort.order}`;
      }
      let url = `/api/v1/catalogs/${referenceOrigin}/items/search/extended`;
      let recordUuidForUrl = recordUuid;
      const newUuid = match.params.id;
      if (newUuid) recordUuidForUrl = newUuid;
      if (isEmpty(recordUuid) && isEmpty(newUuid)) recordUuidForUrl = match.params.item;
      if (detail) {
        const parentUuid = recordUuid ? recordUuid : recordUuidForUrl;
        if (isUnverified) {
          url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/${parentUuid}/items/unverified/search/extended`;
        } else url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/${parentUuid}/items/search/extended`;
      }
      if (isVerifier) {
        url = `/api/v1/catalogs/${referenceOrigin}/items/unverified/extended?${
          userRole === 'verificator' ? 'verifyStatus=verification' : ''
        }`;
      }
      if (!versionId && (changeType !== 'add' || !isOriginVersion)) {
        const data = await fetchFunc({
          url,
          method: 'post',
          data: this.makeBody(),
          params: {
            // page: (isTree && isHierarchy === true) || changedMode === 'create' ? 0 : tableParams.pagination.current,
            page: (isTree && isHierarchy === true) || !isEmpty(filterKey) ? 0 : tableParams.pagination.current,
            size: isTree && isHierarchy === true ? 1000 : tableParams.pagination.pageSize,
            sort: sortParam,
            showRefs: maxRefsDepth || 1,
          },
        });

        const tableData = isTree && isHierarchy === true ? this.prepareTableTreeData(data.content) : data.content;

        this.setState({
          itemData: tableData,
          loading: false,
          tableParams: {
            pagination: {
              showSizeChanger: true,
              pageSize: isTree && isHierarchy === true ? 10 : data.size,
              total: isTree && isHierarchy === true ? tableData.length : data.totalElements,
            },
          },
        });
      } else {
        this.setState({
          itemData: detailData,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };
  findChildrenItem = (uuid, dataContent) => {
    const {hierarchyFields} = this.state;
    return dataContent.reduce((acc, item) => {
      const parentUuids = hierarchyFields.reduce((acc, hierarchyFieldsItem) => {
        const uuid = item[hierarchyFieldsItem.origin] && item[hierarchyFieldsItem.origin].uuid;
        if (uuid) acc.push(uuid);
        return acc;
      }, []);

      if (parentUuids.includes(uuid)) {
        acc.push({
          key: `${item.uuid}_${uuid}`,
          ...item,
        });
      }
      return acc;
    }, []);
  };

  makeBody = () => {
    const {search, filter, historyFilter, hierarchyFields, isTree, isDeduplicationRecordsAll} = this.state;
    const {defaultFilter, isVerifier, isUnverified, detail, recordUuid} = this.props;
    let body = {
      filter: {
        and: [],
      },
    };
    if (isDeduplicationRecordsAll !== true) {
      body = {
        filter: {
          ...body.filter,
          and: [{mainItemUuid: null}],
        },
      };
    }
    if (!isEmpty(hierarchyFields) && isTree === true) {
      return hierarchyFields.reduce((acc, item) => {
        acc[item.origin] = null;
        return acc;
      }, {});
    }
    const isUnverifiedDetail = isUnverified && detail;
    if (isVerifier || isUnverifiedDetail) body = {filter: {and: []}};
    if (filter && !isEmpty(filter)) body.filter.and.push({...filter});
    if (!isEmpty(historyFilter)) body.filter.and.push({...historyFilter});
    if (defaultFilter) body = defaultFilter;
    if (search) body.filter.and.push({'%context_filter': search});
    return body;
  };

  downloadCsv = async () => {
    try {
      const {columns, displayedColumns, sort} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({csvLoading: true});
      const {referenceOrigin} = this.props;
      let sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/report?showRefs=${this.props.maxRefsDepth || '1'}`,
        method: 'post',
        data: {
          filter: this.makeBody().filter,
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: sorter,
        },

        responseType: 'blob',
      });
      saveAs(data, `${referenceOrigin}.csv`);
      this.setState({csvLoading: false, exportValue: data});
    } catch (error) {
      this.setState({csvLoading: false});
    }
  };
  /** Загрузка Json файла */
  downloadJson = async () => {
    try {
      const {columns, displayedColumns, sort} = this.state;
      if (!displayedColumns?.length)
        return errorModalCreate(
          'Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму'
        );
      this.setState({jsonLoading: true});
      const {referenceOrigin} = this.props;
      let sorter = [];
      if (sort.order) {
        sorter.push(`${sort.field},${sort.order}`);
      }
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceOrigin}/items/report?showRefs=${this.props.maxRefsDepth || '1'}`,
        method: 'post',
        data: {
          filter: this.makeBody().filter,
          format: 'JSON',
          reportStructure: getReportStructure(columns, displayedColumns),
          sort: sorter,
        },
        responseType: 'blob',
      });
      saveAs(data, `${referenceOrigin}.json`);
      this.setState({jsonLoading: false, exportValue: data});
    } catch (error) {
      this.setState({jsonLoading: false});
    }
  };
  /** Выбор функции, в зависимости от выбранного формата */
  renderFormats = (format) => {
    switch (format) {
      case 'CSV':
        return this.downloadCsv();
      case 'JSON':
        return this.downloadJson();
      default:
        return null;
    }
  };

  convertFields = () => {
    const {data} = this.state;
    data.forEach((item) => {
      if (!item.options) {
        item.options = {
          gui_editable: true,
        };
        switch (item.fieldType.id) {
          case 1:
          case 2:
          case 3:
          case 7:
          case 8:
          case 10:
          case 11:
          case 13:
            item.options = {
              default_in_list: true,
            };
            break;
          default:
            return false;
        }
      }
    });
    let isContextSearch = this.props.hasContextSearch || data.find((item) => item?.options?.context_search === true);
    let isFilterSearch = data.find((item) => item.options?.attribute_search === true);
    this.setState({isContextSearch, isFilterSearch});
    this.getColumnsList();
  };

  getColumnsList = () => {
    const {detail, isVerification, isVerifier, editSigns, additionalColumns, viewRoute} = this.props;
    const {data} = this.state;
    let columns = data;
    let defaultColumns = this.getDefaultColumns();
    let comboboxColumns = [];
    let dateColumns = [];
    let referenceColumns = [];
    let newColumns = sortFieldsByOrder(columns)
      .map((item) => {
        let obj = {
          fieldOrder: item.fieldOrder,
          title: item.caption,
          dataIndex: item.origin,
          key: item.key || item.origin,
          sorter: true,
          className: 'column--text',
        };
        switch (item.fieldType && item.fieldType.id) {
          case 4:
            const {referenceCatalog, referenceCatalogPattern, origin, options} = item;
            referenceColumns.push(item);
            const refScript = options && options.reference_value_script;
            let pathToData = origin;
            if (!refScript) {
              const displayedField = referenceCatalogPattern ? referenceCatalogPattern.split(';')[1] : 'name';
              pathToData = [origin, displayedField];
              if (displayedField && displayedField.indexOf('.') !== -1) {
                pathToData = [origin, ...displayedField.split('.')];
              }
            }
            obj = {
              ...obj,
              dataIndex: pathToData,
              render: (text, record) => (
                <a
                  href={
                    viewRoute
                      ? `${viewRoute}/${referenceCatalog && referenceCatalog.uuid}`
                      : `/catalog/${referenceCatalog && referenceCatalog.uuid}/${record[origin] && record[origin].uuid}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {refScript
                    ? text
                      ? createReferenceScriptText(text, refScript)
                      : ''
                    : isObject(text)
                    ? JSON.stringify(text)
                    : text}
                </a>
              ),
            };
            break;
          case 7:
            obj = {
              ...obj,
              render: (text) =>
                text ? moment(text).format(get(item, 'options.date_format') || 'YYYY-MM-DD HH:mm:ss') : '',
            };
            break;
          case 8:
            obj = {
              ...obj,
              render: (text) => {
                let dateFormat = get(item, 'options.date_format') || 'YYYY-MM-DD';
                if (text) {
                  switch (dateFormat) {
                    case 'C YYYY':
                      return `${moment(text).format('YYYY')}-${moment(text).quarter()}`;
                    case 'MM YYYY':
                      return moment(text).format('MM.YYYY');
                    default:
                      return moment(text).format(dateFormat);
                  }
                } else return '';
              },
            };
            break;
          case 10:
            obj = {
              ...obj,
              render: (text) => (text ? <CheckOutlined /> : <CloseOutlined />),
            };
            break;
          case 12:
          case 19:
            obj = null;
            break;
          case 14:
            comboboxColumns.push(item);
            break;
          case 15:
            obj = {
              ...obj,
              render: (text) => {
                const comboboxValues = item?.options.combobox_options;
                if (text && typeof text === 'string') {
                  text = JSON?.parse(text)?.map((key) => {
                    const comboboxValue = comboboxValues.find((combobox) => combobox.key === key);
                    return (comboboxValue && comboboxValue.value) || '';
                  });
                } else if (Object.prototype.toString.call(text) === '[object Array]') {
                } else text = [];
                return text && <BaseDiv>{text.join(', ')}</BaseDiv>;
              },
            };
            comboboxColumns.push(item);
            break;
          case 21:
            obj = {
              ...obj,
              render: (text) => (text ? convertExpoToString(text) : ''),
            };
            break;
          default:
        }
        return obj;
      })
      .filter((item) => !_.isNull(item));

    let columnsForRender = newColumns.filter((item) => defaultColumns.some((elem) => elem.origin === item.key));

    if (!detail) {
      let otherColumns = this.getOtherColumns();
      columns = [...otherColumns];
    }
    if (editSigns && editSigns?.editProperties) {
      columnsForRender &&
        !detail &&
        !isVerifier &&
        columnsForRender.unshift({
          key: 1,
          width: '10%',
          render: (text, record) => {
            return (
              <StyledTooltip title="Настройка доступа">
                <UnlockOutlined
                  onClick={() =>
                    this.setState({
                      modalRulesShow: true,
                      rulesRecordUuid: record.uuid,
                    })
                  }
                />
              </StyledTooltip>
            );
          },
        });
    }

    if (isVerification || isVerifier) {
      columnsForRender.unshift(...verifyColumns);
      newColumns.unshift(...verifyColumns);
    }

    if (detail && additionalColumns) {
      columnsForRender.push(...additionalColumns, ...columns);
      newColumns.push(...additionalColumns);
    }
    this.setState({
      columns: newColumns,
      columnsForRender,
      comboboxColumns,
      dateColumns,
      referenceColumns,
      editSigns: editSigns?.editProperties,
    });
  };

  getDefaultColumns = () => {
    const {data} = this.state;
    const {additionalColumns, isVerification, isVerifier} = this.props;
    let defaultColumns = [];
    let displayedColumns = [];

    if (isVerification || isVerifier) {
      displayedColumns.unshift(...verifyColumns.map((item) => item.key));
    }
    data.filter((item) => {
      if (item?.options?.old !== true && item?.options?.default_in_list === true) {
        displayedColumns.push(item.origin);
        defaultColumns.push(item);
      }
      return {defaultColumns, displayedColumns};
    });

    if (additionalColumns) {
      additionalColumns.forEach((item) => {
        displayedColumns.push(item.key);
      });
    }

    this.setState({displayedColumns});
    return defaultColumns;
  };

  getOtherColumns = () => {
    const {data, filedTypeIds} = this.state;
    let otherColumns = [];
    filedTypeIds.forEach((item) => {
      let fields = data.filter((elem) => elem.fieldType.id === item);
      fields.forEach((field) => {
        if (isEmpty(field?.options?.default_in_list) || (field?.options?.old !== true && field?.fieldType !== 12)) {
          otherColumns.push(field);
        } else {
          return false;
        }
      });
    });
    return otherColumns;
  };

  newItemCallback = (item) => {
    const {comboboxColumns, dateColumns} = this.state;
    for (let k in item) {
      let comboboxField;
      let dateField;
      let option;
      if (item.hasOwnProperty(k)) {
        comboboxField = comboboxColumns.find((elem) => elem.origin === k);
        dateField = dateColumns.find((elem) => elem.origin === k);
        if (comboboxField && !_.isNull(comboboxField.options) && comboboxField.options.combobox_options) {
          if (Array.isArray(item[k])) {
            option = [];
            item[k].forEach((optionKey, index) => {
              option = comboboxField.options.combobox_options.find((el) => el.key === optionKey);
              if (option) {
                item[k][index] = option.value;
              }
            });
          } else {
            option = comboboxField.options.combobox_options.find((el) => el.key === item[k]);
            if (option) {
              item[k] = option.value;
            }
          }
        }
        if (dateField && item[k]) item[k] = moment(item[k]).format('DD.MM.YYYY');
      }
    }
    return item;
  };

  getTableData = () => {
    const {itemData} = this.state;
    let newItemData =
      itemData &&
      itemData.map((item) => {
        if (item?.children && item?.children.length) {
          item.children = item?.children.map(this.newItemCallback);
        }
        return this.newItemCallback(item);
      });
    this.setState({convertedItemData: newItemData});
  };

  applyFilter = (action) => {
    const {filterData, contextString, historyData} = this.props;
    const {tableParams} = this.state;
    this.setState(
      {
        search: contextString,
        loading: true,
        filter: filterData,
        historyFilter: historyData,
        filterKey: 'filterKey',
        tableParams: {
          pagination: {
            ...tableParams.pagination,
            current: 0,
          },
        },
      },
      () => this.getCatalogItem(),
      action && isFunction(action) && action()
    );
  };

  handleTableChange = (pagination, filter, sorter) => {
    let sort = {};
    if (sorter.field) {
      sort.field = sorter.columnKey;
      sort.order = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : '';
    }
    if (pagination.pageSize !== this.state.tableParams.pagination?.pageSize) {
      this.setState({data: []});
    }
    this.setState(
      {
        sort,
        loading: true,
        tableParams: {
          pagination: {
            current: pagination === 0 ? 0 : --pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
          },
        },
      },

      () => this.getCatalogItem()
    );
  };

  closeModal = () => {
    this.setState({modalVisible: false});
  };

  openModal = (mode, isEdit, detailUuid) => {
    this.setState({modalVisible: true, mode, isEdit, detailUuid});
  };

  getFieldsList = () => {
    const {data, newElement} = this.state;
    const newFields = [];
    data.forEach((item) => {
      let value = null;
      if (!isEmpty(item?.options) && item?.options?.old !== true) {
        return;
      }
      if (item?.fieldType?.id === 18) {
        return;
      }
      if (newElement[item.origin]) {
        value = newElement[item.origin];
      }
      newFields.push({
        caption: item.caption,
        description: item.description,
        origin: item.origin,
        required: item.required,
        referenceCatalog: item.referenceCatalog,
        referenceCatalogPattern: item.referenceCatalogPattern,
        value,
        fieldType: item.fieldType.id,
        options: item.options,
      });
    });
    this.setState({mainAttrList: newFields});
  };

  onDoubleClick = (record) => {
    const {detail, referenceOrigin, onDoubleClick, defaultData, viewRoute} = this.props;
    if (defaultData) {
      this.setState({
        defaultRecord: record,
        modalVisible: true,
      });
    } else if (detail) {
      this.openModal('edit', false, record.uuid);
    } else if (onDoubleClick) {
      return onDoubleClick(record);
    } else {
      if (viewRoute) {
        return this.props.history.push(`${viewRoute}/${record?.uuid}`);
      } else {
        return this.props.history.push(`/catalog/${referenceOrigin}/${record?.uuid}`);
      }
    }
  };

  checkIsDetailEditable = () => {
    const {editable} = this.props;
    if (isEmpty(editable)) {
      return true;
    }
    return editable;
  };
  /** Переключение состояния для отображения при иерархии в справочнике */
  handleTreeSwitchChange = (prevState) => {
    const {tableParams} = this.state;
    this.setState(
      {
        isTree: !prevState,
        tableParams: {
          pagination: {
            ...tableParams.pagination,
            current: 0,
          },
        },
      },
      () => this.getCatalogItem()
    );
  };
  /** Переключение состояние отображения при наличии дублей в справочнике */
  handleDeduplicationRecordsAllSwitchChange = (checked) => {
    const {tableParams} = this.state;
    this.setState(
      {
        isDeduplicationRecordsAll: !checked,
        tableParams: {
          pagination: {
            ...tableParams.pagination,
            current: 0,
          },
        },
      },
      () => this.getCatalogItem()
    );
  };

  render() {
    const {
      detail,
      recordUuid,
      referenceCaption,
      referenceOrigin,
      referenceUuid,
      isVerifier,
      isUnverified,
      editSigns,
      additionalMeta,
      additionalFields,
      customEditModal,
      rootValues,
      rootForm,
      defaultData,
      inputType,
      renderCustomTable,
      structure,
      hasDuplicates,
      isVerification,
      validateCatalog,
      isHierarchy,
      isOriginVersion,
      isVerificator,
      changeType,
      tableSource,
    } = this.props;

    const {
      displayedColumns,
      columnsForRender,
      columns,
      loading,
      itemData,
      convertedItemData,
      data,
      modalVisible,
      mode,
      detailUuid,
      isFilterSearch,
      isContextSearch,
      modalRulesShow,
      rulesRecordUuid,
      csvLoading,
      isTree,
      defaultRecord,
      hierarchyFields,
      referenceColumns,
      dataFilter,
      isDeduplicationRecordsAll,
      totalElementsWithMainUuid,
      isHierarchyFields,
      jsonLoading,
      tableParams,
    } = this.state;
    const editable = this.checkIsDetailEditable();

    return (
      <BaseDiv className="mt-2">
        {!detail && (
          <h3>
            <strong>{referenceCaption}</strong>
          </h3>
        )}
        {this.props.mode !== 'create' && !isBoolean(isVerificator) && !isOriginVersion && (
          <CatalogsFilters
            fields={data}
            isDeduplicationRecordsAll={isDeduplicationRecordsAll}
            referenceUuid={referenceUuid}
            referenceCaption={referenceCaption}
            referenceOrigin={referenceOrigin}
            applyFilter={this.applyFilter}
            onHistorySearch={!detail}
            onFilterSearch={true}
            onContextSearch={isContextSearch && true}
            isFilterSearch={isFilterSearch}
            isContextSearch={isContextSearch}
            isVerification={isVerification}
            isVerifier={isVerifier}
            conditionFilter={isTree && !isVerifier}
          />
        )}
        <BaseDiv className="catalogs-item__body">
          <RecordTable
            tableSource={tableSource}
            validateCatalog={validateCatalog}
            history={this.props.history}
            changeType={changeType}
            mode={this.props.mode}
            isUnverified={isUnverified}
            getCatalogItem={this.getCatalogItem}
            file={this.state.exportValue}
            recordUuid={isEmpty(recordUuid) ? this.props.match.params.id : recordUuid}
            isHierarchy={isHierarchy}
            tableParams={!isEmpty(convertedItemData) && tableParams.pagination}
            paginationDisabled={defaultData}
            displayedColumns={displayedColumns}
            loading={loading}
            columnsForRender={columnsForRender}
            totalElementsWithMainUuid={totalElementsWithMainUuid}
            dataFilter={dataFilter}
            columns={columns}
            editable={editable}
            isEdit={this.props.isEdit}
            detail={detail}
            data={convertedItemData}
            handleDeduplicationRecordsAllSwitchChange={this.handleDeduplicationRecordsAllSwitchChange}
            handleTableChange={this.handleTableChange}
            onDoubleClick={this.onDoubleClick}
            openModal={this.openModal}
            onExport={(value) => {
              this.applyFilter(this.renderFormats(value));
            }}
            handleTableColumnsChange={(columns) => this.setState(() => ({displayedColumns: [...columns]}))}
            csvLoading={csvLoading}
            jsonLoading={jsonLoading}
            isVerifer={isVerifier}
            isVerification={isVerification}
            editSigns={editSigns}
            setIsTree={this.handleTreeSwitchChange}
            isTree={isTree}
            isHierarchyFields={isHierarchyFields}
            hasDuplicates={hasDuplicates}
            hierarchyField={!!hierarchyFields.length}
            inputType={inputType}
            viewRoute={this.props.viewRoute}
            renderCustomTable={renderCustomTable}
            referenceOrigin={this.props.referenceOrigin}
            referenceColumns={referenceColumns}
            isDeduplicationRecordsAll={isDeduplicationRecordsAll}
            setIsDeduplicationRecordsAll={this.handleDeduplicationRecordsAllSwitchChange}
            mainItemUuidForGoldRecord={itemData}
            isVerifier={isVerifier}
          />
          <StyledModal
            visible={modalVisible}
            onCancel={this.closeModal}
            footer={null}
            destroyOnClose={true}
            width={1100}
          >
            <RecordRedactor
              initialValidateCatalog={!!validateCatalog}
              defaultRecord={defaultRecord}
              isEdit={this.state.isEdit}
              detailUuid={detail}
              referenceOrigin={referenceOrigin}
              recordUuid={isEmpty(recordUuid) ? this.props.match.params.id : recordUuid}
              referenceUuid={referenceUuid}
              detailFieldUuid={detailUuid}
              closeModal={this.closeModal}
              getCatalogItem={this.getCatalogItem}
              maxRefsDepth={this.props.maxRefsDepth}
              mode={mode}
              isUnverified={isUnverified}
              additionalMeta={additionalMeta}
              additionalFields={additionalFields}
              customEditModal={customEditModal}
              rootValues={rootValues}
              rootForm={rootForm}
              renderCustomTable={renderCustomTable}
              structure={structure}
            />
          </StyledModal>
          <StyledModal
            title={'Права записи'}
            visible={modalRulesShow}
            footer={null}
            onCancel={() => this.setState({modalRulesShow: false})}
            destroyOnClose={true}
            width={800}
          >
            <AccessRights
              rulesRecordUuid={rulesRecordUuid}
              store="items"
              referenceOrigin={this.props.referenceOrigin}
            />
          </StyledModal>
        </BaseDiv>
      </BaseDiv>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    changedDetail: store.detail.changedDetail,
    changedMode: store.detail.mode,
    changedField: store.detail.changedField,
    resetFilter: store.filter.filterResetFlag,
    filterData: store.filter.filterBody,
    contextString: store.filter.contextString,
    historyData: store.filter.historyBody,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setResetFlagFilter: (flag) => {
      dispatch(setFilterReset(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Records);
