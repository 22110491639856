import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Popover} from 'antd';
import {isEmpty} from 'lodash';
import Search from 'antd/es/input/Search';

import FilterAttributes from './Components/FilterAttributes/FilterAttributes';

import {catalogsFilters} from './helpers/constant';
import {StyledButton} from '../Themes/Components';
import SystemInfo from './Components/SystemInfo';
import {
  setContextSearchValue,
  setFilterErrorKeyAttribute,
  setFilterReset,
  setFilterSearchValue,
  setHistorySearchValue,
} from '../../redux/actions/actions';

import './CatalogsFilters.scss';
import FilterContext from './Components/FilterContext';
import FilterHistoryChanges from './Components/FilterHistoryChanges';

/** Фильтры справочника */
const CatalogsFilters = (props) => {
  const [formHistory] = Form.useForm();
  const [form] = Form.useForm();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Группы фильтра */
  const [groups, setGroups] = useState([]);
  /** Ключ, общего фильтра */
  const [applyKey, setApplyKey] = useState('');
  /** Ключ активной панели */
  const [activeKeyCollapse, setActiveKeyCollapse] = useState(null);
  /** Массив ошибок валидации */
  const [validateError, setValidateError] = useState([]);
  /** Ключ, для сброса всех фильтров */
  const [allResetFilterKey, setAllResetFilterKey] = useState(null);
  /** Используем хранилище */
  const filterContextSearch = useSelector((state) => state?.filter.contextString);
  const filterHistory = useSelector((state) => state?.filter.historyBody);
  const filterAttributes = useSelector((state) => state?.filter.filterBody);
  /** Валидация на сброс всех фильтров */
  const filtersValidate =
    (!isEmpty(filterAttributes) && !isEmpty(filterHistory)) ||
    (!isEmpty(filterContextSearch) && !isEmpty(filterHistory)) ||
    (!isEmpty(filterAttributes) && !isEmpty(filterContextSearch));
  const {
    onContextSearch,
    isFilterSearch,
    fields,
    onFilterSearch,
    onHistorySearch,
    onHistoryReset,
    applyFilter,
    isDeduplicationRecordsAll,
    itemData,
    catalogListState,
    referenceOrigin,
    referenceUuid,
    conditionFilter,
    isVerifier,
  } = props;
  /** Фильтруем атрибуты на наличие пропса для контекстного поиска */
  const filterAttribute =
    !isEmpty(fields) &&
    fields
      ?.filter((field) => field?.options?.context_search && field?.options?.context_search === true)
      .map((item) => item.caption)
      .slice(',')
      .join(', ');
  /** Отправка значений фильтра */
  const onApplyFilter = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        applyFilter();
        setApplyKey('key');
        dispatch(setFilterErrorKeyAttribute(false));
      })
      .catch((errorInfo) => {
        const {errorFields} = errorInfo;
        const formIndex = errorFields.map((item) => item.name.toString());
        !isEmpty(formIndex) && setValidateError([...formIndex]);
        dispatch(setFilterErrorKeyAttribute(true));
      });
  }, [activeKeyCollapse]);
  /** Очистка всех фильтров */
  const onResetAllFilters = () => {
    form.resetFields();
    formHistory.resetFields();
    setGroups([]);
    setAllResetFilterKey('reset');
    dispatch(setFilterSearchValue(null));
    dispatch(setContextSearchValue(null));
    dispatch(setHistorySearchValue(null));
    dispatch(setFilterReset(true));
  };
  return (
    <div name="catalogsFilter" className="catalogsFilters">
      {onHistorySearch && <SystemInfo referenceOrigin={referenceOrigin} referenceUuid={referenceUuid} />}
      {onContextSearch && conditionFilter && (
        <FilterContext
          applyKey={!isEmpty(applyKey) && applyKey}
          applyFilter={applyFilter}
          setApplyKey={setApplyKey}
          filterAttribute={filterAttribute}
          allResetFilter={!isEmpty(allResetFilterKey) && allResetFilterKey}
        />
      )}
      {onHistorySearch && conditionFilter && (
        <>
          {(isFilterSearch || onFilterSearch) && (
            <FilterAttributes
              groups={groups}
              setGroups={setGroups}
              setValidateError={setValidateError}
              activeKeyCollapse={activeKeyCollapse}
              setActiveKeyCollapse={setActiveKeyCollapse}
              validateError={validateError}
              incomeFields={fields}
              form={form}
              isDeduplicationRecordsAll={isDeduplicationRecordsAll}
              itemData={itemData}
            />
          )}
          <FilterHistoryChanges
            fields={fields}
            formHistory={formHistory}
            activeKeyCollapse={activeKeyCollapse}
            onFilterSearch={onHistorySearch}
            onHistoryReset={onHistoryReset}
          />
        </>
      )}
      {!isEmpty(catalogListState) && (
        <Search
          size="large"
          enterButton={true}
          placeholder={catalogsFilters.placeholderSearch}
          style={{width: 800}}
          allowClear
          onChange={(event) => {
            dispatch(setContextSearchValue(event.target.value));
          }}
          onSearch={() => {
            onApplyFilter();
          }}
          onPressEnter={() => {
            applyFilter();
            setApplyKey('key');
          }}
        />
      )}
      {!isVerifier && isEmpty(catalogListState) && conditionFilter && (onContextSearch || onHistorySearch) && (
        <div className="catalogsFilters__button">
          <StyledButton sName="primaryBtn" type="primary" onClick={onApplyFilter}>
            {catalogsFilters.search}
          </StyledButton>
          {filtersValidate && (
            <Popover content={catalogsFilters.popoverFreshFilter} className="deduplicationProtocolForm__popover">
              <Button
                className="catalogsFilters__button--end"
                sName="primaryBtn"
                type="ghost"
                onClick={onResetAllFilters}
              >
                {catalogsFilters.freshFilter}
              </Button>
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(CatalogsFilters);
