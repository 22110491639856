import React, { useMemo } from 'react'
import { get } from 'lodash'
import { Card } from 'antd';
import DocumentTable from './DocumentTable';

const ServiceDocuments = ({ removeDocFromEcmDocuments, fillEcmDocuments, ecmDocs = [], catalog, editMode, recUuid }) => {
    const document_type_links = useMemo(() => get(catalog, 'options.document_type_links'), [catalog]);

    return (
        <Card className='mb-4'>
            {document_type_links && document_type_links.map((item, index) => {
                const data = ecmDocs.filter(({ ecmDocType }) => item.origin === ecmDocType)
                return <DocumentTable
                    data={data}
                    key={index}
                    origin={item.origin}
                    caption={item.caption}
                    editMode={editMode}
                    fillEcmDocuments={fillEcmDocuments}
                    removeDocFromEcmDocuments={removeDocFromEcmDocuments}
                    total={data.length}
                    recUuid={recUuid}
                />
            })}
        </Card>
    )
}

export default ServiceDocuments
