import {isEmpty} from 'lodash';

export const notificationPageFilters = (notificationData, filter) => {
  let listTmp = notificationData;
  if (
    filter.title &&
    filter.template &&
    filter.subscription &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.captionNotification &&
        notificationData.captionNotification.toUpperCase().indexOf(filter.title.toUpperCase()) + 1 &&
        notificationData.nameTemplateNotification &&
        notificationData.nameTemplateNotification.toUpperCase().indexOf(filter.template.toUpperCase()) + 1 &&
        notificationData.catalogName &&
        notificationData.catalogName.toUpperCase().indexOf(filter.subscription.toUpperCase()) + 1
    );
  }
  if (
    filter.title &&
    isEmpty(filter.template) &&
    isEmpty(filter.subscription) &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.captionNotification &&
        notificationData.captionNotification.toUpperCase().indexOf(filter.title.toUpperCase()) + 1
    );
  }
  if (
    isEmpty(filter.title) &&
    filter.template &&
    isEmpty(filter.subscription) &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.nameTemplateNotification &&
        notificationData.nameTemplateNotification.toUpperCase().indexOf(filter.template.toUpperCase()) + 1
    );
  }
  if (
    isEmpty(filter.title) &&
    isEmpty(filter.template) &&
    filter.subscription &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.catalogName &&
        notificationData.catalogName.toUpperCase().indexOf(filter.subscription.toUpperCase()) + 1
    );
  }
  if (
    filter.title &&
    filter.template &&
    isEmpty(filter.subscription) &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.captionNotification &&
        notificationData.captionNotification.toUpperCase().indexOf(filter.title.toUpperCase()) + 1 &&
        notificationData.nameTemplateNotification &&
        notificationData.nameTemplateNotification.toUpperCase().indexOf(filter.template.toUpperCase()) + 1
    );
  }
  if (
    filter.title &&
    isEmpty(filter.template) &&
    filter.subscription &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.captionNotification &&
        notificationData.captionNotification.toUpperCase().indexOf(filter.title.toUpperCase()) + 1 &&
        notificationData.catalogName &&
        notificationData.catalogName.toUpperCase().indexOf(filter.subscription.toUpperCase()) + 1
    );
  }
  if (
    isEmpty(filter.title) &&
    filter.template &&
    filter.subscription &&
    isEmpty(filter.titleTemplate) &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.nameTemplateNotification &&
        notificationData.nameTemplateNotification.toUpperCase().indexOf(filter.template.toUpperCase()) + 1 &&
        notificationData.catalogName &&
        notificationData.catalogName.toUpperCase().indexOf(filter.subscription.toUpperCase()) + 1
    );
  }
  if (
    isEmpty(filter.title) &&
    isEmpty(filter.template) &&
    isEmpty(filter.subscription) &&
    filter.titleTemplate &&
    filter.methodTemplate
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.nameNotification &&
        notificationData.nameNotification.toUpperCase().indexOf(filter.titleTemplate.toUpperCase()) + 1 &&
        notificationData.methods &&
        notificationData.methods.join(' ,').toUpperCase().indexOf(filter.methodTemplate.toUpperCase()) + 1
    );
  }
  if (
    isEmpty(filter.title) &&
    isEmpty(filter.template) &&
    isEmpty(filter.subscription) &&
    isEmpty(filter.titleTemplate) &&
    filter.methodTemplate
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.methods &&
        notificationData.methods.join(' ,').toUpperCase().indexOf(filter.methodTemplate.toUpperCase()) + 1
    );
  }
  if (
    isEmpty(filter.title) &&
    isEmpty(filter.template) &&
    isEmpty(filter.subscription) &&
    filter.titleTemplate &&
    isEmpty(filter.methodTemplate)
  ) {
    listTmp = notificationData.filter(
      (notificationData) =>
        notificationData.nameNotification &&
        notificationData.nameNotification.toUpperCase().indexOf(filter.titleTemplate.toUpperCase()) + 1
    );
  }

  return listTmp;
};
