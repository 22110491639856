import React, { Component } from 'react';
import './NotFoundPage.scss';

class NotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.history.push('/error/404');
    }

    render() {
        return (
            <div className={'not-found-page'}>
                <h1>404</h1>
            </div>
        )
    }
}

export default NotFoundPage;
