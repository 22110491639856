
export const structureOrgMainResident = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'general', label: '', items: [
                    {type:'container', name: 'general__line-1', greed: 24, items: [
                        {origin:'recType', type:'item', greed: 16},
                        {origin:'nonResidentRf', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'general__line-2', greed: 24, items: [
                        {origin:'ogrn', type:'item', greed: 8},
                        {origin:'inn', type:'item', greed: 8},
                        {origin:'kpp', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'general__line-3', greed: 24, items: [
                        {origin:'description', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'general__line-4', greed: 24, items: [
                        {origin:'caption', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'general__line-5', greed: 24, items: [
                        {origin:'orgform', type:'item', greed: 20},
                        {origin:'okopf', type:'item', greed: 4},
                    ]},
                    {type:'container', name: 'general__line-6', greed: 24, items: [
                        {origin:'extAddressesLegal', type:'item', greed: 24},
                    ]},

                ],
            },
            {
                type: 'group', name: 'statuses', label: 'Особые статусы или формы контрагента', items: [
                    {type:'container', name: 'statuses__line-1', greed: 24, items: [
                        {origin:'stateAgency', type:'item', greed: 8},
                        {origin:'exportCreditAgency', type:'item', greed: 8},
                        {origin:'ensurer', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'statuses__line-2', greed: 24, items: [
                       {origin:'broker', type:'item', greed: 8},
                       {origin:'bank', type:'item', greed: 8},
                       {origin:'financialInstitute', type:'item', greed: 8},
                    ]},
                ],
            },
            {
                type: 'group', name: 'relations', label: 'Отношения с группой РЭЦ', items: [
                    {origin:'relationStatus', type:'item'},
                    {origin:'blockReason', type:'item'},
                    {origin:'clientManager', type:'item'},
                    {origin:'bankClient', type:'item'},
                    {origin:'partnerOrg', type:'item'},
                    {origin:'notifyClientManager', type:'item'},
                ],
            },
            {
                type: 'group', name: 'other_names', label: 'Прочие наименования', items: [
                    {origin:'descriptionEn', type:'item'},
                    {origin:'captionEn', type:'item'},
                    {origin:'altCaptions', type:'item'},
                ],
            },
            {
                type: 'group', name: 'termination_activity', label: 'Прекращение деятельности', items: [
                    {origin:'activityStopped', type:'item'},
                    {origin:'terminationReason', type:'item'},
                    {origin:'terminationDate', type:'item'},
                ],
            },
            {
                type: 'group', name: 'org_size', label: 'Размер организации', items: [
                    {type:'container', name: 'org_size__line-1', greed: 24, items: [
                        {origin:'companySize', type:'item', greed: 8},
                        {origin:'employeeNumber', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'org_size__line-2', greed: 24, items: [
                        {origin:'inMspRegistry', type:'item', greed: 8},
                    ]},
                    {origin:'mspStayPeriod', type:'item'},
                ],
            },
            {
                type: 'group', name: 'org_info', label: 'Прочие сведения', items: [
                    {type:'container', name: 'org_info__line-1', greed: 24, items: [
                        {origin:'registerDate', type:'item', greed: 8},
                        {origin:'egrulDate', type:'item', greed: 8},
                        {origin:'innDate', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'org_info__line-2', greed: 24, items: [
                        {origin:'regAuthority', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'org_info__line-3', greed: 24, items: [
                        {origin:'okpo', type:'item', greed: 8},
                    ]},
                ],
            },
        ]
    }
];

export const structureOrgContactsResident = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'general', label: 'Основная контактная информация', items: [
                    {type:'container', name: 'general__line-1', greed: 24, items: [
                            {origin:'email', type:'item', greed: 8},
                            {origin:'phone', type:'item', greed: 8},
                        ]},
                    {type:'container', name: 'general__line-2', greed: 24, items: [
                            {origin:'url', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'general__line-3', greed: 24, items: [
                            {origin:'urlEng', type:'item', greed: 24},
                        ]},
                ],
            },
            {
                type: 'group', name: 'addresses', label: 'Адреса', items: [
                    {type:'container', name: 'addresses__line-1', greed: 24, items: [
                            {origin:'extAddressesLegal', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'addresses__line-2', greed: 24, items: [
                            {origin:'extAddressesPost', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'addresses__line-3', greed: 24, items: [
                            {origin:'extAddressesFact', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'addresses__line-4', greed: 24, items: [
                            {origin:'extAddresses', type:'item', greed: 24},
                        ]},
                ],
            },
            {
                type: 'group', name: 'contacts', label: 'Контактные лица', items: [
                    {type:'container', name: 'contacts__line-1', greed: 24, items: [
                            {origin:'contactPersonsBoss', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'contacts__line-2', greed: 24, items: [
                            {origin:'contactPersonsMain', type:'item', greed: 24},
                        ]},
                    {type:'container', name: 'contacts__line-3', greed: 24, items: [
                            {origin:'contactPersons', type:'item', greed: 24},
                        ]},
                ],
            },
            {
                type: 'group', name: 'linked_profiles', label: 'Связанные профили пользователей', items: [
                    {type:'container', name: 'linkedProfiles__line-1', greed: 24, items: [
                        {origin:'linked_profiles', type:'item', greed: 24},
                    ]},
                ],
            },
        ]
    }
];
