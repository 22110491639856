import React from 'react';
import {useHistory} from 'react-router';
import CatalogItemAddForm from './CatalogItemAddForm';

import './CatalogItemAdd.scss';

const CatalogItemAdd = () => {
  const history = useHistory();

  return (
    <div className="catalogs-item-add container container--white mt-5">
      <div className="catalogs-item-add__body">
        <CatalogItemAddForm history={history} />
      </div>
    </div>
  );
};

export default CatalogItemAdd;
