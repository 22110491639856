import {fetchFunc} from '../../Utils/security/http/mdm';
import {onError} from '../../components/Helpers/Utils';
import {getUserNameAC} from '../actions/actions';

/** Получение данных об авторизованном пользователе */
export const getUserName = () => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/token/info`,
        method: 'get',
      },
      onError,
      'maintenanceUrl'
    );
    dispatch(getUserNameAC(response));
  } catch (error) {
    throw new Error(error.message);
  }
};
