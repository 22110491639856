import React, { useState } from "react";
import {Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    getSubscriptions,
    deleteSubscription,
    getCatalogs,
    subscribeCatalogs,
    getCatalogueEvents,
    getEventsTypes,
    deleteSubscriptionEvent,
    includeEventsInSubscribe
} from "./effects/actions";
import { DeleteOutlined } from '@ant-design/icons';
import {showDeleteConfirm} from "../../components/Helpers/Modals";
import { ControlledTable } from "../../components/ControlledTable/ControlledTable";

const Subscriptions = ({}) => {

    const dispatch = useDispatch();
    const subscriptions = useSelector(({ subscriptions }) => subscriptions.subscriptions);
    const catalogs = useSelector(({ subscriptions }) => subscriptions.catalogs);
    const eventsTypes = useSelector(({ subscriptions }) => subscriptions.eventsTypes);
    const catalogEvents = useSelector(({ subscriptions }) => subscriptions.catalogEvents);
    const loadings = useSelector(({ subscriptions }) => subscriptions.loadings);

    const subscriptionsColumns = [
        {
            title: 'Название',
            dataIndex: 'caption',
            key: 'caption',
            sortDirections: ['descend', 'ascend'],
            sorter: true,
            render: (text, record) => <Button
                type='link'
                onClick={() => setEventsModal({
                    visible: true,
                    catalogUuid: record.uuid
                })}
            >
                {text}
            </Button>
        },
        {
            title: 'Код справочника',
            dataIndex: ['catalog','origin'],
            key: 'origin'
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return <div className='text-right'>
                    <DeleteOutlined
                        className='mr-2'
                        onClick={() => showDeleteConfirm({
                            content: `Вы действительно хотите удалить подписку для справочника "${record.caption}"?`,
                            onOk: () => dispatch(deleteSubscription(record.uuid)),
                        }) }
                    />
                </div>
            }
        },
    ];

    const catalogsColumns = [
        {
            title: 'Название',
            dataIndex: 'caption',
            key: 'caption',
            sortDirections: ['descend', 'ascend'],
            sorter: true,
        },
        {
            title: 'Код справочника',
            dataIndex: 'origin',
            key: 'origin',
            sortDirections: ['descend', 'ascend'],
            sorter: true,
        },
    ];

    const eventTypesColumns = [
        {
            title: 'Название события',
            dataIndex: 'caption',
            key: 'caption',
            sortDirections: ['descend', 'ascend'],
            sorter: true,
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            sortDirections: ['descend', 'ascend'],
            sorter: true,
        },
    ];

    const eventsColumns = [
        {
            title: 'Название',
            dataIndex: ['eventCode','caption'],
            key: 'caption',
        },
        {
            title: 'Код события',
            dataIndex: ['eventCode','code'],
            key: 'code',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return <div className='text-right'>
                    <DeleteOutlined
                        className='mr-2'
                        onClick={() => showDeleteConfirm({
                            content: `Вы действительно хотите удалить событие из подписки?`,
                            onOk: () => dispatch(deleteSubscriptionEvent(eventsModal.catalogUuid, record.uuid)),
                        }) }
                    />
                </div>
            }
        },
    ];

    const initialSub = {
        visible: false,
        selectedCatalogs: []
    }

    const initialEventsTypes = {
        visible: false,
        selectedEventsTypes: []
    }

    const initialEvents = {
        visible: false,
        catalogUuid: null
    }

    const [subscribeModal, setSubscribeModal] = useState(initialSub);
    const [eventsModal, setEventsModal] = useState(initialEvents);
    const [eventsTypesModal, setEventsTypesModal] = useState(initialEventsTypes);

    return (
        <>
            <ControlledTable
                getDataAction={getSubscriptions}
                config={{
                    columns: subscriptionsColumns,
                    contextSearch: true,
                    contextPlaceholder: 'Наименование или origin',
                    titleActions: () => <div>
                        <Button
                            type='primary'
                            className='ml-2'
                            onClick={() => setSubscribeModal({
                                ...subscribeModal,
                                visible: true
                            })}
                        >
                            Добавить
                        </Button>
                    </div>,
                    loading: loadings.subscriptions,
                    dataSource: subscriptions.content,
                    rowKey: (record) => record.uuid,
                    totalElements: subscriptions.totalElements
                }}
            />
            <Modal
                visible={subscribeModal.visible}
                width={900}
                onCancel={() => setSubscribeModal(initialSub)}
                okButtonProps={{
                    disabled: !subscribeModal.selectedCatalogs.length
                }}
                onOk={async () => {
                    await dispatch(subscribeCatalogs(subscribeModal.selectedCatalogs));
                    setSubscribeModal(initialSub)
                }}
            >
                <ControlledTable
                    getDataAction={getCatalogs}
                    config={{
                        columns: catalogsColumns,
                        contextSearch: true,
                        contextPlaceholder: 'Наименование или origin',
                        loading: loadings.catalogs,
                        dataSource: catalogs.content,
                        rowSelection: {
                            selectedRowKeys: subscribeModal.selectedCatalogs,
                            onChange: (keys) => setSubscribeModal({
                                ...subscribeModal,
                                selectedCatalogs: keys
                            }),
                        },
                        rowKey: (record) => record.uuid,
                        totalElements: catalogs.totalElements
                    }}
                />
            </Modal>
            <Modal
                visible={eventsModal.visible}
                width={900}
                onCancel={() => setEventsModal(initialEvents)}
                footer={null}
                destroyOnClose
            >
                <ControlledTable
                    getDataAction={(tableControl = {}) => getCatalogueEvents(tableControl, eventsModal.catalogUuid)}
                    config={{
                        columns: eventsColumns,
                        contextSearch: true,
                        contextPlaceholder: 'Наименование или код',
                        titleActions: () => <div>
                            <Button
                                type='primary'
                                className='ml-2'
                                onClick={() => setEventsTypesModal({
                                    ...eventsTypesModal,
                                    visible: true
                                })}
                            >
                                Добавить
                            </Button>
                        </div>,
                        loading: loadings.catalogEvents,
                        dataSource: catalogEvents.content,
                        rowKey: (record) => record.uuid,
                        totalElements: catalogEvents.totalElements
                    }}
                />
            </Modal>
            <Modal
                visible={eventsTypesModal.visible}
                width={900}
                onCancel={() => setEventsTypesModal(initialEventsTypes)}
                okButtonProps={{
                    disabled: !eventsTypesModal.selectedEventsTypes.length
                }}
                onOk={async () => {
                    await dispatch(includeEventsInSubscribe(eventsModal.catalogUuid, eventsTypesModal.selectedEventsTypes));
                    setEventsTypesModal(initialEventsTypes)
                }}
            >
                <ControlledTable
                    getDataAction={getEventsTypes}
                    config={{
                        columns: eventTypesColumns,
                        contextSearch: true,
                        loading: loadings.eventsTypes,
                        dataSource: eventsTypes.content,
                        rowSelection: {
                            selectedRowKeys: eventsTypesModal.selectedEventsTypes,
                            onChange: (keys) => setEventsTypesModal({
                                ...eventsTypesModal,
                                selectedEventsTypes: keys
                            }),
                        },
                        rowKey: (record) => record.code,
                        totalElements: eventsTypes.totalElements
                    }}
                />
            </Modal>
        </>
    );
}

export default Subscriptions;