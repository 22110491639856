import React from 'react';
import Catalogs from '../../modules/Catalogs';

const CatalogGroupAccessPage = (props) => {
    return (
       <Catalogs
           editRulesMode = {true}
       />
    );
};

export default CatalogGroupAccessPage;
