import React, { useState, useEffect } from 'react'
import { Table, Card } from 'antd'
import moment from 'moment'
import { fetchFunc } from '../../Utils/security/http/mdm'
import { defaultDateFormat } from '../../components/Helpers/Utils';

const StatusHistory = ({ serviceId }) => {
    const columns = [
        {
            title: 'Пользователь',
            dataIndex: 'authorFio',
            key: 'authorFio'
        },
        {
            title: 'Предыдущий статус',
            dataIndex: ['prevValue', 'caption'],
            key: 'prevValue',
        },
        {
            title: 'Текущий статус',
            dataIndex: ['value', 'caption'],
            key: 'value',
        },
        {
            title: 'Дата изменения статуса',
            dataIndex: 'changeDate',
            key: 'changeDate',
            render: (text) => moment(text).format(defaultDateFormat)
        },
        {
            title: 'Комментарий',
            dataIndex: ['fields', 'statusComment'],
            key: 'statusComment',
        },
    ];

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        getData();
    }, [page, size]);

    const getData = async () => {
        try {
            const data = await fetchFunc({
                url: `/api/v1/service/${serviceId}/statusHistory`,
                method: 'get',
                params: {
                    page,
                    size,
                    sort: 'dateFrom,desc'
                }
            });
            setData(data.content);
            setTotal(data.totalElements);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleTableChange = ({ current, pageSize }) => {
        setPage(current - 1);
        setSize(pageSize);
    };

    return (
        <div>
            <div className='my-3'>
                <strong>История изменения статусов</strong>
            </div>
            <Table
                bordered
                dataSource={data}
                columns={columns}
                loading={loading}
                rowKey={record => record.id}
                pagination={{
                    showSizeChanger: true,
                    total,
                    current: page + 1
                }}
                onChange={handleTableChange}
            />
        </div>
    )
};

export default StatusHistory
