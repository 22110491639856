import {combineReducers} from 'redux';
import {VerifyReducer} from './VerifyReducer';
import {detailReducer} from './detailFieldEditReducer';
import {filterReducer} from './filterReducer';
import {loadingReducer} from './loadingReducer';
import {accessReducer} from './accessReducer';
import {navigateReducer} from './navigateReducer';
import {deduplicationReducer} from './deduplicationReducer';
import {signReducer} from '../../components/SignDoc/effects/reducer';
import {subscriptionsReducer} from '../../modules/Subscriptions/effects/reducer';
import {cardWidgetsReducer} from '../../components/CatalogItemWidgets/effects/reducer';
import {notificationReducer} from './notificationReducer';
import {userReducer} from './userReducer';
import {helpInfoReducer} from './helpInfoReducer';

export const rootReducer = combineReducers({
  access: accessReducer,
  verify: VerifyReducer,
  detail: detailReducer,
  filter: filterReducer,
  loading: loadingReducer,
  sign: signReducer,
  navigate: navigateReducer,
  subscriptions: subscriptionsReducer,
  cartWidgets: cardWidgetsReducer,
  deduplication: deduplicationReducer,
  notification: notificationReducer,
  user: userReducer,
  helpInfo: helpInfoReducer,
});
