import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isArray, isEmpty} from 'lodash';

import {BaseDiv, StyledButton, StyledModal, StyledSelect, StyledSelectOption, StyledTable} from '../Themes/Components';
import CatalogsFilters from '../../components/CatalogsFilters';
import AccessRuleForm from '../AccessRights/AccessRuleForm';
import {setFilterReset} from '../../redux/actions/actions';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {successModalCreate} from '../Helpers/Modals';
import {setCatalogs} from '../../modules/Subscriptions/effects/actions';
import {withRouter} from 'react-router';

class CatalogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.columns,
      displayedColumns: this.props.displayedColumns,
      columnsForRender: [],
      data: [],
      totalElements: 0,
      loading: true,
      page: 0,
      size: 10,
      search: '',
      modalRulesShow: false,
      catalogListState: 'catalogSearch',
      referenceOrigins: [],
    };
  }

  componentDidMount = () => {
    const {columns, displayedColumns} = this.state;
    let newColumns = [];
    if (displayedColumns) {
      columns.forEach((item) => {
        if (displayedColumns.some((elem) => elem === item.key)) {
          newColumns.push(item);
        }
      });
    } else newColumns = columns;

    this.setState({columnsForRender: newColumns}, () => {
      if (this.props.referenceOrigin === 'service') this.getCatalogItems().then();
      else this.getCatalogs().then();
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {resetFilter, setResetFlagFilter} = this.props;
    if (prevProps.resetFilter !== resetFilter && resetFilter) {
      this.applyFilter();
      setResetFlagFilter(false);
    }
  }

  getCatalogs = async (groupUuid) => {
    try {
      const {page, size, search} = this.state;
      const {apiUrl, catalogIdentifier, sort, additionalParams = '', location} = this.props;
      let pageParam = `?page=${page}`;
      let sizeParam = `&size=${size}`;
      let searchParam = apiUrl === '/api/v1/org' ? `&caption_like=${search}` : `&search=${search}`;
      let sortParam = sort ? `&sort=${sort}` : '';
      let groupParam = groupUuid ? `&group=${groupUuid}` : '';
      let filterCatalogDraft = catalogIdentifier ? `&filterCatalogDraft=${catalogIdentifier}` : '';
      const editProperties = location.pathname === '/catalogs-rules' ? '&edit_properties=true' : '';
      let url =
        apiUrl +
        pageParam +
        sizeParam +
        filterCatalogDraft +
        searchParam +
        sortParam +
        groupParam +
        additionalParams +
        editProperties;
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      this.setState({
        data: data.content,
        totalElements: data.totalElements,
        loading: false,
      });
      this.props.setCatalogs(data.content);
    } catch (error) {
      this.setState({loading: false});
    }
  };

  makeBody = () => {
    const {search, filter} = this.state;
    let body = {
      and: [],
    };
    if (filter && !isEmpty(filter)) body.and.push(filter);
    if (search) body.and.push({'%context_filter': search});
    return body;
  };

  getCatalogItems = async () => {
    try {
      let {referenceOrigin} = this.props;
      const {page, size, sort} = this.state;
      let pageParam = `&page=${page}`;
      let sizeParam = `&size=${size}`;
      let sortParam = '';
      if (sort && sort.order) {
        sortParam = `&sort=${sort.field},${sort.order}`;
      }
      let url = `/api/v1/catalogs/${referenceOrigin}/items/search/extended?showRefs=1${pageParam}${sizeParam}${sortParam}`;
      const data = await fetchFunc({
        url,
        method: 'post',
        data: this.makeBody(),
      });
      this.setState({
        data: data.content,
        totalElements: data.totalElements,
        loading: false,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  applyFilter = () => {
    const {filterData, contextString} = this.props;
    this.setState(
      {
        filter: filterData,
        page: 0,
        search: contextString,
        loading: true,
      },
      () => this.getCatalogs()
    );
  };

  handleColumnsSelectChange(value) {
    const {columns} = this.state;
    let newColumnsForRender = [];
    columns.forEach((item) => {
      if (value.some((elem) => elem === item.key)) {
        newColumnsForRender.push(item);
      }
    });
    this.setState({displayedColumns: value, columnsForRender: newColumnsForRender});
  }

  handleTableChange = (pagination) => {
    this.setState(
      {
        loading: true,
        size: pagination.pageSize,
        page: --pagination.current,
      },
      () => this.getCatalogs()
    );
  };

  onSelectedRowKeysChange = (record, selected) => {
    this.setState(({referenceOrigins}) => {
      const {origin} = record;
      if (isArray(record)) referenceOrigins = record.map((item) => item.origin);
      else {
        if (!selected) {
          const index = referenceOrigins.findIndex((item) => item === origin);
          referenceOrigins.splice(index, 1);
        } else referenceOrigins.push(origin);
      }
      return {referenceOrigins};
    });
  };

  updateAccessRules = async (newRights) => {
    const {referenceOrigins} = this.state;
    this.setState({modalRulesShow: false});
    try {
      await referenceOrigins.forEach((item) => this.postAccessRules(newRights, item));
      successModalCreate('', 'Права назначены успешно');
    } catch (error) {
      console.log(error.response);
    }
  };

  postAccessRules = async (rules, referenceOrigin) => {
    let url = `/api/v1/catalogs/${referenceOrigin}/access`;
    try {
      await fetchFunc({
        url,
        method: 'post',
        data: rules,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  render() {
    const {
      columns,
      columnsForRender,
      loading,
      data,
      displayedColumns,
      totalElements,
      selectedRowKeys,
      modalRulesShow,
      referenceOrigins,
      page,
      catalogListState,
    } = this.state;
    const {isFilterSearch, isContextSearch, card} = this.props;
    let columnsShowOptions = [];
    if (displayedColumns) {
      columnsShowOptions = columns.map((item, index) => {
        return (
          <StyledSelectOption value={item.key} key={'column-select' + index}>
            {item.title}
          </StyledSelectOption>
        );
      });
    }

    const rowSelectionObj = {
      selectedRowKeys,
      onSelect: this.onSelectedRowKeysChange,
      onSelectAll: (selected, selectedRows) => {
        this.onSelectedRowKeysChange(selectedRows);
      },
      hideDefaultSelections: true,
    };

    return (
      <>
        <BaseDiv>
          {(isFilterSearch || isContextSearch) && (
            <BaseDiv sName="containerWhite">
              <CatalogsFilters
                isFilterSearch={isFilterSearch && true}
                isContextSearch={isContextSearch}
                applyFilter={this.applyFilter}
                fields={!isEmpty(this.props.fields) && this.props.fields}
                catalogListState={catalogListState}
              />
            </BaseDiv>
          )}
          <BaseDiv sName={card ? 'containerWhite' : 'container'} className={card ? 'mt-5' : ''}>
            {displayedColumns && (
              <BaseDiv>
                <BaseDiv className="d-flex mb-3">
                  <BaseDiv className="d-flex ml-auto align-items-center">
                    <BaseDiv>
                      <BaseDiv className="mr-2">Колонки:</BaseDiv>
                      <StyledSelect
                        mode="multiple"
                        name="displayedColumns"
                        showArrow={true}
                        maxTagCount={0}
                        maxTagPlaceholder={'Колонки'}
                        style={{width: '120px'}}
                        placeholder="Колонки"
                        value={displayedColumns}
                        onChange={(value) => this.handleColumnsSelectChange(value)}
                      >
                        {columnsShowOptions}
                      </StyledSelect>
                    </BaseDiv>
                  </BaseDiv>
                </BaseDiv>
              </BaseDiv>
            )}
            <StyledTable
              columns={columnsForRender}
              dataSource={data}
              loading={loading}
              rowSelection={(this.props.editRulesMode && rowSelectionObj) || this.props.rowSelection}
              onChange={this.handleTableChange}
              rowKey={(record) => record.uuid}
              footer={
                this.props.editRulesMode && referenceOrigins && referenceOrigins.length
                  ? () => (
                      <StyledButton sName="defaultBtn" onClick={() => this.setState({modalRulesShow: true})}>
                        Изменить права доступа
                      </StyledButton>
                    )
                  : null
              }
              pagination={{
                showSizeChanger: true,
                total: totalElements,
                current: page + 1,
              }}
            />
          </BaseDiv>
        </BaseDiv>
        <StyledModal
          title={'Установка прав на группу справочников'}
          visible={modalRulesShow}
          footer={null}
          onCancel={() => this.setState({modalRulesShow: false})}
          destroyOnClose={true}
          width={800}
        >
          <AccessRuleForm store="dictionary" updateAccessRules={this.updateAccessRules} />
        </StyledModal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    filterData: store.filter.filterBody,
    contextString: store.filter.contextString,
    resetFilter: store.filter.filterResetFlag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setResetFlagFilter: (flag) => {
      dispatch(setFilterReset(flag));
    },
    setCatalogs: (data) => {
      dispatch(setCatalogs(data));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogList));
