import {Button, Form, Input, Select} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {get, isEmpty} from 'lodash';
import TextArea from 'antd/es/input/TextArea';

import DynamicSelect from '../FormElements/DynamicSelect';
import {scheduler} from '../../modules/SchedulerCalendar/utils/constant';

import './SchedulerForm.scss';

/**
 * Форма создания.редактирования планировщика
 * @param editableItem  - данные справочника расписаний
 * @param editableItemTask  - данные справочника заданий
 * @param editKey - ключ редактирования
 * @param onCancel - функция для  отмены формы
 * @param onSubmit - функция для отправки формы
 * @param keyForVisibleForm - ключ для отображения формы заданий || расписаний
 */
const SchedulerForm = ({editableItem, editableItemTask, editKey, onCancel, onSubmit, keyForVisibleForm}) => {
  /** Данные формы */
  const [form] = Form.useForm();
  /** uuid Задания */
  const [jobUuid, setJobUuid] = useState('');
  /** Тип расписания/задания */
  const [typeSchedule, setTypeSchedule] = useState('');
  /** Данные для редактирования формы */
  const initialValues = {
    uuid: (!isEmpty(editableItem) || !isEmpty(editableItemTask)) && (editableItem?.uuid || editableItemTask?.uuid),
    name:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.name || editableItemTask?.name
        : form.getFieldValue('name'),
    description:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.description || editableItemTask?.description
        : form.getFieldValue('description'),
    job:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.job?.name || editableItemTask?.job?.name
        : form.getFieldValue('job'),
    schedule:
      (editableItem || editableItemTask) && !isEmpty(editKey)
        ? editableItem?.schedule || editableItemTask?.schedule
        : form.getFieldValue('schedule'),
    type:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? scheduler.types?.find((item) => item.key === editableItem?.type && item.value) || editableItemTask?.type
        : form.getFieldValue('type'),
    url:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.params?.url || editableItemTask?.params?.url
        : form.getFieldValue('url'),
    method:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.params?.method || editableItemTask?.params?.method
        : form.getFieldValue('method'),
    body:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.body || editableItemTask?.body
        : form.getFieldValue('body'),
    request_params:
      !isEmpty(editableItem) || !isEmpty(editableItemTask)
        ? editableItem?.params?.request_params || editableItemTask?.params?.request_params
        : form.getFieldValue('params'),
  };
  /**  Загружаем данные формы для редактирования  */
  useEffect(() => {
    ((editKey === 'edit' && !isEmpty(editableItem)) || !isEmpty(editableItemTask)) &&
      form.setFieldsValue(initialValues);
  }, [form, editKey, editableItem, editableItemTask]);
  /** Получаем значения  */
  const getValue = useCallback(
    (path, defaultValue) => {
      return editableItem || editableItemTask ? get(editableItem || editableItemTask, path) : defaultValue;
    },
    [editableItem, editableItemTask]
  );
  /** Функция сеттер значений компонента Select */
  const handleSelectChange = useCallback(
    (value, name, option) => {
      form.setFieldsValue({[name]: value});
      name === 'job' && setJobUuid(option.uuidValue);
    },
    [form]
  );
  /** Функция сеттер типа задач/расписания */
  const handleChangeType = (value, option) => {
    setTypeSchedule(option.key);
    form.setFieldsValue({schedule: null});
  };
  /**  Отправка запроса на создание/редактирование */
  const onFinish = (values) => {
    if (!isEmpty(keyForVisibleForm)) {
      const {method, name, body, description, url, request_params} = values;
      values = {
        type: 'http',
        name,
        description: description,
        body,
      };
      values.params = {
        method,
        url,
        request_params,
      };
      onSubmit(values, editableItem || editableItemTask);
      form.resetFields();
    } else {
      let {description, name, schedule} = values;
      values = {
        description,
        job: jobUuid || editableItem?.job?.uuid || editableItemTask?.job?.uuid,
        name,
        schedule,
        type:
          scheduler.types.find((item) => item.value === form.getFieldValue('type') && item.key)?.key ||
          initialValues?.type?.key,
      };
      onSubmit(values, editableItem || editableItemTask);
      form.resetFields();
    }
  };
  return (
    <Form
      name="SchedulerForm"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="schedulerForm"
      validateMessages={scheduler.validateMessages}
    >
      {!isEmpty(editableItem) && !isEmpty(editKey) && (
        <Form.Item name="uuid" label="uuid" className="schedulerForm__block--item-right">
          <Input disabled />
        </Form.Item>
      )}
      <div className="schedulerForm__block">
        <Form.Item
          label={!isEmpty(keyForVisibleForm) ? 'Наименование задачи' : 'Наименование расписания'}
          name="name"
          rules={[{required: true}]}
          className="schedulerForm__block--item-left"
        >
          <Input
            setValue={(event) => form.setFieldsValue({name: event.target.value})}
            placeholder={
              !isEmpty(keyForVisibleForm) ? 'Введите наименование задачи' : 'Введите наименование расписания'
            }
          />
        </Form.Item>
        {!isEmpty(editableItemTask) && !isEmpty(editKey) && (
          <Form.Item name="uuid" label="uuid" className="schedulerForm__block--item-right">
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item
          label="Задача планировщика"
          name="job"
          rules={[{required: isEmpty(keyForVisibleForm) && true}]}
          className="schedulerForm__block--item-right"
          hidden={!isEmpty(keyForVisibleForm)}
        >
          <DynamicSelect
            placeholder="Выбрать расписание"
            notFoundContentPlaceholder="Введите запрос"
            dropdownClassName="form-item__dropdown"
            filterOption={true}
            resetOptions={true}
            feachoptions={{
              referenceStore: 'scheduler',
              url: '/api/v1/scheduler/job',
              method: 'get',
              catalogOrigin: 'job',
              displayedField: 'name',
              valueField: 'name',
              valueSearchName: 'name',
              defaultSize: 20,
            }}
            handleSelectChange={(value, option) => {
              handleSelectChange(value, 'job', option);
            }}
          />
        </Form.Item>
      </div>
      <div className="schedulerForm__block">
        <Form.Item
          label={!isEmpty(keyForVisibleForm) ? 'Тип задачи' : 'Стратегия запуска'}
          name="type"
          rules={[{required: isEmpty(keyForVisibleForm) && true}]}
          className="schedulerForm__block--item-left"
        >
          <Select
            defaultValue={!isEmpty(keyForVisibleForm) ? scheduler.typesTask[0].value : null}
            placeholder={!isEmpty(keyForVisibleForm) ? 'Выбрать тип задачи' : 'Выбрать стратегию запуска'}
            disabled={!isEmpty(keyForVisibleForm)}
            options={!isEmpty(keyForVisibleForm) ? scheduler.typesTask : scheduler.types}
            onChange={(value, option) => handleChangeType(value, option)}
          />
        </Form.Item>
        <Form.Item
          label="Расписание периодического запуска"
          name="schedule"
          labelCol="10"
          rules={[{required: form.getFieldValue('type')?.key === 'scheduled'}]}
          className="schedulerForm__block--item-right"
          hidden={!isEmpty(keyForVisibleForm)}
        >
          <Input
            placeholder="Введите расписание"
            disabled={initialValues?.type?.key === 'once' || typeSchedule === 'once'}
          />
        </Form.Item>
        {getValue('type', 'http') === 'http' && (
          <Form.Item
            label="Вызываемый метод"
            name="method"
            rules={[{required: !isEmpty(keyForVisibleForm) && true}]}
            className="schedulerForm__block--item-right"
            hidden={isEmpty(keyForVisibleForm)}
          >
            <Select
              placeholder="Выбрать метод"
              options={scheduler.methodsTask}
              onChange={(value, name) => handleSelectChange(value, name)}
            />
          </Form.Item>
        )}
      </div>
      {getValue('type', 'http') === 'http' && (
        <>
          <div className="schedulerForm__block--url">
            <Form.Item
              label="url"
              name="url"
              rules={[{required: !isEmpty(keyForVisibleForm) && true}]}
              hidden={isEmpty(keyForVisibleForm)}
            >
              <Input onChange={(value, name) => handleSelectChange(value, name)} placeholder="Введите url" />
            </Form.Item>
          </div>
          <div className="schedulerForm__block">
            <Form.Item
              label="Тело запроса"
              name="body"
              className="schedulerForm__block--item-left"
              hidden={isEmpty(keyForVisibleForm)}
            >
              <TextArea rows={6} />
            </Form.Item>
            <Form.Item
              label="Параметры запроса"
              name="request_params"
              className="schedulerForm__block--item-right"
              hidden={isEmpty(keyForVisibleForm)}
            >
              <TextArea onChange={(value, name) => handleSelectChange(value, name)} rows={6} />
            </Form.Item>
          </div>
        </>
      )}
      <Form.Item
        label={!isEmpty(keyForVisibleForm) ? 'Описание задачи' : 'Описание расписания'}
        name="description"
        rules={[{required: true}]}
      >
        <TextArea onChange={(value, name) => handleSelectChange(value, name)} rows={4} />
      </Form.Item>
      <div className="schedulerForm__button">
        <Button
          onClick={() => {
            onCancel();
            form.resetFields();
          }}
          className="schedulerForm__button--left"
        >
          Отменить
        </Button>
        <Button type="primary" htmlType="submit">
          {editableItem || editableItemTask ? 'Сохранить' : 'Создать'}
        </Button>
      </div>
    </Form>
  );
};
export default React.memo(SchedulerForm);
