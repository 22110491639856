import {notifications} from '../../../components/NotificationPage/helpers/constant';
import {isEmpty} from 'lodash';
import {anchorElementColor} from './constant';

/** Функция добавляет тэги br вместо символа переноса строки(\n)   */
export const replaceLineBreaksToTag = (text) => {
  return baseReplaceNotificationTextToPayload(text)?.replace(/\n+/gi, '<br>');
};
export const renderSchedule = (code) => {
  switch (code) {
    case 'ALWAYS':
      return 'Отправлять всегда';
    case 'WORK_DAY':
      return 'Будние дни';
    case 'WEEKEND':
      return 'Выходные дни';
    default:
      return null;
  }
};
export const renderScheduleToPayload = (title) => {
  switch (title) {
    case 'Отправлять всегда':
      return 'ALWAYS';
    case 'Будние дни':
      return 'WORK_DAY';
    case 'Выходные дни':
      return 'WEEKEND';
    default:
      return null;
  }
};
/** Функция render заголовка  */
export const renderTitleTestNotification = (testKey, telephones, phoneNumberForOptions, emailOptions) => {
  switch (testKey) {
    case 'telephone' && telephones?.length === 1:
      return notifications.confirmSendTestTitlePhone;
    case 'telephone' && phoneNumberForOptions?.length === 1:
      return notifications.confirmSendTestTitlePhone;
    case 'emails' && emailOptions?.length === 1:
      return notifications.confirmSendTestTitleEmail;
    case 'view':
      return notifications.previewTitle;
    default:
      return notifications.testTemplate;
  }
};
/** Функция получения даннных email */
export const renderEmailsToPayload = (form) => {
  if (!isEmpty(form.getFieldValue('emailsFirst')) && !isEmpty(form.getFieldValue('emails'))) {
    return [form.getFieldValue('emailsFirst'), form.getFieldValue('emails')]?.toString();
  }
  if (isEmpty(form.getFieldValue('emailsFirst')) && !isEmpty(form.getFieldValue('emails'))) {
    return form.getFieldValue('emails')?.toString();
  }
  if (!isEmpty(form.getFieldValue('emailsFirst')) && isEmpty(form.getFieldValue('emails'))) {
    return form.getFieldValue('emailsFirst')?.toString();
  }
};
/** Преобразование теста уведомления для отображения на UI */
export const replaceNotificationText = (text) => {
  return text
    .replace(`\`$\{props.authorFio}\``, 'Тестовый пользователь')
    .replace(`\`$\{props.catalogCaption}\``, 'Тестовый справочник')
    .replace(`\`$\{props.uuid}\``, '88888888-8888-8888-8888-0477b93ee777')
    .replace(`\`$\{props.authorFio}\``, 'Тестовый пользователь')
    .replace(`\`$\{props.version}\``, 'Тестовая версия')
    .replace(/<style([\s\S]*?)<\/style>/gi, '')
    .replace(/<script([\s\S]*?)<\/script>/gi, '')
    .replace(/^\s*/gim, '')
    .replace(/ ,/gi, ',')
    .replace(/ +/gi, ' ');
};
/** Базовое преобразование текста для отправки уведомления */
export const baseReplaceNotificationTextToPayload = (text) => {
  return text
    ?.replace(`\`$\{props.authorFio}\``, 'Тестовый пользователь')
    ?.replace(`\`$\{props.catalogCaption}\``, 'Тестовый справочник')
    ?.replace(`\`$\{props.uuid}\``, '88888888-8888-8888-8888-0477b93ee777')
    ?.replace(`\`$\{props.authorFio}\``, 'Тестовый пользователь')
    ?.replace(`\`$\{props.version}\``, 'Тестовая версия')
    ?.replace(/<style([\s\S]*?)<\/style>/gi, '\n\n')
    ?.replace(/<script([\s\S]*?)<\/script>/gi, '\n\n')
    ?.replace(/<style([\s\S]*?)<\/style>/gi, '')
    ?.replace(/<script([\s\S]*?)<\/script>/gi, '')
    ?.replace(/^\s*/gim, '')
    ?.replace(/ ,/gi, ',');
};
/** Преобразование текста для отправки уведомления */
export const replaceNotificationTextToPayload = (text) => {
  return baseReplaceNotificationTextToPayload(text).replace(/\n+/gi, '\n\n');
};
/** Преобразование текста для отправки уведомления на Email */
export const replaceEmailNotificationValueToPayload = (text) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = replaceLineBreaksToTag(text);
  return tmp.innerHTML;
};

/** Преобразование текста для для формы шаблона */
export const replaceEmailPayloadToNotificationValue = (text) => {
  return text.replace(/<br>/g, '\n');
};

/** Функция устанавливает единые стили для текста уведомления */
export const setStylesForNotificationText = (text) => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = text;
  const anchorList = wrapper.getElementsByTagName('a');
  Array.from(anchorList).forEach((element) => (element.style.color = anchorElementColor));
  return wrapper.innerHTML;
};

/** Получение данных  номеров телефона при редактировании */
export const renderInitValuesTelephone = (templateTypeSms) => {
  if (typeof templateTypeSms?.transportParams?.phonenumber === 'string') {
    return [maskOptions(templateTypeSms?.transportParams?.phonenumber)];
  } else {
    return Object.values(templateTypeSms?.transportParams).map((item) => maskOptions(item.phonenumber));
  }
};
/** Получение данных  номеров телефона при редактировании для Payload */
export const renderInitValuesTelephoneForPayload = (templateTypeSms) => {
  if (typeof templateTypeSms?.transportParams.phonenumber === 'string') {
    return [{value: maskOptions(templateTypeSms?.transportParams?.phonenumber), key: 0}];
  }
  if (Object.keys(templateTypeSms?.transportParams).length === 1) {
    return {value: maskOptions(templateTypeSms?.transportParams?.phonenumber), key: 0};
  } else {
    return Object.values(templateTypeSms?.transportParams).map((item, index) => {
      return {
        value: maskOptions(item.phonenumber),
        key: index,
      };
    });
  }
};
/** Преобразование номера телефона с маской */
export const maskOptions = (phone) => {
  let tel = typeof phone;
  let USNumber =
    !isEmpty(phone) &&
    tel === 'string' &&
    phone?.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  USNumber = '+' + USNumber[1] + '(' + USNumber[2] + ')' + USNumber[3] + '-' + USNumber[4] + '-' + USNumber[5];
  return USNumber;
};
/** Преобразование TransportParams для payloadData */
export const checkTransportParams = (
  clearKeySms,
  phoneNumber,
  telephones,
  initialValues,
  status,
  templateTypeSms,
  arr
) => {
  if (!isEmpty(clearKeySms) && !isEmpty(phoneNumber) && !isEmpty(telephones)) {
    if (arr?.length === 1) {
      return {
        ...arr
          .map((item) => {
            return {phonenumber: item.value?.match(/\d/g)?.join('')};
          })
          .toString(),
      };
    } else {
      return {
        ...arr.map((item) => {
          return {phonenumber: item.value?.match(/\d/g)?.join('')};
        }),
      };
    }
  }
  if (isEmpty(clearKeySms) && !isEmpty(phoneNumber) && !isEmpty(telephones)) {
    const array = [...telephones, phoneNumber];
    if (array?.length === 1) {
      return {
        ...arr
          .map((item) => {
            return {phonenumber: item.value?.match(/\d/g)?.join('')};
          })
          .toString(),
      };
    } else {
      return {
        ...array.map((item) => {
          return {phonenumber: item.value?.match(/\d/g)?.join('')};
        }),
      };
    }
  }
  if (isEmpty(clearKeySms) && isEmpty(phoneNumber) && !isEmpty(telephones)) {
    if (telephones.length === 1) {
      return {phonenumber: telephones.map((item) => item.value?.match(/\d/g)?.join('')).toString()};
    } else {
      return {
        ...telephones.map((item) => {
          return {phonenumber: item.value?.match(/\d/g)?.join('')};
        }),
      };
    }
  }
  if (!isEmpty(clearKeySms) && !isEmpty(phoneNumber) && isEmpty(telephones)) {
    return {
      phonenumber: phoneNumber.value?.match(/\d/g)?.join(''),
    };
  }
  if (isEmpty(clearKeySms) && !isEmpty(phoneNumber) && isEmpty(telephones)) {
    return {
      phonenumber: phoneNumber.value?.match(/\d/g)?.join(''),
    };
  }
};
/** Преобразование Html в текст */
export const transformationHtmlToText = (html) => {
  let text = html;
  text = text?.replace(/\n/gi, `\n`);
  text = text?.replace(/<\/h1>/gi, '\n=========\n');
  text = text?.replace(/<style([\s\S]*?)<\/style>/gi, '');
  text = text?.replace(/<script([\s\S]*?)<\/script>/gi, '');
  text = text?.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
  text = text?.replace(/<\/div>/gi, '\n');
  text = text?.replace(/<\/li>/gi, '\n');
  text = text?.replace(/<\/!DOCTYPE>/gi, '\n');
  text = text?.replace(/<\/html>/gi, '\n');
  text = text?.replace(/<\/title>/gi, '\n');
  text = text?.replace(/<\/meta>/gi, '\n');
  text = text?.replace(/<\/body>/gi, '\n');
  text = text?.replace(/<li.*?>/gi, '  *  ');
  text = text?.replace(/<\/ul>/gi, '\n');
  text = text?.replace(/<\/p>/gi, '\n');
  text = text?.replace(/<br\s*[\/]?>/gi, '\n');
  text = text?.replace(/\\n/g, ' ');
  text = text?.replace(/<[^>]+>/gi, '');
  text = text?.replace(/^\s*/gim, '');
  text = text?.replace(/ ,/gi, ',');
  text = text?.replace(/ +/gi, ' ');
  text = text?.replace(/\n+/gi, '\n');
  return text;
};
/** Функция сеттер данных в зависисмости от добавленных шаблонов */
export const renderTemplateToPayload = (methodNotificationsSms, methodNotificationsHttp, methodNotificationsEmail) => {
  if (!isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
    return [methodNotificationsSms];
  }
  if (isEmpty(methodNotificationsSms) && !isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
    return [methodNotificationsEmail];
  }
  if (isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && !isEmpty(methodNotificationsHttp)) {
    return [methodNotificationsHttp];
  }
  if (!isEmpty(methodNotificationsSms) && !isEmpty(methodNotificationsEmail) && isEmpty(methodNotificationsHttp)) {
    return [methodNotificationsSms, methodNotificationsEmail];
  }
  if (isEmpty(methodNotificationsSms) && !isEmpty(methodNotificationsEmail) && !isEmpty(methodNotificationsHttp)) {
    return [methodNotificationsHttp, methodNotificationsEmail];
  }
  if (!isEmpty(methodNotificationsSms) && isEmpty(methodNotificationsEmail) && !isEmpty(methodNotificationsHttp)) {
    return [methodNotificationsHttp, methodNotificationsSms];
  }
  if (
    !isEmpty(methodNotificationsSms.transportParams) &&
    !isEmpty(methodNotificationsEmail.transportParams) &&
    !isEmpty(methodNotificationsHttp.transportParams)
  ) {
    return [methodNotificationsSms, methodNotificationsEmail, methodNotificationsHttp];
  }
};
/** Проверка номеров телефона для отображения  */
export const checkIsEmptyTelephone = (
  form,
  editStatus,
  schedule,
  templateTypeSms,
  telephones,
  initialValues,
  setMethodNotificationsSms
) => {
  if (!isEmpty(editStatus)) {
    if (
      !isEmpty(form.getFieldValue('telephone')) &&
      !isEmpty(form.getFieldValue('scheduleSms')) &&
      !isEmpty(form.getFieldValue('textSms'))
    ) {
      setMethodNotificationsSms({
        body: !isEmpty(form.getFieldValue('textSms')) ? form.getFieldValue('textSms') : initialValues.textSms,
        scheduleType: !isEmpty(schedule.sms) ? schedule.sms : renderScheduleToPayload(initialValues.scheduleSms),
        transportType: notifications.sms,
        transportParams:
          initialValues?.telephone?.length > 1
            ? {
                ...initialValues.telephone.map((item) => {
                  return {phonenumber: item?.match(/\d/g)?.join('')};
                }),
              }
            : {phonenumber: initialValues?.telephone.toString()},
        methodUpdateUuid: templateTypeSms?.uuid,
      });
    }
  }
  if (isEmpty(editStatus)) {
    if (
      !isEmpty(form.getFieldValue('telephone')) &&
      !isEmpty(form.getFieldValue('scheduleSms')) &&
      !isEmpty(form.getFieldValue('textSms'))
    ) {
      setMethodNotificationsSms({
        body: form.getFieldValue('textSms'),
        scheduleType: schedule.sms,
        transportType: notifications.sms,
        transportParams:
          telephones?.length === 1
            ? {
                phonenumber: telephones?.map((item) => item?.value)?.toString(),
              }
            : {
                ...telephones?.map((item) => {
                  return {phonenumber: item.value};
                }),
              },
      });
    }
  }
};
/** Проверка Email для отображения  */
export const checkIsEmptyEmailTemplates = (
  form,
  editStatus,
  schedule,
  initialValues,
  setMethodNotificationsEmail,
  subjectEmail,
  templateTypeEmail
) => {
  if (!isEmpty(editStatus)) {
    if (
      !isEmpty(form.getFieldValue('emails')) &&
      !isEmpty(form.getFieldValue('scheduleEmail')) &&
      !isEmpty(form.getFieldValue('textEmail')) &&
      !isEmpty(form.getFieldValue('subjectEmail'))
    ) {
      setMethodNotificationsEmail({
        body: !isEmpty(form.getFieldValue('textEmail')) ? form.getFieldValue('textEmail') : initialValues.textEmail,
        scheduleType: !isEmpty(schedule.email) ? schedule.email : renderScheduleToPayload(initialValues.scheduleEmail),
        transportParams: {
          email: !isEmpty(form.getFieldValue('emails'))
            ? form.getFieldValue('emails').toString()
            : initialValues.emails.toString(),
          topic: !isEmpty(subjectEmail) ? subjectEmail : initialValues.subjectEmail,
        },
        transportType: notifications.email,
        methodUpdateUuid: templateTypeEmail?.uuid,
      });
    }
  }
  if (isEmpty(editStatus)) {
    if (
      !isEmpty(form.getFieldValue('emails')) &&
      !isEmpty(form.getFieldValue('scheduleEmail')) &&
      !isEmpty(form.getFieldValue('textEmail')) &&
      !isEmpty(form.getFieldValue('subjectEmail'))
    ) {
      setMethodNotificationsEmail({
        body: form.getFieldValue('textEmail'),
        scheduleType: schedule.email,
        transportParams: {
          email: form.getFieldValue('emails')?.toString(),
          topic: subjectEmail,
        },
        transportType: notifications.email,
      });
    }
  }
};
/** Проверка Http для отображения  */
export const checkIsEmptyHttpTemplates = (
  form,
  editStatus,
  schedule,
  initialValues,
  notificationText,
  methodHttp,
  templateTypeHttp,
  setMethodNotificationsHttp,
  url
) => {
  if (!isEmpty(editStatus)) {
    if (
      !isEmpty(form.getFieldValue('scheduleHttp')) &&
      !isEmpty(form.getFieldValue('url')) &&
      !isEmpty(form.getFieldValue('methodsHttp')) &&
      !isEmpty(form.getFieldValue('textHttp'))
    ) {
      setMethodNotificationsHttp({
        body: !isEmpty(notificationText.http) ? notificationText.http : initialValues.textHttp,
        scheduleType: !isEmpty(schedule.http) ? schedule.http : renderScheduleToPayload(initialValues.scheduleHttp),
        transportParams: {
          url: !isEmpty(templateTypeHttp?.transportParams?.url)
            ? templateTypeHttp?.transportParams?.url
            : form.getFieldValue('url'),
          method: !isEmpty(initialValues.methodsHttp) ? initialValues.methodsHttp : form.getFieldValue('methodsHttp'),
        },
        transportType: notifications.http,
        methodUpdateUuid: templateTypeHttp?.uuid,
      });
    }
    if (isEmpty(editStatus)) {
      if (
        !isEmpty(form.getFieldValue('scheduleHttp')) &&
        !isEmpty(form.getFieldValue('url')) &&
        !isEmpty(form.getFieldValue('methodsHttp')) &&
        !isEmpty(form.getFieldValue('textHttp'))
      ) {
        setMethodNotificationsHttp({
          body: notificationText.http,
          scheduleType: schedule.http,
          transportParams: {
            url: url,
            method: methodHttp,
          },
          transportType: notifications.http,
        });
      }
    }
  }
};
/** Функция сеттер Email для отображения на экране "Протестировать отправку" */
export const renderEmails = (emailFirst, email, methodNotificationsEmail) => {
  if (isEmpty(emailFirst) && isEmpty(email))
    return methodNotificationsEmail?.transportParams?.email
      ?.split(',')
      ?.map((item, index) => ({value: item, key: index}));
  if (!isEmpty(emailFirst) && !isEmpty(email)) return [emailFirst, ...email];
  if (isEmpty(emailFirst) && !isEmpty(email)) return email;
  if (!isEmpty(emailFirst) && isEmpty(email)) return [emailFirst];
};
/** Функция сеттер номеров телефона для отображения на экране "Протестировать отправку" */
export const renderPhoneNumber = (phoneNumber, telephones, templateTypeSms) => {
  if (isEmpty(phoneNumber) && isEmpty(telephones) && !isEmpty(templateTypeSms)) {
    return [
      Object.values(
        templateTypeSms?.transportParams.phonenumber?.map((item, index) => ({
          value: item,
          key: index,
        }))
      ),
    ];
  }
  if (!isEmpty(phoneNumber) && !isEmpty(telephones))
    return [phoneNumber, ...telephones]?.map((item) => ({...item, value: maskOptions(item?.value)}));
  if (isEmpty(phoneNumber) && !isEmpty(telephones))
    return telephones?.map((item) => ({...item, value: maskOptions(item?.value)}));
  if (!isEmpty(phoneNumber) && isEmpty(telephones)) return [phoneNumber];
};
