export const mdm = {
  confirm: 'Подтвердить',
  delete: 'Удалить',
  select: 'Выбрать',
  cancel: 'Отменить',
  save: 'Сохранить',
  add: 'Добавить',
  addItem: 'Добавить значения',
  listItems: 'Список значений',
  chooseItem: 'Выберите значение',
  inputItem: 'Введите значение',
  key: 'Ключ',
  change: 'Изменить',
  value: 'Значение',
  attention: 'Внимание!',
  apply: 'Применить',
  back: 'Назад',
  record: 'Записи',
  attributes: 'Атрибуты',
  parameters: 'Параметры',
  permissions: 'Права доступа',
  deletedRecord: 'Удаленные записи',
  verificationRecord: 'Записи на верификации',
  notFound: 'Введите запрос',
  textForDeleteCatalog: 'Вы действительно хотите удалить этот справочник ?',
  requiredMessage: 'Поле обязательно для заполнения',
  uniqueFields: 'Уникальные атрибуты при импорте:',
};
