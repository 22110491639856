import React from 'react';
import {Button} from 'antd';
import {isEmpty} from 'lodash';
import {CloseOutlined, CheckOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';

import {addVerify} from '../../../redux/actions/actions';

import './VerificationChecker.scss';

/** Подтверждения/отклонения записи
 * @name - origin поле
 * @initialValue - данные о записям */
const VerificationChecker = ({name}) => {
  /** Используем хранилище */
  const initialValue = useSelector((state) => state?.verify.verifyValues);
  const dispatch = useDispatch();
  /** Сеттер подтверждения/отклонения записи */
  const handleRadioChange = (value) => {
    dispatch(addVerify(name, value));
  };
  return (
    <div className="verificationChecker">
      <Button
        type={!isEmpty(initialValue) && initialValue[name] === true ? 'primary' : 'ghost'}
        shape="circle"
        icon={<CheckOutlined />}
        onClick={() => handleRadioChange(true)}
      />
      <Button
        type={!isEmpty(initialValue) && initialValue[name] === false ? 'danger' : 'ghost'}
        shape="circle"
        icon={<CloseOutlined />}
        onClick={() => handleRadioChange(false)}
      />
    </div>
  );
};

export default React.memo(VerificationChecker);
