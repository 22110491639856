export const catalogNames = {
  BIC: 'bic',
};
export const uploadFileModal = {
  upload: 'Загрузить',
  cancel: 'Отмена',
  fileImport: 'Импорт из файла',
  imported: 'Импортировать',
  file: 'Файл',
  notFound: 'Не найдено значение',
  chooseAttribute: ' Выберите атрибут справочника для импорта',
};
export const tableColumns = [
  {
    title: 'Атрибуты источника',
    dataIndex: 'sourceColumnName',
    key: 'sourceColumnName',
  },
  {
    title: 'Атрибуты справочника',
    dataIndex: 'catalogColumnName',
    key: 'catalogColumnName',
    render: (value) => value,
  },
];
