import React from 'react';
import {Form} from 'antd';

import {StyledInput, StyledButton, StyledForm, StyledFormItem} from '../Themes/Components';
import {layout} from '../Helpers/constants';
import {mdm} from '../../Utils/constant';

/** Форма добавления значений в список
 * @param closeModal - функция закрытия модального окна
 * @param onSubmit - функция для сбора данных с формы */
const ComboboxOptionsForm = ({onSubmit, closeModal}) => {
  /** Данные формы */
  const [form] = Form.useForm();
  return (
    <StyledForm
      {...layout}
      form={form}
      name="combobox-detail-add"
      onFinish={(values) => {
        onSubmit(values);
        closeModal();
      }}
    >
      <StyledFormItem label="Ключ" name="key" rules={[{required: true, message: mdm.requiredMessage}]}>
        <StyledInput />
      </StyledFormItem>
      <StyledFormItem label="Значение" name="value" rules={[{required: true, message: mdm.requiredMessage}]}>
        <StyledInput />
      </StyledFormItem>
      <div className="d-flex justify-content-end">
        <StyledButton sName="dangerBtn" className="mr-3" type="danger" onClick={() => closeModal()}>
          {mdm.cancel}
        </StyledButton>
        <StyledButton sName="primaryBtn" type="primary" htmlType="submit">
          {mdm.save}
        </StyledButton>
      </div>
    </StyledForm>
  );
};

export default ComboboxOptionsForm;
