import React, { useEffect, useState } from 'react';
import {fetchFunc} from "../../Utils/security/http/mdm";
import {Table, Button, Modal} from "antd";
import Records from "../../components/CatalogItemWidgets/Records";
import KYCCheckForm, { KYCselectOptions } from './KYCCheckForm';
import { renderTableDate, renderTableSelect } from '../../components/Helpers/Utils';
import { get } from 'lodash';

const getOptions = (fields, origin) => {
    const field = fields.find(elem => elem.origin === origin);
    if (get(field, 'options.combobox_options')) return field.options.combobox_options;
    else return [];
};

const CatalogsOrgChecks = ({ recordUuid, origin }) => {
    const columns = [
        {
            title: 'Дата начала',
            dataIndex: 'beginDate',
            render: (text, record) => <Button
                onClick={() => showCheckDetail(record.uuid)}
                type='link'
            >
                {renderTableDate(text)}
            </Button>
        },
        {
            title: 'Тип проверки',
            dataIndex: 'checkType',
            render: (text) => renderTableSelect(text, KYCselectOptions)
        },
        {
            title: 'Способ проверки',
            dataIndex: 'checkMethod',
            render: (text) => fields && renderTableSelect(text, getOptions(fields, 'checkMethod'))
        },
        {
            title: 'Результат',
            dataIndex: 'result',
            render: (text) => fields && renderTableSelect(text, getOptions(fields, 'result'))
        },
        {
            title: 'Проверяющий',
            dataIndex: 'inspector',
            render: (text) => text ? `${text.lastName || ''} ${text.firstName || ''} ${text.secondName|| ''}` : '-'
        },
        {
            title: 'Заключение',
            dataIndex: 'resume'
        },
        {
            title: 'Дата окончания проверки',
            dataIndex: 'endDate',
            render: (text) => <div className='text-center' style={{width: 80}}>{renderTableDate(text)}</div>
        },
        {
            title: 'Дата окончания действия',
            dataIndex: 'expirationDate',
            render: (text) => <div className='text-center' style={{width: 80}}>{renderTableDate(text)}</div>
        },
        {
            title: 'Услуга',
            dataIndex: ['serviceId', 'name']
        },
        {
            title: 'Заявка',
            dataIndex: ['orderId', 'caption']
        },
        {
            title: 'ИДО',
            dataIndex: 'ido'
        },
    ];

    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [detailUuid, setDetailUuid] = useState(null);
    const [data, setData] = useState(null);
    const [fields, setFields] = useState(null);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [paramsModalVisible, setParamsModalVisible] = useState(false);
    const [KYCLoading, setKYCLoading] = useState(false);

    useEffect(() => {
        recordUuid && getCheckFields(recordUuid);
    }, [recordUuid]);

    useEffect(() => {
        setLoading(true);
        recordUuid && getCheckData(recordUuid);
    }, [page, size, recordUuid]);

    const KYCCheck = async data => {
        try {
            setKYCLoading(true);
            await fetchFunc({
                url: `/api/v1/bpmn/process/all_counterparty_checks/start`,
                params: {
                    client: recordUuid
                },
                method: 'post',
                data
            }, null, 'bpmn');
            getCheckData(recordUuid);
            setKYCLoading(false);
        } catch (error) {
            setKYCLoading(false);
            throw error;
        }
    };

    const showCheckDetail = (uuid) => {
        setModalShow(true);
        setDetailUuid(uuid)
    };

    const getCheckData = async (uuid) => {
        let pageParam = `&page=${page}`;
        let sizeParam = `&size=${size}`;
        try {
            const fetchData = await fetchFunc({
                url: `/api/v1/catalogs/${origin}/items/search?showRefs=1${pageParam}${sizeParam}`,
                method: 'post',
                data: {
                    checkOrg: {uuid: uuid}
                }
            });
            setData(fetchData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getCheckFields = async () => {
        try {
            const fetchData = await fetchFunc({
                url: `/api/v1/catalogs/${origin}/fields?size=1000`,
                method: 'get',
            });
            setFields(fetchData.content);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleTableChange = (pagination) => {
        setLoading(true);
        setPage(--pagination.current);
        setSize(pagination.pageSize);
    };

    const criterionsField = fields && fields.find(item => item.origin === 'criterions');

    return (
        <div className='org-list__no-structure-items container--white'>
            <Button
                type='primary'
                className='mb-3'
                onClick={() => setParamsModalVisible(true)}
            >
                Запустить проверку
            </Button>
            <Table
                columns={columns}
                dataSource={data && data.content}
                loading={loading}
                rowKey={record => record.uuid}
                onChange={handleTableChange}
                pagination={{
                    showSizeChanger: true,
                    total: data && data.totalElements,
                }}
                bordered
                scroll={{ x: true }}
            />
            <Modal
                visible={modalShow}
                onCancel={() => setModalShow(false)}
                footer={null}
                destroyOnClose={true}
                width={1100}
                title='Критерии оценки'
            >
                {criterionsField && <Records
                    detail = {criterionsField.uuid}
                    referenceOrigin = {origin}
                    recordUuid = {detailUuid}
                />}
            </Modal>
            <Modal
                visible={paramsModalVisible}
                onCancel={() => setParamsModalVisible(false)}
                footer={null}
                width={700}
                title='Параметры проверки'
                destroyOnClose={true}
            >
                <KYCCheckForm
                    onSubmit={KYCCheck}
                    closeModal={() => setParamsModalVisible(false)}
                    loading={KYCLoading}
                />
            </Modal>
        </div>
    );
};

export default CatalogsOrgChecks;
