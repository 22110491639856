import React, {Component} from 'react';
import {isEmpty, isEqual} from 'lodash';
import cn from 'classnames';
import {Button, Select, Switch, Table} from 'antd';

import {recordTable} from './helpers/constant';
import {getFieldsMeta, onError, sortFieldsByOrder} from '../Helpers/Utils';
import {UploadFileModal} from '../UploadFileModal';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {ReactComponent as ExclamationPoint} from '../../assets/images/coolicon.svg';

import './RecordTable.scss';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {showDeleteConfirm} from '../Helpers/Modals';
import {mdm} from '../../Utils/constant';

class RecordTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedColumns: this.props.displayedColumns,
      columnsForRender: this.props.columnsForRender,
      isModalOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {displayedColumns, columnsForRender, file, isVerification, detail} = this.props;
    if (prevProps?.columnsForRender !== columnsForRender) {
      this.setState({columnsForRender});
    }
    if (prevProps?.displayedColumns !== displayedColumns) {
      const newDisplayedColumns = displayedColumns;
      const isRequiredStatusColumn = isEmpty(detail) && !isVerification && newDisplayedColumns[0] !== 'status';
      if (isRequiredStatusColumn) {
        newDisplayedColumns.unshift('status');
      }
      this.setState({displayedColumns: newDisplayedColumns});
    }
    if (prevProps?.file !== file) {
      this.setState({exportValue: null});
    }
  }

  showUploadModal = () => {
    this.setState({...this.state, isModalOpen: true});
  };

  renderAddButton = () => {
    const {
      detail,
      isEdit,
      editable,
      csvLoading,
      openModal,
      onExport,
      editSigns,
      isTree,
      setIsTree,
      treeLoading,
      hierarchyField,
      inputType,
      viewRoute,
      hasDuplicates,
      isDeduplicationRecordsAll,
      setIsDeduplicationRecordsAll,
      isVerifier,
      jsonLoading,
      changeType,
      isUnverified,
    } = this.props;
    let children = '';

    const isAddDetail = detail && (changeType === 'modify' || !isUnverified);
    const isCatalogEditable = editSigns && editSigns.editCatalog && editSigns.inputType !== 'external';

    if ((isAddDetail || isCatalogEditable) && editable && isEdit) {
      children = (
        <Button type="primary" className="mr-2" onClick={() => openModal('create', false)}>
          Добавить
        </Button>
      );
    }
    let renderAddButton = editSigns && editSigns.editCatalog;
    if (inputType && inputType === 'external') renderAddButton = false;
    if (!detail && !isVerifier) {
      children = (
        <>
          {renderAddButton && !viewRoute && (
            <Button type="primary" className="mr-2" onClick={() => openModal('create', false)}>
              Добавить
            </Button>
          )}
          <Button type="primary" className="mr-2" onClick={this.showUploadModal}>
            Импорт из файла
          </Button>
          <Select
            placeholder={recordTable.export}
            loading={this.state.exportValue === 'CSV' ? csvLoading : jsonLoading}
            options={recordTable.formats}
            value={!isEmpty(this.state.file) ? null : this.state.exportValue}
            onChange={(value) => {
              this.setState({exportValue: value});
              onExport(value);
            }}
          />
          {hierarchyField && (
            <div className="ml-3 d-flex justify-content-center align-items-center">
              <p className="mb-1 mr-2">Дерево:</p>
              <Switch
                loading={treeLoading}
                checked={isTree}
                onChange={(prevState) => {
                  setIsTree(!prevState);
                }}
              />
            </div>
          )}
          {hasDuplicates === true && (
            <div className="ml-3 d-flex justify-content-center align-items-center">
              <div className="mb-1 mr-2">{recordTable.duplicates}</div>
              <Switch
                checked={isDeduplicationRecordsAll}
                onChange={(checked) => setIsDeduplicationRecordsAll(!checked)}
              />
            </div>
          )}
        </>
      );
    }
    return <div className="d-flex">{children}</div>;
  };

  handleColumnsSelectChange(value) {
    const {columns, isVerification, handleTableColumnsChange, columnsForRender, detail} = this.props;
    if (handleTableColumnsChange) handleTableColumnsChange(value);
    const newColumnsForRender = columns.filter((item) => value.some((elem) => elem === item.key));
    const isRequiredStatusColumn = isEmpty(detail) && !isVerification && newColumnsForRender[0]?.key !== 'status';

    this.setState({
      displayedColumns: value,
      columnsForRender: isRequiredStatusColumn
        ? [columnsForRender[0], ...sortFieldsByOrder(newColumnsForRender)]
        : [...sortFieldsByOrder(newColumnsForRender)],
    });
  }

  onRow = (record) => {
    const {onDoubleClick, detail, openModal, editable, editSigns, isEdit} = this.props;
    const isEditDetail =
      (detail || (editSigns && editSigns.editCatalog && editSigns.inputType !== 'external')) && editable && isEdit;
    return {
      onDoubleClick: () =>
        isEmpty(detail) ? onDoubleClick(record) : !isEditDetail ? openModal('edit', false, record.uuid) : null,
    };
  };

  handleCancel = () => {
    this.setState({isModalOpen: false});
  };

  importFromFile = async (data) => {
    await fetchFunc({
      url: '/api/v1/catalogs/import',
      method: 'POST',
      data,
    });
    this.handleCancel();
  };

  deleteRecord = async (detailFieldUuid) => {
    const {detail, referenceOrigin, getCatalogItem, isUnverified, recordUuid, history, validateCatalog} = this.props;
    try {
      let url = `/api/v1/catalogs/${referenceOrigin}/items/${detailFieldUuid}`;
      if (detail) {
        url = `/api/v1/catalogs/${referenceOrigin}/detail/${detail}/${recordUuid}/items/${detailFieldUuid}${
          isUnverified ? '/unverified' : ''
        }`;
      }
      await fetchFunc(
        {
          url,
          method: 'delete',
        },
        onError
      );

      if (validateCatalog) {
        let url = `/catalog/${referenceOrigin}/${recordUuid}/verify`;
        if (referenceOrigin === 'org') {
          url = `/catalog/organisations/${recordUuid}/verify`;
        }
        history.push(url);
      } else {
        getCatalogItem();
        this.setState({loading: false});
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {
      renderCustomTable,
      columns,
      data,
      loading,
      handleTableChange,
      isTree,
      detail,
      referenceColumns,
      tableParams,
      editable,
      isEdit,
      editSigns,
      openModal,
      mode,
      tableSource,
    } = this.props;
    const {displayedColumns, columnsForRender, isModalOpen} = this.state;
    let columnsShowOptions = [];
    let maxLength = 0;
    let dropdownMenuWidth;

    if (columnsForRender && columns) {
      columnsShowOptions = columns?.map((item, index) => {
        if (item.title.length > maxLength) {
          maxLength = item.title.length;
        }
        return (
          <Select.Option value={item.key} key={'column-select' + index}>
            {item.title}
          </Select.Option>
        );
      });
    }
    dropdownMenuWidth = maxLength * 5 + 44;
    const EditColumns = [
      {
        title: '',
        key: 'edit',
        width: 20,
        render: (text, record) => (
          <Button
            className="mr-2 border-0"
            onClick={() => {
              openModal('edit', true, record.uuid);
            }}
            type="ghost"
            icon={<EditOutlined />}
          />
        ),
      },
      {
        title: '',
        key: 'delete',
        width: 20,
        render: (text, record) => (
          <Button
            className="border-0"
            onClick={() => {
              this.setState({loading: true});
              showDeleteConfirm({
                content: 'Вы действительно хотите удалить запись справочника?',
                onOk: () => this.deleteRecord(record.uuid),
                okText: mdm.confirm,
                cancelText: mdm.cancel,
              });
            }}
            type="ghost"
            icon={<DeleteOutlined />}
          />
        ),
      },
    ];

    const metaColumns = getFieldsMeta({
      columns: columnsForRender,
      rule: renderCustomTable,
      additionalRule: 'table',
    });
    const isEditDetail =
      (detail || (editSigns && editSigns.editCatalog && editSigns.inputType !== 'external')) && editable && isEdit;
    return (
      <>
        <UploadFileModal
          isModalOpen={isModalOpen}
          handleCancel={this.handleCancel}
          catalogColumns={columns}
          dictName={this.props.referenceOrigin}
          onImport={this.importFromFile}
          referenceColumns={referenceColumns}
        />
        <div className="d-flex mb-2 justify-content-between">
          {this.renderAddButton()}
          <Select
            dropdownStyle={{width: dropdownMenuWidth}}
            dropdownMatchSelectWidth={false}
            mode="multiple"
            name="displayedColumns"
            showArrow={true}
            maxTagCount={0}
            maxTagPlaceholder={'Колонки'}
            style={{width: '130px'}}
            placeholder="Колонки"
            value={displayedColumns}
            onChange={(value) => this.handleColumnsSelectChange(value)}
          >
            {columnsShowOptions}
          </Select>
        </div>
        {isEmpty(displayedColumns) && !isEmpty(data) && !loading && (
          <div className="notificationHiddenAttributes">
            <ExclamationPoint className="mr-2" />
            {recordTable.notificationHiddenAttributes}
          </div>
        )}
        <Table
          style={!isEdit && mode === 'create' ? {cursor: 'pointer', pointerEvents: 'none'} : {cursor: 'pointer'}}
          columns={isEditDetail ? [...metaColumns, ...EditColumns] : metaColumns}
          dataSource={data}
          loading={loading}
          rowKey={(record) => `${record?.uuid}_${record?.id}_${record?.rowKey}_${record?.key}`}
          rowClassName={(record) => {
            const originTableField =
              tableSource && tableSource[0] && tableSource[0].find((field) => field.uuid === record.uuid);
            const newTableField =
              tableSource && tableSource[1] && tableSource[1].find((field) => field.uuid === record.uuid);

            const isChangedField = !isEqual(originTableField, newTableField);

            return cn('', {
              'field-changed': isChangedField,
              'unverified-version': !detail && record?.sysVerifyInfoDraft,
              recordTable__checkMainItemUuidInData: !isEmpty(record.mainItemUuid),
              highlightRow: !detail && record?.sysVerifyInfoDraft,
            });
          }}
          onChange={handleTableChange}
          onRow={this.onRow}
          pagination={(isTree === false || tableParams?.total >= 10) && tableParams}
          bordered
          scroll={{x: true}}
        />
        {!isEdit && mode === 'create' && (
          <div className="notificationHiddenAttributes">
            <ExclamationPoint className="mr-2" />
            Добавление записей детализации доступно только после создания основной записи
          </div>
        )}
      </>
    );
  }
}

export default RecordTable;
