import React, { Component } from 'react';
import './NotAccessPage.scss';

class NotAccessPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className='noaccess'>
                <div>403</div>
                <div className="noaccess__text">
                    Доступ запрещен <span className="blink">_</span>
                </div>
            </div>
        )
    }
}

export default NotAccessPage;
