export const notificationScheduleOptions = [
  {value: 'Отправлять всегда', key: 1},
  {value: 'Будние дни', key: 2},
  {value: 'Выходные дни', key: 3},
];
export const notificationMethodOptions = [
  {value: 'POST', key: 1},
  {value: 'GET', key: 2},
  {value: 'PUT', key: 3},
  {value: 'DELETE', key: 4},
  {value: 'HEAD', key: 5},
  {value: 'PATCH', key: 6},
  {value: 'TRACE', key: 7},
  {value: 'OPTIONS', key: 8},
];
export const notifications = {
  attention: 'Внимание!',
  attentionDescription:
    'Для отправки формы необходимо заполнить и сохранить все поля блока. Заполните все поля, или сверните блок.',
  attentionDescriptionForm: 'Для отправки формы необходимо заполнить хотя бы один блок.',
  form: 'Форма настройки уведомлений',
  templateForm: 'Форма создания шаблона',
  createTemplate: 'Создание шаблона',
  rulesMessage: 'Поле обязательно для заполнения',
  emptySubscription: ' Справочник подписки был удален',
  notFound: 'Не найдено',
  notFoundEvent: 'Для выбранного события нет подписок',
  setting: 'Настройка уведомлений',
  title: 'Название',
  changesSave: 'Изменения сохранены',
  addTitle: 'Введите название',
  active: 'Активно',
  on: 'Вкл.',
  off: 'Выкл.',
  successMessage: 'Уведомление отправлено',
  confirmSendTestTitlePhone: 'Выберите номер телефона для отправки тестового уведомления',
  confirmSendTestTitleEmail: 'Выберите Email для отправки тестового уведомления',
  confirmSendTest: 'Подтвердите отправку уведомления на ',
  confirmDataToSend: 'Подтвердите данные для отправки уведомления',
  patternNumber: 'Введенный номер некорректный',
  noticeTerms: 'Условия уведомлений',
  subscription: 'Подписка',
  selectSubscription: 'Выбрать подписку',
  eventSubscription: 'События подписки ',
  selectEventSubscription: 'Выбрать события подписки',
  addConditionGroup: 'Добавить группу условий',
  formMethods: 'Способы уведомлений',
  test: 'Протестировать уведомление',
  telephone: 'Телефон',
  emails: 'Email',
  template: 'Шаблоны',
  addTemplate: 'Добавить шаблон',
  previewTitle: 'Предпросмотр текста уведомления',
  testTemplate: 'Тестовая отправка уведомления',
  selectTemplate: 'Выбрать шаблон',
  selectSchedule: 'Выбрать график',
  selectMethod: 'Выбрать метод',
  emailAdd: 'Email',
  addNumber: ' Добавить номер',
  addEmail: ' Добавить Email',
  text: 'Текст уведомления',
  preview: 'Предпросмотр уведомления',
  schedule: 'График',
  subject: 'Тема',
  url: 'Url',
  methodsHttp: 'Метод',
  sms: 'SMS',
  email: 'EMAIL',
  http: 'HTTP',
  cancel: 'Отменить',
  close: 'Закрыть',
  save: 'Сохранить',
  send: 'Отправить',
  edit: 'Редактировать',
  fresh: 'Сбросить',
  goBack: 'Назад',
  choose: 'Выбрать',
  popoverOne: ' Недоступны к выбору события,которые уже используютя в справочнике - ',
  popoverTwo: 'и шаблоне - ',
  hintContentsTextForTextNotification: [
    'В сообщении можно использовать следующие параметры справочника:',
    '1. Наименование каталога, параметр: props.catalogCaption.',
    '2. Идентификатор записи, параметр: props.uuid ',
    '3. Автор изменения записи, параметр: props.authorFio.',
    '4. Версия измененной записи, параметр: props.version.',
  ],
};
