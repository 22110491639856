
export const structureBranch = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'separateDivision', label: '', items: [
                    { origin: 'separateDivision', type: 'item' },
                ],
            },
        ]
    }
];

export const structureLicense = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'license', label: '', items: [
                    { origin: 'license', type: 'item' },
                ],
            },
        ]
    }
];

export const structureExport = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'exportExperience', label: '', items: [
                    { origin: 'exportExperience', type: 'item' },
                    { origin: 'congressExperience', type: 'item' },
                ],
            },
        ]
    }
];

export const structureRating = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'rating', label: '', items: [
                    { origin: 'rating', type: 'item' },
                    { origin: 'limits', type: 'item' },
                ],
            },
        ]
    }
]
