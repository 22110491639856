import React, {useEffect, useState} from 'react';
import {Collapse, Alert} from 'antd';
import moment from 'moment';
import {isEmpty} from 'lodash';
import {renderDate, renderVerifierFio, renderStatus, getAuthorFio} from '../Helpers/Utils';
import {Link, useRouteMatch} from 'react-router-dom';

const {Panel} = Collapse;

const getCardUrl = (referenceOrigin, recordUuid) => {
  if (referenceOrigin === 'organisations') {
    return `/${referenceOrigin}/${recordUuid}`;
  }
  return `/catalog/${referenceOrigin}/${recordUuid}`;
};

const SystemInformation = ({
  statusDate,
  data,
  recordUuid,
  referenceOriginLocal,
  validateCatalog,
  businessVersions,
  onVersionOpen,
}) => {
  const {
    uuid,
    version,
    createDate,
    dateForm,
    authorFio,
    sysVerifyInfo,
    sysVerifyInfoDraft,
    id,
    processInstanceId,
    regNumber,
  } = data;

  const [postAuthor, setPostAuthor] = useState('');

  const match = useRouteMatch();
  const {
    params: {scope},
  } = match;

  useEffect(() => {
    const changeType = sysVerifyInfo?.changeType;
    if (!isEmpty(data) && scope !== 'verifier-list' && changeType !== 'add') {
      (async () => {
        const {authorFio} = await getAuthorFio({uuid: recordUuid, match});
        setPostAuthor(authorFio);
      })();
    }
  }, [data]);

  return (
    <Collapse>
      <Panel header="Системная информация" key="1">
        <div className="mb-4">
          <div className="d-flex mb-1">
            <div className="col-6">
              <strong>UUID:</strong> {uuid}
            </div>
            <div className="col-6">
              <strong>Версия:</strong>{' '}
              {version || (sysVerifyInfo && sysVerifyInfo?.sourceId && sysVerifyInfo?.sourceId?.version)}
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="col-6">
              <strong>Дата создания:</strong> {moment(createDate).format('DD-MM-YYYY')}
            </div>
            <div className="col-6">
              <strong>Дата изменения:</strong> {moment(dateForm).format('DD-MM-YYYY') || '-'}
            </div>
          </div>
          {referenceOriginLocal === 'service' && (
            <div className="d-flex mb-1">
              <div className="col-6">
                <strong>Дата присвоения статуса:</strong> {statusDate || '-'}
              </div>
              <div className="col-6">
                <strong>
                  Идентификатор экземпляра <br /> процесса согласования:
                </strong>{' '}
                {processInstanceId || '-'}
              </div>
            </div>
          )}
          <div className="d-flex mb-1">
            <div className="col-6">
              <strong>Автор изменений:</strong> {authorFio}
            </div>
            <div className="col-6">
              <strong>Идентификатор:</strong> {id}
            </div>
          </div>
          <div className="d-flex mb-1">
            {scope !== 'verifier-list' && (
              <div className="col-6">
                <strong>Автор записи:</strong> {postAuthor}
              </div>
            )}
            {referenceOriginLocal === 'service' && (
              <div className="col-6">
                <strong>Реестровый номер:</strong> {regNumber}
              </div>
            )}
          </div>
          {businessVersions && (
            <>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <strong>Текущая версия:</strong> {data?.versionNumber}
                </div>
              </div>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <span className="link" onClick={onVersionOpen}>
                    Последняя действующая версия
                  </span>
                </div>
              </div>
            </>
          )}
          {validateCatalog && (
            <>
              <div className="d-flex mb-1 mt-4">
                <div className="col-9 documents__info-title">Сведения о верификации</div>
              </div>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <strong>Статус:</strong>{' '}
                  {renderStatus(data, sysVerifyInfo ? 'sysVerifyInfo.status' : 'sysVerifyInfoDraft.status')}
                </div>
              </div>
              {sysVerifyInfoDraft && (
                <div className="d-flex mb-1 pl-3">
                  <Alert
                    message={
                      <div>
                        Для записи справочника существует версия, ещё не прошедшая верификацию.{' '}
                        <Link to={`/catalog/${referenceOriginLocal}/${recordUuid}/verify`}>Перейти</Link>
                      </div>
                    }
                    type="info"
                    className="mb-2"
                  />
                </div>
              )}
              {sysVerifyInfo &&
                (sysVerifyInfo.changeType === 'modify' || sysVerifyInfo.changeType === 'delete') &&
                sysVerifyInfo.status !== 'approved' && (
                  <div className="d-flex mb-1 pl-3">
                    <Alert
                      message={
                        <div>
                          Для записи справочника существует действующая версия.{' '}
                          <Link to={getCardUrl(referenceOriginLocal, recordUuid)}>Перейти</Link>
                        </div>
                      }
                      type="info"
                      className="mb-2"
                    />
                  </div>
                )}
              <div className="d-flex mb-1">
                <div className="col-6">
                  <strong>Верификатор:</strong>{' '}
                  {renderVerifierFio(sysVerifyInfo ? 'sysVerifyInfo.verifier' : 'sysVerifyInfoDraft.verifier', data)}
                </div>
              </div>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <strong>Дата начала верификации:</strong>{' '}
                  {renderDate(sysVerifyInfo ? 'sysVerifyInfo.dateFrom' : 'sysVerifyInfoDraft.dateFrom', data)}
                </div>
              </div>
              <div className="d-flex mb-1">
                <div className="col-6">
                  <strong>Дата окончания верификации:</strong>{' '}
                  {renderDate(sysVerifyInfo ? 'sysVerifyInfo.dateTo' : 'sysVerifyInfoDraft.dateTo', data)}
                </div>
              </div>
            </>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default SystemInformation;
