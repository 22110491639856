import {useCallback, useState} from 'react';
import {fetchFunc} from '../Utils/security/http/mdm';
import {errorModalCreate} from '../components/Helpers/Modals';

const useCRUD = ({apiUrl, service, onDeleteError, onCreateError, idField = 'uuid', additionalParamString = ''}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState({});
  const [fields, setFields] = useState([]);

  const getData = useCallback(
    async ({page = 0, size = 10, params, type, body}) => {
      try {
        setLoading(true);
        const data = await fetchFunc(
          {
            url: `${apiUrl}${additionalParamString}${type === 'search' ? '&search' : ''}`,
            method: type === 'search' ? 'post' : 'get',
            data: body,
            params: {
              page,
              size,
              ...params,
            },
          },
          null,
          service
        );
        setData(data.content);
        setTotal(data.totalElements);
        setLoading(false);
        return data.totalElements;
      } catch (error) {
        setLoading(false);
      }
    },
    [apiUrl, service]
  );

  const changeData = async (values, editableItem, params) => {
    try {
      setLoading(true);
      await fetchFunc(
        {
          url: `${apiUrl}${editableItem && editableItem[idField] ? `/${editableItem[idField]}` : ''}`,
          method: editableItem && editableItem[idField] ? 'put' : 'post',
          data: values,
          params,
        },
        onCreateError,
        service
      );
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const deleteData = async (uuid) => {
    try {
      await fetchFunc(
        {
          url: `${apiUrl}/${uuid}`,
          method: 'delete',
        },
        onDeleteError,
        service
      );
    } catch (error) {
      throw error;
    }
  };

  const getItem = async (uuid) => {
    try {
      setLoading(true);
      let record = await fetchFunc(
        {
          url: `${apiUrl}/${uuid}`,
        },
        null,
        service
      );
      setRecord(record);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const getCatalogFields = async (catalog) => {
    try {
      const response = await fetchFunc({
        url: `/api/v1/catalogs/${catalog}/fields?size=1000`,
        method: 'get',
      });
      setFields(response.content);
    } catch (error) {
      errorModalCreate((error.response && error.response.data.message) || 'Неизвестная ошибка');
    }
  };

  return {
    getData,
    changeData,
    deleteData,
    getItem,
    loading,
    data,
    total,
    record,
    clearRecord: () => setRecord({}),
    getCatalogFields,
    fields,
  };
};

export default useCRUD;
