export const catalogsFilters = {
  searchPlaceholder: 'Контекстный поиск',
  search: 'Поиск',
  historySearch: 'Фильтрация по истории изменений',
  filterSearch: 'Фильтрация по атрибутам',
  attributeForSearch: 'Атрибуты для поиска: ',
  catalogName: 'Наименование в БД',
  uuid: 'UUID',
  popoverFreshFilter: 'Очистить все значения фильтров',
  freshFilter: 'Очистить фильтр',
  reset: 'Сброс',
  placeholderSearch: 'Укажите наименование атрибута для поиска',
  attention: 'Внимание!',
  message: 'Невозможно выбрать одинаковый атрибут для одной группы',
  popover: 'Достигнуто максимальное количество фильтров - 3',
  addGroup: 'Добавить группу',
  operator: 'Оператор',
  addFilter: 'Добавить фильтр',
  attribute: 'Атрибут',
};
export const operatorOptions = [
  {key: 'and', value: 'И'},
  {key: 'or', value: 'ИЛИ'},
];

export const integerConditions = [
  {value: '=', label: '='},
  {value: '_from', label: '>='},
  {value: '_to', label: '<='},
];

export const stringConditions = [
  {value: '_like', label: 'Содержит'},
  {value: '_in', label: 'Совпадает'},
  {value: '_startlike', label: 'Начинается с'},
];
