import _, {isArray, isEmpty, isUndefined, get} from 'lodash';

export const processRule = (rule = {}, data) => {
  const joinRule = _.has(rule, 'or') ? 'or' : 'and';
  let flag = false;
  if (joinRule === 'and') flag = true;
  _.forIn(rule[joinRule], (value, key) => {
    const fieldData = _.hasIn(data, key) ? get(data, key) : '';
    let matching = fieldData === value;
    if (Array.isArray(value)) {
      let index = value.findIndex((elem) => elem === fieldData);
      matching = index !== -1;
    }
    if (value === 'no_empty') matching = fieldData;
    if (joinRule === 'or') {
      if (matching) flag = true;
    } else {
      if (!matching) flag = false;
    }
  });
  return flag;
};

export const searchProcessRule = (rules, data) => {
  return (
    rules &&
    rules.find((item) => {
      return processRule(item.rule, data);
    })
  );
};

export const startRuleProcess = (rule, data) => {
  const ruleIsArray = _.isArray(rule);
  if (!isEmpty(data)) {
    if (!ruleIsArray && isUndefined(rule.rule)) return true;
    else {
      if (ruleIsArray) {
        const suitableRule = searchProcessRule(rule, data);
        if (suitableRule) return processRule(suitableRule.rule, data);
        else return true;
      } else {
        return processRule(rule.rule, data);
      }
    }
    //return (!ruleIsArray && isUndefined(rule.rule)) ? true : processRule(ruleIsArray ? searchProcessRule(rule, data).rule : rule.rule, data);
  }
};

export const getValueForProcess = (rule, valueName, data) => {
  return isArray(rule) ? searchProcessRule(rule, data)[valueName] : rule[valueName];
};

const setupField = (rules, data) => {
  const r = {
    visible: {
      rule: {or: {actual: true, ccode: 'AOA1'}},
    },
    required: {
      rule: {and: {actual: true, ccode: 'AOA'}},
    },
    caption: {
      rule: {or: {actual: true, ccode: 'AOA'}},
      caption: 'Новый заголовок',
    },
    validation: {
      rule: {or: {actual: true, ccode: 'AOA'}},
      pattern: /^([А-Яа-яA-Za-z0-9-_]+)@([А-Яа-яA-Za-z0-9-_]+)\.([А-Яа-яA-Za-z0-9-_]{2,6})$/,
    },
    mask: {
      rule: {or: {actual: true, ccode: 'AOA'}},
      mask: '+7 (999) 999-99-99',
    },
    location: {
      rule: {or: {actual: true, ccode: 'AOA'}},
      tab: 'tab1',
      block: 'block1',
      fieldOrder: null,
    },
  };

  const {visible, required, validation, caption, mask, location, value} = rules;

  const getRuleValue = (rule, defaultFlag = false) => {
    let processRuleObj = rule;
    if (isArray(rule)) {
      processRuleObj = searchProcessRule(rule, data);
    }
    if (processRuleObj && processRuleObj.value) {
      return processRuleObj.value;
    } else if (processRuleObj && processRuleObj.rule) {
      return processRule(processRuleObj.rule, data);
    } else {
      return defaultFlag;
    }
  };

  return {
    visible: visible ? getRuleValue(visible) : true,
    visibleRule: visible,
    required: required ? getRuleValue(required) : false,
    requiredRule: required,
    caption: caption ? (startRuleProcess(caption, data) ? getValueForProcess(caption, 'caption', data) : null) : null,
    captionRule: caption,
    validation: validation
      ? startRuleProcess(validation, data)
        ? getValueForProcess(validation, 'pattern', data)
        : null
      : null,
    validationRule: validation,
    mask: mask ? (startRuleProcess(mask, data) ? getValueForProcess(mask, 'mask', data) : null) : null,
    maskRule: mask,
    value: value ? (startRuleProcess(value, data) ? getValueForProcess(value, 'value', data) : null) : null,
    valueRule: value,
  };
};

export const getRelatedFieldsFromRule = (rule) => {
  if (rule) {
    const joinRule = _.has(rule, 'or') ? 'or' : 'and';
    let fieldKeys = [];
    _.forIn(rule[joinRule], (value, key) => {
      fieldKeys.push(key);
    });
    return fieldKeys;
  } else return null;
};

export function getFieldConfig(rules, data) {
  return setupField(rules, data);
}

const optionsUnifier = (incomeOptions, frontOptions) => {
  let resultOptions = {
    ...incomeOptions,
  };
  _.forIn(frontOptions, (value, key) => {
    if (!_.has(resultOptions, key)) {
      resultOptions[key] = value;
    }
  });
  return resultOptions;
};

export const metaUnifier = (incomeMeta, frontMeta) => {
  return incomeMeta.map((incomeItem) => {
    let resultMetaItem = {};
    const sameFrontMetaItem = frontMeta.find((item) => item.origin === incomeItem.origin);
    if (sameFrontMetaItem) {
      if (!incomeItem.options) {
        resultMetaItem = {
          ...incomeItem,
          options: sameFrontMetaItem.options,
        };
      } else {
        resultMetaItem = {
          ...incomeItem,
          options: optionsUnifier(incomeItem.options, sameFrontMetaItem.options),
        };
      }
    } else resultMetaItem = incomeItem;
    return resultMetaItem;
  });
};

export const ruleValuesGenerator = (namesArr, store, data = {}) => {
  console.log(data, 'data');
  let resultObj = {};
  namesArr.forEach((item) => {
    if (item.indexOf('.') !== -1 && !['countryId.uuid'].includes(item)) {
      return (resultObj[item] = get(data, item));
    } else {
      return (resultObj[item] = _.isFunction(store) ? store(item) : get(store, item));
    }
  });
  return resultObj;
};
