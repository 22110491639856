import {SET_MENU_ACCESS} from '../actions/actionTypes';

const initialState = {
  menuAccess: {
    hasSchedulerAccess: false,
    hasCounterpartyVerificationAccess: false,
    hasSystemCatalogsAccess: false,
    hasTypeCreateAccess: false,
    hasSubscriptionsAccess: false,
  },
};

export const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_ACCESS:
      return {
        ...state,
        menuAccess: action.payload,
      };
    default:
      return state;
  }
};
