import React, { useEffect, useState } from 'react';
import {fetchFunc} from "../../Utils/security/http/mdm";
import _ from "lodash";
import {charCounter, createReferenceScriptText} from "../Helpers/Utils";


const ReferenceReader = ({
                             catalog,
                             uuid,
                             code,
                             apiUrl,
                             apiInfoUrl,
                             href,
                             referenceCatalogPattern,
                             referenceScript,
                             alternativeReferenceCatalogPattern = null,
                             displayedFieldSeparator = ' ',
                             customView,
                             detailMode,
                             viewRoute}) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        ((catalog && uuid) || apiInfoUrl) && getReferenceInfo(catalog, uuid);
        catalog && code && getReferenceList(catalog, code);
    }, [catalog, uuid, code]);

    const getReferenceInfo = async (catalog, uuid) => {
        let depth = detailMode ? 1 : 0;

        if (_.isString(referenceCatalogPattern)) {
            const patternArr = referenceCatalogPattern.split(';');
            depth = charCounter(patternArr[1] || patternArr[0],/[^.]/g);
        }

        try {
            const fetchData = await fetchFunc({
                url: apiInfoUrl || `/api/v1/catalogs/${catalog}/items/${uuid}?showRefs=${depth}`,
                method: 'get'
            });
            setData(fetchData);
        } catch (error) {
            console.log(error)
        }
    };

    const getReferenceList = async (catalog, code) => {
        try {
            const fetchData = await fetchFunc({
                url: apiUrl || `/api/v1/catalogs/${catalog}/items`,
                method: 'get'
            });
            const refItem = fetchData.content.find(item => item.code === code);
            refItem && setData(refItem);
        } catch (error) {
            console.log(error)
        }
    };

    const renderLabel = (data) => {
        let text = '';
        if (referenceScript) {
            text = createReferenceScriptText(data, referenceScript)
        } else {
            if (_.isArray(referenceCatalogPattern)) {
                referenceCatalogPattern.forEach((item, index) => {
                    text = text + _.get(data, item) + (index !== referenceCatalogPattern.length - 1 ?
                        displayedFieldSeparator : '')
                });
            } else {
                const patternArr = referenceCatalogPattern.split(';');
                if(patternArr.length > 2) {
                    text = `${data[patternArr[1]]} - ${data[patternArr[2]]}`
                } else {
                    const patternStr = patternArr[1] || patternArr[0];
                    if(patternStr.indexOf('.') !== -1) {
                        const path = patternStr.split('.');
                        text = _.get(data, path)
                    } else {
                        text = `${data[patternStr] || data[alternativeReferenceCatalogPattern]}` // обработан только частный случай с caption - description, для других мест не требуется
                    }
                }
            }
        }
        return text || `Значение c паттен ${referenceCatalogPattern} не заполнено в базе`
    };

    const renderCustomView = (data) => {
        const columnsGreed = parseInt(12/customView.length);
        return customView.map((item, index) => {
            const last = index ===  customView.length - 1;
            return <div className={`col-${columnsGreed} mt-3 pl-0` + (last ? ' pr-0' : '')} key={index}>
                <div className='mb-1'><b>{item.label}</b></div>
                <div className='field__item--bordered'>{data[item.pattern] || '-'}</div>
            </div>
        })
    };

    return <div className={customView ? 'd-flex' : ''}>
        {customView ? data && renderCustomView(data) :
            <>
                {apiUrl ? data && (referenceCatalogPattern ? renderLabel(data) : data.caption || data.description) :
                    <a href={href || (viewRoute ? `${viewRoute}/${uuid}` : `/catalog/${catalog}/${uuid}`)} target='_blank' rel='noopener noreferrer'>
                        {data && (referenceCatalogPattern ? renderLabel(data) : data.caption || data.description)}
                    </a>
                }
            </>
        }
    </div>
};

export default ReferenceReader;
