import React from 'react';
import {Form, Button, DatePicker} from 'antd';

const VersionsByDateForm = ({onFinish}) => {
  return (
    <div className="mt-3">
      <Form className="d-flex" name="versions-by-date" onFinish={onFinish}>
        <Form.Item name="date" label="Показать версию на дату">
          <DatePicker format={'DD.MM.YYYY'} />
        </Form.Item>
        {/*<Form.Item*/}
        {/*    name='includeDeprecatedHistory'*/}
        {/*    initialValue={false}*/}
        {/*    valuePropName='checked'*/}
        {/*>*/}
        {/*    <Checkbox className='ml-3'>*/}
        {/*        Включая дубли*/}
        {/*    </Checkbox>*/}
        {/*</Form.Item>*/}
        <Form.Item className="text-right">
          <Button className="ml-3" type="primary" htmlType="submit">
            Показать
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VersionsByDateForm;
