import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Checkbox, Collapse, DatePicker, Form, Input, InputNumber, notification, Popover, Select} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import _, {concat, get, isEmpty} from 'lodash';
import ruRU from 'antd/lib/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';

import {mdm} from '../../../../Utils/constant';
import DynamicSelect from '../../../FormElements/DynamicSelect';
import {catalogsFilters, integerConditions, operatorOptions, stringConditions} from '../../helpers/constant';
import {backendDateFormat, convertDateToPayload, defaultDateFormat} from '../../../Helpers/Utils';
import {setFilterReset, setFilterSearchValue} from '../../../../redux/actions/actions';
import {ReactComponent as ExclamationPoint} from '../../../../assets/images/coolicon.svg';

import './FilterAttributes.scss';

/**
 * Фильтрация по аттрибутам
 * @param form - данные формы
 * @param incomeFields - данные атрибутов
 * @param activeKeyCollapse - ключ панели
 * @param setActiveKeyCollapse -функция переключения панели
 * @param groups - группы фильтра
 * @param setGroups - сеттер групп
 * @param validateError - ошибки валидации
 * @param setValidateError - сеттер ошибок валидации
 */
const FilterAttributes = ({
  incomeFields,
  form,
  activeKeyCollapse,
  setActiveKeyCollapse,
  groups,
  setGroups,
  validateError,
  setValidateError,
}) => {
  dayjs.locale('ru');
  const {Panel} = Collapse;
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Информация о полях справочника */
  const [fields, setFields] = useState([]);
  /** Массив операторов */
  const [operators, setOperators] = useState([]);
  /** Атрибуты */
  const [attributeOptions, setAttributeOptions] = useState([]);
  /** Значение оператора внутри группы для отправки значений фильтра */
  const [operator, setOperator] = useState('and');
  /** Оператор между группами для отправки значений фильтра */
  const [operatorLengthGroupsArr, setOperatorLengthGroupsArr] = useState([]);
  /** Массив значений оператора внутри группы */
  const [operatorValueArr, setOperatorValueArr] = useState([operatorOptions[0].value]);
  /** Массив значений оператора между группами */
  const [operatorLengthGroupsValueArr, setOperatorLengthGroupsValueArr] = useState([]);
  /** Индекс группы */
  const [indexGroup, setIndexGroup] = useState(null);
  /** Состояние для всплывающего уведомления */
  const [api, contextHolder] = notification.useNotification();
  /** Состояние для валидации формы */
  const filterErrorFlag = useSelector((state) => state?.filter.filterErrorAttribute);
  /** Получаем поля справочника */
  useEffect(() => {
    incomeFields && setFields(concat(fields, incomeFields));
  }, [incomeFields]);
  /** Обновляем поля удовлетворяющие условию */
  useEffect(() => {
    let newFields = [];
    fields &&
      fields.forEach((item) => {
        if (item.fieldType.id !== 12) {
          newFields.push(
            <Select.Option key={item.origin} value={item.origin}>
              {item.caption}
            </Select.Option>
          );
        }
      });
    setAttributeOptions(newFields);
  }, [fields]);
  /** Функция для оповещения при выборе атриюута в группе */
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: catalogsFilters.attention,
      description: catalogsFilters.message,
    });
  };
  /** Функция добавления группы */
  const addGroup = () => {
    setIndexGroup(groups.length);
    setGroups([
      ...groups,
      {
        value: 'and',
        operator: operator,
        valueOut: groups.length ? operatorLengthGroupsArr[indexGroup] : 'and',
        groupNumber: groups.length,
        filters: [
          {
            attribute: '',
            value: '',
          },
        ],
      },
    ]);
    if (groups.length) {
      let newOperators = operators;
      let newOperatorsValueArr = operatorValueArr;
      let newOperatorLengthGroupsValueArr = operatorLengthGroupsValueArr;
      let newOperatorLengthGroupsArr = operatorLengthGroupsArr;
      newOperators.push(operatorOptions[0].value);
      newOperatorsValueArr.push(operatorOptions[0].value);
      newOperatorLengthGroupsValueArr.push(operatorOptions[0].value);
      newOperatorLengthGroupsArr.push('and');
      setOperators([...newOperators]);
      setOperatorValueArr([...newOperatorsValueArr]);
      setOperatorLengthGroupsArr([...newOperatorLengthGroupsArr]);
      setOperatorLengthGroupsValueArr([...newOperatorLengthGroupsValueArr]);
    }
  };
  /** Функция сеттер оператора внутри группы */
  const handleFilterOperatorChange = (value, index, option) => {
    let newGroups = groups;
    let newOperatorsValueArr = operatorValueArr;
    setOperator(option.key);
    newOperatorsValueArr[index] = value;
    newGroups[index].value = option.key;
    setGroups([...newGroups]);
    setOperatorValueArr([...newOperatorsValueArr]);
    getFilterBody();
  };
  /** Функция сеттер оператора между группами */
  const handleOperatorChange = (value, index, option) => {
    let newGroups = groups;
    let newOperators = operators;
    let newOperatorLengthGroupsValueArr = operatorLengthGroupsValueArr;
    let newOperatorLengthGroupsArr = operatorLengthGroupsArr;
    newOperatorLengthGroupsValueArr[index] = value;
    newOperatorLengthGroupsArr[index] = option.key;
    newGroups[index + 1].valueOut = option.key;
    setGroups([...newGroups]);
    setOperators([...newOperators]);
    setOperatorLengthGroupsArr([...newOperatorLengthGroupsArr]);
    setOperatorLengthGroupsValueArr([...newOperatorLengthGroupsValueArr]);
    getFilterBody();
  };
  /** Удаление фильтра в группе */
  const deleteFilter = (index, filterIndex) => {
    const {resetFields, getFieldValue, setFieldsValue} = form;
    resetFields([
      `attribute-${index}-${filterIndex}`,
      `condition-${index}-${filterIndex}`,
      `field-${index}-${filterIndex}`,
    ]);
    let newGroups = groups;
    const newGroupsFilterLength = newGroups[index].filters && newGroups[index].filters.length;

    if (newGroupsFilterLength > 1) {
      for (let i = filterIndex + 1; i < newGroupsFilterLength; i++) {
        setFieldsValue({
          [`attribute-${index}-${i - 1}`]: getFieldValue(`attribute-${index}-${i}`),
          [`condition-${index}-${i - 1}`]: getFieldValue(`condition-${index}-${i}`),
          [`field-${index}-${i - 1}`]: getFieldValue(`field-${index}-${i}`),
        });
        resetFields([`attribute-${index}-${i}`, `condition-${index}-${i}`, `field-${index}-${i}`]);
      }
    }
    newGroups[index].filters.splice(filterIndex, isEmpty(validateError) ? 1 : validateError?.length);
    getFilterBody();
    setGroups([...newGroups]);
  };
  /** Удаление группы */
  const deleteGroup = (index) => {
    const {resetFields, getFieldValue, setFieldsValue} = form;
    const fieldsForReset = [];
    groups[index].filters &&
      groups[index].filters.forEach((item, filterIndex) => {
        fieldsForReset.push(
          `attribute-${index}-${filterIndex}`,
          `condition-${index}-${filterIndex}`,
          `field-${index}-${filterIndex}`
        );
      });
    resetFields(fieldsForReset);
    let newGroups = groups;
    let newOperatorLengthGroupsValueArr = operatorLengthGroupsValueArr;
    let newGroupsLength = newGroups.length;
    if (newGroupsLength > 1) {
      for (let i = index + 1; i < newGroupsLength; i++) {
        newGroups[i].filters.forEach((item, filterIndex) => {
          setFieldsValue({
            [`attribute-${i - 1}-${filterIndex}`]: getFieldValue(`attribute-${i}-${filterIndex}`),
            [`condition-${i - 1}-${filterIndex}`]: getFieldValue(`condition-${i}-${filterIndex}`),
            [`field-${i - 1}-${filterIndex}`]: getFieldValue(`field-${i}-${filterIndex}`),
          });
          resetFields([`attribute-${i}-${filterIndex}`, `condition-${i}-${filterIndex}`, `field-${i}-${filterIndex}`]);
        });
      }
    }
    newGroups.splice(index, 1);
    newOperatorLengthGroupsValueArr.splice(index - 1, 1);
    let newOperators = operators;
    newOperators.splice(index - 1, 1);
    setOperatorLengthGroupsValueArr([...newOperatorLengthGroupsValueArr]);
    setGroups([...newGroups]);
    setOperators(newOperators);
  };
  /** Функция сеттер условия в зависимости от типа поля */
  const handleFilterConditionChange = (value, index, filterIndex) => {
    let newGroups = groups;
    newGroups[index].filters[filterIndex].condition = value;
    getFilterBody();
    setGroups([...newGroups]);
  };
  /** Функция сеттер атрибута */
  const handleAttributeChange = (value, index, filterIndex) => {
    const attributeInOneGroup = groups
      ?.find((item) => item.groupNumber === index)
      ?.filters.map((item) => item.attribute);
    if (!isEmpty(attributeInOneGroup)) {
      if (attributeInOneGroup?.includes(value) && operator === 'and') {
        form.resetFields([
          `attribute-${index}-${filterIndex}`,
          `condition-${index}-${filterIndex}`,
          `field-${index}-${filterIndex}`,
        ]);
        deleteFilter(index, filterIndex);
        openNotificationWithIcon('warning');
      } else {
        let newGroups = groups;
        const fieldOrigin = value;
        const field = fields && fields.find((elem) => elem.origin === fieldOrigin);
        if (value !== newGroups[index].filters[filterIndex].attribute) {
          form.resetFields([`condition-${index}-${filterIndex}`, `field-${index}-${filterIndex}`]);
        }
        switch (field && field.fieldType.id) {
          case 1:
          case 11:
            newGroups[index].filters[filterIndex].attribute = value;
            newGroups[index].filters[filterIndex].value = '';
            setGroups([...newGroups]);
            break;
          default:
            newGroups[index].filters[filterIndex].attribute = value;
            newGroups[index].filters[filterIndex].value = '';
        }
        getFilterBody();
        setGroups([...newGroups]);
      }
    }
  };
  /** Функция сеттер значения */
  const handleFilterValueChange = (value, index, filterIndex, fieldName) => {
    let newGroups = groups;
    switch (fieldName) {
      case 'Date':
        newGroups[index].filters[filterIndex].value = value && value.format(backendDateFormat);
        break;
      case 'DateTime':
        newGroups[index].filters[filterIndex].value = value && convertDateToPayload(value);
        break;
      default:
        newGroups[index].filters[filterIndex].value = value;
    }
    getFilterBody();
    setGroups([...newGroups]);
  };
  /** Добавление фильтра */
  const addFilter = (index) => {
    let newGroups = groups;
    newGroups[index].filters.push({
      attribute: '',
      value: '',
    });
    setGroups([...newGroups]);
  };
  /** Функция сбора данных фильтра для отправки */
  const getFilterBody = () => {
    let and = [];
    let or = [...groups];
    const secondGroup = groups?.find((item) => item?.groupNumber === 1)?.valueOut;
    let core = {
      value: isEmpty(secondGroup) ? 'and' : secondGroup,
      filters: [],
    };
    operators.forEach((item, index) => {
      if (item === 'and') {
        if (operators[index - 1] === 'and') {
          and.push(groups[index + 1]);
          let delIndex = or.findIndex((elem) => elem.groupNumber === groups[index + 1].groupNumber);
          or.splice(delIndex, 1);
        } else {
          and.push(groups[index], groups[index + 1]);
          let delIndex = or.findIndex((elem) => elem.groupNumber === groups[index].groupNumber);
          or.splice(delIndex, 2);
        }
      }
    });
    if (and.length) {
      or.push({
        value: groups[indexGroup].operator,
        filters: [...and],
      });
    }
    core.filters = or;
    dispatch(setFilterSearchValue(getFilters(core)));
  };
  /** Функция подготовки данных для отправки */
  const getFilters = useCallback(
    (core) => {
      let obj = {
        [core.value]: [],
      };
      let group = {};
      let groupArr = [];
      const thirdGroup = groups?.find((item) => item?.groupNumber === 2)?.valueOut;
      let lastGroup = {};
      core.filters.forEach((item) => {
        if (item.filters) {
          group = {
            ...getFilters(item),
          };
          if (item?.groupNumber < 2 || (item?.groupNumber === 2 && thirdGroup === core.value)) {
            groupArr.push(group);
          } else {
            lastGroup = [getFilters(item)];
          }
        } else {
          let filterItem = getFilterItem(item, group);
          group = {
            ...filterItem,
          };

          groupArr.push(group);
        }
      });
      if (groups?.length === 3) {
        lastGroup = !isEmpty(lastGroup) && {[thirdGroup]: [...lastGroup]};
        obj[core.value] = [...groupArr];
        if (thirdGroup !== core.value) {
          obj = {
            [thirdGroup]: {...lastGroup, ...obj},
          };
        }
      } else {
        obj[core.value] = [...groupArr];
      }
      return obj;
    },
    [groups]
  );
  /** Функция подготовки данных  с учетом атрибута и значения */
  const getFilterItem = ({attribute, condition, value}, core) => {
    if (Array.isArray(value)) {
      if (condition === '!=') {
        return {
          and: {
            [`${attribute}_from`]: value[1],
            [`${attribute}_to`]: value[0],
          },
        };
      } else {
        if (attribute === 'status') {
          return {
            and: {
              [`${attribute}_in`]: value,
            },
          };
        } else {
          return {
            and: {
              [`${attribute}_from`]: value[0],
              [`${attribute}_to`]: value[1],
            },
          };
        }
      }
    } else {
      if (!_.isUndefined(condition)) {
        switch (condition) {
          case '!=':
            attribute = `wo_${attribute}`;
            break;
          case '=':
          case '':
            attribute = `${attribute}`;
            break;
          default:
            attribute = `${attribute}${condition}`;
        }
      } else {
        attribute = `${attribute}_in`;
      }
    }
    if (core[attribute] || core[`${attribute}_in`]) {
      if (core[attribute]) {
        value = [value];
        value.push(core[attribute]);
        delete core[attribute];
      } else {
        value = [value, ...core[`${attribute}`]];
      }
      attribute = `${attribute}`;
    }
    return {
      [attribute]: value,
    };
  };
  /** Очистка фильтра */
  const onReset = () => {
    form.resetFields();
    setOperators([]);
    setGroups([]);
    setOperatorLengthGroupsValueArr([]);
    dispatch(setFilterSearchValue({}));
    dispatch(setFilterReset(true));
  };
  /** Функция рендер компонентов в зависимости от типа поля */
  const renderField = (index, field, filterIndex, fieldName) => {
    let children = null;
    const typeId = field?.fieldType?.id;
    switch (typeId) {
      case 1:
        children = renderInput(index, filterIndex);
        break;
      case 2:
      case 3:
      case 'id':
        children = renderFloat(field.origin, index, filterIndex);
        break;
      case 4:
      case 20:
        children = renderDynamicSelect(field, index, filterIndex, fieldName);
        break;
      case 8:
        children = renderDate(index, filterIndex, typeId);
        break;
      case 7:
        children = renderDateInTime(index, filterIndex, typeId);
        break;
      case 10:
        children = renderCheckbox(index, filterIndex);
        break;
      case 11:
        children = renderTextArea(index, filterIndex);
        break;
      case 14:
        children = renderSelect(field, index, filterIndex);
        break;
      default:
        return null;
    }
    return (
      <>
        {children ? (
          <Form.Item
            name={`field-${index}-${filterIndex}`}
            rules={
              typeId !== 10 && [
                {
                  required: true,
                  message: mdm.requiredMessage,
                },
              ]
            }
          >
            {children}
          </Form.Item>
        ) : (
          ''
        )}
      </>
    );
  };

  const conditionsSelect = (conditionOptions, index, filterIndex, defaultValue) => {
    return (
      <Select
        defaultValue={defaultValue}
        style={{width: '100%'}}
        onChange={(value) => handleFilterConditionChange(value, index, filterIndex)}
      >
        {conditionOptions.map((item, index) => (
          <Select.Option key={index} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderConditions = (typeId, index, filterIndex) => {
    let children = null;
    switch (typeId) {
      case 1:
      case 11:
      case 13:
        children = conditionsSelect(stringConditions, index, filterIndex, '_in');
        break;
      case 2:
      case 3:
      case 8:
      case 7:
        children = conditionsSelect(integerConditions, index, filterIndex, '=');
        break;
      default:
        return null;
    }
    return <>{children ? <Form.Item name={`condition-${index}-${filterIndex}`}>{children}</Form.Item> : ''}</>;
  };

  const renderInput = (index, filterIndex) => {
    return (
      <Input
        placeholder={mdm.inputItem}
        onChange={(event) => handleFilterValueChange(event.target.value, index, filterIndex)}
      />
    );
  };

  const renderFloat = (fieldName, index, filterIndex) => {
    return (
      <InputNumber
        name={fieldName}
        style={{width: '100%'}}
        onChange={(value) => handleFilterValueChange(value, index, filterIndex)}
      />
    );
  };

  const renderSelect = (field, index, filterIndex) => {
    const options = get(field, 'options.combobox_options');
    let renderedOptions = [];
    if (options) {
      renderedOptions = options?.map((item) => (
        <Select.Option key={item.key} value={item.key}>
          {item.value}
        </Select.Option>
      ));
    }
    return (
      <Select
        placeholder={mdm.chooseItem}
        popupClassName="form-item__dropdown"
        style={{width: '100%'}}
        onChange={(value) => handleFilterValueChange(value, index, filterIndex, field.origin, 'select')}
      >
        {renderedOptions}
      </Select>
    );
  };

  const renderDynamicSelect = (field, index, filterIndex, fieldName) => {
    let searchUrl;
    let method = 'post';
    let displayedField;
    let valueSearchName;
    let refScript = null;
    let valueField = null;
    let mode = null;
    if (field.referenceCatalog?.origin === 'user_profile') {
      searchUrl = '/api/v1/catalogs/user_profile/items/search';
      method = 'post';
      displayedField = ['lastName', 'firstName', 'middleName'];
    } else if (field.fieldType?.id === 20) {
      searchUrl = '/api/v1/statuses';
      method = 'get';
      valueSearchName = 'caption';
      displayedField = 'caption';
      valueField = 'id';
      mode = 'multiple';
    } else {
      if (field.referenceCatalogPattern) {
        if (_.isArray(field.referenceCatalogPattern)) {
          displayedField = field.referenceCatalogPattern;
        } else {
          const refValues = field.referenceCatalogPattern.split(';');
          displayedField = refValues.length === 3 ? [refValues[1], refValues[2]] : refValues[1];
        }
      }
      searchUrl = `/api/v1/catalogs/${field.referenceCatalog.uuid}/items/search/extended`;
      const refValues = field.referenceCatalogPattern.split(';');
      displayedField = refValues.length === 3 ? [refValues[1], refValues[2]] : refValues[1];
    }

    if (field.options && field.options.reference_value_script) refScript = field.options.reference_value_script;

    return (
      <DynamicSelect
        name={fieldName}
        style={{width: '100%'}}
        mode={mode}
        placeholder={mdm.select}
        allowClear={true}
        resetOptions={true}
        filterMode={true}
        filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
        notfoundcontentplaceholder={mdm.notFound}
        displayedFieldSeparator={
          field.displayedFieldSeparator || get(field, 'options.detailCfg.displayedFieldSeparator')
        }
        popupClassName="form-item__dropdown"
        feachoptions={{
          referenceStore: 'mdm',
          url: searchUrl,
          catalogOrigin: field.referenceCatalog?.origin,
          method,
          displayedField,
          valueSearchName,
          refScript,
          valueField,
          defaultSize: 20,
        }}
        handleSelectChange={(value) => {
          form.setFieldsValue({[`field-${index}-${filterIndex}`]: value});
          handleFilterValueChange(value, index, filterIndex, field.origin, 'select');
        }}
      />
    );
  };

  const renderDate = (index, filterIndex) => {
    return (
      <DatePicker
        locale={ruRU}
        onChange={(date) => handleFilterValueChange(date, index, filterIndex, 'Date')}
        style={{width: '100%'}}
        format={defaultDateFormat}
      />
    );
  };

  const renderDateInTime = (index, filterIndex) => {
    return (
      <DatePicker
        locale={ruRU}
        onChange={(date) => handleFilterValueChange(date, index, filterIndex, 'DateTime')}
        showTime
        format="DD.MM.YYYY  HH:mm"
      />
    );
  };

  const renderCheckbox = (index, filterIndex) => {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Checkbox onChange={(event) => handleFilterValueChange(event.target.checked, index, filterIndex, 'checkbox')} />
      </div>
    );
  };

  const renderTextArea = (index, filterIndex) => {
    return (
      <Input.TextArea
        placeholder={mdm.inputItem}
        rows={2}
        onChange={(event) => handleFilterValueChange(event.target.value, index, filterIndex, 'checkbox')}
      />
    );
  };
  const onResetFilterForm = () => {
    if (filterErrorFlag === true) {
      !isEmpty(validateError) &&
        isEmpty(activeKeyCollapse) &&
        validateError.map((item) => deleteFilter(item.slice(6, 7), item.slice(8, 9)));
      setValidateError([]);
    }
  };
  return (
    <div onClick={onResetFilterForm}>
      <Collapse
        className="catalogsFilters__collapse"
        onChange={(key) => {
          setActiveKeyCollapse(key);
        }}
      >
        <Panel key="filter" header={catalogsFilters.filterSearch} className="catalogsFilters__collapse--panel">
          <>
            <>{contextHolder}</>
            <Form
              form={form}
              className="container d-flex flex-column"
              onValuesChange={() => {
                getFilterBody();
              }}
            >
              <div className="d-flex mb-3">
                <Button disabled={groups.length >= 3} onClick={addGroup}>
                  {catalogsFilters.addGroup}
                </Button>
                {groups.length >= 3 && (
                  <Popover content={catalogsFilters.popover} className="filter-popover">
                    <ExclamationPoint className="ml-2" />
                  </Popover>
                )}
              </div>
              {groups.map((item, index) => (
                <Fragment key={index}>
                  <div className="d-flex filter-item">
                    <div className="col-3 d-flex flex-column align-items-start operator-block p-0 justify-content-center">
                      {item?.filters?.length > 1 && (
                        <div className="d-flex align-items-center mb-2">
                          <p className="mr-3 mb-0">{catalogsFilters.operator}</p>
                          <Select
                            style={{width: 80}}
                            value={operatorValueArr[index]}
                            options={operatorOptions}
                            onChange={(value, option) => handleFilterOperatorChange(value, index, option, 'value')}
                          />
                        </div>
                      )}
                      <div className="d-flex">
                        <Button type="primary" onClick={() => addFilter(index)}>
                          {catalogsFilters.addFilter}
                        </Button>
                      </div>
                      <div className="d-flex mt-1 btn-del">
                        <Button onClick={() => deleteGroup(index)} style={{border: 'none'}}>
                          <DeleteOutlined />
                        </Button>
                      </div>
                    </div>
                    <div className="col-9 p-0 d-flex flex-column mr-2">
                      {item.filters.map((filter, filterIndex) => {
                        const fieldOrigin = groups[index].filters[filterIndex].attribute.split('_')[0];
                        const field = fields && fields.find((elem) => elem.origin === fieldOrigin);
                        const fieldName = `${fieldOrigin}-${index}-${filterIndex}`;
                        return (
                          <div className=" filter-item" key={filterIndex}>
                            <Form.Item name={`attribute-${index}-${filterIndex}`}>
                              <Select
                                placeholder={catalogsFilters.attributeForSearch}
                                popupClassName="form-item__dropdown"
                                style={{width: 150}}
                                onChange={(value) => {
                                  handleAttributeChange(value, index, filterIndex);
                                }}
                              >
                                {attributeOptions}
                              </Select>
                            </Form.Item>
                            <div className="col-2 p-0">
                              {renderConditions(field && field.fieldType.id, index, filterIndex)}
                            </div>
                            <div className="col-5 p-0 pr-2">{renderField(index, field, filterIndex, fieldName)}</div>
                            <div className="col-1 p-0">
                              <Button onClick={() => deleteFilter(index, filterIndex)} style={{border: 'none'}}>
                                <DeleteOutlined />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {operatorLengthGroupsValueArr[index] && (
                    <Select
                      className="my-3"
                      style={{width: 80}}
                      options={operatorOptions}
                      value={operatorLengthGroupsValueArr[index]}
                      onChange={(value, option) => handleOperatorChange(value, index, option)}
                    />
                  )}
                </Fragment>
              ))}
              {!isEmpty(groups) && (
                <div className="d-flex ml-auto mt-2">
                  <Button onClick={onReset}>{catalogsFilters.reset}</Button>
                </div>
              )}
            </Form>
          </>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FilterAttributes;
