import {Table} from 'antd';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {headerMDM, helpersModal} from '../Helpers/constants';

import './HelpModalInfo.scss';

/** Модальное окно для инфорации пользователю */
const HelpInfoModal = () => {
  /** Информация о версиях приложения */
  const helpInfo = useSelector((state) => state?.helpInfo.helpInfo);
  /** Преобразования информации о версия к виду таблицы */
  const helpInfoTable = useMemo(() => {
    if (!isEmpty(helpInfo)) {
      return [
        {name: 'mdm-api-adapter', version: helpInfo?.build?.version},
        {name: 'docstore', version: helpInfo.docstore?.version},
        {name: 'keycloak - spring - adapter', version: helpInfo['keycloak-spring-adapter']?.version},
        {name: 'mdm-api-service', version: helpInfo['mdm-api-service']?.version},
        {name: 'mdm-core', version: helpInfo['mdm-core']?.version},
      ];
    }
  }, [helpInfo]);
  /** Колонки для таблицы */
  const columns = [
    {
      title: headerMDM.component,
      dataIndex: ['name'],
      key: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: headerMDM.versionTable,
      dataIndex: ['version'],
      key: 'version',
      render: (text) => <p>{text}</p>,
    },
  ];
  return (
    <div className="helpModal">
      <div className="helpModal__version-product">{headerMDM.about}</div>
      {helpersModal.info?.map((info, index) => (
        <div key={index}>{info}</div>
      ))}
      <a href="https://www.7tech.ru/">{headerMDM.url}</a>
      <div>
        {headerMDM.version} {window._env.REACT_APP_VERSION}
      </div>
      <div className="helpModal__version-product">{headerMDM.productVersion}</div>
      <Table className="helpModal__table-version" columns={columns} dataSource={helpInfoTable} pagination={false} />
    </div>
  );
};
export default HelpInfoModal;
