import {fetchFunc} from '../../../Utils/security/http/mdm';
import {SET_CATALOG_FIELDS, SET_LOADING, SET_SOURCE_PRIORITY} from './actionTypes';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {errorModalCreate} from '../../Helpers/Modals';

export const getCatalogFields = (referenceOrigin, search = '') => {
  return async (dispatch) => {
    dispatch(setLoading('catalogFields', true));
    const searchParam = search ? `&search=${search}` : '';
    const fetchData = await fetchFunc({
      url: `/api/v1/catalogs/${referenceOrigin}/fields?size=1000${searchParam}`,
      method: 'get',
    });
    dispatch(setCatalogFields(fetchData));
    dispatch(setLoading('catalogFields', false));
  };
};

/* Получаем список приоритетов систем */
export const getSourcePriority = (fieldUuid, page = 0, size = 10) => {
  return async (dispatch) => {
    dispatch(setLoading('sourcePriority', true));
    const fetchData = await fetchFunc({
      url: `/api/v1/source-priority?size=${size}&page=${page}&fieldUuid=${fieldUuid}`,
      method: 'get',
    });
    dispatch(setSourcePriority(fetchData));
    dispatch(setLoading('sourcePriority', false));
  };
};

export const changeSourcePriority = (fieldUuid, referenceUuid, values, editableItem) => {
  return async (dispatch) => {
    const data = {
      catalogIdentifier: referenceUuid,
      fieldUuid: fieldUuid,
      priority: values.priority,
      subsystem: values.subsystem && {id: values.subsystem},
    };
    const method = editableItem ? 'put' : 'post';
    await fetchFunc({
      url: `/api/v1/source-priority/${editableItem ? editableItem.id : ''}`,
      method,
      data,
    });
    dispatch(getSourcePriority(fieldUuid));
  };
};

export const deleteSourcePriority = (fieldUuid, priorityId) => {
  return async (dispatch) => {
    await fetchFunc({
      url: `/api/v1/source-priority/${priorityId}`,
      method: 'delete',
    });
    dispatch(getSourcePriority(fieldUuid));
  };
};

/* SourcePriority */

export const createCatalogDraft = (referenceUuid, history) => {
  return async () => {
    const data = await fetchFunc({
      url: '/api/v1/catalog-draft',
      method: 'put',
      data: {
        catalogIdentifier: {
          catalogIdentifier: referenceUuid,
        },
      },
    });
    history.push(`/catalog-draft/${data.uuid}`);
  };
};

export const exportOriginData = (referenceOrigin) => {
  return async () => {
    const data = await fetchFunc(
      {
        url: `/api/v1/catalogs/${referenceOrigin}/metadata`,
        method: 'get',
        responseType: 'blob',
      },
      async (response) => {
        const text = await response.data.text();
        errorModalCreate(JSON.parse(text).message);
      }
    );
    saveAs(data, `${referenceOrigin}-metadata-${moment().format('DD-MM-YYYY-h-mm')}`);
  };
};

export const setCatalogFields = (values) => {
  return {
    type: SET_CATALOG_FIELDS,
    payload: values,
  };
};

export const setSourcePriority = (values) => {
  return {
    type: SET_SOURCE_PRIORITY,
    payload: values,
  };
};

export const setLoading = (store, value) => {
  return {
    type: SET_LOADING,
    payload: value,
    store: store,
  };
};
