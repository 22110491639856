import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal, Spin, Switch} from 'antd';

import {
  deleteNotification,
  getNotificationId,
  getNotificationList,
  getNotificationSubscriptionList,
  setActivateNotification,
  setDeactivateNotification,
} from '../../../redux/thunk/notificationThunk';
import {
  captionCatalogNameFilter,
  captionNotificationFilter,
  captionTemplateNotificationFilter,
} from '../helpers/notificationFiltersUtils';
import {notificationPage} from '../helpers/constant';
import {StyledTable} from '../../../components/Themes/Components';
import {notificationPageFilters} from '../helpers/notificationPageFilters';
import NotificationPageForm from '../NotificationPageForm/NotificationPageForm';
import {notifications} from '../../../components/NotificationPage/helpers/constant';
import NotificationPageFilters from '../NotificationPageFilters/NotificationPageFilters';
import {ReactComponent as DeleteDeduplication} from '../../../assets/images/delete.svg';

import './NotificationPageList.scss';

/** Список уведомлений */
const NotificationPageList = () => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Состояние отображения модального окна */
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({
    title: null,
    template: null,
    subscription: null,
    titleTemplate: null,
    methodTemplate: null,
  });
  /** Начальное состояние статуса для отображения формы уведомления */
  const [status, setStatus] = useState('');
  /** Используем хранилище */
  const notificationId = useSelector((state) => state?.notification.notificationId);
  const notificationList = useSelector((state) => state?.notification.notificationList);
  const notificationSubscription = useSelector((state) => state?.notification.notificationSubscriptionList);
  const loading = useSelector((state) => state?.notification.loading);
  /** Получаем список уведомлений */
  useEffect(() => {
    dispatch(getNotificationList());
  }, [notificationSubscription]);
  /** Получаем информацию по подпискам и событиям */
  useEffect(() => {
    dispatch(getNotificationSubscriptionList());
  }, []);
  /**
   *Сеттер значения переключателя уведомления
   * @param record - значение переключателя и id уведомления
   * */
  const handleActiveChange = (record) => {
    record.active === false
      ? dispatch(setActivateNotification(record.id))
      : dispatch(setDeactivateNotification(record.id));
  };
  /** Функция перехода к форме просмотра/редактирования шаблона уведомления */
  const notificationInfoHandler = (uuid) => {
    dispatch(getNotificationId(uuid));
    !isEmpty(notificationSubscription) && setNotificationModalVisible(true);
    setStatus('viewing');
  };
  /** Функция для отображения отфильтрованной таблицы  */
  const filteringNotificationList = notificationPageFilters(notificationList, filter);
  /** Колонки для таблицы */
  const columns = [
    {
      title: notificationPage.tableName,
      dataIndex: 'captionNotification',
      key: 1,
      width: 230,
      render: (text, record) => (
        <div
          style={{cursor: 'pointer'}}
          onDoubleClick={() => {
            notificationInfoHandler(record.id);
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: notificationPage.template,
      dataIndex: 'nameTemplateNotification',
      key: 2,
      width: 230,
      render: (text) => <p>{text}</p>,
    },
    {
      title: notificationPage.subscription,
      dataIndex: 'catalogName',
      key: 3,
      width: 230,
      render: (text) => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: 4,
      width: 70,
      render: (record) => (
        <Switch
          className="toggle"
          key={notifications.email}
          checkedChildren={notifications.on}
          unCheckedChildren={notifications.off}
          checked={record.active}
          onChange={() => handleActiveChange(record)}
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 5,
      width: 70,
      render: (record) => (
        <DeleteDeduplication style={{cursor: 'pointer'}} onClick={() => dispatch(deleteNotification(record.id))} />
      ),
    },
  ];
  return (
    <div className="notificationPageList">
      <Button onClick={history.goBack} size="middle" style={{width: 90}}>
        {notificationPage.goBack}
      </Button>
      <div className="notificationPageList__header">
        <div className="notificationPageList__header--title">{notificationPage.title}</div>
        <Button
          type="primary"
          className="notificationPageList__header--button"
          onClick={() => {
            setStatus('create');
            setNotificationModalVisible(true);
          }}
        >
          {notificationPage.addNotification}
        </Button>
      </div>
      {loading === true ? (
        <>
          <NotificationPageFilters
            notification={true}
            captionNotificationFilter={captionNotificationFilter(notificationList)}
            captionTemplateNotificationFilter={captionTemplateNotificationFilter(notificationList)}
            captionCatalogNameFilter={captionCatalogNameFilter(notificationList)}
            setFilter={setFilter}
          />
          <StyledTable columns={columns} dataSource={filteringNotificationList} rowKey={(record) => record.id} />
        </>
      ) : (
        <Spin />
      )}
      <Modal
        closable={false}
        visible={notificationModalVisible}
        footer={null}
        okText={notifications.save}
        cancelText={notifications.cancel}
        loading={true}
        width={700}
      >
        <NotificationPageForm
          notificationId={!isEmpty(notificationId) && notificationId}
          status={status}
          setNotificationModalVisible={setNotificationModalVisible}
        />
      </Modal>
    </div>
  );
};
export default React.memo(NotificationPageList);
