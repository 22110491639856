export const structureBank = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'exporter', label: 'Информация о банке', items: [
                    { origin: 'corrScore', type: 'item' },
                    {
                        type: 'container', greed: 24, items: [
                            { origin: 'bic', type: 'item', greed: 24 },
                        ]
                    },
                    { origin: 'swift', type: 'item' },
                    { origin: 'telex', type: 'item' },
                    { origin: 'iban', type: 'item' },
                    { origin: 'authorizedCapital', type: 'item' },
                    { origin: 'kgrEntryDate', type: 'item' },
                    { origin: 'capitalCurrencyId', type: 'item' },
                    { origin: 'creditStatusId', type: 'item' },
                    { origin: 'ownCapital', type: 'item' },
                    { origin: 'genLicDate', type: 'item' },
                ]
            }
        ]
    }
];
