import React from 'react';
import ButtonWithDocumentSelection from '../../ButtonWithDocumentSelection';
import { Button } from 'antd';
import { Docs } from 'ecm-docs';
import moment from "moment";
import security from '../../../Utils/security';
import '../RecordReader.scss'


export const DetailsMeta = {
    durationStates: [
        {
            origin: 'duration',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "indefinite": false } }
                    },
                }
            }
        },
        {
            origin: 'stage',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            }
        },
    ],
    license: [
        {
            origin: 'licenseNumber',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
        {
            origin: 'licenseStatusId',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
        {
            origin: 'licenseTypeId',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
        {
            origin: 'okvedId',
            options: {
                fieldsForSearch: ['code', 'caption'],
                referenceCatalogPattern: ['code', 'caption'],
            },
        },
    ],
    limits: [
        {
            origin: 'limitTypeId',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
        {
            origin: 'limitSum',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                    mask: {
                        mask: '999 999 999,99',
                    },
                },
            },
        },
        {
            origin: 'limitCurrencyId',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
        {
            origin: 'docCaption',
            options: {
                edit_gui_editable: false,
                gui_editable: false,
            },
        },
        {
            origin: 'docOrigin',
            options: {
                edit_gui_editable: false,
                gui_editable: false,
            },
        },
        {
            origin: 'docUuid',
            options: {
                edit_gui_editable: false,
                gui_editable: false,
            },
        },
    ],
    rating: [
        {
            origin: 'ratingId',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
        {
            origin: 'ratingValue',
            options: {
                rules: {
                    required: {
                        value: true,
                    },
                },
            },
        },
    ],
    separateDivision: [
        {
            origin: 'numberRafp',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "nonResidentRf": true } }
                    },
                },
            },
        },
        {
            origin: 'receiptDate',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "nonResidentRf": true } }
                    },
                },
            },
        },
        {
            origin: 'certificateNumber',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "nonResidentRf": true } }
                    },
                },
            },
        },
        {
            origin: 'kpp',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "nonResidentRf": false } }
                    },
                },
            },
        },
        {
            origin: 'bic',
            options: {
                rules: {
                    visible: {
                        rule: { "and": { "nonResidentRf": false, "bank": true } }
                    },
                },
            },
        },
        {
            origin: 'swift',
            options: {
                rules: {
                    visible: {
                        rule: { "and": { "nonResidentRf": false, "bank": true } }
                    },
                },
            },
        },
    ],
    congressExperience: [
        {
            origin: 'ceActivity',
            options: {
                fieldsForSearch: ['uin', 'captionFull'],
                referenceCatalogPattern: ['uin', 'captionFull'],
            },
        },
    ],
    exportExperience: [
        {
            origin: 'eeExportVol',
            options: {
                rules: {
                    mask: {
                        mask: '999 999 999',
                    },
                },
            },
        },
    ],
    tariffGrid: [
        {
            origin: 'lowerLimitType',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "setLowerLimit": true } }
                    },
                },
            },
        },
        {
            origin: 'lowerLimitValue',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "setLowerLimit": true } }
                    },
                },
            },
        },
        {
            origin: 'upperLimitType',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "setUpperLimit": true } }
                    },
                },
            },
        },
        {
            origin: 'upperLimitValue',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "setUpperLimit": true } }
                    },
                },
            },
        },
        {
            origin: 'currencyId',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "inContractCurrency": false } }
                    },
                    required: {
                        value: true,
                    },
                },
            },
        },
    ],
};

export const customRegionMetaField = (countryUuid) => {
    return [
        {
            origin: 'crmFedsId',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "countryId": `${countryUuid}`, "countryId.uuid": `${countryUuid}` } }
                    }
                },
            }
        },
        {
            origin: 'crmRegionsId',
            options: {
                rules: {
                    visible: {
                        rule: { "or": { "countryId": `${countryUuid}`, "countryId.uuid": `${countryUuid}` } }
                    }
                }
            }
        },
    ]
}

export const customEditModal = {
    retsProductDocument: [
        {
            origin: 'docOrigin',
            fieldType: { id: 4 },
            caption: 'Тип документа',
            referenceCatalog: {
                referenceStore: 'ecm',
                url: '/api/v1/document-type',
                method: 'get',
                valueSearchName: 'search',
                valueField: 'caption',
                inComeOptionUrl: '/api/v1/document-type'
            },
            referenceCatalogPattern: 'id;caption',
            options: {
                gui_editable: true,
            },
        },
    ]
};

export const AditionalFields = {
    limits: [
        {
            origin: 'docLink',
            fieldType: {
                id: 'customField'
            },
            options: {
                render: (form, data) => {
                    if (data) {
                        const { docCaption, docOrigin, docUuid } = data;
                        const { REACT_APP_ECM_FRONT_URL } = window._env;
                        return <Button
                            type='primary'
                            href={`${REACT_APP_ECM_FRONT_URL}/document/${docOrigin}.${docUuid}`}
                            target={'_blank'}
                        >
                            {docCaption}
                        </Button>
                    } else {
                        return null
                    }
                },
            },
        },
        {
            origin: 'doc',
            fieldType: {
                id: 'customField'
            },
            options: {
                onlyForEdit: true,
                render: (form, recordUuid) => <ButtonWithDocumentSelection
                    form={form}
                    recordUuid={recordUuid}
                />,
            },
        }
    ],
};

export const modifiedDocTmpltColumn = {
    reader: {
        caption: "Шаблон документа",
        fieldType: { id: 'docTypeDetail' },
        id: 13828,
        origin: "templateDocName",
        sorter: true,
        title: "Шаблон документа",
        options: {
            render: ({ record, ecmDocData, isEditMode, setEcmDocData, itemCaption }) => {
                const { REACT_APP_ECM_URL: ecmApiUrl, REACT_APP_ECM_FRONT_URL: ecmFrontUrl } = window._env;
                const { getToken } = security;
                const docName = ecmDocData?.templateDocName || (!isEditMode && record?.templateDocName);
                const docUuid = ecmDocData?.templateDocUuid || (!isEditMode && record?.templateDocUuid) || '';
                return (
                    <>
                        {itemCaption && <div className='field__item-title'><b>{itemCaption}</b></div>}
                        {docUuid
                            ? <a
                                href={`${window._env.REACT_APP_ECM_FRONT_URL}/document/c00409.${docUuid}`}
                                target='_blank'
                                rel='noopener noreferrer'>
                                {docName}
                            </a>
                            : '-'
                        }
                        <div style={{ display: 'flex', marginTop: '15px' }}>
                            {isEditMode
                                && (
                                    <Docs
                                        defaultDocsSelectorFilter={{ types: ['c00409'] }}
                                        bindDefaultDocsSelectorFilter={true}
                                        ecmApiUrl={ecmApiUrl}
                                        ecmFrontUrl={ecmFrontUrl}
                                        showDocsList={false}
                                        showCreateBtn={true}
                                        initValues={{
                                            ecmDocType: 'c00409',
                                            docTypeUuid: 'c00409',
                                            source: 'МДМ',
                                            regDate: moment(),
                                            isEndData: false,
                                            docKind: '2',
                                            orgId: '4a27ff58-7ee0-488f-9cd1-6c7c5035513e'
                                        }}
                                        formItemsProps={{
                                            docTypeUuid: {
                                                readOnly: true
                                            }
                                        }}
                                        token={getToken()}
                                        onDocumentsAdd={(doc) => {
                                            const { caption, uuid, documentTypeIdentifier: { documentTypeIdentifier } } = Array.isArray(doc) ? doc[0] : doc
                                            return setEcmDocData({
                                                templateDocType: documentTypeIdentifier,
                                                templateDocUuid: uuid,
                                                templateDocName: caption
                                            })
                                        }}
                                        multiply={false}
                                    />

                                )

                            }
                            {(isEditMode && ecmDocData.templateDocUuid)
                                && (
                                    <Button onClick={() => {
                                        setEcmDocData({
                                            templateDocType: null,
                                            templateDocUuid: null,
                                            templateDocName: null,
                                        })
                                    }}
                                        danger
                                        type="text"
                                    >
                                        Очистить
                                    </Button>
                                )
                            }
                        </div>
                    </>
                )
            }
        }
    },
    table: {
        className: "column--text",
        dataIndex: "templateDocName",
        fieldOrder: 9500,
        key: "templateDocName",
        sorter: true,
        title: "Шаблон документа",
        render: (val, record) => (
            <a
                href={`${window._env.REACT_APP_ECM_FRONT_URL}/document/c00409.${record.templateDocUuid || ''}`}
                target='_blank'
                rel='noopener noreferrer'>
                {record.templateDocName}
            </a>
        )
    },
    redactor: {
        origin: 'docOrigin',
        fieldType: { id: 4 },
        caption: 'Тип документа',
        referenceCatalog: {
            referenceStore: 'ecm',
            url: '/api/v1/document-type',
            method: 'get',
            valueSearchName: 'search',
            valueField: 'caption',
            inComeOptionUrl: '/api/v1/document-type'
        },
        referenceCatalogPattern: 'id;caption',
        required: true,
        options: {
            gui_editable: true,
        },
    },
}