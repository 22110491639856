import contactsPage from '../../modules/ContactsPage/';
import NotAccessPage from '../../modules/NotAccessPage/';
import NotFoundPage from '../../modules/NotFoundPage/';
import catalogs from '../../modules/Catalogs/';
import catalogsDraft from '../../modules/CatalogsDraft';
import detailDraftRedactor from '../../components/DetailDraftRedactor';
import catalogDraftCard from '../../modules/CatalogDraftCard';
import catalogItem from '../../modules/CatalogItem';
import catalogItemAdd from '../../modules/CatalogItemAdd';
import catalogsOrgList from '../../modules/CatalogsOrg';
import catalogsOrgCard from '../../modules/CatalogsOrg/CatalogsOrgCard';
import catalogItemRecord from '../../modules/CatalogItemRecord';
import catalogItemImport from '../../modules/CatalogItemImport';
import catalogGroupAccessPage from '../../modules/CatalogGroupAccessPage';
import schedulerTasks from '../../modules/SchedulerTasks';
import schedulerCalendar from '../../modules/SchedulerCalendar';
import Services from '../../modules/Services';
import ServiceCard from '../../modules/Services/ServiceCard';

import VerifierList from '../Verification/components/VerifierList';
import VerifierOrgList from '../../modules/VerifyOrg/VerifyOrgList';
import VerifyRecords from '../../modules/VerifyRecords';
import VerifyOrgCard from '../../modules/VerifyOrg/VerifyOrgCard';
import Verification from '../Verification/Verification';
import VerifyCard from '../Verification/components/VerifyCard';
import LinkTypes from '../../modules/LinkTypes/LinkTypes';
import SettingsPage from '../../modules/SettingsPage';

import externalSystemsList from '../../modules/ExternalSystemsList/ExternalSystemsList';
import subsystemsList from '../../modules/SubsystemsList/SubsystemsList';
import Subscriptions from '../../modules/Subscriptions';
import DeduplicationProtocol from '../../modules/Deduplication/DeduplicationProtocol/DeduplicationProtocol';
import DeduplicationProtocols from '../../modules/Deduplication/DeduplicationProtocols/DeduplicationProtocols';
import DeduplicationJob from '../../modules/Deduplication/DeduplicationJob/DeduplicationJob';
import DeduplicationProtocolForm from '../Deduplication/DeduplicationProtocolForm/DeduplicationProtocolForm';
import NotificationPageTemplates from '../NotificationPage/NotificationPageTemplates/NotificationPageTemplates';
import NotificationPageList from '../NotificationPage/NotificationPageList/NotificationPageList';

export const initialRoutes: [{path: string, footer: null, main: React.FunctionComponent, mode: string}] = [
  {
    path: '/:scope(catalog-import)',
    footer: null,
    main: catalogItemImport,
  },
  {
    path: '/:scope(catalog)/organisations/:id/verify',
    footer: null,
    main: VerifyOrgCard,
    mode: 'user',
  },
  {
    path: '/:scope(catalog)/:item/:id/verify',
    footer: null,
    main: VerifyCard,
    mode: 'user',
  },
  {
    path: '/:scope(catalog)/:item/:id',
    footer: null,
    main: catalogItemRecord,
  },
  {
    path: '/:scope(catalog)/:item',
    footer: null,
    main: catalogItem,
  },
  {
    path: '/:scope(catalog-draft)/:draftId',
    footer: null,
    main: catalogDraftCard,
  },
  {
    path: '/:scope(catalog-draft)',
    footer: null,
    main: catalogsDraft,
  },
  {
    path: '/:scope(organisations)/:id',
    footer: null,
    main: catalogsOrgCard,
  },
  {
    path: '/:scope(organisations)',
    footer: null,
    main: catalogsOrgList,
  },
  {
    path: '/:scope(detail-editor)/:draftId/:catalogIdentifier/:detailId',
    footer: null,
    main: detailDraftRedactor,
    status: 'Черновик',
  },
  {
    path: '/:scope(catalog-add)',
    footer: null,
    main: catalogItemAdd,
  },
  {
    path: '/:scope(catalogs-rules)',
    footer: null,
    main: catalogGroupAccessPage,
  },
  {
    path: '/:scope(catalog)',
    footer: null,
    main: catalogs,
  },
  {
    path: '/:scope(scheduler-tasks)',
    footer: null,
    main: schedulerTasks,
  },
  {
    path: '/:scope(scheduler-calendar)',
    footer: null,
    main: schedulerCalendar,
  },
  {
    path: '/:scope(verifier-list)/catalogs/:item/:id',
    footer: null,
    main: Verification,
  },
  {
    path: '/:scope(verifier-list)/catalogs/:item',
    footer: null,
    main: VerifyRecords,
  },
  {
    path: '/:scope(verifier-list)/catalogs',
    footer: null,
    main: VerifierList,
  },
  {
    path: '/:scope(verifier-list)/org',
    footer: null,
    main: VerifierOrgList,
  },
  {
    path: '/:scope(services)/:id',
    footer: null,
    main: ServiceCard,
  },
  {
    path: '/:scope(deduplication)/protocols/:id',
    footer: null,
    main: DeduplicationProtocol,
  },
  {
    path: '/:scope(notification)/template',
    footer: null,
    main: NotificationPageTemplates,
  },
  {
    path: '/:scope(notification)/list',
    footer: null,
    main: NotificationPageList,
  },
  {
    path: '/:scope(deduplication)/protocols',
    footer: null,
    main: DeduplicationProtocols,
  },
  {
    path: '/:scope(deduplication)',
    footer: null,
    main: DeduplicationJob,
  },
  {
    path: '/:scope(deduplication)/deduplication-add',
    footer: null,
    main: DeduplicationProtocolForm,
  },
  {
    path: '/:scope(services)',
    footer: null,
    main: Services,
  },
  {
    path: '/:scope(link-type)',
    footer: null,
    main: LinkTypes,
  },
  {
    path: '/:scope(subscriptions)',
    footer: null,
    main: Subscriptions,
  },
  {
    path: '/:scope(settings)',
    footer: null,
    main: SettingsPage,
  },
  {
    path: '/:scope(contacts)',
    footer: null,
    main: contactsPage,
  },
  {
    path: '/:scope(external-systems)',
    footer: null,
    main: externalSystemsList,
  },
  {
    path: '/:scope(subsystems)',
    footer: null,
    main: subsystemsList,
  },
  {
    path: '/:scope(error)/403',
    footer: null,
    main: NotAccessPage,
  },
  {
    path: '/:scope(error)/404',
    footer: null,
    main: NotFoundPage,
  },
  {
    path: '*',
    footer: null,
    main: NotFoundPage,
  },
];
