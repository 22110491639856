/** Проверка на добавление условия  при валидации */
export const getValidationRules = (validator) => {
  if (validator?.validator_operator_Length === 'eq') {
    return [
      {
        min: Number(validator?.validator_Length),
        message: `Значение по умолчанию должно быть равно ${validator?.validator_Length} символов`,
      },
      {
        max: Number(validator?.validator_Length),
        message: `Значение по умолчанию должно быть равно ${validator?.validator_Length} символов`,
      },
    ];
  } else {
    return [
      {
        max: Number(validator?.validator_Length),
        message: `Значение по умолчанию должно быть не больше ${validator?.validator_Length} символов`,
      },
    ];
  }
};
