export const createBreadcrumbNameMap = (items, parent) => {
    let toggleList = [];
    items.forEach(item => {
        if (item.route) {
            toggleList.push(
                { id: item.id, route: item.route, parent }
            );
        }
        if (item.items && item.items.length) {
            toggleList = [
                ...toggleList,
                ...createBreadcrumbNameMap(item.items, item.id)
            ]
        }
    });
    return toggleList
}