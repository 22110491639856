import React, {useState} from 'react';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import {DeleteOutlined} from '@ant-design/icons';
import ComboboxOptionsForm from './ComboboxOptionsForm';

import {mdm} from '../../Utils/constant';
import {setDataForOptionsTypeSelect} from '../../redux/actions/actions';
import {StyledTable, StyledButton, StyledModal} from '../Themes/Components';

import './DetailTable.scss';

/** Модальное окно добавления значений в список
 * @param detailSubmit - функция сбора данных для payload
 * @param disabled - условия для disabled
 * @param okModal - функция setter значений в форму
 * @param setComboboxData - функция setter значений
 * @param onCancel - функция закрытия модального окна
 * @param data - данные для отображения в таблице */
const DetailTable = ({detailSubmit, data, disabled, okModal, setComboboxData, onCancel}) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Начальное состояние модального окна */
  const [modalVisible, setModalVisible] = useState(false);
  /** Функция удаления значения  */
  const onDeleteHandler = (record) => {
    const filterData = data.filter((item) => item?.key !== record?.key);
    dispatch(setDataForOptionsTypeSelect(filterData));
    setComboboxData(filterData);
  };
  /** Колонки для таблицы */
  const comboboxDetailColumns = [
    {
      title: mdm.key,
      dataIndex: 'key',
    },
    {
      title: mdm.value,
      dataIndex: 'value',
    },
    {
      render: (text, record) => {
        return <DeleteOutlined onClick={() => onDeleteHandler(record)} />;
      },
    },
  ];

  return (
    <div className="detailTable">
      <div className="detailTable__title">{mdm.listItems}</div>
      <StyledTable
        columns={comboboxDetailColumns}
        dataSource={!isEmpty(data) && data}
        rowKey={(record) => record.key}
        pagination={false}
      />
      <div className="d-flex justify-content-end mt-3">
        <StyledButton
          sName="primaryBtn"
          disabled={disabled}
          className="mr-3"
          type="ghost"
          onClick={() => setModalVisible(true)}
        >
          {mdm.add}
        </StyledButton>
        <StyledButton
          sName="dangerBtn"
          className="mr-3"
          type="danger"
          onClick={() => {
            onCancel();
            setComboboxData([]);
          }}
        >
          {mdm.cancel}
        </StyledButton>
        <StyledButton sName="primaryBtn" type="primary" htmlType="submit" onClick={okModal}>
          {mdm.save}
        </StyledButton>
      </div>
      <StyledModal
        title={mdm.addItem}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose={true}
        closable={false}
        footer={null}
      >
        <ComboboxOptionsForm
          disabled={disabled}
          setModalVisible={setModalVisible}
          closeModal={() => setModalVisible(false)}
          onSubmit={(values) => detailSubmit(values)}
        />
      </StyledModal>
    </div>
  );
};

export default React.memo(DetailTable);
