import React, {useState} from 'react';
import {isEmpty} from 'lodash';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import {Button, Form, message, Select} from 'antd';
import Text from 'antd/es/typography/Text';
import {useDispatch, useSelector} from 'react-redux';

import {notifications} from './helpers/constant';
import {htmlParserParams} from './helpers/htmlParserParams';
import {testNotification} from '../../redux/thunk/notificationThunk';
import {
  maskOptions,
  renderTitleTestNotification,
  replaceEmailNotificationValueToPayload,
  replaceNotificationTextToPayload,
  setStylesForNotificationText,
} from '../../modules/NotificationPage/helpers/utils';
import './NotificationPageModalTestTelephone.scss';

/**
 * Форма отправки тестового уведомления
 * @param telephones - массив номеров телефона
 * @param methodNotificationsSms - данные шаблона Sms
 * @param methodNotificationsEmail - данные шаблона Email
 * @param methodNotificationsHttp - данные шаблона HTTP
 * @param setIsVisibleModalForPhone - состояние модального окна
 */
const NotificationPageModalTestTelephone = ({
  methodNotificationsSms,
  methodNotificationsEmail,
  methodNotificationsHttp,
  setIsVisibleModalForPhone,
  testKey,
  textEmail,
}) => {
  /** Данные формы */
  const [form] = Form.useForm();
  /** Отправка данных для хранилища */
  const dispatch = useDispatch();
  /** Состояние для всплывающего сообщения */
  const [messageApi, contextHolder] = message.useMessage();
  /** Состояние для выбора телефона/email */
  const [selectForNotification, setSelectForNotification] = useState('');
  /** Используем хранилище */
  const successMessage = useSelector((state) => state?.notification.notificationTestSuccess);
  const emailOptions = useSelector((state) => state?.notification.notificationEmailsForTest);
  const phoneNumbersOptions = useSelector((state) => state?.notification.notificationPhoneNumberForTest);
  /** Преобразование номера  телефона для подтвердения */
  const phoneNumber =
    phoneNumbersOptions?.length === 1 && phoneNumbersOptions?.map((item) => maskOptions(item.value)).toString();
  /** Преобразование номеров телефона  для подтвердения */
  const phoneNumberForOptions =
    !isEmpty(methodNotificationsSms) &&
    Object.values(methodNotificationsSms?.transportParams)?.map((item, index) => ({
      value: maskOptions(item?.phonenumber),
      key: index,
    }));
  /** Функция для отображения сообщения при успешной отправке */
  const success = () => {
    messageApi
      .open({
        type: 'success',
        content: notifications.successMessage,
      })
      .then();
  };
  /**
   * Функция сеттер выбранного телефона/email для отправки тестового уведомления
   * @param value - значение телефона/email */
  const selectForNotificationHandler = (value) => {
    setSelectForNotification(value);
  };
  /** Функция для отправки формы */
  const handleSubmit = () => {
    let prepareTransportParams = {createDate: dayjs().format()};
    if (testKey === 'emails') {
      prepareTransportParams = {
        ...prepareTransportParams,
        body: setStylesForNotificationText(replaceEmailNotificationValueToPayload(methodNotificationsEmail?.body)),
        notificationUuid: methodNotificationsEmail?.methodUpdateUuid,
        scheduleType: methodNotificationsEmail?.scheduleType,
        transportType: methodNotificationsEmail?.transportType,
        transportParams: isEmpty(selectForNotification)
          ? methodNotificationsEmail?.transportParams
          : {
              email: selectForNotification?.toString(),
              topic: methodNotificationsEmail?.transportParams.topic,
            },
      };
    }
    if (testKey === 'telephone') {
      prepareTransportParams = {
        ...prepareTransportParams,
        body: replaceNotificationTextToPayload(methodNotificationsSms?.body),
        notificationUuid: methodNotificationsSms?.methodUpdateUuid,
        scheduleType: methodNotificationsSms?.scheduleType,
        transportType: methodNotificationsSms?.transportType,
        transportParams: {
          phonenumber: isEmpty(selectForNotification)
            ? phoneNumber?.match(/\d/g)?.join('')
            : selectForNotification?.match(/\d/g)?.join(''),
        },
      };
    }
    if (testKey === 'http') {
      prepareTransportParams = {
        ...prepareTransportParams,
        body: replaceNotificationTextToPayload(methodNotificationsHttp?.body),
        notificationUuid: methodNotificationsHttp?.methodUpdateUuid,
        scheduleType: methodNotificationsHttp?.scheduleType,
        transportType: methodNotificationsHttp?.transportType,
        transportParams: methodNotificationsHttp?.transportParams,
      };
    }
    dispatch(testNotification(prepareTransportParams));
    successMessage === 'success' && success();
    form.resetFields();
    setSelectForNotification([]);
    setIsVisibleModalForPhone(false);
  };
  /** Функция очистки формы */
  const resetHandler = () => {
    form.resetFields();
    setIsVisibleModalForPhone(false);
  };

  return (
    <>
      <Form layout={'vertical'} form={form} name="notificationPageModalTestTelephone" onFinish={handleSubmit}>
        <>
          {contextHolder}
          <div className="notificationPageModalTestTelephone__title">
            {renderTitleTestNotification(testKey, phoneNumbersOptions, emailOptions, phoneNumberForOptions)}
          </div>
          <Form.Item hidden={testKey !== 'telephone'}>
            {phoneNumbersOptions?.length >= 2 ? (
              <Form.Item
                label={notifications.confirmSendTestTitlePhone}
                name="selectForNotification"
                rules={[
                  {
                    required: true,
                    message: notifications.rulesMessage,
                  },
                ]}
              >
                <Select
                  options={phoneNumbersOptions}
                  placeholder={notifications.choose}
                  onChange={(value) => selectForNotificationHandler(value)}
                />
              </Form.Item>
            ) : (
              <>
                <div className="notificationPageModalTestTelephone__text">
                  {notifications.confirmSendTest}
                  <span className="notificationPageModalTestTelephone__text--data">{phoneNumber}</span>
                </div>
              </>
            )}
          </Form.Item>
          <Form.Item hidden={testKey !== 'emails'}>
            {emailOptions?.length >= 2 ? (
              <Form.Item
                label={notifications.confirmSendTestTitleEmail}
                name="selectForNotification"
                rules={[
                  {
                    required: true,
                    message: notifications.rulesMessage,
                  },
                ]}
              >
                <Select
                  options={emailOptions}
                  placeholder={notifications.choose}
                  onChange={(value) => selectForNotificationHandler(value)}
                />
              </Form.Item>
            ) : (
              <div className="notificationPageModalTestTelephone__text">
                {notifications.confirmSendTest}
                <span className="notificationPageModalTestTelephone__text--data">
                  {emailOptions?.map((item) => item.value).toString()}
                </span>
              </div>
            )}
          </Form.Item>
          <Form.Item hidden={testKey !== 'http'}>
            <div className="notificationPageModalTestTelephone__text">
              {notifications.confirmSendTest}{' '}
              <span className="notificationPageModalTestTelephone__text--data">
                {methodNotificationsHttp?.transportParams?.url}
              </span>
            </div>
          </Form.Item>
          <Form.Item hidden={testKey !== 'view'}>
            <Text style={{whiteSpace: 'pre-wrap'}}>
              {!isEmpty(textEmail) &&
                parse(
                  setStylesForNotificationText(replaceEmailNotificationValueToPayload(textEmail)),
                  htmlParserParams
                )}
            </Text>
          </Form.Item>
          <div className="d-flex justify-content-end notificationPageForm__button">
            <Button type="danger" className="mr-3" htmlType="button" onClick={resetHandler}>
              {testKey === 'view' ? notifications.close : notifications.cancel}
            </Button>
            <Button type="primary" htmlType="submit" hidden={testKey === 'view'}>
              {notifications.send}
            </Button>
          </div>
        </>
      </Form>
    </>
  );
};
export default React.memo(NotificationPageModalTestTelephone);
