import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Button, Modal, Spin} from 'antd';
import {isEmpty} from 'lodash';

import DeduplicationProtocolSystemInfo from './components/DeduplicationProtocolSystemInfo/DeduplicationProtocolSystemInfo';
import DeduplicationGroupModal from './components/DeduplicationGroupModal/DeduplicationGroupModal';
import DeduplicationFilters from '../DeduplicationFilters/DeduplicationFilters';
import {deduplication} from '../helpers/constants';
import {statusConnectionFilter, userConfirmConnectFilter} from '../helpers/DeduplicationUtils';
import {getDeduplicationGroupProtocolInfo, getDeduplicationProtocolInfo} from '../../../redux/thunk/deduplicationThunk';
import {StyledTable} from '../../../components/Themes/Components';
import {deduplicationJobsFilter} from '../helpers/deduplicationFilterUtils';
import {resetDeduplicationProtocolInfo, resetDeduplicationProtocolInfoGroup} from '../../../redux/actions/actions';

import './DeduplicationProtocol.scss';

/**
 * @param match - данные для загрузки id при перезагрузки страницы
 * Протокол дедупликации */
const DeduplicationProtocol = ({match}) => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Маршрутизатор */
  const history = useHistory();
  /** Получаем данные по группам для формы сравнения*/
  const [record, setRecord] = useState({});
  /** Получаем uuid группы*/
  const [groupId, setGroupId] = useState('');
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({
    statusConnection: null,
    userConfirmConnect: null,
  });
  /** Состояние модального окна*/
  const [isDeduplicationModalVisible, setIsDeduplicationModalVisible] = useState(false);
  /** Используем хранилище */
  const deduplicationProtocolInfo = useSelector((state) => state?.deduplication.deduplicationProtocolInfo);
  const submitRequest = useSelector((state) => state?.deduplication.submitRequest);
  /** Получаем данные по протоколу */
  useEffect(() => {
    return () => dispatch(resetDeduplicationProtocolInfo());
  }, []);
  useEffect(() => {
    isEmpty(deduplicationProtocolInfo) &&
      submitRequest !== true &&
      dispatch(getDeduplicationProtocolInfo(match.params.id));
  }, [deduplicationProtocolInfo, submitRequest]);
  /** Функция для отображения отфильтрованной таблицы  */
  const filteringDeduplicationProtocolInfoList = deduplicationJobsFilter(deduplicationProtocolInfo, filter);
  /** Получение id группы */
  const checkGroupId = !isEmpty(deduplicationProtocolInfo) && deduplicationProtocolInfo[0]?.groupId;

  /** Получения значения кода  статуса задания */
  const status =
    deduplicationProtocolInfo &&
    deduplicationProtocolInfo
      .map((item) => {
        return item?.groupId === groupId && item.status;
      })
      ?.map((item) => item.key)
      .toString()
      .replace(/[\s.,%]/g, '');

  /** Колонки для таблицы */
  const columns = [
    {
      title: deduplication.statusConnection,
      dataIndex: ['status', 'title'],
      key: 5,
      render: (text) => <p>{text}</p>,
    },
    {
      title: deduplication.groupName,
      dataIndex: 'groupName',
      key: 6,
      render: (text) => <p>{text}</p>,
    },
    {
      title: deduplication.degreeProximity,
      dataIndex: 'degreeProximity',
      key: 7,
      render: (text) => <p>{text}</p>,
    },
    {
      title: deduplication.userProfile,
      dataIndex: ['username'],
      key: 8,
      render: (text, record) => <p>{record.status.key === 'LINK_SET' && text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: 9,
      render: (record) =>
        record.status.key !== 'LINK_BLOCKED' && (
          <Button
            type="link"
            onClick={() => {
              setRecord(record);
              setGroupId(record?.groupId);
              dispatch(getDeduplicationGroupProtocolInfo(record?.groupId));
              setIsDeduplicationModalVisible(true);
            }}
          >
            {deduplication.openComparisonForm}{' '}
          </Button>
        ),
    },
  ];
  if (isEmpty(deduplicationProtocolInfo))
    return <Spin size="large" className="deduplicationProtocols__spinner-wrapper" style={{marginTop: 20}} />;
  return (
    <div className="deduplicationProtocol">
      <div>
        <Button
          onClick={() => history.push('/deduplication/protocols')}
          size="middle"
          className="deduplicationProtocol__btn-go-back"
        >
          {deduplication.goBack}
        </Button>
        <div className="deduplicationProtocol__title">{deduplication.protocol}</div>
      </div>

      <DeduplicationProtocolSystemInfo
        deduplicationProtocolInfo={!isEmpty(deduplicationProtocolInfo) && deduplicationProtocolInfo[0]}
      />
      {!isEmpty(deduplicationProtocolInfo) && !isEmpty(checkGroupId) && (
        <>
          <DeduplicationFilters
            statusConnectionFilter={statusConnectionFilter(deduplicationProtocolInfo)}
            userConfirmConnectFilter={userConfirmConnectFilter(deduplicationProtocolInfo)}
            onSubmit={(filter) => setFilter(filter)}
            filterProtocol={true}
            setFilter={setFilter}
          />
          <StyledTable
            columns={columns}
            dataSource={deduplicationProtocolInfo ? filteringDeduplicationProtocolInfoList : null}
            rowKey={record.groupId}
          />
        </>
      )}
      {isEmpty(checkGroupId) && submitRequest !== true && (
        <div className="deduplicationProtocol__emptyProtocol">
          <div className="deduplicationProtocol__emptyProtocol--title">{deduplication.emptyProtocolTitle}</div>
          {deduplication.emptyProtocol.map((item) => (
            <div>{item}</div>
          ))}
        </div>
      )}
      <Modal
        open={isDeduplicationModalVisible}
        visible={isDeduplicationModalVisible}
        footer={null}
        okText={deduplication.save}
        onCancel={() => {
          setIsDeduplicationModalVisible(false);
          dispatch(resetDeduplicationProtocolInfoGroup());
        }}
        cancelText={deduplication.cancel}
        width={900}
        className="deduplicationProtocol__modal"
        closable={false}
      >
        <DeduplicationGroupModal
          status={status}
          groupId={groupId}
          deduplicationProtocolInfo={!isEmpty(deduplicationProtocolInfo) && deduplicationProtocolInfo[0]}
          setIsDeduplicationModalVisible={setIsDeduplicationModalVisible}
        />
      </Modal>
    </div>
  );
};

export default React.memo(DeduplicationProtocol);
