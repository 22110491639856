import React from 'react'
import { Card } from 'antd'
import ProductOpinions from './ProductOpinions';
import ProductVote from './ProductVote';
import RegProduct from './RegProduct';
import PoChanges from './PoChanges';
import PilotingResults from './PilotingResults';

const Approval = ({ serviceId, data, meta }) => {

    return (
        <>
            <Card
                title='Мнения по продукту'
                className={'container--white mb-5'}
            >
                <ProductOpinions
                    serviceId = {serviceId}
                />
            </Card>
            <Card
                title='Голосования продуктового комитета'
                className={'container--white mb-5'}
            >
                <ProductVote
                    serviceId = {serviceId}
                />
            </Card>
            {data && <>
                {data.reglamentStageEndDate && <RegProduct
                    serviceId = {serviceId}
                    data = {data}
                    meta = {meta}
                />}
                {data.softwareUpdateStageEndDate && <PoChanges
                    serviceId = {serviceId}
                    data = {data}
                    meta = {meta}
                />}
                {data.pilotEndReportStageDate && <PilotingResults
                    serviceId = {serviceId}
                    data = {data}
                />}
            </>}
        </>
    )
}

export default Approval
