import { SET_RECORD_LOADING } from "../actions/actionTypes"

const initialState = {
    recordLoading: false,
}

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECORD_LOADING:
            return {
                ...state,
                recordLoading: action.payload
            }
        default:
            return state;
    }
};