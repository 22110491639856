import React, {useState, useEffect, useCallback} from 'react';
import {BaseDiv, StyledTable} from '../Themes/Components';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {fetchFunc} from '../../Utils/security/http/mdm';

const AccessRightsTable = (props) => {
  const {
    handlePaginationChange,
    loading,
    data: {content, totalElements},
    editRule,
    showDeleteRuleConfirm,
  } = props;

  const [tableContent, setTableContent] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let arr = [];
    if (content) {
      setTableContent([]);
      content.forEach(async (item) => {
        if (item.userId) {
          await getUserName(item.userId).then((userName) => {
            item.userName = userName;
            arr.push(item);
            forceUpdate();
          });
        } else arr.push(item);
      });
      setTableContent(arr);
    }
  }, [content]);

  const getUserName = async (userId) => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/user_profile/items/${userId}`,
        method: 'get',
      });
      return data.username;
    } catch (error) {}
  };

  const columns = [
    {
      title: 'Пользователь, Роль',
      dataIndex: 'roleName',
      width: '30%',
      render: (text, record) => {
        return text || record.userName;
      },
    },
    {
      title: 'Уровень доступа',
      dataIndex: 'access',
      width: '30%',
      render: (text) => {
        switch (text) {
          case 0:
            return 'Доступ запрещен';
          case 1:
            return 'Просмотр';
          case 2:
            return 'Полный';
          default:
            return 'Полный';
        }
      },
    },
    {
      width: '30%',
      render: (text, record) => {
        return (
          <BaseDiv>
            <EditOutlined
              className="mr-2"
              onClick={() => {
                editRule(record.id);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                showDeleteRuleConfirm(record.roleName ? record.roleName : record.userName, record.id);
              }}
            />
          </BaseDiv>
        );
      },
    },
  ];

  return (
    <StyledTable
      loading={loading}
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={[...tableContent]}
      pagination={{
        onChange: handlePaginationChange,
        total: totalElements,
      }}
    />
  );
};

export default AccessRightsTable;
