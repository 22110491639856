import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {
  Button,
  Modal,
  Breadcrumb,
  Form,
  Input,
  Upload,
  Radio,
  Select,
  Table,
  Dropdown,
  Menu,
  Checkbox,
  DatePicker,
  InputNumber,
  Card,
  Tooltip,
  Tabs,
  Switch,
  Collapse,
} from 'antd';

export const getElementStyles = (props) => {
  return props.theme[props.sName] ? props.theme[props.sName](props) : '';
};

export const BaseDiv = styled.div`
  ${(props) => getElementStyles(props)}
`;

export const StyledCheckbox = styled(Checkbox)`
  ${(props) => getElementStyles(props)}
`;

export const StyledDatePicker = styled(DatePicker)`
  ${(props) => getElementStyles(props)}
`;

export const StyledInputNumber = styled(InputNumber)`
  ${(props) => getElementStyles(props)}
`;

export const StyledSpan = styled.span`
  ${(props) => getElementStyles(props)}
`;

export const StyledLink = styled.a`
  ${(props) => getElementStyles(props)}
`;

export const StyledButton = styled(Button)`
  ${(props) => getElementStyles(props)}
`;

export const StyledButtonGroup = styled(Button.Group)`
  ${(props) => getElementStyles(props)}
`;

export const StyledForm = styled(Form)`
  ${(props) => getElementStyles(props)}
`;

export const StyledFormItem = styled(Form.Item)`
  ${(props) => getElementStyles(props)}
`;

export const StyledInput = styled(Input)`
  ${(props) => getElementStyles(props)}
`;

export const StyledTextarea = styled(Input.TextArea)`
  ${(props) => getElementStyles(props)}
`;

export const StyledInputSearch = styled(Input.Search)`
  ${(props) => getElementStyles(props)}
`;

export const StyledUpload = styled(Upload)`
  ${(props) => getElementStyles(props)}
`;

export const StyledModal = styled(Modal)`
  ${(props) => getElementStyles(props)}
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  ${(props) => getElementStyles(props)}
`;

export const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  ${(props) => getElementStyles(props)}
`;

export const StyledRouterLink = styled(Link)`
  ${(props) => getElementStyles(props)}
`;

export const StyledRadio = styled(Radio)`
  ${(props) => getElementStyles(props)}
`;

export const StyledRadioGroup = styled(Radio.Group)`
  ${(props) => getElementStyles(props)}
`;

export const StyledSelect = styled(Select)`
  ${(props) => getElementStyles(props)}
`;

export const StyledSelectOption = styled(Select.Option)`
  ${(props) => getElementStyles(props)}
`;

export const StyledTable = styled(Table)`
  ${(props) => getElementStyles(props)}
`;

export const StyledDropdown = styled(Dropdown)`
  ${(props) => getElementStyles(props)}
`;

export const StyledMenu = styled(Menu)`
  ${(props) => getElementStyles(props)}
`;

export const StyledMenuItem = styled(Menu.Item)`
  ${(props) => getElementStyles(props)}
`;

export const StyledCard = styled(Card)`
  ${(props) => getElementStyles(props)}
`;

export const StyledTooltip = styled(Tooltip)`
  ${(props) => getElementStyles(props)}
`;

export const StyledTabs = styled(Tabs)`
  ${(props) => getElementStyles(props)}
`;

export const StyledTabPane = styled(Tabs.TabPane)`
  ${(props) => getElementStyles(props)}
`;

export const StyledSwitch = styled(Switch)`
  ${(props) => getElementStyles(props)}
`;

export const StyledCollapse = styled(Collapse)`
  ${(props) => getElementStyles(props)}
`;

export const StyledCollapsePanel = styled(Collapse.Panel)`
  ${(props) => getElementStyles(props)}
`;
