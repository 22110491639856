import React, { useState } from 'react';
import { Tabs } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import StatusListForm from '../../components/Settings/StatusForms/StatusListForm';
import StatusMatrixForm from '../../components/Settings/StatusForms/StatusMatrixForm';
import StatusList from '../../components/Settings/StatusList';
import ReportTemplates from '../../components/Settings/ReportTemplates/ReportTemplates';
import { onError } from '../../components/Helpers/Utils';

const statusMatrixColumns = [
    {
        title: 'Сущность',
        dataIndex: 'entityCaption'
    },
    {
        title: 'Статус',
        dataIndex: ['status', 'caption']
    },
];

const statusColumns = [
    {
        title: 'Наименование',
        dataIndex: 'caption'
    },
    {
        title: 'Активный',
        dataIndex: 'active',
        render: (text) => text && <CheckOutlined />
    },
];

const sysActionColumns = [
    {
        title: 'Наименование',
        dataIndex: 'caption'
    }
];

const StatusAddForm = ({ editibleItem, onReset, onSubmit }) => {
    return <StatusListForm
        editibleItem={editibleItem}
        onSubmit={onSubmit}
        onReset={onReset}
    />
};

const StatusMatrixAddForm = ({ editibleItem, onReset, onSubmit }) => {
    return <StatusMatrixForm
        editibleItem={editibleItem}
        onSubmit={onSubmit}
        onReset={onReset}
    />
};



const SettingsPage = () => {
    const [activeKey, setActiveKey] = useState('1');

    return (
        <div className='container container--white p-0'>
            <div className='row m-0'>
                <div className='col p-0'>
                    <Tabs
                        activeKey={activeKey}
                        onChange={setActiveKey}
                        destroyInactiveTabPane={true}
                        tabPosition={'left'}
                    >
                        <Tabs.TabPane tab="Список статусов" key="1">
                            <StatusList
                                isFilter
                                store={'statuses'}
                                columns={statusColumns}
                                cardTitle='Список статусов'
                                AddForm={StatusAddForm}
                                onDeleteError={(error) => onError(error, 'Ошибка удаления!')}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Статусная модель" key="2">
                            <StatusList
                                store={'status-matrix'}
                                columns={statusMatrixColumns}
                                cardTitle='Статусная модель'
                                AddForm={StatusMatrixAddForm}
                                modalWidth={1100}
                                onCreateError={(error) => onError(error, 'Ошибка создания!')}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Список действий" key="3">
                            <StatusList
                                isFilter
                                store={'actions'}
                                columns={statusColumns}
                                cardTitle='Список действий'
                                AddForm={StatusAddForm}
                                onDeleteError={(error) => onError(error, 'Ошибка удаления!')}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Список доступных функций на переходе" key="4">
                            <StatusList
                                store={'status-matrix/sysactions'}
                                columns={sysActionColumns}
                                cardTitle='Список доступных функций на переходе'
                                readOnly
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Шаблоны отчета" key="5">
                            <ReportTemplates />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
