import React, {useEffect, useMemo} from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ProductNavigation} from 'product-navigation';

import {getMenuAccess, setBreadcrumbsNames} from '../../redux/actions/actions';
import {createBreadcrumbNameMap} from './helpers/utils';
import {initialRoutes} from './rotes';
import {initialMenuItems} from './menu';
import {BaseDiv} from '../../components/Themes/Components';
import {getUserName} from '../../redux/thunk/userThunk';
import {getHelpInfo} from '../../redux/thunk/helpThunk';

const App = () => {
  const dispatch = useDispatch();
  const menuAccess = useSelector(({access}) => access.menuAccess);
  const catalogs = useSelector(({subscriptions}) => subscriptions.catalogs);
  useEffect(() => {
    dispatch(getUserName());
  }, []);

  useEffect(() => {
    dispatch(getHelpInfo());
  }, []);

  useEffect(() => {
    dispatch(getMenuAccess());
  }, []);

  const menuList = useMemo(() => {
    let newMenuList = [...initialMenuItems];

    const hasVerification = newMenuList.find((item) => item.id === 14);

    if (menuAccess.hasCounterpartyVerificationAccess && !hasVerification) {
      newMenuList.push({
        id: 14,
        route: '',
        title: 'Верификация',
        icon: 'CheckOutlined',
        items: [
          {
            id: 15,
            route: '/verifier-list/catalogs',
            title: 'Справочники',
            icon: null,
            items: [],
          },
        ],
      });
    } else if (!hasVerification) {
      newMenuList.push({
        id: 14,
        route: '',
        title: 'Верификация',
        icon: 'CheckOutlined',
        items: [
          {
            id: 15,
            route: '/verifier-list/catalogs',
            title: 'Справочники',
            icon: null,
            items: [],
          },
        ],
      });
    }

    if (menuAccess.hasSystemCatalogsAccess && !newMenuList.find((item) => item.id === 30)) {
      newMenuList.push({
        id: 30,
        route: '/notification',
        title: 'Уведомления',
        icon: 'BellOutlined',
        items: [
          {
            id: 31,
            route: '/notification/template',
            title: 'Шаблоны',
            icon: null,
            items: [],
          },
          {
            id: 32,
            route: '/notification/list',
            title: ' Список уведомлений',
            icon: null,
            items: [],
          },
        ],
      });
    }
    if (menuAccess.hasSchedulerAccess && !newMenuList.find((item) => item.id === 9)) {
      newMenuList.push({
        id: 9,
        route: '',
        title: 'Планировщик',
        icon: 'ScheduleOutlined',
        items: [
          {
            id: 10,
            route: '/scheduler-tasks',
            title: 'Задачи планировщика',
            icon: null,
            items: [],
          },
          {
            id: 11,
            route: '/scheduler-calendar',
            title: 'Расписание планировщика',
            icon: null,
            items: [],
          },
        ],
      });
    }
    if (!newMenuList.find((item) => item.id === 4)) {
      newMenuList.push({
        id: 4,
        route: '',
        title: 'Администрирование',
        icon: 'SettingOutlined',
        items: [
          {
            id: 6,
            route: '/catalogs-rules',
            title: 'Установить доступ для группы справочников',
            icon: null,
            items: [],
          },
          {
            id: 7,
            route: '/catalog-draft',
            title: 'Заявки на изменение справочников',
            icon: null,
            items: [],
          },
        ],
      });
    }
    const administrationMenuBlock = newMenuList.find((item) => item.id === 4);
    if (menuAccess.hasTypeCreateAccess && administrationMenuBlock) {
      administrationMenuBlock.items.unshift(
        {
          id: 5,
          route: '/catalog-add',
          title: 'Создать справочник',
          icon: null,
          items: [],
        },
        {
          id: 8,
          route: '/catalog-import',
          title: 'Импортировать заявку из файла',
          icon: null,
          items: [],
        }
      );
    }
    const hasSystemCatalogs = newMenuList.find((item) => item.name === 'systemCatalogs');

    if (menuAccess.hasSystemCatalogsAccess && !hasSystemCatalogs) {
      const systemCatalogs = {
        id: Date.now(),
        name: 'systemCatalogs',
        route: '',
        title: 'Системные справочники',
        icon: 'DatabaseOutlined',
        items: [
          {
            id: Date.now() + 1,
            route: '/external-systems',
            title: 'Внешние системы',
            icon: null,
            items: [],
          },
          {
            id: Date.now() + 2,
            route: '/subsystems',
            title: 'Подсистемы',
            icon: null,
            items: [],
          },
          /** Скрыто в рамках задачи MDM-413 */
          // {
          //   id: Date.now() + 3,
          //   route: '/link-type',
          //   title: 'Типы кросс-связей',
          //   icon: null,
          //   items: [],
          // },
        ],
      };
      newMenuList.push(systemCatalogs);

      if (menuAccess.hasSubscriptionsAccess && !systemCatalogs.items.find((item) => item.title === 'Подписки')) {
        systemCatalogs.items.push({
          id: Date.now() + 4,
          route: '/subscriptions',
          title: 'Подписки',
          icon: null,
          items: [],
        });
      }

      const deduplicationCatalogs = newMenuList.find((item) => item.id === 24);

      if (
        menuAccess.hasSystemCatalogsAccess &&
        deduplicationCatalogs &&
        !deduplicationCatalogs.items.find((item) => item.id === 26)
      ) {
        deduplicationCatalogs.items.push({
          id: 26,
          route: '/deduplication',
          title: 'Задания дедупликации',
          icon: null,
          items: [],
        });
      }
    }
    dispatch(setBreadcrumbsNames(createBreadcrumbNameMap(newMenuList)));
    return newMenuList;
  }, [
    catalogs,
    dispatch,
    menuAccess.hasCounterpartyVerificationAccess,
    menuAccess.hasSchedulerAccess,
    menuAccess.hasSubscriptionsAccess,
    menuAccess.hasSystemCatalogsAccess,
    menuAccess.hasTypeCreateAccess,
  ]);

  return (
    <BaseDiv sName="app">
      <BrowserRouter>
        <BaseDiv sName="mainContainer" className="d-flex flex-column container-fluid p-0 h-100">
          <BaseDiv sName="productNavigation">
            <ProductNavigation items={menuList} />
          </BaseDiv>
          <BaseDiv sName="appContent">
            <Switch>
              <Redirect exact from="/" to="/catalog" />
              {initialRoutes.map((route, i) => {
                return (
                  <Route
                    key={'route-' + i}
                    path={route.path}
                    render={(props) => {
                      return <route.main {...props} {...route} />;
                    }}
                    other={route}
                  />
                );
              })}
            </Switch>
          </BaseDiv>
          <Switch>
            {initialRoutes?.map((route, i) => (
              <Route key={i} path={route.path} component={route.footer} other={route} />
            ))}
          </Switch>
        </BaseDiv>
      </BrowserRouter>
    </BaseDiv>
  );
};

export default React.memo(App);
