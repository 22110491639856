import React from 'react';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {StyledModal} from '../Themes/Components';

export function errorModalCreate(text) {
  StyledModal.error({
    sName: 'errorModal',
    title: 'Ошибка',
    content: text.replace(/[:"]/g, ''),
  });
}

export function successModalCreate(title, text) {
  StyledModal.success({
    title: title,
    content: text,
  });
}

export function warningModalCreate(title, text) {
  StyledModal.warning({
    title: title,
    content: text,
  });
}

export function infoModalCreate({title, content}) {
  StyledModal.info({
    title,
    content,
  });
}

export function confirmModalCreate({title, content, okText, onOk, cancelText, onCancel}) {
  StyledModal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
  });
}

export function showDeleteConfirm({title = 'Внимание', content, onOk}) {
  StyledModal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okType: 'danger',
    onOk,
    okText: 'Да',
    cancelText: 'Нет',
  });
}

export const showConfirm = ({title, content, action, okText = 'Ок', cancelText = 'Отмена', onCancel}) => {
  StyledModal.confirm({
    title,
    content,
    okText,
    cancelText,
    onOk() {
      action();
    },
    onCancel,
  });
};
