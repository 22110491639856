import React, {useEffect, useState} from 'react';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {Table, Collapse} from 'antd';
import SimpleFilter from '../../components/CatalogsFilters/Components/SimpleFilter';

const CatalogsOrgDocs = ({recordUuid, columns, store, filterFields, header}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => {
    setLoading(true);
    recordUuid && getDocsData(recordUuid);
  }, [page, size, recordUuid, filterData]);

  const getDocsData = async (uuid) => {
    try {
      let method = 'post';
      let data = {
        fullText: '',
        onlyLastVersions: true,
        fields: {
          'orgId.uuid': uuid, // UUID Контрагента
          ...filterData,
        },
      };
      let params = {};
      let url = `/api/v1/document/search`;
      if (store === 'registry') {
        url = `/api/v1/registry/by-org/${uuid}`;
        data = {
          onlyLastVersions: true,
          fields: {
            caption: filterData && filterData.caption && `~${filterData.caption}`,
            'status.id': filterData && filterData['status.id'],
          },
        };
        params = {
          date: filterData && filterData.createDate,
        };
      }
      if (store === 'services') {
        url = `/api/v1/counterparty/process-search`;
        data = {
          onlyActive: true,
          ...filterData,
        };
        params = {
          clientId: recordUuid,
        };
      }
      const fetchData = await fetchFunc(
        {
          url,
          method,
          data,
          params: {
            page,
            size,
            ...params,
          },
        },
        null,
        store === 'services' ? 'mdm' : 'ecm'
      );
      setData(fetchData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setLoading(true);
    setPage(--pagination.current);
    setSize(pagination.pageSize);
  };

  const onFilterSearch = (data) => {
    if (data['createDate']) data['createDate'] = `${data['createDate'].format('YYYY-MM-DD')}`;
    setPage(0);
    setFilterData(data);
  };

  return (
    <div className="org-list__no-structure-items container--white">
      {header}
      {filterFields && (
        <Collapse>
          <Collapse.Panel key="filter" header="Фильтрация">
            <SimpleFilter
              fields={filterFields}
              onFilterSearch={onFilterSearch}
              onFilterClear={() => setFilterData({})}
            />
          </Collapse.Panel>
        </Collapse>
      )}
      <Table
        columns={columns}
        dataSource={data && data.content}
        loading={loading}
        rowKey={(record) => record.uuid}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: data && data.totalElements,
        }}
        bordered
        scroll={{x: true}}
      />
    </div>
  );
};

export default CatalogsOrgDocs;
