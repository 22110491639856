import React, {useMemo, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {
  changeSourcePriority,
  deleteSourcePriority,
  getCatalogFields,
  getSourcePriority,
  createCatalogDraft,
  exportOriginData,
} from './effects/actions';
import {modalFields} from './utils/constants';
import {UnlockOutlined, CheckOutlined, CloseOutlined, BarChartOutlined} from '@ant-design/icons';
import {
  BaseDiv,
  StyledSelect,
  StyledSelectOption,
  StyledTable,
  StyledModal,
  StyledButton,
  StyledTooltip,
  StyledInputSearch,
} from '../Themes/Components';
import PriorityModal from './PriorityModal';
import AccessRights from '../AccessRights';
import {includes, isEmpty} from 'lodash';

const Attributes = ({referenceOrigin, referenceUuid, editSigns, prioritySupport, priorityModalVisible}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = [
    {
      title: 'Поле',
      dataIndex: 'caption',
      key: 'caption',
    },
    {
      title: 'Код',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'Тип',
      dataIndex: ['fieldType', 'caption'],
      key: 'fieldType',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const [displayedColumns, setDisplayedColumns] = useState(['caption', 'origin', 'fieldType']);
  const [attrModalShow, setAttrModalShow] = useState({
    visible: false,
    record: null,
  });
  const [rulesModalShow, setRulesModalShow] = useState({
    visible: false,
    recordUuid: null,
  });
  const [priorityModalShow, setPriorityModalShow] = useState({
    visible: false,
    fieldUuid: null,
  });
  const [priorityPage, setPriorityPage] = useState(0);
  const [prioritySize, setPrioritySize] = useState(10);

  const fields = useSelector(({cartWidgets}) => cartWidgets.catalogFields);
  const loadings = useSelector(({cartWidgets}) => cartWidgets.loadings);
  const sourcePriority = useSelector(({cartWidgets}) => cartWidgets.sourcePriority);

  useEffect(() => {
    referenceOrigin && dispatch(getCatalogFields(referenceOrigin));
  }, [referenceOrigin]);

  useEffect(() => {
    priorityModalVisible && dispatch(getSourcePriority(priorityModalShow.fieldUuid, priorityPage, prioritySize));
  }, [priorityModalVisible, prioritySize, priorityPage, priorityModalShow]);

  const columnsForRender = useMemo(() => {
    let resultColumns = [];
    columns.forEach((item) => {
      if (includes(displayedColumns, item.key)) resultColumns.push(item);
    });
    if (editSigns && editSigns.editProperties) {
      resultColumns.unshift({
        key: 'actions',
        width: '12%',
        render: (text, record) => {
          return (
            <BaseDiv>
              <StyledTooltip title="Настройка доступа">
                <UnlockOutlined
                  className="mr-2"
                  onClick={() =>
                    setRulesModalShow({
                      visible: true,
                      recordUuid: record.uuid,
                    })
                  }
                />
              </StyledTooltip>
              {record.fieldType.id !== 12 && prioritySupport && (
                <StyledTooltip title="Настроить приоритет источников">
                  <BarChartOutlined
                    onClick={() => {
                      setPriorityModalShow({
                        visible: true,
                        fieldUuid: record.uuid,
                      });
                      dispatch(getSourcePriority(record.uuid, priorityPage, prioritySize));
                    }}
                  />
                </StyledTooltip>
              )}
            </BaseDiv>
          );
        },
      });
    }
    return resultColumns;
  }, [displayedColumns, columns, editSigns]);

  const renderFieldValue = (record, origin) => {
    let value;
    let addr = origin.split('.');
    if (addr.length > 1) {
      value = record && record[addr[0]] && record[addr[0]][addr[1]];
    } else {
      value = record && record[addr[0]];
    }
    switch (typeof value) {
      case 'boolean':
        return value ? <CheckOutlined /> : <CloseOutlined />;
      case 'string':
        return value;
      case 'object':
        if (record.options && record.options.combobox_options && record.options.combobox_options.length) {
          return record.options.combobox_options.map((item) => <BaseDiv key={item.key}>{item.value}</BaseDiv>);
        }
        break;
      default:
        return '-';
    }
  };

  const renderFieldAttributes = (record) => {
    let newModalFields = [...modalFields];
    switch (record && record.fieldType?.id) {
      case 4:
        newModalFields.push(
          {title: 'Справочник - источник', origin: 'referenceCatalog.caption'},
          {title: 'Поле для отображения', origin: 'referenceCatalogPattern'},
          {title: 'Фильтрация значений', origin: 'options.filter'}
        );
        break;
      case 7:
      case 8:
        newModalFields.push(
          {title: 'Формат значения', origin: 'options.date_format'},
          {title: 'Значение по умолчанию', origin: 'options.date_default_value_type'},
          {title: 'Календарных дней от текущей даты', origin: 'options.date_duration'},
          {title: 'Значение', origin: 'default_value'}
        );
        break;
      case 10:
        newModalFields.push({title: 'Значение по умолчанию', origin: 'default_value'});
        break;
      case 14:
        newModalFields.push(
          {title: 'Способ отображения на форме', origin: 'options.control_type'},
          {title: 'Список значений', origin: 'options.combobox_options'}
        );
        break;
      case 15:
        newModalFields.push({title: 'Список значений', origin: 'options.combobox_options'});
        break;
      default:
    }
    return (
      <BaseDiv className="d-flex flex-wrap">
        {newModalFields.map((item, index) => (
          <BaseDiv key={index} className="col-12 mb-5">
            <BaseDiv>
              <strong>{item.title}</strong>
            </BaseDiv>
            {renderFieldValue(record, item.origin)}
          </BaseDiv>
        ))}
      </BaseDiv>
    );
  };

  return (
    <BaseDiv>
      <BaseDiv>
        <BaseDiv className="d-flex mb-2 justify-content-between">
          <BaseDiv>
            {editSigns && editSigns.editAttributes && (
              <StyledButton className="mr-2" onClick={() => dispatch(createCatalogDraft(referenceUuid, history))}>
                Изменить
              </StyledButton>
            )}
            <StyledTooltip title="Экспортировать структуру справочника в файл">
              <StyledButton onClick={() => dispatch(exportOriginData(referenceOrigin))}>Экспортировать</StyledButton>
            </StyledTooltip>
          </BaseDiv>
          <StyledSelect
            mode="multiple"
            name="displayedColumns"
            showArrow={true}
            maxTagCount={0}
            maxTagPlaceholder={'Колонки'}
            style={{width: '120px'}}
            placeholder="Колонки"
            value={displayedColumns}
            onChange={(value) => setDisplayedColumns(value)}
          >
            {columns.map((item) => {
              return (
                <StyledSelectOption value={item.key} key={item.key}>
                  {item.title}
                </StyledSelectOption>
              );
            })}
          </StyledSelect>
        </BaseDiv>
      </BaseDiv>
      <StyledTable
        title={() => (
          <StyledInputSearch
            placeholder="Укажите наименования полей для поиска"
            allowClear
            enterButton={true}
            size="large"
            name="requestString"
            onSearch={(value) => dispatch(getCatalogFields(referenceOrigin, value))}
          />
        )}
        columns={columnsForRender}
        dataSource={[...fields.content]}
        loading={loadings.catalogFields}
        rowKey={(record) => record.uuid}
        onRow={(record) => {
          return {
            onDoubleClick: () =>
              setAttrModalShow({
                visible: true,
                record,
              }),
          };
        }}
        pagination={{
          showSizeChanger: true,
          total: fields.totalElements,
        }}
      />
      <StyledModal
        title="Просмотр атрибутов"
        visible={attrModalShow.visible}
        onCancel={() =>
          setAttrModalShow({
            ...attrModalShow,
            visible: false,
          })
        }
        footer={null}
      >
        {attrModalShow.record && renderFieldAttributes(attrModalShow.record)}
      </StyledModal>
      <StyledModal
        title={'Права записи'}
        visible={rulesModalShow.visible}
        footer={null}
        onCancel={() =>
          setRulesModalShow({
            ...rulesModalShow,
            visible: false,
          })
        }
        destroyOnClose={true}
        width={800}
      >
        <AccessRights rulesRecordUuid={rulesModalShow.recordUuid} store="fields" referenceOrigin={referenceOrigin} />
      </StyledModal>

      <PriorityModal
        data={!isEmpty(sourcePriority.content) && sourcePriority.content}
        loading={loadings.sourcePriority}
        totalElements={sourcePriority.totalElements}
        modalVisible={priorityModalShow.visible}
        handleTableChange={({pageSize, current}) => {
          setPriorityPage(pageSize);
          setPrioritySize(--current);
        }}
        create={(values, editableItem) =>
          dispatch(changeSourcePriority(priorityModalShow.fieldUuid, referenceUuid, values, editableItem))
        }
        delete={(priorityId) => dispatch(deleteSourcePriority(priorityModalShow.fieldUuid, priorityId))}
        onCancel={() =>
          setPriorityModalShow({
            ...priorityModalShow,
            visible: false,
          })
        }
      />
    </BaseDiv>
  );
};

export default Attributes;
