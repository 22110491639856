import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Table, Modal, Select} from 'antd';
import {isEmpty} from 'lodash';
import Search from 'antd/es/input/Search';

import {searchScheduleExecution, searchScheduleTask} from '../../redux/thunk/catalogThunk';
import CatalogsFilters from '../CatalogsFilters';

const ListWithAddForm = (props) => {
  const {
    handleColumnsChange,
    tableConfig: {data, columns, loading, total, displayedColumns, expandable, current},
    changeData,
    editCatalog,
    addModalTitle,
    FormComponent,
    isSearch,
    setEditableItem,
    editableItem,
    setEditableItemTask,
    editableItemTask,
    handleTableChange,
    onFilterSearch,
    onContextSearch,
    onHistorySearch,
    onHistoryReset,
    contextSearchValue,
    fields,
    onExport,
    exportLoading,
    modalWidth,
    additionalActions,
    withoutContainer,
    getEditableItem,
    clearEditableItem,
    applyFilter,
  } = props;
  const dispatch = useDispatch();
  const [searchTask, setSearchTask] = useState('');
  const scheduleExecution = useSelector((store) => store?.filter.scheduleExecution);
  let columnsShowOptions = useMemo(() => {
    return columns.map((item, index) => {
      return (
        <Select.Option value={item.key} key={'column-select' + index}>
          {item.title}
        </Select.Option>
      );
    });
  }, [columns]);
  const [modalVisible, setModalVisible] = useState(false);
  const [keyForVisibleForm, setKeyForVisibleForm] = useState('');
  const [editKey, setEditKey] = useState('');
  const [columnsForRender, setColumnsForRender] = useState([]);
  const [displayedColumnsState, setDisplayedColumns] = useState(displayedColumns);

  useEffect(() => {
    if (displayedColumns) {
      handleColumnsSelectChange(displayedColumns);
    } else {
      setColumnsForRender(columns);
    }
  }, [displayedColumns, columns]);

  const onRow = (record) => {
    return {
      onDoubleClick: async () => {
        if (setEditableItem || setEditableItemTask) {
          addModalTitle === 'задач' ? setEditableItemTask(record) : setEditableItem(record);
          addModalTitle === 'задач' ? setKeyForVisibleForm('job') : setKeyForVisibleForm('');
          setModalVisible(true);
          setEditKey('edit');
        }
        if (getEditableItem) {
          try {
            await getEditableItem(record);
            addModalTitle === 'задач' ? setKeyForVisibleForm('job') : setKeyForVisibleForm('');
            setModalVisible(true);
          } catch (error) {}
        }
      },
    };
  };

  const handleColumnsSelectChange = (value) => {
    if (handleColumnsChange) handleColumnsChange(value);
    let columnsForRender = columns.filter((item) => value.some((elem) => elem === item.key));
    setColumnsForRender(columnsForRender);
    setDisplayedColumns(value);
  };

  const onFormSubmit = (values, editableItem) => {
    setModalVisible(false);
    changeData(values, editableItem);
    clearEditableItem && clearEditableItem();
    setEditableItem && setEditableItem(null);
    setEditableItemTask && setEditableItemTask(null);
    setEditKey(null);
  };

  const openAddModal = useCallback(() => {
    setModalVisible(true);
    addModalTitle === 'задач' ? setKeyForVisibleForm('job') : setKeyForVisibleForm('');
    clearEditableItem && clearEditableItem(null);
    setEditableItem && setEditableItem(null);
    setEditableItemTask && setEditableItemTask(null);
  }, [setModalVisible, setEditableItemTask, setEditableItem]);

  return (
    <div className="col-12">
      {isSearch && (
        <div className="container container--white mb-2">
          <Search
            placeholder="Укажите наименование для поиска"
            enterButton={true}
            size="medium"
            style={{width: 800}}
            onSearch={(value) => {
              setSearchTask(value);
              addModalTitle === 'задач'
                ? dispatch(searchScheduleTask(value))
                : dispatch(searchScheduleExecution(value));
            }}
          />
        </div>
      )}
      {(onContextSearch || onFilterSearch || onHistorySearch) && (
        <div className="container container--white">
          <CatalogsFilters
            applyFilter={applyFilter}
            onContextSearch={onContextSearch}
            contextSearchValue={contextSearchValue}
            onFilterSearch={onFilterSearch}
            onHistorySearch={onHistorySearch}
            onHistoryReset={onHistoryReset}
            fields={fields}
          />
        </div>
      )}
      <div className={`container ${withoutContainer ? '' : 'container--white'}`}>
        <div className="d-flex justify-content-start mb-2">
          {FormComponent && editCatalog && (
            <Button type="primary" onClick={openAddModal}>
              Добавить
            </Button>
          )}
          {onExport && (
            <Button onClick={onExport} className="ml-2" loading={exportLoading}>
              Выгрузить
            </Button>
          )}
          {additionalActions}
          {displayedColumns && (
            <Select
              className="ml-auto"
              mode="multiple"
              name="displayedColumns"
              showArrow={true}
              maxTagCount={0}
              maxTagPlaceholder={'Колонки'}
              style={{width: '120px'}}
              placeholder="Колонки"
              value={displayedColumnsState}
              onChange={(value) => handleColumnsSelectChange(value)}
            >
              {columnsShowOptions}
            </Select>
          )}
        </div>
        <Table
          dataSource={!isEmpty(searchTask) ? scheduleExecution : data}
          columns={columnsForRender}
          loading={loading}
          rowClassName={(record) =>
            !isEmpty(record?.sysVerifyInfoDraft) && record?.sysVerifyInfoDraft ? 'unverified-version' : ''
          }
          style={{cursor: 'pointer'}}
          rowKey={(record) => record.uuid}
          onChange={handleTableChange}
          pagination={{
            total: total,
            showSizeChanger: true,
            current,
          }}
          onRow={onRow}
          scroll={{
            x: true,
          }}
          expandable={expandable}
        />
      </div>
      <Modal
        title={
          editableItem || editableItemTask ? `Форма редактирования ${addModalTitle}` : `Форма создания ${addModalTitle}`
        }
        width={modalWidth || 700}
        open={modalVisible}
        onCancel={useCallback(() => {
          setModalVisible(false);
          clearEditableItem && clearEditableItem(null);
          setEditableItem(null);
          setEditableItemTask(null);
        }, [setModalVisible, setEditableItem, setEditableItemTask])}
        footer={null}
        closable={false}
      >
        <FormComponent
          onSubmit={onFormSubmit}
          editKey={editKey}
          keyForVisibleForm={keyForVisibleForm}
          editableItem={editableItem}
          editableItemTask={editableItemTask}
          onCancel={useCallback(
            () =>
              setModalVisible(
                false,
                setEditKey(null),
                setEditableItemTask && setEditableItemTask(null),
                setEditableItem && setEditableItem(null)
              ),
            [setModalVisible, editKey, editableItem, editableItemTask]
          )}
        />
      </Modal>
    </div>
  );
};

export default React.memo(ListWithAddForm);
