import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'antd'
import useCRUD from '../../hooks/CRUD.hook'
import { errorModalCreate } from '../Helpers/Modals';

const MultiplySelection = ({ visible, columns, onCancel, onOk, isSingleSelection, rowKey, defaultSelected = [] }) => {
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const { getData, data, loading, total } = useCRUD({ apiUrl: '/api/v1/external-system' });
    const [selectedRowKeys, setSelectedRowKeys] = useState(defaultSelected);
    const [seletedRows, setSeletedRows] = useState([]);

    useEffect(() => {
        setSelectedRowKeys(defaultSelected)
        setSeletedRows([]);
    }, [defaultSelected])

    useEffect(() => {
        getData({ page, size })
    }, [page, size]);

    const handleTableChange = (paginator) => {
        setPage(--paginator.current);
        setSize(paginator.pageSize);
    };

    const rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSeletedRows(selectedRows);
        },
        selectedRowKeys,
        preserveSelectedRowKeys: true,
    };

    const onModalOk = () => {
        if (isSingleSelection && selectedRowKeys.length > 1) errorModalCreate('Максимум 1 элемент');
        else {
            onOk(seletedRows, selectedRowKeys);;
        }

    };

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={onModalOk}
        >
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                rowKey={record => record[rowKey]}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                pagination={{
                    total,
                    showSizeChanger: true
                }}
            />
        </Modal>
    )
}

export default React.memo(MultiplySelection)
