export const structureTypeOfActivity = [
    {
        type: 'container', name: 'main_container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'general', label: '', items: [
                    { origin: 'okved', type: 'item' },
                    { origin: 'okveds', type: 'item' },
                    { origin: 'tnveds', type: 'item' },
                ],
            },
        ]
    }
]
