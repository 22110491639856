import {
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_LOADING,
  SET_CATALOGS_LOADING,
  SET_CATALOGS,
  SET_EVENTS_TYPES_LOADING,
  SET_EVENTS_TYPES,
  SET_CATALOG_EVENTS,
  SET_CATALOG_EVENTS_LOADING,
} from './actionTypes';
import {SET_CURRENT_CATALOG} from '../../../redux/actions/actionTypes';

const initialState = {
  subscriptions: {
    content: [],
  },
  catalogs: {
    content: [],
  },
  currentCatalog: {},
  eventsTypes: {
    content: [],
  },
  catalogEvents: {
    eventUuid: null,
    content: [],
  },
  loadings: {
    subscriptions: false,
    catalogs: false,
    eventsTypes: false,
    catalogEvents: false,
  },
};

export const subscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case SET_CATALOGS:
      return {
        ...state,
        catalogs: action.payload,
      };
    case SET_CURRENT_CATALOG:
      return {
        ...state,
        currentCatalog: action.payload,
      };
    case SET_EVENTS_TYPES:
      return {
        ...state,
        eventsTypes: action.payload,
      };
    case SET_CATALOG_EVENTS:
      return {
        ...state,
        catalogEvents: action.payload,
      };
    case SET_CATALOG_EVENTS_LOADING:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          catalogEvents: action.payload,
        },
      };
    case SET_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          subscriptions: action.payload,
        },
      };
    case SET_CATALOGS_LOADING:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          catalogs: action.payload,
        },
      };
    case SET_EVENTS_TYPES_LOADING:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          eventsTypes: action.payload,
        },
      };
    default:
      return state;
  }
};
