import React from 'react'
import { Card } from 'antd'
import { CatalogsExtractor } from "mdm-lib";
import security from "../../../../Utils/security";
import { fileDownloadBtn } from "../../../../components/Helpers/FileDownloader";
import { convertDate } from "../../../../components/Helpers/Utils";
const { getToken } = security;


const PilotingResults = ({ serviceId, data }) => {

    return (
        <Card
            title='Фактура по результатам пилотирования'
            className={'container--white mb-5'}
        >
            <div className='d-flex mb-3 mt-3 align-items-center'>
                <div className='mr-2 font-weight-bold'>Фактура:</div>
                {data.pilotEndReportFile && fileDownloadBtn({catalogIdentifier: 'service', uuid: serviceId, fileUuid: data.pilotEndReportFile})}
            </div>
            <div className='d-flex mb-3'>
                <div className='mr-2 font-weight-bold'>Сотрудник, подготовивший фактуру:</div>
                <div>
                    <CatalogsExtractor
                        token={getToken()}
                        catalog={'user_profile'}
                        uuid = {data.pilotEndReportUserId && data.pilotEndReportUserId.uuid}
                        render = {
                            (value) => value &&
                                <a
                                    target='_blank'
                                    href={`/catalog/user_profile/${value.uuid}`}
                                >
                                    {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                                </a>
                        }
                    />
                </div>
            </div>
            <div className='d-flex mb-3'>
                <div className='mr-2 font-weight-bold'>Дата выполнения:</div>
                <div>{convertDate(data.pilotEndReportStageDate)}</div>
            </div>
        </Card>
    )
}

export default PilotingResults
