
export const structureIpMainNoResident = [
    {
        type: 'container', greed: 24, className: '', items: [
            {
                type: 'group', name: 'general', label: '', items: [
                    {type:'container', name: 'general__line-1', greed: 24, items: [
                        {origin:'recType', type:'item', greed: 16},
                        {origin:'nonResidentRf', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'general__line-2', greed: 24, items: [
                        {origin:'regCountry', type:'item', greed: 8},
                        {origin:'ogrnAnalog', type:'item', greed: 8},
                        {origin:'innAnalog', type:'item', greed: 8},
                    ]},
                    {type:'container', name: 'general__line-3', greed: 24, items: [
                        {origin:'description', type:'item', greed: 24},
                    ]},
                    {type:'container', name: 'general__line-4', greed: 24, items: [
                        {origin:'extAddresses', type:'item', greed: 24},
                    ]},

                ],
            },
            {
                type: 'group', name: 'relations', label: 'Отношения с группой РЭЦ', items: [
                    {origin:'relationStatus', type:'item'},
                    {origin:'blockReason', type:'item'},
                    {origin:'clientManager', type:'item'},
                    {origin:'bankClient', type:'item'},
                    {origin:'partnerOrg', type:'item'},
                ],
            },
            {
                type: 'group', name: 'other_names', label: 'Прочие наименования', items: [
                    {origin:'descriptionEn', type:'item'},
                    {origin:'altCaptions', type:'item'},
                ],
            },
            {
                type: 'group', name: 'termination_activity', label: 'Прекращение деятельности', items: [
                    {origin:'activityStopped', type:'item'},
                    {origin:'terminationReason', type:'item'},
                    {origin:'terminationDate', type:'item'},
                ],
            },
            {
                type: 'group', name: 'org_size', label: 'Размер организации', items: [
                    {type:'container', greed: 24, items: [
                        {origin:'employeeNumber', type:'item', greed: 8},
                    ]},
                ],
            },
        ]
    }
];
