import React from 'react';
import {Select, Spin} from 'antd';
import _, {isEmpty, isNull, isString} from 'lodash';

import {errorModalCreate} from '../../Helpers/Modals';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import {charCounter, createReferenceScriptText, onError} from '../../Helpers/Utils';

class DynamicSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      renderFields: [],
      fetching: false,
      depth: 0,
      nextFetch: true,
      data: [],
    };
    this.pageNumberRef = React.createRef();
  }

  componentDidMount() {
    const {
      defaultValue,
      detailMode,
      value,
      feachoptions: {displayedField},
    } = this.props;
    if (displayedField && displayedField.indexOf('.') !== -1) {
      this.setState({
        depth: _.isString(displayedField) ? charCounter(displayedField, /[^.]/g) : 0,
      });
    } else {
      if (detailMode) this.setState({depth: 1});
    }
    if (defaultValue || value) {
      this.setState(
        {
          fetching: true,
        },
        () => this.getOptions()
      );
    }
  }

  getInComeOption = async (uuid) => {
    this.setState({
      fetching: true,
    });
    const {referenceStore, valueField = 'uuid', catalogOrigin, inComeOptionUrl} = this.props.feachoptions;
    const {depth} = this.state;
    let urlForFetching;
    let method = 'get';
    if (!isEmpty(uuid)) {
      if (inComeOptionUrl) urlForFetching = `${inComeOptionUrl}/${uuid}`;
      else {
        if (catalogOrigin === 'catalogs') urlForFetching = `/api/v1/catalogs/${uuid}?showRefs=${depth}`;
        else {
          urlForFetching = `/api/v1/catalogs/${catalogOrigin}/items/search/extended?showRefs=${depth}`;
          method = 'post';
        }
      }

      try {
        const data = await fetchFunc(
          {
            url: urlForFetching,
            method,
            data: method === 'post' && {
              [valueField]: uuid,
            },
          },
          onError,
          referenceStore || 'mdm'
        );

        if (data) {
          this.setState({
            options: [...this.state.options, method === 'post' ? data.content[0] || [] : data],
            fetching: false,
            data,
          });
        }
      } catch (error) {
        this.setState({
          fetching: false,
        });
      }
    }
  };

  getOptions = async (searchValue) => {
    const {
      referenceStore,
      url,
      method,
      displayedField,
      valueSearchName,
      defaultSize,
      extraSearchParams,
      getFieldValue,
      relatedParams,
      valueField = 'uuid',
      fieldsForSearch,
      defaultFilter,
      refScript,
      keyForOptions,
    } = this.props.feachoptions;
    const {value, detailMode, selectedUuid, resetOptions, mode, filterMode} = this.props;
    if (!this.state.nextFetch) return;
    if (isNull(this.pageNumberRef.current)) this.pageNumberRef.current = 0;
    if (selectedUuid) this.pageNumberRef.current = 0;

    const {depth} = this.state;
    const size = defaultSize;
    const page = this.pageNumberRef.current;

    let valueString = searchValue ? (method === 'get' ? '&' + valueSearchName + '=' + searchValue.toString() : '') : '';
    let urlForFetching = `${url}?size=${size}${extraSearchParams || valueString || ''}&showRefs=${depth}&page=${page}`;
    let data;
    if (method === 'post') {
      if (fieldsForSearch || refScript) {
        data = {
          filter: {},
        };
        let fieldsStore = [];
        if (refScript) {
          if (isString(refScript)) {
            try {
              fieldsStore = JSON.parse(refScript).names;
            } catch (e) {
              errorModalCreate(`Ошибка JSON - ${refScript}`);
            }
          } else {
            fieldsStore = refScript.names;
          }
        } else fieldsStore = fieldsForSearch;
        fieldsStore.forEach((field) => {
          data.filter[field] = searchValue;
        });
        data.filter['%context_filter'] = searchValue;
      } else if (Array.isArray(displayedField)) {
        if (detailMode) {
          data = {filter: {}};
          displayedField.forEach((item) => {
            data.filter[item] = searchValue;
          });
        } else {
          data = {
            filter: {
              [Array.isArray(valueSearchName) ? valueSearchName[0] : valueSearchName]: searchValue,
              ['%context_filter']: searchValue,
            },
          };
        }
      } else {
        mode === 'create'
          ? (data = {
              fetchFields: [displayedField],
            })
          : (data = {
              filter: {
                [displayedField]: searchValue,
                ['%context_filter']: searchValue,
              },
            });
      }
    }
    let filter = null;
    if (defaultFilter) filter = {...defaultFilter};
    if (relatedParams) {
      const filterParam = relatedParams[2] ? `${relatedParams[0]}.${relatedParams[1]}` : relatedParams.join('.');
      filter = {[filterParam]: getFieldValue(relatedParams[2] || relatedParams[0])};
    }
    if (_.isPlainObject(filter)) _.assign(data, filter);
    try {
      const fetchData = await fetchFunc(
        {
          method,
          url: urlForFetching,
          data,
        },
        null,
        referenceStore || 'mdm'
      );
      if (fetchData) {
        this.setState(
          {
            options:
              isEmpty(this.state.options) || isEmpty(keyForOptions)
                ? [...fetchData.content]
                : [...this.state.options, ...fetchData.content],
            fetching: false,
            nextFetch: fetchData.totalPages > page,
          },
          () => {
            if (!resetOptions) this.pageNumberRef.current++;

            if (!relatedParams && filterMode !== true) {
              if (value && !searchValue) {
                if (Array.isArray(value)) {
                  let notFetchedData = value.filter(
                    (val) => !fetchData.content.some((item) => item[valueField] === val)
                  );
                  if (notFetchedData && notFetchedData.length) {
                    notFetchedData.forEach((item) => this.getInComeOption(item));
                  }
                } else {
                  if (!fetchData.content.some((item) => item[valueField] === value)) {
                    this.getInComeOption(selectedUuid ? selectedUuid : value, searchValue);
                  }
                }
              }
            }
          }
        );
      }
    } catch (error) {
      this.setState(
        {
          fetching: false,
        },
        () => errorModalCreate(error.message)
      );
    }
  };

  getValForDisplayedField = (store, displayedField) => {
    if (displayedField && displayedField.indexOf('.') !== -1) {
      let displayPath = displayedField.split('.');
      const value = _.get(store, displayPath);
      return _.isString(value) ? value : null;
    } else {
      return store[displayedField];
    }
  };

  renderOptions(arr, displayedField) {
    const {
      displayedFieldSeparator = ' ',
      feachoptions: {referenceCatalogue, valueField, refScript},
    } = this.props;
    let tmpArray = [];

    function itemCheck(item) {
      if (tmpArray.indexOf(item[displayedField]) === -1) {
        tmpArray.push(item[displayedField]);
        return true;
      }
      return false;
    }
    let uniqueArr = arr.filter((item) => itemCheck(item));

    return uniqueArr.map((item, index) => {
      if (!item[displayedField]) return null;
      const displayedFieldsArray =
        _.isArray(displayedField) && displayedField.map((fieldName) => _.get(item, fieldName));
      return (
        <Select.Option
          value={valueField ? item[valueField] : referenceCatalogue === 'subsystem' ? item.id : item.uuid}
          fieldCaption={item[valueField]}
          fieldOrigin={item.origin}
          key={index}
          uuidValue={item.uuid}
          referenceCatalogPatternId={item.id}
          referenceOrigin={item.origin}
        >
          {refScript
            ? createReferenceScriptText(item, refScript)
            : displayedFieldsArray
            ? displayedFieldsArray.join(displayedFieldSeparator)
            : _.isObject(item[displayedField])
            ? 'Не верный формат поля для caption'
            : this.getValForDisplayedField(item, displayedField) || 'Значение не заполнено'}
        </Select.Option>
      );
    });
  }

  onSearchSelectValues = (searchValue) => {
    this.setState(
      {
        fetching: !isEmpty(searchValue) && true,
      },
      () => {
        this.getOptions(searchValue);
      }
    );
  };

  onPopupScroll = (event) => {
    const {clientHeight, scrollTop, scrollHeight} = event.target;
    const {selectedUuid} = this.props;
    const scroll = Math.ceil(clientHeight + scrollTop);
    if (scroll >= scrollHeight - 2 && !selectedUuid) {
      this.getOptions();
    }
  };

  render() {
    const {
      notFoundContent,
      handleSelectChange,
      resetOptions = false,
      filterOption = false,
      mode,
      filterMode = false,
      fullResponseValue,
      selectedUuid,
      defaultValue,
    } = this.props;
    const {displayedField} = this.props.feachoptions;
    const {options, fetching} = this.state;

    return (
      <Select
        {...this.props}
        defaultValue={defaultValue}
        mode={mode}
        selectedUuid={selectedUuid}
        resetOptions={resetOptions}
        showArrow={true}
        showSearch={true}
        filterMode={filterMode}
        filterOption={filterOption}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundContent}
        loading={fetching}
        onSearch={_.debounce((value) => this.onSearchSelectValues(value), 5000)}
        onPopupScroll={this.onPopupScroll}
        onChange={(value, option) => {
          if (!fullResponseValue) {
            let itemData = options.find((item) => item.caption === value);
            handleSelectChange(value, option, itemData);
          } else {
            handleSelectChange(value, option);
          }
        }}
        onDropdownVisibleChange={(open) => {
          if (open) this.onSearchSelectValues();
        }}
      >
        {options && this.renderOptions(options, displayedField)}
      </Select>
    );
  }
}

export default DynamicSelect;
