import React from "react";
import { CatalogsExtractor } from "mdm-lib";
import security from "../../../Utils/security";
import { Table } from "antd";
import { convertDate } from "../../../components/Helpers/Utils";
import { isString } from 'lodash';
const { getToken } = security;

export const serviceMeta = [
    {
        origin: 'cost',
        options: {
            caption: 'Стоимость услуги для портала',
            rules: {
                mask: {
                    mask: '999 999 999',
                },
            },
        }
    },
    {
        origin: 'reconciliationHistory',
        options: {
            caption: 'История изменения статусов'
        }
    },
    {
        origin: 'typeServiceId',
        options: {
            caption: 'Тип услуги'
        }
    },
    {
        origin: 'orgId',
        options: {
            filter: {
                partnerOrg: true,
            },
        }
    },
    {
        origin: 'serviceTypesId',
        options: {
            clearFields: ['serviceCategoryId', 'serviceSubcategoriesId'],
        }
    },
    {
        origin: 'serviceCategoryId',
        options: {
            relatedParams: ['typeOfService', 'uuid', 'serviceTypesId'],
            clearFields: ['serviceSubcategoriesId'],
            rules: {
                visible: {
                    rule: { "or": { "serviceTypesId": 'no_empty' } }
                },
            }
        }
    },
    {
        origin: 'serviceSubcategoriesId',
        options: {
            relatedParams: ['serviceCategoryId', 'uuid'],
            rules: {
                visible: {
                    rule: { "and": { "serviceCategoryId": 'no_empty', "serviceTypesId": 'no_empty' } }
                },
            },
        }
    },
    {
        origin: 'reconcilingId',
        options: {
            valueSearchName: 'lastName_like_ci',
            referenceCatalogPattern: ['lastName', 'firstName', 'middleName'],
        }
    },
    {
        origin: 'complexServices',
        options: {
            detailMode: 'select',
            detailCfg: {
                field: 'complexServiceId',
                origin: 'complex_service',
                referenceCatalogPattern: 'name',
            }
        }
    },
    {
        origin: 'addSubdivisionsProdApproval',
        options: {
            detailMode: 'select',
            detailCfg: {
                field: 'subdivisionId',
                defaultFilter: {'listedByDefault': false},
                origin: 'subdivisions_for_service_opinions',
                referenceCatalogPattern: ['staffStructId.caption', 'userRoleId.role'],
                displayedFieldSeparator: " - "
            }
        }
    },
    {
        origin: "retsProductDocument",
        options: {
            customDetail: 'retsProductDocument',
            structure: [
                {
                    type: 'container', greed: 24, className: '', items: [
                        {
                            type: 'group', greed: 24, className: '', items: [
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'docOrigin', type: 'item', greed: 24 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'source', type: 'item', greed: 8 },
                                        { origin: 'submittingMethod', type: 'item', greed: 8 },
                                        { origin: 'required', type: 'item', greed: 8 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'templateDocName', type: 'item', greed: 16 },
                                    ]
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    },

    {
        type: 'group', name: 'compensation', label: 'Тарифная сетка расчета компенсаций', items: [
            {
                type: 'container', name: 'compensation__line-1', greed: 24, items: [
                    { origin: 'tariffGrid', type: 'item', greed: 24 },
                ]
            },
        ],
    },
    {
        origin: "tariffGrid",
        options: {
            structure: [
                {
                    type: 'container', greed: 24, className: 'tariffGrid', items: [
                        {
                            type: 'group', greed: 24, className: 'tariffGrid', items: [
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'compensationId', type: 'item', greed: 24 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-2', greed: 24, items: [
                                        { origin: 'startDate', type: 'item', greed: 12 },
                                        { origin: 'endDate', type: 'item', greed: 12 },
                                    ]
                                },
                            ]
                        },
                        {
                            type: 'group', greed: 24, className: 'compensation', label: 'Параметры компенсации', items: [
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'inContractCurrency', type: 'item', greed: 8 },
                                        { origin: 'compensationValueTypeId', type: 'item', greed: 16 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'currencyId', type: 'item', greed: 8 },
                                        { origin: 'compensationType', type: 'item', greed: 8 },
                                        { origin: 'value', type: 'item', greed: 8 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'setLowerLimit', type: 'item', greed: 8 },
                                        { origin: 'lowerLimitType', type: 'item', greed: 8 },
                                        { origin: 'lowerLimitValue', type: 'item', greed: 8 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'setUpperLimit', type: 'item', greed: 8 },
                                        { origin: 'upperLimitType', type: 'item', greed: 8 },
                                        { origin: 'upperLimitValue', type: 'item', greed: 8 },
                                    ]
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    },
    {
        origin: "regions",
        options: {
            structure: [
                {
                    type: 'container', greed: 24, className: 'regions', items: [
                        {
                            type: 'group', greed: 24, className: 'regions', items: [
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'countryId', type: 'item', greed: 8 },
                                        { origin: 'crmFedsId', type: 'item', greed: 8 },
                                        { origin: 'crmRegionsId', type: 'item', greed: 8 },
                                    ]
                                },
                                {
                                    type: 'container', name: 'general__line-1', greed: 24, items: [
                                        { origin: 'maxRegionRecipientCount', type: 'item', greed: 24 },
                                    ]
                                },
                            ]
                        },
                    ]
                }
            ]
        }
    },
    {
        origin: 'description',
        options: {
            rules: {
                required: {
                    rule: { value: true }
                },
            },
        }
    }
]

export const additionalServiceFields = [
    {
        origin: 'statusDate',
        fieldType: { id: 1 },
        caption: 'Дата присвоения статуса',
        options: {
            gui_editable: false,
        },
    },
    {
        origin: 'serviceActualization',
        fieldType: {
            id: 'customField'
        },
        options: {
            render: (form, data) => {
                return <div>
                    <div className="field__item-title"><b>Сведения об актуализации</b></div><CatalogsExtractor
                    token={getToken()}
                    catalog={'service_actualization'}
                    params = {{
                        size: 1000,
                    }}
                    filter = {
                        {
                            "serviceId": isString(data) ? data : data.uuid
                        }
                    }
                    render = {
                        (value) => {
                            return value && <Table
                                bordered
                                dataSource={value.content}
                                columns={[
                                    {
                                        title: 'Дата уведомления о необходимости актуализации',
                                        dataIndex: 'notifyDate',
                                        key: 'notifyDate',
                                        render: (text) => convertDate(text)
                                    },
                                    {
                                        title: 'Ответственный сотрудник',
                                        key: 'userProfileId',
                                        dataIndex: ['userProfileId', 'uuid'],
                                        render: text => {
                                            return <CatalogsExtractor
                                                token={getToken()}
                                                catalog={'user_profile'}
                                                uuid = {text}
                                                render = {
                                                    (value) => value &&
                                                        <a
                                                            target='_blank'
                                                            href={`/catalog/user_profile/${value.uuid}`}
                                                        >
                                                            {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                                                        </a>
                                                }
                                            />
                                        }
                                    },
                                    {
                                        title: 'Результат актуализации',
                                        dataIndex: 'result',
                                        key: 'result',
                                        render: (text) => text ? text === 'service_actual' ? 'Услуга актуализирована' : 'Актуализация не требуется' : ''
                                    },
                                    {
                                        title: 'Дата окончания процесса актуализации',
                                        dataIndex: 'endDate',
                                        key: 'endDate',
                                        render: (text) => convertDate(text)
                                    },
                                ]}
                                rowKey={record => record.uuid}
                            />
                        }
                    }
                /></div>
            },
        },
    },
]
