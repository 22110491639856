import React, {Component} from 'react';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

import {StyledModal, StyledTable, StyledButton, BaseDiv} from '../Themes/Components';
import AddPriorityForm from './components/AddPriorityForm/AddPriorityForm';
import {mdm} from '../../Utils/constant';

export default class PriorityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 10,
      addModalVisible: false,
      columns: [
        {
          title: 'Подсистема',
          dataIndex: ['subsystem', 'caption'],
          key: 'subsystem',
          render: (text) => {
            if (text) {
              return text;
            } else {
              return 'По умолчанию';
            }
          },
        },
        {
          title: 'Идентификатор подсистемы',
          dataIndex: ['subsystem', 'id'],
          key: 'subsystemId',
        },
        {
          title: 'Внешняя система',
          dataIndex: ['subsystem', 'externalSystem', 'caption'],
          key: 'externalSystem',
        },
        {
          title: 'Приоритет',
          dataIndex: 'priority',
          key: 'priority',
        },
        {
          key: 'edit',
          render: (text, record) => (
            <StyledButton shape="circle" onClick={() => this.openModal(record)}>
              <EditOutlined />
            </StyledButton>
          ),
        },
        {
          key: 'delete',
          render: (text, record) => (
            <StyledButton shape="circle" onClick={() => this.props.delete(record.id)}>
              <DeleteOutlined />
            </StyledButton>
          ),
        },
      ],
    };
  }

  openModal = (record) => {
    this.setState({
      editableItem: record,
      addModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({addModalVisible: false});
  };

  createPriority = (values) => {
    const {editableItem} = this.state;
    this.props.create(values, editableItem);
  };

  render() {
    const {columns, addModalVisible, editableItem} = this.state;
    const {modalVisible, onOk, onCancel, data, totalElements, handleTableChange} = this.props;
    return (
      <StyledModal
        visible={modalVisible}
        onCancel={onCancel}
        onOk={onOk}
        width={1000}
        footer={null}
        destroyOnClose={true}
      >
        <BaseDiv className="d-flex mb-2">
          <StyledButton sName="defaultBtn" onClick={() => this.openModal()}>
            {mdm.add}
          </StyledButton>
        </BaseDiv>
        <StyledTable
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalElements,
            showSizeChanger: true,
          }}
        />
        <StyledModal
          title="Внешние источники"
          visible={addModalVisible}
          onCancel={this.closeModal}
          footer={null}
          destroyOnClose={true}
        >
          <AddPriorityForm editableItem={editableItem} onSubmit={this.createPriority} closeModal={this.closeModal} />
        </StyledModal>
      </StyledModal>
    );
  }
}
