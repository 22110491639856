import React from 'react';
import {isEmpty} from 'lodash';

import CatalogImportForm from './CatalogItemImportForm';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {errorModalCreate} from '../../components/Helpers/Modals';

import './CatalogItemImport.scss';

const CatalogItemImport = (props) => {
  const onError = (response) => {
    errorModalCreate((response && response.data.message) || 'Неизвестная ошибка');
  };

  const catalogImport = async (data) => {
    try {
      const response = await fetchFunc(
        {
          url: `/api/v1/catalogs/${data.origin}/metadata`,
          method: 'post',
          data,
        },
        onError
      );
      !isEmpty(response) && props.history.push(`/catalog-draft/${response}`);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div className="container container--white mt-5">
      <CatalogImportForm catalogImport={catalogImport} />
    </div>
  );
};

export default CatalogItemImport;
