export const checkBdNameFormat = (_, value) => {
  const Reg = new RegExp('^[a-zA-Z][a-z0-9_]*$');
  if (Reg.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject('Неверный формат');
};

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const checkOptionsFormat = (_, value) => {
  if (!value) return Promise.resolve();
  else {
    if (IsJsonString(value)) {
      return Promise.resolve();
    } else return Promise.reject('Неверный формат');
  }
};
