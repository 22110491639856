import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {isEmpty} from 'lodash';
import cn from 'classnames';
import dayjs from 'dayjs';
import {Button, Modal, Popover, Spin} from 'antd';

import {deduplication} from '../helpers/constants';
import {deduplicationJobsFilter} from '../helpers/deduplicationFilterUtils';
import DeduplicationProtocolForm from '../DeduplicationProtocolForm/DeduplicationProtocolForm';
import DeduplicationFilters from '../DeduplicationFilters/DeduplicationFilters';
import {BaseDiv, StyledTable} from '../../../components/Themes/Components';
import {ReactComponent as StartDeduplication} from '../../../assets/images/startDeduplication.svg';
import {ReactComponent as DeleteDeduplication} from '../../../assets/images/delete.svg';
import {ReactComponent as ExclamationPoint} from '../../../assets/images/coolicon.svg';
import {
  authorUserNameFilter,
  catalogFilter,
  jobTitleFilter,
  renderStatusDeduplicationColor,
  statusFilter,
} from '../helpers/DeduplicationUtils';
import {
  addStartDeduplication,
  getDeduplicationJobProtocol,
  getDeduplicationJobProtocolInfoDelete,
  getDeduplicationProtocolInfo,
} from '../../../redux/thunk/deduplicationThunk';
import {addRulesDeduplication, setSystemInfoProtocolAC} from '../../../redux/actions/actions';

import './DeduplicationJob.scss';

/** Протоколы задания дедупликации */
const DeduplicationJob = () => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Состояние отображения модального окна */
  const [deduplicationModalVisible, setDeduplicationModalVisible] = useState(false);
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({
    status: null,
    author: null,
    catalog: null,
    launchDate: null,
    name: null,
    statusConnection: null,
    userConfirmConnect: null,
  });
  /** Используем хранилище */
  const protocolsJobDeduplication = useSelector((state) => state?.deduplication.deduplicationJobProtocol);
  const loading = useSelector((state) => state?.deduplication.loading);
  const [formStatus, setFormStatus] = useState('');
  const [record, setRecord] = useState({});
  const [recordUuid, setRecordUuid] = useState(null);

  /** Получение данных о заданиях дедупликации */
  useEffect(() => {
    if (!protocolsJobDeduplication) {
      dispatch(getDeduplicationJobProtocol());
    }
  }, [protocolsJobDeduplication, recordUuid]);

  /** Функция для отображения отфильтрованной таблицы */
  const filteringDeduplicationJobList = deduplicationJobsFilter(protocolsJobDeduplication, filter);
  /** Отрисовка подсказки для алгоритмов */
  const renderPopoverContent = (
    <div>
      {deduplication.status.map((content, key) => (
        <p key={key}>{content}</p>
      ))}
    </div>
  );
  /** Функция перехода к форме просмотра/редактирования задания дедупликации */
  const deduplicationJobInfoHandler = (record) => {
    return {
      onDoubleClick: () => {
        setRecordUuid(record?.uuid);
        setRecord(record);
        setFormStatus('editStatus');
        dispatch(addRulesDeduplication(record?.rulesDeduplication));
        setDeduplicationModalVisible(true);
      },
    };
  };
  /** Колонки для таблицы заданий дедупликации */
  const columnsDeduplicationTableList = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (text) => <div className="deduplicationJob__open-job-id-btn">{text}</div>,
    },
    {
      title: 'Автор',
      dataIndex: 'username',
      key: 'username',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Дата запуска',
      dataIndex: 'launchDate',
      key: 'launchDate',
      width: 150,
      render: (text) => <p>{!isEmpty(text) && dayjs(text).format('DD.MM.YYYY HH:mm')}</p>,
    },
    {
      title: (
        <Popover content={renderPopoverContent} className="deduplicationJob__popover ">
          <div className="deduplicationJob__popover--exclamation-point">{deduplication.statusInTable}</div>
          <ExclamationPoint />
        </Popover>
      ),
      dataIndex: ['status', 'title'],
      key: 'title',
      render: (text, record) => <p className={renderStatusDeduplicationColor(record?.status?.key)}>{text}</p>,
    },
    {
      title: 'Справочник',
      dataIndex: 'catalogDeduplicationName',
      key: 'catalogDeduplicationName',
      width: 260,
      render: (text) => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <Button
          className="deduplicationJob__button-box--text"
          type={record?.status?.key === 5 ? 'link' : 'text'}
          onClick={
            record?.status?.key === 5
              ? () => {
                  dispatch(setSystemInfoProtocolAC(record.protocolDeduplication));
                  dispatch(getDeduplicationProtocolInfo(record.protocolDeduplication.uuid));
                  history.push(`/deduplication/protocols/${record.protocolDeduplication.uuid}`);
                }
              : null
          }
        >
          {record?.status?.key === 5 ? deduplication.openProtocol : null}
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <StartDeduplication
          className={cn('deduplicationJob__button-box--text', {
            ' deduplicationJob__button-disabled': record?.status?.key === 2 || record?.status?.key === 3,
          })}
          onClick={
            record?.status?.key === 1 || record?.status?.key === 4 || record?.status?.key === 5
              ? () => {
                  dispatch(addStartDeduplication(record.uuid));
                }
              : null
          }
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <DeleteDeduplication
          className={cn('deduplicationJob__button-box--text', {
            ' deduplicationJob__button-disabled':
              record?.status?.key === 2 || record?.status?.key === 4 || record?.status?.key === 5,
          })}
          onClick={
            record?.status?.key === 1 || record?.status?.key === 3
              ? () => dispatch(getDeduplicationJobProtocolInfoDelete(record?.uuid))
              : null
          }
        />
      ),
    },
  ];

  return (
    <div className="deduplicationJob">
      <BaseDiv className="deduplicationJob__title ">{deduplication.title}</BaseDiv>
      <Button
        id="job"
        className="deduplicationJob__button "
        type="primary"
        onClick={() => {
          setDeduplicationModalVisible(true);
          setFormStatus('createNewDeduplication');
        }}
      >
        {deduplication.buttonText}
      </Button>
      {loading === true ? (
        <>
          <DeduplicationFilters
            deduplicationJobFilter={true}
            jobTitleFilter={jobTitleFilter(protocolsJobDeduplication)}
            authorUserName={authorUserNameFilter(protocolsJobDeduplication)}
            catalogTitle={catalogFilter(protocolsJobDeduplication)}
            statusDeduplication={statusFilter(protocolsJobDeduplication)}
            protocolsJobDeduplication={protocolsJobDeduplication}
            setFilter={setFilter}
          />
          <StyledTable
            columns={columnsDeduplicationTableList}
            dataSource={protocolsJobDeduplication ? filteringDeduplicationJobList : null}
            rowKey={(record) => record?.key}
            onRow={deduplicationJobInfoHandler}
            pagination={{pageSize: 6}}
          />
        </>
      ) : (
        <Spin />
      )}

      <Modal
        visible={deduplicationModalVisible}
        footer={null}
        okText={deduplication.save}
        cancelText={deduplication.cancel}
        className="deduplicationJob__modal"
        loading={true}
        closable={false}
      >
        <DeduplicationProtocolForm
          formStatus={formStatus}
          protocolsJobDeduplicationInfo={formStatus === 'editStatus' ? record : null}
          recordUuid={recordUuid}
          setDeduplicationModalVisible={setDeduplicationModalVisible}
        />
      </Modal>
    </div>
  );
};
export default React.memo(DeduplicationJob);
