import React, { useState, useEffect } from 'react'
import { Table, Modal } from 'antd'
import { get } from 'lodash';
import { CatalogsExtractor } from "mdm-lib";
import { CheckOutlined } from "@ant-design/icons";
import security from "../../../../Utils/security";
import { convertDate, optionsExtractor } from "../../../../components/Helpers/Utils";
import { fileDownloadBtn } from "../../../../components/Helpers/FileDownloader";
import { getCatalogFields } from "../../../../components/Helpers/Extractors";
const { getToken } = security;


const ProductVote = ({ serviceId }) => {

    const columns = [
        {
            title: 'Дата начала голосования',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text) => text && convertDate(text)
        },
        {
            title: 'Дата окончания голосования',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text) => text && convertDate(text)
        },
        {
            title: 'Результат голосования',
            dataIndex: 'result',
            key: 'result',
            render: text => text ? optionsExtractor(text, fields, 'result') : ''
        },
        {
            title: 'Причина голосования',
            dataIndex: 'votingReason',
            key: 'votingReason',
            render: text => optionsExtractor(text, fields, 'votingReason')
        },
        {
            title: 'Способ голосования',
            dataIndex: 'votingMethod',
            key: 'votingMethod',
            render: text => optionsExtractor(text, fields, 'votingMethod')
        },
    ];

    const faceToFaceColumns = [
        {
            title: 'Член комитета',
            key: 'userId',
            dataIndex: ['userId', 'uuid'],
            render: text => {
                return <CatalogsExtractor
                    token={getToken()}
                    catalog={'user_profile'}
                    uuid = {text}
                    render = {
                        (value) => value &&
                            <a
                                target='_blank'
                                href={`/catalog/user_profile/${value.uuid}`}
                            >
                                {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                            </a>
                    }
                />
            }
        },
        {
            title: 'Голос',
            dataIndex: 'vote',
            render: text => {
                return <CatalogsExtractor
                    token={getToken()}
                    customUrl = {'/api/v1/catalogs/product_committee_vote/detail/separateVote/fields'}
                    pathToField = 'content'
                    render = {(value) => value && optionsExtractor(text, value, 'vote')}
                />
            }
        },
        {
            title: 'Наложено вето',
            dataIndex: 'veto',
            render: (text) => <span> {text && <CheckOutlined />}</span>
        },
    ];

    const separateVoteColumns = [
        {
            title: 'Член комитета',
            key: 'userId',
            dataIndex: ['userId', 'uuid'],
            render: text => {
                return <CatalogsExtractor
                    token={getToken()}
                    catalog={'user_profile'}
                    uuid = {text}
                    render = {
                        (value) => value &&
                            <a
                                target='_blank'
                                href={`/catalog/user_profile/${value.uuid}`}
                            >
                                {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                            </a>
                    }
                />
            }
        },
        {
            title: 'Голос',
            dataIndex: 'vote',
            render: text => {
                return <CatalogsExtractor
                    token={getToken()}
                    customUrl = {'/api/v1/catalogs/product_committee_vote/detail/separateVote/fields'}
                    pathToField = 'content'
                    render = {(value) => value && optionsExtractor(text, value, 'vote')}
                />
            }
        },
        {
            title: 'Наложено вето',
            dataIndex: 'veto',
            render: (text) => <span> {text && <CheckOutlined />}</span>
        },
        {
            title: 'Дата',
            dataIndex: 'voteDate',
            key: 'voteDate',
            render: (text) => convertDate(text)
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment'
        },
        {
            title: 'Файл с комментариями',
            dataIndex: 'file',
            render: text => text ? fileDownloadBtn({catalogIdentifier: 'product_committee_vote', uuid: modalData?.uuid, fileUuid: text}) : ''
        },
    ];

    const [visible, setVisible] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            setFields(await getCatalogFields('product_committee_vote'));
        }
        fetch();
    }, []);

    const handleTableChange = ({ current, pageSize }) => {
        setPage(current - 1);
        setSize(pageSize);
    };

    return (
        <>
        <CatalogsExtractor
            token={getToken()}
            catalog={'product_committee_vote'}
            params = {{
                size,
                page,
                showDetails: 1
            }}
            filter = {
                {
                    "serviceId": serviceId
                }
            }
            render = {
                (value) => {
                    const data = get(value, 'content', null);
                    return data &&
                    <Table
                        bordered
                        dataSource={data}
                        columns={columns}
                        rowKey={record => record.uuid}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: () => {
                                    setModalData(record)
                                    setVisible(true)
                                }, // double click row
                            };
                        }}
                        pagination={{
                            showSizeChanger: false,
                            current: page + 1
                        }}
                        onChange={handleTableChange}
                    />}
            }
        />
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                width={1200}
            >
                {modalData && <div className='p-3'
                >
                    <div className='d-flex mb-3'>
                        <div className='mr-2 font-weight-bold'>Дата начала голосования:</div>
                        <div>{modalData.startDate && convertDate(modalData.startDate)}</div>
                    </div>
                    <div className='d-flex mb-3'>
                        <div className='mr-2 font-weight-bold'>Дата окончания голосования:</div>
                        <div>{modalData.endDate && convertDate(modalData.endDate)}</div>
                    </div>
                    <div className='d-flex mb-3'>
                        <div className='mr-2 font-weight-bold'>Результат голосования:</div>
                        <div>
                            {modalData.result && optionsExtractor(modalData.result, fields, 'result')}
                        </div>
                    </div>
                    <div className='d-flex mb-3'>
                        <div className='mr-2 font-weight-bold'>Причина голосования:</div>
                        <div>
                            {modalData.votingReason && optionsExtractor(modalData.votingReason, fields, 'votingReason')}
                        </div>
                    </div>
                    <div className='d-flex mb-3'>
                        <div className='mr-2 font-weight-bold'>Способ голосования:</div>
                        <div>
                            {modalData.votingMethod && optionsExtractor(modalData.votingMethod, fields, 'votingMethod')}
                        </div>
                    </div>
                    {
                        modalData.votingMethod === 'faceToFace' && <div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Секретарь:</div>
                                <div>
                                    <CatalogsExtractor
                                        token={getToken()}
                                        catalog={'user_profile'}
                                        uuid = {modalData.secretaryId && modalData.secretaryId.uuid}
                                        render = {
                                            (value) => value &&
                                                <a
                                                    target='_blank'
                                                    href={`/catalog/user_profile/${value.uuid}`}
                                                >
                                                    {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                                                </a>
                                        }
                                    />
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Номер протокола:</div>
                                <div>{modalData.protocolNumber}</div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Дата протокола:</div>
                                <div>{modalData.protocolDate && convertDate(modalData.protocolDate)}</div>
                            </div>
                            <div className='d-flex mb-3 align-items-center'>
                                <div className='font-weight-bold'>Файл с комментариями:</div>
                                {modalData.file && fileDownloadBtn({catalogIdentifier: 'product_committee_vote', uuid: modalData?.uuid, fileUuid: modalData.file})}
                            </div>
                            <div className='mb-3'>
                                <div className='mb-2 font-weight-bold'>Голоса очного голосования:</div>
                                <div>
                                    <Table
                                            bordered
                                            dataSource={modalData.faceToFaceVote}
                                            columns={faceToFaceColumns}
                                            rowKey={record => record.uuid}
                                            onChange={handleTableChange}
                                            pagination={false}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='mb-3'>
                        <div className='mb-2 font-weight-bold'>Голоса заочного голосования:</div>
                        <div>
                            <Table
                                bordered
                                dataSource={modalData.separateVote}
                                columns={separateVoteColumns}
                                rowKey={record => record.uuid}
                                onChange={handleTableChange}
                                pagination={false}
                            />
                        </div>
                    </div>
                    {
                        modalData.protocolUoNumber && <div>
                            <div className='mb-2 font-weight-bold'>Одобрение уполномоченным органом</div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Номер протокола:</div>
                                <div>{modalData.protocolUoNumber}</div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Дата протокола:</div>
                                <div>{modalData.protocolUoDate && convertDate(modalData.protocolUoDate)}</div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Результат одобрения:</div>
                                <div>
                                    {optionsExtractor(modalData.resultUo, fields, 'resultUo')}
                                </div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div className='mr-2 font-weight-bold'>Файл протокола:</div>
                                {modalData.file && fileDownloadBtn({catalogIdentifier: 'product_committee_vote', uuid: modalData?.uuid, fileUuid: modalData.file})}
                            </div>
                        </div>
                    }
                </div>}
            </Modal>

        </>
    )
}

export default ProductVote
