import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { fetchFunc } from '../../Utils/security/http/mdm';
import { onError } from '../Helpers/Utils';


const TableCard = ({
  config: {
    filter,
    store,
    method = 'post',
    url = '',
    showSizeChanger = false,
  },
  columns
}) => {

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(50);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(filter) {
      (async () => {
        try {
          setLoading(true)
          const { content, totalElements } = await fetchFunc({
            url: url || `/api/v1/catalogs/${store}/items/search`,
            method,
            data: filter,
            params: {
              size,
              page,
            }
          }, onError);
          setTotal(totalElements)
          setData(content)
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [filter, method, page, size, store, url])

  const handleTableChange = useCallback(({ current, pageSize }) => {
    setPage(current - 1);
    setSize(pageSize);
  }, []);

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        pagination={{
          total,
          showSizeChanger,
          current: page + 1,
        }}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        bordered
      />
    </>
  );
};

export default TableCard;
