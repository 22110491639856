import React from 'react'
import { Form, Input, Button } from 'antd'

const buttonLayout = {
    wrapperCol: { span: 7, offset: 17 }
};

const validateMessages = {
    required: 'Поле обязательно для заполнения!',
};

const ExternalSystenForm = ({ onSubmit, editibleItem, onCancel }) => {

    const initialValues = {
        caption: editibleItem && editibleItem.caption,
        code: editibleItem && editibleItem.code,
    };

    return (
        <Form
            onFinish={(values) => onSubmit(values, editibleItem)}
            initialValues={initialValues}
            validateMessages={validateMessages}
        >
            <Form.Item label='Наименование' name='caption' rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label='Код' name='code' rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item {...buttonLayout}>
                <Button type='primary' className='mr-1' htmlType='submit'>{editibleItem ? 'Обновить' : 'Создать'}</Button>
                <Button onClick={onCancel}>Отмена</Button>
            </Form.Item>
        </Form>
    )
}

export default ExternalSystenForm
