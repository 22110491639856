import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { useDispatch } from "react-redux";

export const ControlledTable = ({
                                    config,
                                    getDataAction,
                                }) => {

    const { Search } = Input;
    const dispatch = useDispatch();

    const [tableControl, setTableControl] = useState({
        page: 0,
        size: 10,
        sorter: null,
        search: null
    })

    const handleTableChange = ({ current }, filters, sorter) => {
        const { order, field } = sorter;
        let sorterString = '';
        if (order) sorterString = `${field},${order === 'descend' ? 'desc' : 'asc'}`
        setTableControl({
            ...tableControl,
            page: current - 1,
            sorter: sorterString
        })
    }

    useEffect(() => {
        dispatch(getDataAction(tableControl))
    }, [tableControl]);

    return (
        <Table
            {...config}
            title={() => <div className='d-flex'>
                {config.contextSearch && <Search
                    placeholder={config.contextPlaceholder}
                    onSearch={(value => setTableControl({
                        ...tableControl,
                        search: value
                    }))}
                />}
                {config.titleActions && config.titleActions()}
            </div>}
            pagination={{
                showSizeChanger: false,
                total: config.totalElements,
                current: tableControl.page + 1
            }}
            onChange={handleTableChange}
        />
    )

}