export const scheduler = {
  validateMessages: {
    required: 'Поле обязательно для заполнения!',
  },
  methodsTask: [
    {value: 'GET', key: 'GET'},
    {value: 'POST', key: 'POST'},
    {value: 'PUT', key: 'PUT'},
    {value: 'DELETE', key: 'DELETE'},
    {value: 'PATCH', key: 'PATCH'},
  ],
  typesTask: [
    {key: 'http', value: 'http'},
    {key: 'java', value: 'java'},
    {key: 'amq', value: 'java'},
  ],
  types: [
    {key: 'once', value: 'Разовое'},
    {key: 'scheduled', value: 'Периодическое'},
  ],
};
