import React from 'react';
import CatalogList from '../../../components/CatalogList';
import {Link} from 'react-router-dom';

const columns = [
  {
    title: 'Название',
    dataIndex: 'caption',
    key: 'caption',
    render: (text, record) => {
      return <Link to={`/verifier-list/catalogs/${record.uuid}`}>{text || 'Не задано'}</Link>;
    },
  },
  {
    title: 'Код',
    dataIndex: 'origin',
    key: 'origin',
  },
  {
    title: 'Записи на верификации',
    dataIndex: 'countOnVerificationItems',
    key: 'countOnVerificationItems',
    width: 20,
    align: 'center',
  },
];

const displayedColumns = ['caption', 'countOnVerificationItems'];

const VerifierList = () => {
  return (
    <CatalogList
      columns={columns}
      displayedColumns={displayedColumns}
      apiUrl="/api/v1/catalogs"
      additionalParams="&verified_only=true"
      sort={'caption,asc'}
      isContextSearch={true}
      card={true}
      type="verify"
    />
  );
};

export default VerifierList;
