import React, {useEffect, useState} from 'react';
import {Form, Select} from 'antd';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {
  BaseDiv,
  StyledButton,
  StyledCheckbox,
  StyledForm,
  StyledFormItem,
  StyledRadio,
  StyledRadioGroup,
  StyledSelect,
  StyledSelectOption,
} from '../Themes/Components';
import {layout, tailLayout} from '../Helpers/constants';
import {accessArr, accessRule} from './helpers/constants';
import DynamicSelect from '../../components/FormElements/DynamicSelect';
import {getUsers, getUsersRoles} from '../../redux/thunk/catalogThunk';

const AccessRuleForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {store, editableUserRules} = props;
  const [accessFor, setAccessFor] = useState(editableUserRules && editableUserRules.roleName ? 'role' : 'user');
  const [noAccess, setNoAccess] = useState(false);
  const [userSelectedId, setUserSelectedId] = useState('');
  /** Используем хранилище */
  const editableUser = useSelector((state) => state?.user.userList);
  const usersRoles = useSelector((state) => state?.detail.usersRoles);
  /** Фильтруем роли  */
  const filterRoles = usersRoles
    ?.map((item, index) => {
      return {value: item.role, key: index};
    })
    .filter(
      (item) =>
        item.value !== 'catalog_admin' &&
        item.value !== 'catalog_create' &&
        item.value !== 'ROLE_ROLE_SYSTEM' &&
        item.value !== 'ROLE_SYSTEM' &&
        item.value !== 'mdm_admin' &&
        item
    );
  console.log(filterRoles);
  /** Получаем userName пользователя */
  const user =
    !isEmpty(editableUserRules) &&
    !isEmpty(editableUser) &&
    editableUser?.find((item) => item.uuid === editableUserRules.userId && item)?.username;
  let initialValues = {};
  if (editableUserRules) {
    const {access, actions, roleName, userId} = editableUserRules;

    const baseValues = {
      accessFor: userId ? 'user' : 'role',
      access: access === 4 ? 2 : access,
      roleName: !isEmpty(roleName) ? roleName : form.getFieldValue('roleName'),
      userId: !isEmpty(userId) ? user : form.getFieldValue('userId'),
    };

    initialValues = baseValues;

    if (store === 'dictionary') {
      initialValues = {
        ...baseValues,
        all_fields: actions.all_fields,
        all_items: actions.all_items,
        attributes: actions.attributes,
        files: actions.files,
        properties: actions.properties,
        verifier: actions.verifier,
      };
    }
  } else {
    initialValues = {
      accessFor: accessFor,
      access: 2,
      all_items: true,
      all_fields: true,
      files: true,
      attributes: false,
      properties: false,
    };
  }
  /** Получаем список ролей */
  useEffect(() => {
    dispatch(getUsersRoles({}));
  }, []);
  /** Получаем список пользователей */
  useEffect(() => {
    dispatch(getUsers({}));
  }, []);
  /** Функция сеттрер значений формы при редактировании */
  useEffect(() => {
    form.setFieldsValue(initialValues);
    setUserSelectedId(editableUserRules?.userId || '');
  }, [form, user]);

  const onChangeAccessFor = (event) => {
    event.preventDefault();
    const {value} = event.target;
    setAccessFor(value);
    if (value === 'user') form.resetFields(['roleName']);
    else form.resetFields(['userId']);
  };
  /** Функция для изменения значения пользователя */
  const handleUserChange = (value, option) => {
    setUserSelectedId(option.uuidValue);
    form.setFieldsValue({userId: value});
  };

  const renderAccessCheckboxes = () => {
    return accessArr.map((item, index) => (
      <StyledFormItem {...tailLayout} key={index} name={item.name} valuePropName="checked">
        <StyledCheckbox disabled={noAccess}>{item.label}</StyledCheckbox>
      </StyledFormItem>
    ));
  };

  const prepareRuleValue = (values) => {
    const {access, all_fields, all_items, attributes, files, properties, verifier, roleName} = values;
    const mode = props.editableUserRules ? 'edit' : 'create';

    let newRights = {
      access: store === 'status-matrix' ? 3 : access,
      roleName,
    };

    if (!roleName) {
      newRights.userId = !isEmpty(userSelectedId) ? userSelectedId : null;
    }

    if (store === 'dictionary') {
      newRights.actions = {
        all_fields,
        all_items,
        attributes,
        files,
        properties,
        verifier,
      };
    }
    props.updateAccessRules(newRights, mode);
  };
  return (
    <StyledForm {...layout} form={form} name="basic" onFinish={prepareRuleValue}>
      <StyledFormItem label={accessRule.accessFor} name="accessFor">
        <StyledRadioGroup onChange={onChangeAccessFor}>
          <StyledRadio value="user">{accessRule.user}</StyledRadio>
          <StyledRadio value="role">{accessRule.role}</StyledRadio>
        </StyledRadioGroup>
      </StyledFormItem>
      {accessFor === 'user' ? (
        <StyledFormItem
          label={accessRule.user}
          name="userId"
          rules={[{required: true, message: accessRule.requiredMessage}]}
        >
          <DynamicSelect
            style={{width: '100%'}}
            placeholder={accessRule.select}
            filterOption={true}
            feachoptions={{
              referenceStore: 'mdm',
              url: '/api/v1/catalogs/user_profile/items/search/extended',
              method: 'post',
              catalogOrigin: 'user_profile',
              displayedField: 'username',
              valueField: 'username',
              valueSearchName: 'username',
              defaultSize: 20,
            }}
            handleSelectChange={(value, option) => handleUserChange(value, option)}
          />
        </StyledFormItem>
      ) : (
        <StyledFormItem label={accessRule.roleLabel} name="roleName">
          <Select
            options={filterRoles}
            placeholder={accessRule.select}
            showSearch
            onChange={(value) => {
              form.setFieldsValue({roleName: value});
            }}
          />
        </StyledFormItem>
      )}
      {store !== 'status-matrix' && (
        <StyledFormItem label={accessRule.accessLevel} name="access">
          <StyledSelect
            onChange={(value) => {
              if (value === 0) {
                setNoAccess(true);
                accessArr.forEach((item) => {
                  form.setFieldsValue({[item.name]: false});
                });
              } else setNoAccess(false);
            }}
          >
            <StyledSelectOption key={0} value={0}>
              {accessRule.accessIsDenied}
            </StyledSelectOption>
            {store !== 'status-matrix-action' && (
              <StyledSelectOption key={1} value={1}>
                {accessRule.view}
              </StyledSelectOption>
            )}
            <StyledSelectOption key={2} value={2}>
              {accessRule.full}
            </StyledSelectOption>
          </StyledSelect>
        </StyledFormItem>
      )}
      {store === 'dictionary' && <BaseDiv>{renderAccessCheckboxes()}</BaseDiv>}
      <StyledFormItem {...tailLayout} className="text-right">
        <StyledButton sName="primaryBtn" className="mr-3" type="primary" htmlType="submit">
          {editableUserRules ? accessRule.save : accessRule.create}
        </StyledButton>
      </StyledFormItem>
    </StyledForm>
  );
};

export default React.memo(AccessRuleForm);
