import {cloneElement} from 'react';

export const htmlParserParams = {
  trim: true,
  transform: (reactNode, {name}) => {
    if (name === 'ul') {
      return cloneElement(reactNode, {
        style: {
          'list-style-type': 'disc',
        },
      });
    }
    if (name === 'a') {
      return cloneElement(reactNode, {
        style: {
          'text-decoration': 'underline',
        },
      });
    }

    return reactNode;
  },
};
