import React, { Component } from 'react';
import './ContactsPage.scss';

class ContactsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    };

    render() {
        return (
            <div className='contacts-page p-5'>
                <h2 className='p-4'><strong>Контактная информация</strong></h2>
                <p>г. Москва<br/></p>
                <p>+7 (495) 969-20-22<br/></p>
                <p><a href="mailto:info@7tech.ru" target="_self">info@7tech.ru</a></p>
            </div>
        )
    }
}

export default ContactsPage;
