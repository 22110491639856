import React, {useState, useEffect} from 'react';
import {Alert, Button, Spin, Tooltip} from 'antd';
import {useHistory} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {includes, get, isEqual, isEmpty} from 'lodash';
import {DEFAULT_FILTER} from './helpers/constant';

import {fetchFunc} from '../../Utils/security/http/mdm';
import RecordReader from '../../components/RecordRedactor/RecordReader';
import {setVerify} from '../../redux/actions/actions';
import SystemInformation from '../../components/SystemInformation';
import TextArea from 'antd/es/input/TextArea';

import './Verification.scss';

/** Верификация записи */
const Verification = ({match: {params}}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dataFromCpe, setDataFromCpe] = useState(null);
  const [fields, setFields] = useState([]);
  const [comment, setComment] = useState('');
  const [data, setData] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [changeType, setChangeType] = useState('');
  const [record, setRecord] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [tableSource, setTableSource] = useState([]);
  const verifyInfo = useSelector((state) => state?.verify?.verifyValues);
  const catalogIdentifier = params.item;
  const uuid = params.id;

  useEffect(() => {
    getUnverifiedItem().then();
    getFields().then();
  }, []);

  const getUnverifiedItem = async () => {
    try {
      const response = await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/`,
      });
      if (response) {
        const {sysVerifyInfo} = response;
        const changeType = sysVerifyInfo && sysVerifyInfo.changeType;
        setData(response);
        setChangeType(changeType);
        setComment(sysVerifyInfo && sysVerifyInfo.remark);
        dispatch(setVerify({...sysVerifyInfo.result}));
        if (changeType === 'modify') {
          await getRecord();
          catalogIdentifier === 'org' && (await getDataFromCpe());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRecord = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/`,
      });
      setRecord(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFields = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/fields?size=1000`,
      });
      setFields(data.content);
    } catch (error) {}
  };

  const changeVerifyInfo = async () => {
    try {
      await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/verifyinfo`,
        method: 'put',
        data: {
          remark: comment,
          result: verifyInfo,
        },
      });
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const denyDraft = async () => {
    try {
      await changeVerifyInfo();
      await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/reject`,
        method: 'post',
      });
      if (catalogIdentifier === 'org') history.push(`/verifier-list/${catalogIdentifier}`);
      else history.push(`/verifier-list/catalogs/${catalogIdentifier}`);
    } catch (error) {}
  };

  const approveVerify = async () => {
    try {
      await changeVerifyInfo();
      await fetchFunc({
        url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/unverified/approve`,
        method: 'post',
      });
      if (catalogIdentifier === 'org') history.push(`/verifier-list/${catalogIdentifier}`);
      else history.push(`/verifier-list/catalogs/${catalogIdentifier}`);
    } catch (error) {}
  };

  const getDataFromCpe = async () => {
    try {
      const respData = await fetchFunc(
        {
          url: `/api/v1/org-from-cpe/${uuid}`,
          method: 'GET',
        },
        onCpeError,
        'org'
      );
      setDataFromCpe(respData);
    } catch (error) {}
  };

  const onCpeError = () => {
    setDataFromCpe(null);
  };

  let fieldsForRender = fields;

  const diffFields = get(data, 'sysVerifyInfo.result.diff_fields', null);

  if (catalogIdentifier === 'org') {
    const orgFields =
      changeType === 'modify'
        ? diffFields
        : [
            'recType',
            'nonResidentRf',
            'regCountry',
            'description',
            'ogrn',
            'inn',
            'codeOgrnAnalog',
            'innAnalog',
            'extAddresses',
            'contactPersons',
          ];

    if (diffFields) {
      if (includes(orgFields, 'director') && !includes(orgFields, 'contactPersons')) orgFields.push('contactPersons');
      if (includes(orgFields, 'legalAddress') && !includes(orgFields, 'extAddresses')) orgFields.push('extAddresses');
    }

    fieldsForRender = fields.filter((item) => includes(orgFields, item.origin));
  }

  fieldsForRender = fieldsForRender.map((item) => {
    const hasChanged = !isEqual(data?.[item.origin], record?.[item.origin]);
    return {
      ...item,
      hasChanged,
    };
  });
  if (isEmpty(data)) return <Spin size="large" className="verification-spinner-wrapper" style={{marginTop: 20}} />;
  return (
    <>
      <div className="container container--white">
        {changeType && changeType !== 'modify' && (
          <Alert
            className="verification-alert"
            showIcon
            message={
              changeType === 'add'
                ? 'Выполняется добавление указанной ниже записи справочника'
                : 'Выполняется удаление указанной ниже записи справочника'
            }
            type={changeType === 'add' ? 'info' : 'error'}
          />
        )}

        <div className="container container--white">
          <SystemInformation data={!isEmpty(data) && data} referenceOriginLocal={catalogIdentifier} />
        </div>

        <div className="d-flex overflow-auto">
          <div className={`${record ? 'col-6' : 'col-12'}`}>
            <h6 className="mb-1">Новая версия</h6>
            <RecordReader
              setTableColumns={setTableColumns}
              setTableSource={setTableSource}
              tableColumns={tableColumns}
              tableSource={tableSource}
              fieldsMeta={!isEmpty(fieldsForRender) && fieldsForRender}
              data={!isEmpty(data) && data}
              referenceOrigin={catalogIdentifier}
              isUnverified
              isVerificator={changeType !== 'delete'}
              recordsDefaultFilter={DEFAULT_FILTER}
            />
          </div>
          {record && changeType !== '' && (
            <div className="col-6">
              <h6 className="mb-2">Исходная версия</h6>
              <RecordReader
                setTableColumns={setTableColumns}
                setTableSource={setTableSource}
                tableColumns={tableColumns}
                tableSource={tableSource}
                fieldsMeta={!isEmpty(fieldsForRender) && fieldsForRender}
                data={record}
                isOriginVersion
                changeType={changeType}
                referenceOrigin={catalogIdentifier}
                recordsDefaultFilter={DEFAULT_FILTER}
              />
            </div>
          )}
          {catalogIdentifier === 'org' && data && !data.nonResidentRf && (
            <div className="col-5">
              <h6 className="mb-2">Данные ФНС</h6>
              {dataFromCpe ? (
                <RecordReader
                  fieldsMeta={!isEmpty(fieldsForRender) && fieldsForRender}
                  data={dataFromCpe}
                  referenceOrigin={catalogIdentifier}
                />
              ) : (
                <div className="text-center">Сведения ФНС отсутствуют</div>
              )}
            </div>
          )}
        </div>
        <div className="verification-comment">
          <h6 className=" mb-2">Замечания по результатам верификации</h6>
          <TextArea
            className="verification-comment-input"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="fixed-button mt-3">
          <Button
            type="default"
            onClick={() => {
              setSaveLoading(true);
              changeVerifyInfo();
              history.push(
                catalogIdentifier === 'org'
                  ? `/verifier-list/${catalogIdentifier}`
                  : `/verifier-list/catalogs/${catalogIdentifier}`
              );
            }}
            loading={saveLoading}
          >
            Сохранить
          </Button>
          {catalogIdentifier === 'org' && data && data.sysVerifyInfo && data.sysVerifyInfo.changeType !== 'add' && (
            <Button
              type="default"
              href={catalogIdentifier === 'org' ? `/organisations/${uuid}` : `/catalog/${uuid}`}
              className="ml-1"
              target={'_blank'}
            >
              Исходная запись
            </Button>
          )}
          <Button type="default" className="ml-1" onClick={denyDraft}>
            Запросить уточнение
          </Button>
          {data && data.sysVerifyInfo && data.sysVerifyInfo.status === 'verification' && (
            <Tooltip title="Внимание! Будут применены все изменения записи, в том числе помеченные как отклонённые">
              <Button type="primary" className="ml-1" onClick={approveVerify}>
                Подтвердить изменения
              </Button>
            </Tooltip>
          )}
          <Button
            type="danger"
            className="ml-1"
            onClick={() =>
              history.push(
                catalogIdentifier === 'org'
                  ? `/verifier-list/${catalogIdentifier}`
                  : `/verifier-list/catalogs/${catalogIdentifier}`
              )
            }
          >
            Закрыть
          </Button>
        </div>
      </div>
    </>
  );
};

export default React.memo(Verification);
