import {isEmpty} from 'lodash';

export const captionNotificationFilter = (notifications) => {
  const title = notifications?.map((notification) => {
    return notification.captionNotification;
  });
  const uniqueNotifications = [...new Set(title)];
  return uniqueNotifications.map((notification, index) => {
    return {key: index, value: notification};
  });
};
export const captionTemplateNotificationFilter = (notifications) => {
  const title = notifications
    ?.map((notification) => {
      return notification?.nameTemplateNotification;
    })
    .filter((notification) => !isEmpty(notification) && notification);
  const uniqueNotifications = [...new Set(title)];
  return uniqueNotifications.map((notification, index) => {
    return {key: index, value: notification};
  });
};

export const captionCatalogNameFilter = (notifications) => {
  const title = notifications
    ?.map((notification) => {
      return notification.catalogName;
    })
    .filter((notification) => !isEmpty(notification) && notification);
  const uniqueNotifications = [...new Set(title)];
  return uniqueNotifications.map((notification, index) => {
    return {key: index, value: notification};
  });
};
export const nameTemplateFilter = (templates) => {
  const title = templates?.map((template) => {
    return template.nameNotification;
  });
  const uniqueTemplates = [...new Set(title)];
  return uniqueTemplates?.map((template, index) => {
    return {key: index, value: template};
  });
};
export const methodsTemplateFilter = (templates) => {
  const title = templates
    ?.map((template) => {
      return template.methods.join(' ,');
    })
    .filter((template) => !isEmpty(template) && template);
  const uniqueTemplates = [...new Set(title)];
  return uniqueTemplates?.map((template, index) => {
    return {key: index, value: template};
  });
};
