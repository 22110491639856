export const recordTable = {
  notificationHiddenAttributes:
    'Не задано полей для отображения. Необходимо выбрать поля для отображения в выпадающем списке "Колонки" или настроить отображение в параметрах.',
  formats: [
    {value: 'CSV'},
    {value: 'JSON'},
    // , {value: 'XML'}, {value: 'XLS'}, {value: 'XLSX'}
  ],
  export: 'Экспорт в файл',
  duplicates: 'Отображать дубли:',
};
