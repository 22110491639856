import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Alert} from 'antd';

const VerifyAlert = ({name}) => {
  const initialValues = useSelector((state) => state?.verify.verifyValues);
  const [value, setValue] = useState(initialValues && initialValues[name]);

  useEffect(() => {
    setValue(initialValues && initialValues[name]);
  }, [initialValues, name]);

  return (
    <>
      {value !== undefined && value !== null && (
        <Alert message={value ? 'Проверено' : 'Отказано'} type={value ? 'success' : 'error'} showIcon />
      )}
    </>
  );
};

export default VerifyAlert;
