import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Form, Input, Select} from 'antd';

import {deduplication} from '../../../helpers/constants';
import {renderAlgorithmDeduplication} from '../../../helpers/DeduplicationUtils';
import {requiredRuleWithoutText} from '../../../../../components/EditFieldForm/helpers/constants';
import {addAlgorithmDeduplication, addDegreeProximityDeduplication} from '../../../../../redux/actions/actions';

import './FormList.scss';

/**
 * Форма выбора алгоритмов
 * @param formStatus - ключ к форме редактирования или создания
 * @param defaultDegreeProximity- значение степени близости по дефолту
 * @param attribute   - Значения правил дедупликации
 * @param protocolsJobDeduplicationInfo -данные задания на дедупликацию
 * @param comboBoxOptions  -Значения алгоритмов
 * @param status - статус протокола
 * @param key - ключ
 * @param form -значения формы
 * @param index - порядковый индекс
 */
const FormList = ({
  formStatus,
  initialValues,
  attribute,
  protocolsJobDeduplicationInfo,
  comboBoxOptions,
  status,
  key,
  index,
  form,
}) => {
  /** Отправка данных для хранилища */
  const dispatch = useDispatch();
  /**  Загружаем данные для редактирования формы  дедупликации */
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [protocolsJobDeduplicationInfo, form]);
  /** Сеттер алгоритма дедупликации */
  const handleChangeAlgorithmDeduplication = (value, comboBoxOptions, rulesDeduplication, index) => {
    dispatch(addAlgorithmDeduplication(value, comboBoxOptions, attribute.fieldOrigin, index));
  };
  /** Сеттер степени близости */
  const handleChangeDegreeProximityInput = (value, index) => {
    dispatch(addDegreeProximityDeduplication(value, attribute.fieldOrigin, index));
  };

  return (
    <div className=" form-list ">
      <div className=" form-list__block" key={key} {...form}>
        <Form.Item
          disabled={formStatus === 'editStatus' && (status === 5 || status === 2)}
          key={index}
          name={['attributeCatalog', index]}
        >
          <Input
            defaultValue={attribute?.fieldCaption}
            className="form-list__block-title"
            readOnly
            disabled={formStatus === 'editStatus' && (status === 5 || status === 2 || status === 3)}
            bordered={false}
          />
        </Form.Item>
        <Form.Item key={index} name={['algorithmDeduplication', index]} rules={requiredRuleWithoutText} {...form}>
          <Select
            id={attribute.fieldOrigin}
            placeholder={deduplication.select}
            disabled={formStatus === 'editStatus' && (status === 5 || status === 2 || status === 3)}
            className="form-list__block-select"
            value={renderAlgorithmDeduplication(attribute?.algorithmDeduplication)}
            options={comboBoxOptions}
            onChange={(value, comboBoxOptions) =>
              handleChangeAlgorithmDeduplication(value, comboBoxOptions, index, attribute.fieldOrigin)
            }
          />
        </Form.Item>
        <Form.Item key={index} name={['degreeProximity', index]} {...form}>
          <Input
            type="number"
            min="0"
            max="1"
            id={attribute.fieldOrigin}
            step={0.1}
            value={attribute.degreeProximity}
            defaultValue={0.8}
            className="form-list__block-input"
            disabled={formStatus === 'editStatus' && (status === 5 || status === 2 || status === 3)}
            onChange={(event) => handleChangeDegreeProximityInput(event.target.value, index, attribute.fieldOrigin)}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default React.memo(FormList);
