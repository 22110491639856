import React from 'react';
import { Form, DatePicker, Checkbox, Button } from 'antd';
import { backendDateFormat } from '../../components/Helpers/Utils';

const StaticDownloadParamsForm = ({ onSubmit, loading }) => {
    const [form] = Form.useForm();

    const onFinish = values => {
        if (values.dateFrom && values.dateTo) {
            values.dateFrom = values.dateFrom.format(backendDateFormat);
            values.dateTo = values.dateTo.format(backendDateFormat);
        }
        onSubmit(values);
    };

    const disabledDateTo = (current) => {
        const dateFrom = form.getFieldValue('dateFrom');
        return current && dateFrom && current < dateFrom.endOf('day');
    };

    const disabledDateFrom = (current) => {
        const dateTo = form.getFieldValue('dateTo');
        return current && dateTo && current < dateTo.endOf('day');
    };

    return (
        <Form
            name='static_download_parametrs'
            onFinish={onFinish}
            form={form}
        >
            <Form.Item name='dateFrom' label='Период выборки c'>
                <DatePicker
                    format='DD.MM.YYYY'
                    disabledDate={disabledDateFrom}
                />
            </Form.Item>
            <Form.Item name='dateTo' label='Период выборки по'>
                <DatePicker
                    format='DD.MM.YYYY'
                    disabledDate={disabledDateTo}
                />
            </Form.Item>
            <Form.Item
                name='getOrgModifiedAtPeriod'
                label='Выводить общее количество карточек организаций, измененных за период'
                valuePropName='checked'
                initialValue={false}
            >
                <Checkbox />
            </Form.Item>
            <Form.Item
                name='getOrgRelatedToMdm'
                label='Выводить организации, имеющие связанную запись в МДМ'
                valuePropName='checked'
                initialValue={false}
            >
                <Checkbox />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 20 }}>
                <Button
                    type='primary'
                    className='ml-auto'
                    htmlType='submit'
                    loading={loading}
                >
                    Выгрузить
                </Button>
            </Form.Item>
        </Form>
    )
};

export default StaticDownloadParamsForm
