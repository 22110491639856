import React from 'react';
import {getFieldConfig} from '../Helpers/RuleConfigurator';
import {Popover} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {get, isEmpty, isNumber} from 'lodash';
import {useRouteMatch} from 'react-router';
import moment from 'moment';
import _ from 'lodash';

import {sortFieldsByOrder, findNameValueByKey, getFieldsMeta, convertExpoToString} from '../Helpers/Utils';
import StructureRenderer from './StructureRenderer';
import Records from '../CatalogItemWidgets/Records';
import VerificationChecker from '../Verification/VerificationChecker/VerificationChecker';
import VerifyAlert from '../Verification/VerifyAlert';
import ReferenceReader from './ReferenceReader';
import {AditionalFields, DetailsMeta} from './meta/meta';
import {errorModalCreate} from '../Helpers/Modals';

import './RecordReader.scss';

const RecordReader = (props) => {
  const {
    renderCustomTable,
    data,
    referenceOrigin,
    recordUuid,
    referenceUuid,
    structure,
    fieldsMeta,
    isVerificator,
    isUnverified,
    detailFieldUuid,
    detailUuid,
    rootValues,
    maxRefsDepth,
    versionId,
    recordsDefaultFilter,
    isOriginVersion,
    changeType,
    setTableColumns,
    setTableSource,
    tableColumns,
    tableSource,
  } = props;
  const match = useRouteMatch();
  const detailMeta = DetailsMeta;
  const getFieldValueFromData = (origin) => {
    return isNumber(data[origin]) ? data[origin] : data[origin] || null;
  };

  const getComboboxLabel = ({options}, itemValue) => {
    const option =
      options && options?.combobox_options && options.combobox_options?.find((item) => item.key === itemValue);
    return option && option?.value;
  };

  const renderItem = (field, index) => {
    const {options, fieldType, description, caption, origin, referenceCatalog} = field;
    let cnf = {visible: true};
    let optionsCnf = {};
    if (options) {
      if (options.caption) cnf.caption = options?.caption;
      if (options.customView) {
        cnf.customView = options?.customView;
      }
      let dataForRules = data;
      if (detailUuid)
        dataForRules = {
          ...data,
          ...rootValues,
        };
      if (options.rules && !isEmpty(data)) {
        if (_.isString(options.rules)) {
          try {
            optionsCnf = getFieldConfig(JSON.parse(options.rules), dataForRules);
          } catch (error) {
            errorModalCreate('Неверный формат у строки rules ' + options.rules);
          }
        } else {
          optionsCnf = getFieldConfig(options.rules, dataForRules);
        }
      }
    }
    if (referenceCatalog?.options?.special_interface) {
      cnf.viewRoute = referenceCatalog?.options?.special_interface?.view_route;
    }
    cnf = {
      ...cnf,
      ...optionsCnf,
    };
    const itemCaption = isEmpty(cnf?.caption) ? caption : cnf?.caption;
    let resultValue = '';
    const itemValue = getFieldValueFromData(origin);
    let className = fieldType.id === 'customField' ? 'field__item' : 'field__item--bordered';
    className = field.hasChanged ? `${className} field__item_changed` : className;
    switch (fieldType.id) {
      case 10:
        className = '';
        resultValue = !itemValue ? <CloseOutlined /> : <CheckOutlined />;
        break;
      case 7:
        resultValue = itemValue
          ? moment(itemValue).format(get(field, 'options.date_format') || 'YYYY-MM-DD HH:mm:ss')
          : '-';
        break;
      case 4:
        resultValue = itemValue
          ? _.has(itemValue, 'uuid')
            ? renderReference(field, itemValue, cnf.customView, cnf.viewRoute)
            : itemValue
          : '-';
        break;
      case 8:
        let dateFormat = get(field, 'options.date_format') || 'YYYY-MM-DD';
        switch (dateFormat) {
          case 'C YYYY':
            resultValue = itemValue ? `${moment(itemValue).format('YYYY')}-${moment(itemValue).quarter()}` : '-';
            break;
          case 'MM YYYY':
            resultValue = itemValue ? moment(itemValue).format('MM.YYYY') : '-';
            break;
          default:
            resultValue = itemValue ? moment(itemValue).format(dateFormat) : '-';
        }
        break;
      case 12:
        className = '';
        if (field.options && field.options.detailMode === 'select') {
          resultValue = renderSelectDetail(field, itemValue);
        } else resultValue = renderDetail(field, itemValue);
        break;
      case 14:
        resultValue = getComboboxLabel(field, itemValue);
        break;
      case 15: {
        resultValue = Array.isArray(itemValue)
          ? itemValue.map((item, index) => {
              return (
                <div key={index}>
                  {(item && item.value) || findNameValueByKey(field.options.combobox_options, item)}
                </div>
              );
            })
          : itemValue &&
            JSON.parse(itemValue)
              .map((key) => findNameValueByKey(field.options.combobox_options, key))
              .join(', ');
        break;
      }
      case 117:
        resultValue = renderCompositeField(field);
        break;
      case 112:
        resultValue = renderNonDynamicDetail(field, itemValue);
        break;
      case 19:
        resultValue = itemValue ? (typeof itemValue !== 'string' ? JSON.stringify(itemValue) : itemValue) : '';
        break;
      case 20:
        resultValue = itemValue ? (_.isObject(itemValue) ? itemValue?.caption || itemValue.value : itemValue) : '';
        break;
      case 21:
        resultValue = itemValue ? convertExpoToString(itemValue) : '-';
        break;
      case 'customField':
        if (options && !options.onlyForEdit) {
          resultValue = options.render && options.render(null, data);
        }
        break;
      case 'docTypeDetail':
        return (resultValue = field.options.render({record: data, itemCaption}));
      default: {
        resultValue = isNumber(itemValue) ? itemValue : itemValue || '-';
      }
    }
    return (
      <div key={index} className={`field ${isVerificator ? 'col' : isUnverified ? 'col-10' : ''}`}>
        {cnf?.visible ? (
          description ? (
            <div className="mb-2 mt-2">
              <Popover content={description} placement="topLeft">
                <div className="field__item-title">{caption}</div>
              </Popover>
              <div className={className}>{resultValue || '-'}</div>
            </div>
          ) : (
            <div>
              <div className="field__item-title">{itemCaption}</div>
              <div className={className}>{resultValue}</div>
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderFields = (meta) => {
    return (
      <div className="org-list__no-structure-items container--white">
        {sortFieldsByOrder(meta).map((field, index) => {
          if (field?.options && field.options.onlyForEdit) {
            return null;
          } else {
            return (
              <div
                key={field.origin}
                className={`documents__info-item col-12 mb-4 ${isVerificator || isUnverified ? 'row' : ''}`}
              >
                {isVerificator && isUnverified && <VerificationChecker name={!isEmpty(field) && field?.origin} />}
                {renderItem(field, index)}
                {isUnverified && !isVerificator && (
                  <div className="col-2 mb-0 d-flex justify-content-center align-items-end">
                    <VerifyAlert name={!isEmpty(field) && field.origin} />
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderDetail = (item, itemValue) => {
    return (
      <Records
        setTableColumns={setTableColumns && setTableColumns}
        tableColumns={tableColumns && tableColumns}
        setTableSource={setTableSource && setTableSource}
        tableSource={tableSource && tableSource}
        detail={item.uuid || item.origin}
        referenceOrigin={referenceOrigin}
        referenceUuid={referenceUuid}
        recordUuid={detailFieldUuid || recordUuid}
        additionalColumns={item.options && item.options.additionalColumns}
        additionalMeta={_.has(detailMeta, item.origin) && detailMeta[item.origin]}
        additionalFields={_.has(AditionalFields, item.origin) && AditionalFields[item.origin]}
        isUnverified={isUnverified}
        isOriginVersion={isOriginVersion}
        changeType={changeType}
        isVerificator={isVerificator}
        versionId={versionId}
        match={match}
        detailData={itemValue}
        rootValues={rootValues || data}
        maxRefsDepth={maxRefsDepth}
        renderCustomTable={item?.options?.customDetail}
        structure={item?.options?.structure}
        defaultFilter={recordsDefaultFilter}
      />
    );
  };

  const renderNonDynamicDetail = (item, value) => {
    return (
      <Records
        detail={item.uuid || item.origin}
        versionId={versionId}
        defaultData={value || []}
        referenceOrigin={referenceOrigin}
        additionalColumns={item.options && item.options.additionalColumns}
        match={match}
        rootValues={rootValues || data}
        maxRefsDepth={maxRefsDepth}
        defaultFilter={recordsDefaultFilter}
      />
    );
  };

  const renderReference = (item, value, customView, viewRoute) => {
    const {referenceCatalog, referenceCatalogPattern, displayedFieldSeparator, options} = item;
    if (_.has(value, 'uuid'))
      return (
        <ReferenceReader
          catalog={referenceCatalog.origin}
          uuid={value.uuid}
          referenceCatalogPattern={(options && options.referenceCatalogPattern) || referenceCatalogPattern}
          referenceScript={options && options.reference_value_script}
          displayedFieldSeparator={displayedFieldSeparator}
          customView={customView}
          viewRoute={viewRoute}
        />
      );
  };

  const renderSelectDetail = (item, value) => {
    const {
      options: {
        detailCfg: {referenceCatalogPattern, field, origin, displayedFieldSeparator = ' '},
      },
    } = item;
    return (
      value &&
      value.map((item) => (
        <ReferenceReader
          catalog={origin}
          uuid={item[field].uuid}
          referenceCatalogPattern={referenceCatalogPattern}
          detailMode={true}
          displayedFieldSeparator={displayedFieldSeparator}
        />
      ))
    );
  };

  const renderCompositeField = (item) => {
    const {options} = item;
    const {paramVal, paramName, storeName} = options.extractor;
    if (_.has(data, storeName)) {
      const dataField = data[storeName].find((item) => item[paramName] === paramVal);
      return dataField ? options.convertForDisplay(dataField) : '-';
    } else return '-';
  };

  return (
    <div>
      {structure ? (
        <StructureRenderer
          structure={structure}
          meta={getFieldsMeta({
            columns: fieldsMeta,
            rule: renderCustomTable,
            additionalRule: 'reader',
          })}
          renderItem={renderItem}
          isUnverified={isUnverified}
        />
      ) : (
        fieldsMeta &&
        renderFields(
          getFieldsMeta({
            columns: fieldsMeta,
            rule: renderCustomTable,
            additionalRule: 'reader',
          })
        )
      )}
    </div>
  );
};

export default React.memo(RecordReader);
