import React from 'react'
import { saveAs } from 'file-saver';
import { fetchFunc } from "../../Utils/security/http/mdm";
import { Button } from "antd";

const downloadFile = async({ id, fileId, camundaId }, url) => {
    return await fetchFunc({
        method: 'GET',
        url,
        responseType: 'blob'
    });
};

const fileDownloaderByUrl = async({catalogIdentifier, uuid, fileUuid})=> {
    const url = `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/file/${fileUuid}`;
    try {
        const fetchData = await fetchFunc({
            method: 'GET',
            url
        });
        const data = await downloadFile({}, `${url}/download`);
        saveAs(data, fetchData && fetchData.name);
    } catch (err) {
        console.error(err);
    }
};

export const fileDownloadBtn = ({
                                    catalogIdentifier, uuid, fileUuid
                                }) => {
    return <Button
        type='link'
        onClick={() => fileDownloaderByUrl({
            catalogIdentifier,
            uuid,
            fileUuid
        } ) }
    >
        Скачать
    </Button>

}