import React, {useState, useEffect} from 'react';
import {DatePicker, Select, Form, Button, Collapse} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {setHistorySearchValue, setFilterReset, setFilterError} from '../../../redux/actions/actions';
import {catalogsFilters} from '../helpers/constant';
import {isEmpty} from 'lodash';
import moment from 'moment/moment';

const {RangePicker} = DatePicker;

const validateMessages = {
  required: 'Поле обязательно для заполнения!',
};

/**
 * Фильтр по истории изменений
 * @param initialValues - начальные значения дат
 * @param fields - поля для поиска
 * @param formHistory - данные формы
 */
const FilterHistoryChanges = ({fields, formHistory}) => {
  const dispatch = useDispatch();
  const {Panel} = Collapse;
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const filterErrorFlag = useSelector((state) => state?.filter.filterError);
  /** Начальные значения дат */
  const initialValues = {
    range: [moment(), moment().add(7, 'days')],
  };
  useEffect(() => {
    let newFields = [];
    fields &&
      fields.forEach((item) => {
        if (item.fieldType.id !== 12) {
          newFields.push(
            <Select.Option key={item.origin} value={item.origin}>
              {item.caption}
            </Select.Option>
          );
        }
      });
    setAttributeOptions(newFields);
  }, [fields]);
  /** Очистка фильтра */
  const onReset = () => {
    formHistory.resetFields();
    dispatch(setHistorySearchValue(null));
    dispatch(setFilterReset(true));
  };
  /** Сброс фильтра при сворачивании панели */
  const onResetHistoryFilterForm = () => {
    if (filterErrorFlag === true && isEmpty(isActive)) {
      formHistory.resetFields();
      dispatch(setHistorySearchValue(null));
      dispatch(setFilterReset(false));
    }
  };
  const getFilterBody = async (changedValues, allValues) => {
    formHistory
      .validateFields()
      .then(() => {
        const {fields, range} = allValues;
        const filterBody = {
          '%history_filter': {
            attributes: fields,
            dateFrom: `${range[0].format('YYYY-MM-DD')}T00:00:00.000+0300`,
            dateTo: `${range[1].format('YYYY-MM-DD')}T23:59:59.000+0300`,
          },
        };
        dispatch(setHistorySearchValue(filterBody));
      })
      .catch((errorInfo) => {
        const {errorFields} = errorInfo;
        if (errorFields.length) dispatch(setFilterError(true));
        else {
          const {fields, range} = allValues;
          const filterBody = {
            '%history_filter': {
              attributes: fields,
              dateFrom: `${range[0].format('YYYY-MM-DD')}T00:00:00.000+0300`,
              dateTo: `${range[1].format('YYYY-MM-DD')}T23:59:59.000+0300`,
            },
          };
          dispatch(setHistorySearchValue(filterBody));
          dispatch(setFilterError(false));
        }
      });
  };

  return (
    <div onClick={onResetHistoryFilterForm}>
      <Collapse
        className="catalogsFilters__collapse"
        onChange={(key) => {
          setIsActive(key);
        }}
      >
        <Panel key="historyFilter" header={catalogsFilters.historySearch}>
          <Form
            name="history_filter"
            form={formHistory}
            onValuesChange={getFilterBody}
            validateMessages={validateMessages}
            initialValues={initialValues}
          >
            <div className="d-flex filter-item mb-2">
              <div className="col-3 d-flex flex-column align-items-start operator-block p-0 justify-content-center">
                <p className="mr-3 mb-0">Период выборки</p>
              </div>
              <div className="col-9 p-0 pl-2 d-flex flex-column">
                <Form.Item name="range" className="m-0" rules={[{required: true}]}>
                  <RangePicker style={{width: '100%'}} format="DD.MM.YYYY" />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex filter-item mb-2">
              <div className="col-3 d-flex flex-column align-items-start operator-block p-0 justify-content-center">
                <p className="mr-3 mb-0">Выберите поля</p>
              </div>
              <div className="col-9 p-0 d-flex flex-column">
                <Form.Item name="fields" rules={[{required: true}]}>
                  <Select mode="multiple" className="pl-2" style={{width: '100%'}} placeholder="Выберите поля">
                    {attributeOptions}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <Form.Item wrapperCol={{offset: 20}}>
              <div className="d-flex justify-content-end">
                <Button className="mr-1" onClick={onReset}>
                  {catalogsFilters.reset}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default React.memo(FilterHistoryChanges);
