import React, {useEffect, useState} from 'react';
import {get, isEmpty} from 'lodash';
import {Tabs, Button, Modal, Spin} from 'antd';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';

import {mdm} from '../../Utils/constant';
import Versions from '../../components/Versions';
import {fetchFunc} from '../../Utils/security/http/mdm';
import AccessRights from '../../components/AccessRights';
import Records from '../../components/CatalogItemWidgets/Records';
import Attributes from '../../components/CatalogItemWidgets/Attributes';

import './CatalogItem.scss';

/** Информация о справочнике
 * @match - Название справочника и uuid */
const CatalogItem = ({match}) => {
  /** Маршрутизатор */
  const history = useHistory();
  const location = useLocation();
  /** Данные по справочнику */
  const [data, setData] = useState({});
  /** Uuid справочника */
  const [referenceUuid, setReferenceUuid] = useState('');
  // Восстановление удаленных записей
  const [isRestored, setIsRestored] = useState(false);
  /** Состояние для дозагрузки  */
  const [loading, setLoading] = useState(false);
  const [isloadingCatalog, setLoadingCatalog] = useState(false);
  /** Наличие дубликатов  */
  const [hasDuplicates, setHasDuplicates] = useState(false);
  /** Наличие пропса иерархии */
  const [isHierarchy, setIsHierarchy] = useState(false);
  const [requestUuid, setRequestUuid] = useState('');

  useEffect(() => {
    getCatalog().then();
  }, []);

  /** Получаем информацию по справочнику */
  const getCatalog = async () => {
    setLoadingCatalog(true);
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${referenceUuid || match.params.item}`,
        method: 'get',
      });
      setData(data);
      setReferenceUuid(data?.uuid);
      setIsHierarchy(data?.hierarchy);
      setHasDuplicates(data?.hasDuplicates);
      setLoadingCatalog(false);
    } catch (error) {}
  };
  /** Создание заявки на изменения поля hidden
     Применение созданной заявки и редирект на страницу справочников
    */
  const handleClickDeleteCatalog = async () => {
    setLoading(true);
    try {
      const request = await fetchFunc({
        url: `/api/v1/catalog-draft`,
        method: 'put',
        data: {
          catalogIdentifier: {
            catalogIdentifier: data.origin,
          },
          hidden: true,
        },
      });
      if (request?.uuid) {
        await fetchFunc({
          url: `/api/v1/catalog-draft/${request.uuid}/actions`,
          method: 'post',
          data: {action: 'Применить'},
        });
        setLoading(false);
        setRequestUuid('catalog');
        isEmpty(request?.actions) ? history.push(`/catalog/${requestUuid}`) : history.push('/catalog');
      }
    } catch (error) {}
  };

  const hideVerification = data?.options?.special_interface?.hide_verification;

  const viewRoute = data?.options?.special_interface?.view_route;

  const editSigns = {
    editAttributes: data?.editAttributes,
    editCatalog: data?.editCatalog,
    editProperties: data?.editProperties,
    inputType: data?.inputType,
  };
  return (
    <div className="container container--white">
      <div className="mb-2 d-flex justify-content-between">
        <Button size="middle" style={{cursor: 'pointer'}} onClick={() => history.push('/catalog')}>
          {mdm.back}
        </Button>
        {editSigns.editProperties && (
          <Button
            type="ghost"
            size="middle"
            style={{cursor: 'pointer'}}
            loading={loading}
            onClick={() => {
              Modal.confirm({
                title: mdm.attention,
                content: <div>{mdm.textForDeleteCatalog}</div>,
                okText: mdm.confirm,
                cancelText: mdm.cancel,
                onOk: async () => {
                  await handleClickDeleteCatalog();
                },
              });
            }}
          >
            <div className="d-flex justify-content-between w-55 align-items-center">
              <div> {mdm.delete} </div>
            </div>
          </Button>
        )}
      </div>
      <Tabs>
        <Tabs.TabPane tab={mdm.record} key="1">
          {isloadingCatalog ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spin size="large" />
            </div>
          ) : (
            <Records
              isRestored={isRestored}
              referenceOrigin={data?.origin}
              hasContextSearch={data?.hasContextSearch}
              referenceCaption={data?.caption}
              isHierarchy={isHierarchy}
              hasDuplicates={hasDuplicates}
              inputType={data?.inputType}
              referenceUuid={referenceUuid}
              history={history}
              location={location}
              match={match}
              editSigns={editSigns}
              maxRefsDepth={get(data, ['options', 'max_refs_depth'])}
              viewRoute={viewRoute} //прокидываю спешел интерфейс
            />
          )}
        </Tabs.TabPane>
        {(editSigns.editAttributes || editSigns.editProperties) && (
          <Tabs.TabPane tab={mdm.attributes} key="2">
            <Attributes
              referenceUuid={referenceUuid || match.params.item}
              referenceOrigin={data?.origin}
              prioritySupport={data?.prioritySupport}
              history={history}
              location={location}
              editSigns={editSigns}
            />
          </Tabs.TabPane>
        )}
        {editSigns.editProperties && (
          <Tabs.TabPane tab={mdm.permissions} key="3">
            <AccessRights referenceOrigin={data.origin} />
          </Tabs.TabPane>
        )}
        {editSigns.editAttributes && editSigns.editProperties && (
          <Tabs.TabPane tab={mdm.deletedRecord} key="4">
            <Versions
              type="deleted"
              setIsRestored={setIsRestored}
              location={location}
              history={history}
              catalogUuid={referenceUuid || match.params.item}
            />
          </Tabs.TabPane>
        )}
        {data.validateRecords && !hideVerification && (
          <Tabs.TabPane tab={mdm.verificationRecord} key="5">
            <Records
              referenceOrigin={data?.origin}
              referenceCaption={data?.caption}
              referenceUuid={referenceUuid}
              history={history}
              location={location}
              match={match}
              isVerifier
              onDoubleClick={(record) => history.push(`/catalog/${data.origin}/${record.uuid}/verify`)}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default React.memo(CatalogItem);
