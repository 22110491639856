const app = () => `
padding-top: 50px;
  & > div {
    height: 100%;
  }

  &-title {
    color: red;
    font-size: 1.5em;
  }

  &__main {
    overflow: hidden;
    height: 100%;
    transition: all 0.5s ease-out;
    margin-left: 250px;
    padding-left: unset;

    & > div {
      overflow: auto;
      height: 100%;

      &::-webkit-scrollbar { width: 3px; height: 3px;}
      &::-webkit-scrollbar-button {  background-color: #ced4da; }
      &::-webkit-scrollbar-track {  background-color: #ced4da;}
      &::-webkit-scrollbar-track-piece { background-color: #ffffff;}
      &::-webkit-scrollbar-thumb { height: 50px; background-color: #ced4da; border-radius: 3px;}
      &::-webkit-scrollbar-corner { background-color: #999;}
      &::-webkit-resizer { background-color: #666;}
    }
  }
`;

const appContent = () => `
  padding: 24px 40px 24px 110px;
  display: flex;
  justify-content: center;
  width:100%;
      flex-direction: column;
   & > div {
     margin:0 auto;
       width: 1110px;
  }
`;

const mainContainer = () => `

`;
const settingsPage = () => `
   ${containerWhite()}
`;

const contacts = () => `
  color: #5F5F5F;
  font-size: 18px;
  & a {
    text-decoration: underline;
    color: #27AAE1;
  }
`;

export const containerWhite = () => `
    z-index: 10;
    padding: 1.5rem;
    margin-bottom: 25px;
    background: #fff;
    box-shadow: 0 0 12px 0 rgba(47,67,78,.19);
    
    & .ant-card-body,
    & .documents__info-item,
    & .ant-card-head {
        padding: 0;
    }
`;

export const loadingOverlay = (props) => `
   position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: ${props.loading ? 'visible' : 'hidden'}
  &.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    font-size: 42px;
    color: #326FB9;
  }
`;

const productNavigation = () => `
   .main-menu-wrap {
   height:95vh;
  }
  .ant-menu {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-menu::-webkit-scrollbar {
    display: none;
  }
`;

export const baseStyles = {
  app,
  appContent,
  mainContainer,
  contacts,
  containerWhite,
  settingsPage,
  productNavigation,
};
