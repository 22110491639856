import React from "react";

import { containerWhite } from './baseStyles';

const documentNavigate = (props) => `
   ${containerWhite()}
`;

const documentNavigateTabs = (props) => `
  & .ant-tabs-tab {
      margin-right: 0;
  }
`;

export const documentCard = {
    documentNavigate,
    documentNavigateTabs,
}