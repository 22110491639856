import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal, Spin, Table} from 'antd';
import {isEmpty} from 'lodash';
import {EditTwoTone} from '@ant-design/icons';

import {
  deleteNotificationTemplate,
  getNotificationTemplate,
  getNotificationTemplateList,
} from '../../../redux/thunk/notificationThunk';
import {notificationPage} from '../helpers/constant';
import {deduplication} from '../../Deduplication/helpers/constants';
import {notificationPageFilters} from '../helpers/notificationPageFilters';
import {notifications} from '../../../components/NotificationPage/helpers/constant';
import {ReactComponent as DeleteDeduplication} from '../../../assets/images/delete.svg';
import NotificationPageFilters from '../NotificationPageFilters/NotificationPageFilters';
import {methodsTemplateFilter, nameTemplateFilter} from '../helpers/notificationFiltersUtils';
import NotificationPageTemplateForm from '../NotificationPageTemplateForm/NotificationPageTemplateForm';

import './NotificationPageTemplates.scss';

/** Список уведомлений */
const NotificationPageTemplates = () => {
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({
    title: null,
    template: null,
    subscription: null,
    titleTemplate: null,
    methodTemplate: null,
  });
  /** Состояние отображения модального окна */
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  /** Ключ для отображения  формы шаблона */
  const [status, setStatus] = useState('');
  /** uuid  для таблицы */
  const [tableKey, setTableKey] = useState('');
  /** uuid  шаблона */
  const [templateUuid, setTemplateUuid] = useState('');
  /** Начальное состояние номера страницы */
  const [page, setPage] = useState(0);
  /**Количество тоборажаемых элементов на странице */
  const [size, setSize] = useState(10);
  /** Используем хранилище */
  const notificationTemplateList = useSelector((state) => state?.notification.notificationTemplateList);
  const notificationTemplate = useSelector((state) => state?.notification.notificationTemplate);
  const loading = useSelector((state) => state?.notification.loadingTemplates);
  /** Преобразования для получения initValues для формы редактирования Sms */
  const templateTypeSms = useMemo(() => {
    if (!isEmpty(notificationTemplate) && !isEmpty(templateUuid)) {
      return (
        templateUuid === notificationTemplate?.uuid &&
        notificationTemplate?.methodNotifications?.find((item) => item.transportType === 'SMS' && item)
      );
    }
  }, [notificationTemplate, templateUuid]);
  /** Преобразования для получения initValues для формы редактирования Email */
  const templateTypeEmail = useMemo(() => {
    if (!isEmpty(notificationTemplate) && !isEmpty(templateUuid)) {
      if (templateUuid === notificationTemplate?.uuid) {
        return notificationTemplate?.methodNotifications?.find((item) => item.transportType === 'EMAIL' && item);
      }
    }
  }, [notificationTemplate, templateUuid]);
  /** Преобразования для получения initValues для формы редактирования HTTP */
  const templateTypeHttp = useMemo(() => {
    if (!isEmpty(notificationTemplate) && !isEmpty(templateUuid)) {
      if (templateUuid === notificationTemplate?.uuid) {
        return notificationTemplate?.methodNotifications?.find((item) => item.transportType === 'HTTP' && item);
      }
    }
  }, [notificationTemplate, templateUuid]);

  /** Получение списка уведомлений */
  useEffect(() => {
    dispatch(getNotificationTemplateList());
  }, []);
  /**
   * Функция перехода к форме редактирования шаблона уведомления
   * @param uuid - значение uuid*/
  const notificationTemplateInfoHandler = (uuid) => {
    setTemplateUuid(uuid);
    dispatch(getNotificationTemplate(uuid));
    setNotificationModalVisible(true);
    setStatus('edit');
  };
  /** Функция для отображения отфильтрованной таблицы  */
  const filteringNotificationTemplateList = notificationPageFilters(notificationTemplateList, filter);
  /** Колонки для таблицы */
  const column = [
    {
      title: notificationPage.tableName,
      dataIndex: 'nameNotification',
      key: 'nameNotification',
      width: 230,
      render: (text) => <p>{text}</p>,
    },
    {
      title: notificationPage.methods,
      dataIndex: 'methods',
      key: 'methods',
      width: 200,
      render: (text) => <p>{text?.join(', ')}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: 'uuid',
      width: 70,
      render: (record) => <EditTwoTone onClick={() => notificationTemplateInfoHandler(record.uuid)} />,
    },
    {
      title: '',
      dataIndex: '',
      key: 'uuid',
      width: 70,
      render: (record) => (
        <DeleteDeduplication
          onClick={() => {
            setTableKey(record.uuid);
            dispatch(deleteNotificationTemplate(record.uuid));
          }}
        />
      ),
    },
  ];
  /** Функция загрузки при пагинации страницы */
  const handleTableChange = useCallback(({current, pageSize}) => {
    setPage(current - 1);
    setSize(pageSize);
  }, []);

  return (
    <div className="notificationPageTemplates">
      <div className="notificationPageTemplates__header">
        <div className="notificationPageTemplates__header--title">{notifications.template}</div>
        <Button
          type="primary"
          className="notificationPageTemplates__header--button"
          onClick={() => {
            setNotificationModalVisible(true);
            setStatus('');
          }}
        >
          {notifications.addTemplate}
        </Button>
      </div>
      {loading === true ? (
        <>
          <NotificationPageFilters
            templates={true}
            setFilter={setFilter}
            nameTemplateFilter={nameTemplateFilter(notificationTemplateList)}
            methodsTemplateFilter={methodsTemplateFilter(notificationTemplateList)}
          />
          <Table
            columns={column}
            dataSource={filteringNotificationTemplateList}
            rowKey={(record) => record.uuid}
            pagination={{size, current: page + 1}}
            onChange={handleTableChange}
            key={tableKey}
          />
        </>
      ) : (
        <Spin />
      )}
      <Modal
        visible={notificationModalVisible}
        footer={null}
        okText={deduplication.save}
        cancelText={deduplication.cancel}
        width={1000}
        closable={false}
        destroyOnClose
      >
        <NotificationPageTemplateForm
          notificationTemplate={!isEmpty(notificationTemplate) ? notificationTemplate : null}
          setNotificationModalVisible={setNotificationModalVisible}
          templateTypeSms={!isEmpty(templateTypeSms) && templateTypeSms}
          templateTypeEmail={!isEmpty(templateTypeEmail) && templateTypeEmail}
          templateTypeHttp={!isEmpty(templateTypeHttp) && templateTypeHttp}
          status={status}
          uuid={templateUuid}
        />
      </Modal>
    </div>
  );
};
export default React.memo(NotificationPageTemplates);
