import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18, offset: 6 },
};

const initialValues = {
    active: false,
};

const StatusListForm = ({ onSubmit, editibleItem, onReset }) => {
    const [form] = Form.useForm();

    return (
        <>
            <Form
                form={form}
                {...formItemLayout}
                onFinish={onSubmit}
                initialValues={editibleItem || initialValues}
            >
                <Form.Item label="Наименование" name='caption' rules={[{ required: true }]}>
                    <Input name='caption' />
                </Form.Item>
                <Form.Item label="Активный" name='active' valuePropName='checked'>
                    <Checkbox
                        name='active'
                    />
                </Form.Item>
                <Form.Item {...formTailLayout} className='text-right'>
                    <Button className='mr-3' type="primary" htmlType='submit'>
                        {editibleItem ? 'Сохранить' : 'Создать'}
                    </Button>
                    <Button type="danger" onClick={onReset}>
                        Отмена
                        </Button>
                </Form.Item>
            </Form>
        </>
    )
};

export default StatusListForm;
