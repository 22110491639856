import {
  addNotificationAC,
  addNotificationTemplateAC,
  deleteNotificationAC,
  deleteNotificationTemplateAC,
  editNotificationTemplateAC,
  getFilteredSubscriptionAC,
  getNotificationIdAC,
  getNotificationListAC,
  getNotificationListSubscriptionAC,
  getNotificationTemplateAC,
  getNotificationTemplateListAC,
  loadingNotificationListAC,
  loadingPNotificationTemplatesAC,
  sendSuccessSendMessageAC,
  setActivateNotificationAC,
  setDeactivateNotificationAC,
  testNotificationAC,
} from '../actions/actions';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {onError} from '../../components/Helpers/Utils';

/** Создание шаблона уведомления */
export const addNotificationTemplate = (notification) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/notification-template`,
        method: 'post',
        data: notification,
      },
      onError,
      'notificationUrl'
    );
    dispatch(addNotificationTemplateAC(response));
  } catch (error) {
    throw new Error(error.message);
  }
};

/** Создание  уведомления */
export const addNotification = (notification) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/settings`,
        method: 'post',
        data: notification,
      },
      onError,
      'notifyAdapter'
    );
    dispatch(addNotificationAC(response));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Редактирование шаблон уведомления */
export const editNotification = (notification, uuid) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/notification-template/${uuid}`,
        method: 'put',
        data: notification,
      },
      onError,
      'notificationUrl'
    );
    dispatch(editNotificationTemplateAC(response, uuid));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Протестировать уведомление */
export const testNotification = (notification) => async (dispatch) => {
  try {
    await fetchFunc(
      {
        url: `/api/v1/notification/test-notify`,
        method: 'post',
        data: notification,
      },
      onError,
      'notificationUrl'
    );
    dispatch(testNotificationAC());
    dispatch(sendSuccessSendMessageAC('success'));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка уведомлений */
export const getNotificationList = () => async (dispatch) => {
  let onSubmitRequest;
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/settings?size=10000&sort=createDate%2Cdesc`,
        method: 'get',
      },
      onError,
      'notifyAdapter'
    );
    if (response) {
      onSubmitRequest = true;
    }
    dispatch(getNotificationListAC(response.content));
    dispatch(loadingNotificationListAC(onSubmitRequest));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Детальная информация по уведомлению */
export const getNotificationId = (id) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/settings/${id}`,
        method: 'get',
      },
      onError,
      'notifyAdapter'
    );
    dispatch(getNotificationIdAC(response));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Активация уведомления */
export const setActivateNotification = (notifyConditionId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/settings/activate/${notifyConditionId}`,
        method: 'patch',
      },
      onError,
      'notifyAdapter'
    );
    dispatch(setActivateNotificationAC(response, notifyConditionId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Деактивация уведомления */
export const setDeactivateNotification = (notifyConditionId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/settings/deactivate/${notifyConditionId}`,
        method: 'patch',
      },
      onError,
      'notifyAdapter'
    );
    dispatch(setDeactivateNotificationAC(response, notifyConditionId));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Удаление прикрепленного уведомления */
export const deleteNotification = (uuid) => async (dispatch) => {
  try {
    await fetchFunc(
      {
        url: `/api/v1/settings/${uuid}`,
        method: 'delete',
      },
      onError,
      'notifyAdapter'
    );
    dispatch(deleteNotificationAC(uuid));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка шаблонов */
export const getNotificationTemplateList = () => async (dispatch) => {
  let onSubmitRequest;
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/notification-template?size=10000&sort=createDate%2Cdesc`,
        method: 'get',
      },
      onError,
      'notificationUrl'
    );
    if (response) {
      onSubmitRequest = true;
    }
    dispatch(getNotificationTemplateListAC(response.content));
    dispatch(loadingPNotificationTemplatesAC(onSubmitRequest));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Удаление шаблона */
export const deleteNotificationTemplate = (uuid) => async (dispatch) => {
  try {
    await fetchFunc(
      {
        url: `/api/v1/notification-template/${uuid}`,
        method: 'delete',
      },
      onError,
      'notificationUrl'
    );
    dispatch(deleteNotificationTemplateAC(uuid));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение информации по шаблону */
export const getNotificationTemplate = (uuid) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/notification-template/${uuid}`,
        method: 'get',
      },
      onError,
      'notificationUrl'
    );
    dispatch(getNotificationTemplateAC(response));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка подписок */
export const getNotificationSubscriptionList = () => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/subscriptions`,
        method: 'get',
      },
      onError,
      'mdm'
    );
    dispatch(getNotificationListSubscriptionAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение  списка доступных  подписок и событий */
export const getFilteredSubscription = (templateId) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/settings/get-available-subscriptions/${templateId}`,
        method: 'get',
      },
      onError,
      'notifyAdapter'
    );
    dispatch(getFilteredSubscriptionAC(response));
  } catch (error) {
    throw new Error(error.message);
  }
};
