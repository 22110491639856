import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ru from 'react-phone-number-input/locale/ru';
import './PhoneNumber.scss';

const PhoneNumber = ({onValueChange, incomeValue, disabled}) => {

	const onPhoneChange = (value) => {
		onValueChange(value);
	}

	return (
		<PhoneInput
			disabled={disabled}
			labels={ru}
			value={incomeValue}
			onChange={onPhoneChange}
		/>
	)
}

export default PhoneNumber
