import React, {Component} from 'react';
import {get, isEmpty} from 'lodash';
import {Button, Form, Tooltip} from 'antd';

import {StyledInput, StyledInputNumber} from '../../../Themes/Components';
import DynamicSelect from '../../../FormElements/DynamicSelect';
import {fetchFunc} from '../../../../Utils/security/http/mdm';
import {mdm} from '../../../../Utils/constant';
import {priorityForm} from '../../helpers/constant';
import {requiredRule} from '../../../EditFieldForm/helpers/constants';

import './AddPriorityForm.scss';

/** Форма добавления приоритетов систем */
export default class AddPriorityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subsystem: '',
      data: {},
      id: null,
      externalSystem: '',
    };
  }

  formRef = React.createRef();

  getSubsystem = async (uuid) => {
    try {
      return await fetchFunc({
        url: `/api/v1/subsystem/${uuid}`,
        method: 'get',
      });
    } catch (error) {}
  };

  handleSelectChange = async (value, name) => {
    const {setFieldsValue} = this.formRef.current;

    if (name === 'subsystem') {
      let id;
      let externalSystem;
      if (value) {
        let data = await this.getSubsystem(value);
        id = data.id;
        externalSystem = data.externalSystem && data.externalSystem.caption;
        this.setState({
          subsystem: name,
          data,
          id: data.id,
          externalSystem: data.externalSystem && data.externalSystem.caption,
        });
        setFieldsValue({
          id,
          externalSystem,
        });
      }
    }
    setFieldsValue({[name]: value});
  };

  onFinish = (values) => {
    this.props.onSubmit(values);
    this.props.closeModal();
  };

  onReset = () => {
    this.formRef.current.resetFields();
    this.props.closeModal();
  };

  render() {
    const {editableItem, closeModal} = this.props;
    const {externalSystem, data} = this.state;
    const initialValues = {
      subsystem: get(editableItem, 'subsystem.id') || null,
      id: get(editableItem, 'subsystem.id') || null,
      externalSystem: get(editableItem, 'subsystem.externalSystem.caption') || null,
      priority: editableItem ? editableItem.priority : 0,
    };
    return (
      <Form
        sName="styledForm"
        name="add-priority"
        onFinish={this.onFinish}
        ref={this.formRef}
        initialValues={initialValues}
        className="addPriorityForm"
      >
        <Form.Item label={priorityForm.subsystem} name="subsystem" rules={requiredRule}>
          <DynamicSelect
            name="subsystem"
            style={{width: '100%'}}
            placeholder={mdm.select}
            notfoundcontentplaceholder={mdm.notFound}
            allowClear={true}
            disabled={editableItem}
            feachoptions={{
              referenceStore: 'mdm',
              url: '/api/v1/subsystem',
              method: 'get',
              referenceCatalogue: 'subsystem',
              valueField: 'id',
              displayedField: 'caption',
              defaultSize: 20,
            }}
            handleSelectChange={(value) => this.handleSelectChange(value, 'subsystem')}
          />
        </Form.Item>

        {(!isEmpty(data) || !isEmpty(editableItem)) && (
          <Form.Item label={priorityForm.id} name="id">
            <StyledInput disabled />
          </Form.Item>
        )}
        {(!isEmpty(externalSystem) || !isEmpty(editableItem)) && (
          <Form.Item label={priorityForm.externalSystem} name="externalSystem">
            <StyledInput disabled />
          </Form.Item>
        )}
        <Tooltip title={priorityForm.tooltipPriority}>
          <Form.Item label={priorityForm.priority} name="priority" rules={requiredRule}>
            <StyledInputNumber precision={0} />
          </Form.Item>
        </Tooltip>
        <div className="addPriorityForm__button">
          <Button className="addPriorityForm__button--left" type="primary" htmlType="submit">
            {mdm.save}
          </Button>
          <Button type="danger" onClick={closeModal}>
            {mdm.cancel}
          </Button>
        </div>
      </Form>
    );
  }
}
