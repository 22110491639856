import React from 'react';
import {Collapse, Descriptions} from 'antd';
import {catalogsFilters} from '../helpers/constant';

const SystemInfo = ({referenceOrigin, referenceUuid}) => {
  const {Panel} = Collapse;
  return (
    <Collapse className="catalogsFilters__collapse">
      <Panel header="Системная информация" key="1" className="catalogsFilters__collapse--panel">
        <Descriptions>
          <Descriptions.Item label={catalogsFilters.catalogName}> {referenceOrigin}</Descriptions.Item>
          <Descriptions.Item label={catalogsFilters.uuid}>{referenceUuid}</Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
};
export default SystemInfo;
