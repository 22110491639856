export const accessArr = [
  {label: 'Доступ до всех записей', name: 'all_items'},
  {label: 'Доступ до всех полей', name: 'all_fields'},
  // {label: 'Доступ до всех файлов', name: 'files'},
  {label: 'Администрирование: атрибуты справочника', name: 'attributes'},
  {label: 'Администрирование: свойства справочника', name: 'properties'},
  {label: 'Верификатор', name: 'verifier'},
];

export const accessRule = {
  accessFor: 'Доступ для',
  user: 'Пользователя',
  role: 'Роли',
  requiredMessage: 'Поле обязательно для заполнения',
  select: 'Выбрать',
  notFound: 'Не  ',
  manual: 'Ручной ввод',
  roleLabel: 'Роль',
  userLabel: 'Пользователь',
  selectFromList: 'Выбор из списка',
  accessLevel: 'Уровень доступа',
  accessIsDenied: 'Доступ запрещен',
  view: 'Просмотр',
  full: 'Полный',
  save: 'Сохранить',
  create: 'Создать',
};
