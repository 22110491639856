import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Input, Popover, Select} from 'antd';
import {isEmpty} from 'lodash';

import {
  addNotification,
  getFilteredSubscription,
  getNotificationTemplateList,
} from '../../../redux/thunk/notificationThunk';
import {notifications} from '../../../components/NotificationPage/helpers/constant';
import {requiredRule} from '../../../components/EditFieldForm/helpers/constants';
import {ReactComponent as ExclamationPoint} from '../../../assets/images/coolicon.svg';

import './NotificationPageForm.scss';
import Icon, {InboxOutlined} from '@ant-design/icons';

/**
 * Форма заполнения уведомления
 * @param setNotificationModalVisible - состояние модального окна
 * @param status - ключ для просмотра формы уведомления
 * @param notificationId - информация по уведомлению
 * */
const NotificationPageForm = ({setNotificationModalVisible, status, notificationId}) => {
  /** Данные формы */
  const [form] = Form.useForm();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Используем хранилище*/
  const notificationTemplateList = useSelector((state) => state?.notification.notificationTemplateList);
  const notificationEventList = useSelector((state) => state?.notification.notificationEventList);
  /** Начальное состояние  способа подписки */
  const [eventSubscription, setEventSubscription] = useState([]);
  /** Uuid справочника на который совершена подписка */
  const [catalogIdentifier, setCatalogIdentifier] = useState('');
  /** Uuid шаблона уведомления */
  const [templateUuid, setTemplateUuid] = useState('');
  /** Начальное состояние справочника-подписки */
  const [subscription, setSubscription] = useState('');
  /** Начальное состояние значения  шаблона */
  const [templateCaption, setTemplateCaption] = useState('');
  /** Начальное состояние событий для выбранного шаблона и подписки */
  const [unavailableEvents, setUnavailableEvents] = useState([]);
  /** Данные для отображения информации уведомления */
  const initialValues = {
    name:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.captionNotification
        : form.getFieldValue('name'),
    activeNotification:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.active
        : form.getFieldValue('activeNotification'),
    template:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.nameTemplateNotification
        : form.getFieldValue('template'),
    subscription:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.catalogName
        : form.getFieldValue('subscription'),
    eventSubscription:
      !isEmpty(notificationId) && status === 'viewing'
        ? notificationId.subscriptions?.map((item) => item.eventCaption)
        : form.getFieldValue('eventSubscription'),
  };
  /** Получаем список шаблонов */
  useEffect(() => {
    dispatch(getNotificationTemplateList());
  }, []);
  /**  Загружаем данные для редактирования шаблона */
  useEffect(() => {
    status === 'viewing' && !isEmpty(notificationId) && form.setFieldsValue(initialValues);
  }, [form, notificationId, status]);
  /**  Инициируем данные переключателя */
  useEffect(() => {
    status === 'create' && form.setFieldsValue({activeNotification: true});
  }, [form, status]);
  useEffect(() => {
    !isEmpty(templateUuid) && dispatch(getFilteredSubscription(templateUuid));
  }, [templateUuid]);
  /** Получаем список шаблонов */
  const templates = notificationTemplateList?.map((template) => ({
    value: template.nameNotification,
    key: template.uuid,
  }));
  /** Получаем список подписок */
  const notificationSubscriptionList = notificationEventList?.flatMap((item) => {
    return {
      value: item.catalogName,
      key: item.catalogIdentifier,
    };
  });
  /** Получаем список  событий для выбранной подписки */
  const eventSubscriptionOptions = useMemo(() => {
    const eventsForSelectedSubscription =
      !isEmpty(catalogIdentifier) &&
      notificationEventList?.find((item) => item.catalogIdentifier === catalogIdentifier && item);
    const availableEvents = eventsForSelectedSubscription?.availableEvents?.map((item) => ({
      value: item?.eventCaption,
      key: item.eventCode,
      eventCode: item.eventCode,
    }));
    const unavailableEvents = eventsForSelectedSubscription?.unavailableEvents?.map((item) => ({
      value: item?.eventCaption,
      key: item.eventCode,
      eventCode: item.eventCode,
      disabled: true,
    }));
    setUnavailableEvents(unavailableEvents);
    return availableEvents?.concat(unavailableEvents);
  }, [catalogIdentifier, notificationEventList, templateUuid]);
  /**
   *Сеттер значения подписки
   * @param value - значение подписки
   * @param option - дополнительная информация
   * */
  const handleSubscriptionChange = (value, option) => {
    setSubscription(value);
    setCatalogIdentifier(option.key);
  };
  /**
   *Сеттер значения события  подписки
   * @param value - значение подписки
   * @param option - дополнительная информация
   * */
  const handleEventSubscription = (value, option) => {
    setEventSubscription(option);
  };
  /**
   * Функция сеттер названия уведомления
   * @param value - значение подписки
   * */
  const handleTitleNotificationChange = (value) => {
    form.setFieldsValue({name: value});
  };
  /**
   * Функция сеттер шаблона
   * @param value - значение подписки
   * @param option - дополнительная информация
   * */
  const handleTemplateChange = (value, option) => {
    setTemplateCaption(value);
    setTemplateUuid(option.key);
    form.setFieldsValue({subscription: null, activeNotification: true});
    setUnavailableEvents([]);
    setEventSubscription([]);
    setSubscription('');
  };
  /** Формируем объект для payloadData */
  const createCurrentNotification = () => {
    const eventSubscriptionForPayloadData = eventSubscription.map((item) => ({
      eventCaption: item.value,
      eventCode: item.eventCode,
    }));
    return {
      active: true,
      captionNotification: form.getFieldValue('name'),
      catalogIdentifier: catalogIdentifier,
      notificationTemplateUUID: templateUuid,
      subscriptions: eventSubscriptionForPayloadData,
    };
  };

  /** Очистка данных формы */
  const resetHandler = () => {
    form.resetFields();
    setNotificationModalVisible(false);
    setEventSubscription([]);
    setUnavailableEvents([]);
    setTemplateUuid('');
    setTemplateCaption(null);
    setSubscription(null);
  };
  /** Отправка данных формы */
  const submitHandler = () => {
    dispatch(addNotification(createCurrentNotification()));
    form.resetFields();
    setNotificationModalVisible(false);
    setTemplateUuid('');
    setEventSubscription([]);
    setUnavailableEvents([]);
    setTemplateCaption(null);
    setSubscription(null);
  };
  return (
    <div className="notificationForm">
      <h5>{notifications.form}</h5>
      <Form layout={'vertical'} form={form} name="notification_template" autoComplete="off" onFinish={submitHandler}>
        <div className="notificationForm__box-one">
          <Form.Item
            label={notifications.title}
            name="name"
            className="notificationForm__box-one--name"
            rules={requiredRule}
          >
            <Input
              disabled={status === 'viewing'}
              className="notificationForm__name-box"
              placeholder={notifications.addTitle}
              onChange={(event) => handleTitleNotificationChange(event.target.value)}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="template"
          label={notifications.template}
          className="notificationForm__template"
          rules={requiredRule}
        >
          <Select
            disabled={status === 'viewing'}
            placeholder={notifications.selectTemplate}
            options={templates}
            filterOption={true}
            resetOptions={true}
            showSearch
            onChange={(value, option) => handleTemplateChange(value, option)}
          />
        </Form.Item>
        <div className="notificationForm__subtitle">{notifications.noticeTerms}</div>
        <div className="notificationForm__block-groups">
          <div className="notificationForm__block-groups--subscription-box">
            <Form.Item
              label={notifications.subscription}
              className="notificationForm__block-groups--subscription-box--subscription"
              name="subscription"
              id="subscription"
              rules={requiredRule}
            >
              <Select
                disabled={(isEmpty(form.getFieldValue('template')) || status === 'viewing') && true}
                placeholder={notifications.selectSubscription}
                options={notificationSubscriptionList}
                filterOption={true}
                resetOptions={true}
                showSearch
                onChange={(value, option) => handleSubscriptionChange(value, option)}
              />
            </Form.Item>
            {status === 'viewing' && isEmpty(initialValues.subscription) && (
              <div className="notificationForm__block-groups--subscription-box--empty">
                {notifications.emptySubscription}
              </div>
            )}
          </div>

          <Form.Item
            name="eventSubscription"
            label={
              <div className="notificationForm__popover">
                <div>{notifications.eventSubscription}</div>
                {!isEmpty(unavailableEvents) && (
                  <Popover
                    content={
                      <div>
                        {notifications.popoverOne}
                        <span className="notificationForm__popover--span">{subscription}</span>{' '}
                        {notifications.popoverTwo}
                        <span className="notificationForm__popover--span">{templateCaption}</span>
                      </div>
                    }
                    key="1"
                  >
                    {' '}
                    <ExclamationPoint className="ml-2" />
                  </Popover>
                )}
                {isEmpty(eventSubscriptionOptions) && !isEmpty(subscription) && (
                  <Popover content={<div>{notifications.notFoundEvent}</div>} key="1">
                    {' '}
                    <ExclamationPoint className="ml-2" />
                  </Popover>
                )}
              </div>
            }
            className="notificationForm__eventSubscription"
            rules={requiredRule}
          >
            <Select
              mode="multiple"
              filterOption={true}
              resetOptions={true}
              options={eventSubscriptionOptions}
              placeholder={notifications.selectEventSubscription}
              onChange={(value, option) => handleEventSubscription(value, option)}
              disabled={(isEmpty(subscription) || status === 'viewing') && true}
              showSearch
            />
          </Form.Item>
        </div>

        <div className="d-flex justify-content-end notificationPageForm__button">
          <Button type="danger" className="mr-3" htmlType="button" onClick={resetHandler}>
            {status !== 'viewing' ? notifications.cancel : notifications.close}
          </Button>
          <Button type="primary" htmlType="submit" hidden={status === 'viewing'}>
            {notifications.save}
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default React.memo(NotificationPageForm);
