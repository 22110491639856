import React, {useState, useEffect} from 'react';
import moment from 'moment';
import ListWithAddForm from '../../ListWithAddForm/ListWithAddForm';
import useCRUD from '../../../hooks/CRUD.hook';
import TemplateForm from './TemplateForm';
import {DeleteOutlined, CheckOutlined} from '@ant-design/icons';
import {defaultDateFormat} from '../../Helpers/Utils';
import {showDeleteConfirm} from '../../Helpers/Modals';

const ReportTemplates = () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'caption',
    },
    {
      title: 'Активный',
      dataIndex: 'active',
      render: (text) => (text ? <CheckOutlined /> : ''),
    },
    {
      title: 'Автор',
      dataIndex: 'authorId',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createDate',
      render: (text) => moment(text).format(defaultDateFormat),
    },
    {
      key: 'del',
      render: (text, record) => (
        <DeleteOutlined
          onClick={() =>
            showDeleteConfirm({
              content: 'Вы действительно хотите удалить шаблон печати?',
              onOk: () => onDelete(record.uuid),
            })
          }
        />
      ),
    },
  ];

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const {getData, changeData, getItem, deleteData, record, clearRecord, data, loading, total} = useCRUD({
    apiUrl: `/api/v1/catalogs-type/service/printforms`,
  });

  useEffect(() => {
    getData({page, size, params: {showRefs: 2}});
  }, [page, size]);

  const onCreate = async (values) => {
    try {
      const {file, caption} = values;
      let data = new FormData();
      data.append('file', file.file, file.file.name);
      await changeData(data, record, {caption: caption});
      getData({page, size});
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (uuid) => {
    try {
      await deleteData(uuid);
      getData({page, size});
    } catch (error) {}
  };

  const handleTableChange = ({current, pageSize}) => {
    setPage(current - 1);
    setSize(pageSize);
  };

  return (
    <div className="py-4">
      <ListWithAddForm
        getEditableItem={(record) => getItem(record.uuid)}
        clearEditableItem={clearRecord}
        editibleItem={record}
        editCatalog={true}
        changeData={onCreate}
        handleTableChange={handleTableChange}
        tableConfig={{
          data,
          loading,
          columns,
          total,
        }}
        addModalTitle="шаблон"
        FormComponent={TemplateForm}
        modalWidth={600}
        withoutContainer
      />
    </div>
  );
};

export default ReportTemplates;
