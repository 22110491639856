import React, { useState, useEffect } from 'react'
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import useCRUD from '../../hooks/CRUD.hook';
import { errorModalCreate, successModalCreate } from '../../components/Helpers/Modals';
import {getEditibleCatalogFlag } from '../../components/Helpers/Extractors';
import moment from 'moment';
import { fetchFunc } from '../../Utils/security/http/mdm';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useRouteMatch } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {setFilterReset} from "../../redux/actions/actions";
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';
import CatalogsOrgForm from "./CatalogsOrgForm";
import {Button, Modal, Tabs} from 'antd';
import StaticDownloadParamsForm from './StaticDownloadParamsForm';
import Records from '../../components/CatalogItemWidgets/Records';
import { defaultDateFormat, getReportStructure } from '../../components/Helpers/Utils';

const columns = [
    {
        title: 'Название',
        dataIndex: 'caption',
        key: 'caption',
        render: (text, record) => <Link
            to={`/organisations/${record.uuid}`}
        >
            {text || record.description}
        </Link>
    },
    {
        title: 'ИНН',
        dataIndex: 'inn',
        key: 'inn'
    },
    {
        title: 'ОГРН',
        dataIndex: 'ogrn',
        key: 'ogrn'
    },
    {
        title: 'Аналог ИНН',
        dataIndex: 'codeOgrnAnalog',
        key: 'codeOgrnAnalog'
    },
    {
        title: 'Аналог ОГРН',
        dataIndex: 'innAnalog',
        key: 'innAnalog'
    },
    {
        title: 'Страна',
        dataIndex: ['regCountry', 'caption'],
        key: 'country'
    },
    {
        title: 'Нерезидент РФ',
        dataIndex: 'nonResidentRf',
        key: 'nonResidentRf',
        render: (text) => text ? 'Да' : 'Нет'
    },
    {
        title: 'Дата добавления',
        dataIndex: 'createDate',
        key: 'createDate',
        render: (date) => moment(date).format(defaultDateFormat)
    }
];

const apiUrl = '/api/v1/catalogs/org/items';

const CatalogsOrgList = () => {
    const [filter, setFilter] = useState({});
    const [historyFilter, setHistoryFilter] = useState(null);
    const [sort, setSort] = useState('caption,asc');
    const [search, setSearch] = useState('');
    const [editCatalog, setEitCatalog] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [fields, setFields] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);
    const [staticDataLoading, setStaticDataLoading] = useState(false);
    const [staticDownloadModalVisible, setStaticDownloadModalVisible] = useState(false)
    const breadcrumbMap = useSelector(state => state.menu.breadcrumbMap );
    const [displayedColumns, setDisplayedColumns] = useState(['caption', 'ogrn', 'inn', 'codeOgrnAnalog', 'innAnalog', 'country', 'nonResidentRf'])
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const { getData, data, loading, total } = useCRUD({
        apiUrl,
    });

    const contextString = useSelector(state => state.filter.contextString);
    const filterBody = useSelector(state => state.filter.filterBody);
    const historyBody = useSelector(state => state.filter.historyBody);
    const filterResetFlag = useSelector(state => state.filter.filterResetFlag);

    useEffect(() => {
        if (location) {
            let active = breadcrumbMap.find(elem => elem.route === location.pathname);
            dispatch({
                type: 'SET_ACTIVE_MENU_ELEMENT',
                data: active
            });
        }
        getCatalogFields();
    }, []);

    useEffect(() => {
        if (filterResetFlag) {
            if (isEmpty(filterBody)) setFilter({});
            if (!historyBody) setHistoryFilter(null);
            dispatch(setFilterReset(false));
        }
    }, [filterResetFlag]);

    useEffect(() => {

        const body = makeFilterBody(filter, historyFilter, search);

        getData({
            page,
            size,
            type: !isEmpty(body) && 'search',
            body,
            params: {
                sort,
                showRefs: 2,
            },
        });
    }, [page, size, filter, historyFilter, search]);

    const makeFilterBody = (filter, historyFilter, search ) => {
        let body = {
            and: []
        };
        if (filter && !isEmpty(filter)) body.and.push(filter);
        if (historyFilter && !isEmpty(historyFilter)) body.and.push(historyFilter);
        if (search) {
            let searchBody = {
                ['%context_filter']: search
            };
            body.and.push(searchBody);
        }
        return body
    }

    const getCatalogFields = async () => {
        try {
            const response = await fetchFunc({
                url: '/api/v1/catalogs/org/fields?size=1000',
                method: 'get'
            });
            setFields(response.content)
            setEitCatalog(await getEditibleCatalogFlag('org'));
        } catch (error) {
            errorModalCreate(error.response.data.message)
        }
    };

    const onExport = async () => {
        if(!displayedColumns?.length) return (
            errorModalCreate("Нет колонок, доступных для вывода в отчёт. Добавьте необходимые колонки в списковую форму")
        );
        applyFilter()
        try {
            setExportLoading(true);
            const data = await fetchFunc({
                url: `/api/v1/catalogs/org/items/report?showRefs=1`,
                method: 'post',
                data: {
                    filter: makeFilterBody(filterBody, historyBody, contextString),
                    reportStructure: getReportStructure(columns, displayedColumns),
                    sort: [sort]
                },
                responseType: 'blob'
            });
            saveAs(data, `orgs.csv`);
            setExportLoading(false)
        } catch (error) {
            setExportLoading(false)
        }
    };

    const getStaticCsv = async (values) => {
        try {
            const { dateFrom, dateTo, getOrgModifiedAtPeriod, getOrgRelatedToMdm } = values;
            setStaticDataLoading(true);
            const data = await fetchFunc({
                url: '/api/v1/org/count-report',
                responseType: 'blob',
                params: {
                    dateFrom,
                    dateTo,
                    getOrgModifiedAtPeriod,
                    getOrgRelatedToMdm
                }
            }, null, 'org');
            saveAs(data, `Статистические данные.csv`);
            setStaticDataLoading(false);
            setStaticDownloadModalVisible(false)
        } catch (error) {
            setStaticDataLoading(false);
        }
    };

    const handleTableChange = (paginator) => {
        setPage(--paginator.current);
        setSize(paginator.pageSize);
    };

    const applyFilter = () => {
        setSearch(contextString);
        setFilter(filterBody);
        setHistoryFilter(historyBody);
        setPage(0);
    }

    const onCreate = async (values) => {
        try {
            const response = await fetchFunc({
                url: '/api/v1/catalogs/org/items',
                method: 'post',
                data: {fields: {
                    ...values
                }}
            });
            history.push(`/organisations/${response.uuid}`)
        } catch (error) {
            errorModalCreate(error.response &&
                error.response.data &&
                error.response.data.message)
        }
    };

    return (
        <> <Tabs>
            <Tabs.TabPane tab="Список контрагентов" key="1">
                <ListWithAddForm
                    handleColumnsChange={(clmns) => setDisplayedColumns(() => [...clmns])}
                    changeData={onCreate}
                    handleTableChange={handleTableChange}
                    editCatalog={editCatalog}
                    tableConfig={{
                        data,
                        loading,
                        columns,
                        total,
                        displayedColumns,
                        current: page + 1
                    }}
                    additionalActions={[
                        <Button key='static' onClick={() => setStaticDownloadModalVisible(true)} className='ml-2' loading={staticDataLoading}>Статистические данные</Button>
                    ]}
                    applyFilter={applyFilter}
                    onFilterSearch={true}
                    onContextSearch={true}
                    onHistorySearch={true}
                    onExport={onExport}
                    exportLoading={exportLoading}
                    addModalTitle='контрагент'
                    FormComponent={CatalogsOrgForm}
                    fields={fields}
                    contextSearchValue={search}
                    modalWidth={1100}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Записи на верификации" key="2">
                <Records
                    referenceOrigin={'org'}
                    referenceCaption={'Юридические лица'}
                    referenceUuid={'cb7315bb-8c53-4c96-937c-3453468cc8ab'}
                    history={history}
                    location={location}
                    match={match}
                    isVerifier
                    onDoubleClick={(record) => history.push(`/catalog/organisations/${record.uuid}/verify`)}
                />
            </Tabs.TabPane>
        </Tabs>

            <Modal
                visible={staticDownloadModalVisible}
                onCancel={() => setStaticDownloadModalVisible(false)}
                footer={null}
                title='Параметры выгрузки'
                width={700}
            >
                <StaticDownloadParamsForm
                    onSubmit={getStaticCsv}
                    loading={staticDataLoading}
                />
            </Modal>
        </>
    )
};

export default CatalogsOrgList;
