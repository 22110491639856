import {
  SET_CONTEXT_SEARCH_VALUE,
  SET_FILTER_SEARCH_VALUE,
  SET_HISTORY_SEARCH_VALUE,
  SET_FILTER_RESET,
  SET_FILTER_ERROR,
  SET_SCHEDULE_EXECUTION,
} from '../actions/actionTypes';

const initialState = {
  contextString: '',
  filterBody: {},
  historyBody: null,
  filterResetFlag: false,
  filterError: false,
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTEXT_SEARCH_VALUE:
      return {
        ...state,
        contextString: action.payload,
      };
    case SET_SCHEDULE_EXECUTION:
      return {
        ...state,
        scheduleExecution: action.payload,
      };
    case SET_FILTER_SEARCH_VALUE:
      return {
        ...state,
        filterBody: action.payload,
      };
    case SET_HISTORY_SEARCH_VALUE:
      return {
        ...state,
        historyBody: action.payload,
      };
    case SET_FILTER_RESET:
      return {
        ...state,
        filterResetFlag: action.payload,
      };
    case SET_FILTER_ERROR:
      return {
        ...state,
        filterError: action.payload,
      };
    default:
      return state;
  }
};
