
import {fetchFunc} from "../../Utils/security/http/mdm";

export const getReferenceInfo = async ({apiUrl, refUuid}) => {
    try {
        return await fetchFunc({
            url: `${apiUrl}/${refUuid}`,
            method: 'get'
        });
    } catch (error) {
        console.log(error)
    }
};

export const getStatusInfo = async (statusId) => {
    try {
        const data = await fetchFunc({
            url: `/api/v1/status-matrix`,
            method: 'get',
            params: {
                size: 20
            }
        });
        return data.content.find(item => item.status.id === statusId)
    } catch (error) {
        console.log(error)
    }
};


export const getVerifyCatalogRule = async (catalogIdentifier) => {
    try {
        const data = await fetchFunc({
            url: `/api/v1/catalogs/${catalogIdentifier}`,
            method: 'get'
        });
        return data && data.verification
    } catch (error) {
        console.log(error)
    }
};

export const getEditibleCatalogFlag = async (origin) => {
    try {
        const data = await fetchFunc({
            url: `/api/v1/catalogs/${origin}`,
            method: 'get'
        });
        return data && data.editCatalog
    } catch (error) {
        console.log(error)
    }
};

export const getEditibleRecordFlag = async (catalogIdentifier, uuid) => {
    try {
        const data = await fetchFunc({
            url: `/api/v1/catalogs/${catalogIdentifier}/items/${uuid}/fact/access`,
            method: 'get'
        });
        return data && data.canEdit
    } catch (error) {
        console.log(error)
    }
};

export const getCatalogFields = async (catalogOrigin) => {
    try {
        const data = await fetchFunc({
            url: `/api/v1/catalogs/${catalogOrigin}/fields?size=100`,
            method: 'get'
        });
        return data && data.content
    } catch (error) {
        console.log(error)
    }
};