import React, { Component } from 'react';
import CatalogList from '../../components/CatalogList';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

class CatalogsDraft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Номер заявки',
                    dataIndex: 'orderNumber',
                    key: 'orderNumber',
                    render: (text, record) => <Link to={`/catalog-draft/${record.uuid}`}>{text}</Link>
                },
                {
                    title: 'Наименование',
                    dataIndex: 'description',
                    key: 'description'
                },
                {
                    title: 'Справочник',
                    dataIndex: 'caption',
                    key: 'caption'
                },
                {
                    title: 'Тип заявки',
                    dataIndex: 'draftAction',
                    key: 'draftAction',
                    render: (text) => <span>{text === 'create' ? 'Создание' : 'Изменение'}</span>
                },
                {
                    title: 'Статус',
                    dataIndex: ['status', 'caption'],
                    key: 'status'
                },
                {
                    title: 'Дата создания',
                    dataIndex: 'createDate',
                    key: 'createDate'
                },
                {
                    title: 'Автор',
                    dataIndex: 'authorFio',
                    key: 'authorFio'
                }
            ],
            displayedColumns: ['orderNumber', 'caption', 'draftAction', 'status'],
        };
    };

    componentDidMount() {
        const { breadcrumbNameMap, location, toggleActive } = this.props;
        let active = breadcrumbNameMap.find(elem => elem.route === location.pathname);
        toggleActive(active);
    }


    render() {
        const { columns, displayedColumns } = this.state;
        return (
            <CatalogList
                card={true}
                type = 'draft'
                isContextSearch={true}
                columns={columns}
                displayedColumns={displayedColumns}
                apiUrl='/api/v1/catalog-draft'
            />
        )
    }
}

const mapStateToProps = (store, ownProps) => {
    return { breadcrumbNameMap: store.navigate.breadcrumbNames };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleActive: (elementRef = false) => {
            dispatch({
                type: 'SET_ACTIVE_MENU_ELEMENT',
                data: elementRef
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogsDraft);
