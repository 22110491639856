import {
  SET_CHANGED_DETAIL_NAME,
  SET_CATALOG_EDIT_CAPTION,
  SET_CHANGED_DETAIL_FIELD,
  SET_CHANGED_DETAIL_MODE,
  SET_REFERENCE_CATALOG_PATTERN_ORIGIN,
  SET_DATA_OPTIONS,
  SET_DATA_OPTIONS_RESET,
  GET_FIELDS_TYPE,
  SET_VALIDATOR_LENGTH,
  SET_NUMERIC_COMPARISON,
  GET_USERS_ROLES,
} from '../actions/actionTypes';

const initialState = {
  changedDetail: '',
  changedField: '',
  mode: '',
  referenceCatalogPatternStore: '',
  referenceCatalogPatternOption: [],
  catalogEditCaption: {
    content: [],
  },
};

export const detailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANGED_DETAIL_NAME:
      return {
        ...state,
        changedDetail: action.payload,
      };
    case GET_FIELDS_TYPE:
      return {
        ...state,
        fieldsType: action.payload,
      };
    case GET_USERS_ROLES:
      return {
        ...state,
        usersRoles: action.payload,
      };
    case SET_CHANGED_DETAIL_FIELD:
      return {
        ...state,
        changedField: action.payload,
      };
    case SET_CATALOG_EDIT_CAPTION:
      return {
        ...state,
        catalogEditCaption: action.payload,
      };
    case SET_CHANGED_DETAIL_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case SET_REFERENCE_CATALOG_PATTERN_ORIGIN:
      return {
        ...state,
        referenceCatalogPatternStore: action.payload,
      };
    case SET_DATA_OPTIONS:
      return {
        ...state,
        dataOptions: action.payload,
      };
    case SET_DATA_OPTIONS_RESET:
      return {
        ...state,
        dataOptions: [],
      };
    case SET_VALIDATOR_LENGTH:
      return {
        ...state,
        validatorLength: action.payload,
      };
    case SET_NUMERIC_COMPARISON:
      return {
        ...state,
        numericComparison: action.payload,
      };
    default:
      return state;
  }
};
