import React from 'react';
import {Form, Checkbox, Input, InputNumber, Select} from 'antd';
import {setNumericComparisonAC, setValidatorLengthAC} from '../../redux/actions/actions';
import {useDispatch} from 'react-redux';

const numberOperators = [
  {key: 'eq', value: '='},
  {key: 'less', value: '<'},
  {key: 'more', value: '>'},
  {key: 'range', value: 'Интервал'},
];
const lengthOperators = [
  {key: 'eq', value: '='},
  {key: 'max', value: 'max'},
];

const requiredRule = [{required: true, message: 'Поле обязательно для заполнения'}];

const Validators = (props) => {
  const {mapOption} = props;
  const dispatch = useDispatch();

  const handleNumericComparisonChange = async () => {
    try {
      await props.form.validateFields(['defaultValue']);
    } catch (errorInfo) {}
  };

  const dispatchValidatorRules = () => {
    dispatch(setValidatorLengthAC(props.form?.getFieldsValue(['validator_operator_Length', 'validator_Length'])));
  };

  return (
    <Form.Item noStyle shouldUpdate={(prevState, nextState) => prevState.fieldTypeId !== nextState.fieldTypeId}>
      {({getFieldValue, setFieldsValue, resetFields}) => {
        const type = getFieldValue('fieldTypeId');
        return (
          (type === 1 || type === 2 || type === 3 || type === 11 || type === 13 || type === 15) && (
            <Form.Item label="Валидаторы">
              {(type === 1 || type === 11 || type === 13) && (
                <Form.Item>
                  <Form.Item name="is_Pattern" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{width: '30%'}}
                      onChange={({target: {checked}}) => {
                        if (!checked) {
                          setFieldsValue({
                            validator_Pattern: '',
                          });
                        }
                      }}
                    >
                      Регулярное выражение
                    </Checkbox>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, next) => prev.is_Pattern !== next.is_Pattern}>
                    {({getFieldValue, setFieldsValue}) => {
                      const isPattern = getFieldValue('is_Pattern');
                      return (
                        isPattern && (
                          <Form.Item name="validator_Pattern" extra="Регулярное выражение" noStyle rules={requiredRule}>
                            <Input style={{width: '70%'}} />
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              )}
              {(type === 1 || type === 11) && (
                <Form.Item>
                  <Form.Item name="is_Length" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{width: '30%'}}
                      onChange={({target: {checked}}) => {
                        if (!checked) {
                          setFieldsValue({
                            validator_operator_Length: '',
                            validator_Length: '',
                          });
                        }
                      }}
                    >
                      Количество символов
                    </Checkbox>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, next) => prev.is_Length !== next.is_Length}>
                    {({getFieldValue}) => {
                      const isLength = getFieldValue('is_Length');
                      return (
                        isLength && (
                          <Form.Item noStyle>
                            <Form.Item name="validator_operator_Length" noStyle rules={requiredRule}>
                              <Select
                                placeholder="Выбрать"
                                style={{width: '30%', marginRight: '5%'}}
                                onChange={(value) => {
                                  setFieldsValue({
                                    validator_operator_Length: value,
                                  });
                                  dispatchValidatorRules();
                                }}
                              >
                                {mapOption(lengthOperators)}
                              </Select>
                            </Form.Item>
                            <Form.Item name="validator_Length" noStyle rules={requiredRule}>
                              <Input
                                extra="Количество символов"
                                style={{width: '35%'}}
                                onChange={(event) => {
                                  setFieldsValue({
                                    validator_Length: event.target.value,
                                  });
                                  dispatchValidatorRules();
                                }}
                              />
                            </Form.Item>
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              )}
              {(type === 2 || type === 3) && (
                <Form.Item>
                  <Form.Item name="is_NumericComparison" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{width: '30%'}}
                      onChange={({target: {checked}}) => {
                        if (!checked) {
                          setFieldsValue({
                            validator_operator_NumericComparison: '',
                            validator_NumericComparison_bottom: '',
                            validator_NumericComparison_top: '',
                            validator_NumericComparison: '',
                          });
                          handleNumericComparisonChange();
                          dispatch(
                            setNumericComparisonAC(
                              props.form.getFieldsValue([
                                'validator_NumericComparison_bottom',
                                'validator_NumericComparison_top',
                                'validator_NumericComparison',
                                'validator_operator_NumericComparison',
                              ])
                            )
                          );
                        }
                      }}
                    >
                      Сравнение с числом
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.is_NumericComparison !== next.is_NumericComparison}
                  >
                    {({getFieldValue}) => {
                      const isNumeric = getFieldValue('is_NumericComparison');
                      return (
                        isNumeric && (
                          <Form.Item noStyle>
                            <Form.Item name="validator_operator_NumericComparison" noStyle rules={requiredRule}>
                              <Select
                                placeholder="Выбрать"
                                style={{width: '30%', marginRight: '5%'}}
                                onChange={(value) => {
                                  handleNumericComparisonChange();
                                  if (value !== 'range') {
                                    setFieldsValue({
                                      validator_NumericComparison_bottom: '',
                                      validator_NumericComparison_top: '',
                                      validator_NumericComparison: '',
                                    });
                                    dispatch(
                                      setNumericComparisonAC(
                                        props.form.getFieldsValue([
                                          'validator_NumericComparison_bottom',
                                          'validator_NumericComparison_top',
                                          'validator_NumericComparison',
                                          'validator_operator_NumericComparison',
                                        ])
                                      )
                                    );
                                  }
                                }}
                              >
                                {mapOption(numberOperators)}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, next) =>
                                prev.validator_operator_NumericComparison !== next.validator_operator_NumericComparison
                              }
                            >
                              {({getFieldValue}) => {
                                const RangeRule = [
                                  {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                  },
                                  {
                                    validator: async (_, value) => {
                                      const bottomValue = getFieldValue('validator_NumericComparison_bottom');
                                      const topValue = getFieldValue('validator_NumericComparison_top');
                                      if (
                                        typeof bottomValue == 'number' &&
                                        typeof topValue == 'number' &&
                                        bottomValue > topValue
                                      ) {
                                        return Promise.reject('Начало интервала должно быть больше конца');
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ];

                                const handleNumericComparisonTopChange = async () => {
                                  try {
                                    await props.form.validateFields(['validator_NumericComparison_bottom']);
                                  } catch (errorInfo) {}
                                };

                                return getFieldValue('validator_operator_NumericComparison') === 'range' ? (
                                  <Form.Item noStyle>
                                    <Form.Item name="validator_NumericComparison_bottom" noStyle rules={RangeRule}>
                                      <InputNumber
                                        style={{width: '15%', marginRight: '5%'}}
                                        onChange={() => {
                                          dispatch(
                                            setNumericComparisonAC(
                                              props.form.getFieldsValue([
                                                'validator_NumericComparison_bottom',
                                                'validator_NumericComparison_top',
                                                'validator_operator_NumericComparison',
                                              ])
                                            )
                                          );
                                          handleNumericComparisonChange();
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item name="validator_NumericComparison_top" noStyle rules={requiredRule}>
                                      <InputNumber
                                        style={{width: '15%'}}
                                        onChange={() => {
                                          dispatch(
                                            setNumericComparisonAC(
                                              props.form.getFieldsValue([
                                                'validator_NumericComparison_bottom',
                                                'validator_NumericComparison_top',
                                                'validator_operator_NumericComparison',
                                              ])
                                            )
                                          );
                                          handleNumericComparisonChange();
                                          handleNumericComparisonTopChange();
                                        }}
                                      />
                                    </Form.Item>
                                  </Form.Item>
                                ) : (
                                  <Form.Item name="validator_NumericComparison" noStyle rules={requiredRule}>
                                    <InputNumber
                                      placeholder="Введите значение"
                                      style={{width: '35%'}}
                                      onChange={() => {
                                        dispatch(
                                          setNumericComparisonAC(
                                            props.form.getFieldsValue([
                                              'validator_NumericComparison_bottom',
                                              'validator_NumericComparison_top',
                                              'validator_NumericComparison',
                                              'validator_operator_NumericComparison',
                                            ])
                                          )
                                        );
                                        handleNumericComparisonChange();
                                      }}
                                    />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              )}
              {type === 15 && (
                <Form.Item>
                  <Form.Item name="is_Enumeration" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{width: '30%'}}
                      onChange={({target: {checked}}) => {
                        if (!checked) {
                          setFieldsValue({
                            validator_Enumeration: '',
                          });
                        }
                      }}
                    >
                      Вхождение в список
                    </Checkbox>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prev, next) => prev.is_Enumeration !== next.is_Enumeration}>
                    {({getFieldValue}) => {
                      const isEnum = getFieldValue('is_Enumeration');
                      return (
                        isEnum && (
                          <Form.Item
                            name="validator_Enumeration"
                            extra="Количество символов"
                            noStyle
                            rules={requiredRule}
                          >
                            <Input style={{width: '70%'}} />
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              )}
            </Form.Item>
          )
        );
      }}
    </Form.Item>
  );
};

export default Validators;
