import moment from 'moment';
import {saveAs} from 'file-saver';
import {errorModalCreate} from './Modals';
import _, {forIn, get, hasIn, isEmpty, isString} from 'lodash';
import {getCustomizedDocDetailColumns} from './docDetailCustomizator';

import {fetchFunc} from '../../Utils/security/http/mdm';
import {modifiedDocTmpltColumn} from '../RecordRedactor/meta/meta';

export const defaultDateFormat = 'DD.MM.YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
export const backendDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function convertDate(date) {
  let resultDate = new Date(date);
  return moment(resultDate).format(backendDateTimeFormat);
}
/** Функция перевода даты для отправки для фильтра */
export function convertDateToPayload(date) {
  return moment(date).utcOffset('+00:00').format(`YYYY-MM-DDTHH:mm:00.000Z`);
}
/** Функция перевода даты для отправки при создании */
export function convertDateToPayloadForCreate(date) {
  return moment(date).utc().format(`YYYY-MM-DDTHH:mm:00.000+00:00`);
}

export const camelToSnake = (string) => {
  if (!isEmpty(string)) {
    return string
      .replace(/[\w]([A-Z])/g, function (m) {
        return m[0] + '_' + m[1];
      })
      .toLowerCase();
  }
};

export const getEmptyRequiredFields = (fields, data, type) => {
  return fields.filter(
    (item) =>
      item.required && (type === 'create' ? item.fieldType.id !== 12 : item.fieldType !== 12) && !data[item.origin]
  );
};

export const normalizeValues = ({values, fields, key, type}) => {
  if (type === 'editFieldForm') {
    if (values.fieldTypeId === 8 && key === 'defaultValue')
      values[key] = values[key] ? convertDate(values[key], backendDateFormat) : values[key];
    if (values.fieldTypeId === 15 && key === 'defaultValue') values[key] = JSON.stringify(values[key]);
    return values;
  } else {
    let newValues = {};
    forIn(values, async (value, key) => {
      const itemFromMeta = fields && fields.find((item) => item.origin === key);
      if (itemFromMeta) {
        if (!itemFromMeta.accessReadOnly) {
          const itemFromMetaType = itemFromMeta && itemFromMeta.fieldType && itemFromMeta.fieldType.id;
          switch (itemFromMetaType) {
            case 2:
            case 3:
              if (!values[key]) newValues[key] = null;
              else {
                if (get(itemFromMeta, 'options.rules.mask.mask')) {
                  const resultValue = value.toString().replace(/\s/g, '');
                  newValues[key] = resultValue.replace(/,/, '.');
                } else newValues[key] = values[key];
              }
              break;
            case 8:
              newValues[key] = value ? value.format(backendDateFormat) : null;
              break;
            case 7:
              newValues[key] = value ? convertDateToPayloadForCreate(value) : null;
              break;
            case 19:
              newValues[key] = value
                ? isString(value)
                  ? await convertToJson(value, `Неверный JSON у поля ${itemFromMeta.caption}`)
                  : value
                : null;
              break;
            case 12:
              if (itemFromMeta.options && itemFromMeta.options.detailMode === 'select') {
                newValues[key] =
                  values[key] &&
                  values[key].map((item) => {
                    return {
                      [itemFromMeta.options.detailCfg.field]: item,
                    };
                  });
              }
              break;
            case 21:
              newValues[key] = values[key] || null;
              break;
            default:
              newValues[key] = values[key];
          }
        }
      }
    });
    return newValues;
  }
};

export const getFieldsMeta = ({columns = [], rule, additionalRule = ''}) => {
  switch (rule) {
    case 'retsProductDocument':
      if (additionalRule) {
        return getCustomizedDocDetailColumns({
          columns,
          itemsForAdd: [modifiedDocTmpltColumn[additionalRule]],
          itemsForRemove: ['templateDocType', 'templateDocUuid', 'templateDocName'],
        });
      }
      const columnsWithTypeFiled = getCustomizedDocDetailColumns({
        columns,
        itemsForAdd: [modifiedDocTmpltColumn.redactor],
        itemsForRemove: ['docOrigin', 'templateDocType', 'templateDocUuid', 'templateDocName'],
        addToBegin: true,
      });
      return getCustomizedDocDetailColumns({
        columns: columnsWithTypeFiled,
        itemsForAdd: [modifiedDocTmpltColumn.reader],
      });
    default:
      return columns;
  }
};

export const statuses = [
  {key: 'rejected', value: 'Отклонено'},
  {key: 'draft', value: 'Черновик'},
  {key: 'verification', value: 'На верификации'},
  {key: 'approved', value: 'Утверждено'},
];

export const errors = [{key: 'Доступ запрещен: Access is denied', value: 1}];

const changeTypes = [
  {key: 'delete', value: 'Удаление'},
  {key: 'add', value: 'Добавление'},
  {key: 'modify', value: 'Изменение'},
];

export const renderDate = (path, data) => {
  const date = get(data, path);
  if (date) return moment(date).format(defaultDateFormat);
  else return '-';
};

export const renderVerifierFio = (path, data) => {
  return `${get(data, `${path}.lastName`) || ''} ${get(data, `${path}.firstName`) || ''} ${
    get(data, `${path}.middleName`) || ''
  }`;
};

export const renderChangeType = (text) => {
  let type = changeTypes.find((elem) => elem.key === text);
  if (type) return type.value;
  else return '-';
};

export const renderStatus = (data, store) => {
  const status = statuses.find((elem) => elem.key === get(data, store));
  if (status) return status.value;
};

export const renderError = (data, store, url, catchError) => {
  const error = errors.find((elem) => elem.key === get(data, store))?.value;
  switch (error) {
    case 1: {
      const pattern = /catalogs\/(.*?)\//;
      const CatalogName = url.match(pattern)[1];
      errorModalCreate(`Доступ для справочника ${CatalogName} запрещен`);
      return;
    }
    default: {
      errorModalCreate(get(data, store, `Неизвестная ошибка: ${catchError.message}`));
      return;
    }
  }
};

export const calculateDate = ({duration}) => new Date(new Date().setDate(new Date().getDate() + duration));

export const convertToJson = async (text, errorText = 'Неверный JSON') => {
  try {
    return JSON.parse(text);
  } catch (error) {
    errorModalCreate(errorText);
    throw error;
  }
};

export const renderTableDate = (date) => (date ? moment(date).format('DD-MM-YYYY') : '');

export const renderTableSelect = (text, options) => {
  let item = options.find((elem) => elem.key === text);
  return (item && item.value) || '-';
};

export const sortFieldsByOrder = (fields) => {
  return fields.sort((a, b) => {
    return a.fieldOrder - b.fieldOrder;
  });
};

export const hiddenFieldsHandler = (hiddenFields, origin, mode) => {
  let newArr = hiddenFields || [];
  if (mode === 'add') {
    if (!newArr.includes(origin)) newArr.push(origin);
  } else {
    const fieldIdx = hiddenFields.findIndex((item) => item === origin);
    newArr.splice(fieldIdx, 1);
  }
  return newArr;
};

export const setDatePickerSettings = (item, incomeDateFormat) => {
  let pickerType;
  let defaultTime = '00:00:00';
  let defaultTimeFormat = 'HH:mm:ss';
  const dateFormatFromOptions = get(item, 'options.date_format', null);
  if (dateFormatFromOptions) {
    switch (dateFormatFromOptions) {
      case 'MM YYYY':
        pickerType = 'month';
        break;
      case 'C YYYY':
        pickerType = 'quarter';
        break;
      case 'DD.MM.YYYY HH:MM':
        defaultTimeFormat = 'HH:mm';
        defaultTime = '00:00';
        break;
      default:
    }
  }
  return {
    pickerType,
    defaultTime,
    defaultTimeFormat,
    dateFormat: dateFormatFromOptions || incomeDateFormat,
  };
};

export const onError = (response) => {
  if (_.get(response, 'data.exception') === 'ru.seventech.mdm.api.error.ValidationException')
    errorModalCreate(_.get(response, 'data.message'));
  else errorModalCreate(JSON.parse(_.get(response, ['request', 'responseText'])).message);
};

export const createFilterString = (filter) => {
  let searchString = '';
  _.forIn(filter, (value, key) => {
    if (!_.isUndefined(value)) searchString += `&${key}=${value}`;
  });
  return searchString;
};

export const getReportStructure = (columns, displayedColumns) => {
  const reportStructure = [];
  columns.forEach(({title, key}) => {
    if (displayedColumns.some((elem) => elem === key)) {
      reportStructure.push({
        title,
        origin: key,
      });
    }
  });
  return reportStructure;
};

export const checkEditible = (field, mode) => {
  let isEditible = true;
  if (mode !== 'create') {
    const gui_editable = get(field, 'options.gui_editable');
    if (gui_editable === undefined) {
      isEditible = gui_editable;
    }
  } else {
    const edit_gui_editable = get(field, 'options.edit_gui_editable');
    if (edit_gui_editable !== undefined) {
      isEditible = edit_gui_editable;
    }
  }
  if (field.accessReadOnly) {
    isEditible = false;
  }
  return isEditible;
};

export const charCounter = (str, sChar) => {
  return str.replace(sChar, '').length;
};

export const findNameValueByKey = (arr, key) => {
  const item = arr.find((item) => item.key === key);
  return (item && item.value) || '';
};

export const createReferenceScriptText = (data, referenceScript) => {
  let text = '';
  try {
    const parsedRefScript = isString(referenceScript) ? JSON.parse(referenceScript) : referenceScript;
    if (hasIn(parsedRefScript, 'separator')) {
      const {names, separator} = parsedRefScript;
      names.forEach((item, index) => {
        if (separator !== 'or') {
          text =
            text + (data[item] ? `${data[item]}${index !== names.length - 1 ? parsedRefScript['separator'] : ''}` : '');
        } else {
          text = !text ? data[item] : text;
        }
      });
    }
    return text;
  } catch (e) {
    console.log(`Ошибка JSON - ${referenceScript}`);
    return 'неверный скрипт отображения поля';
  }
};

const getValueByKey = (key, store) => {
  const item = store.find((item) => item.key === key);
  return (item && item.value) || 'Значение не найдено';
};

export const optionsExtractor = (value, store, name) => {
  const item = store.find((item) => item.origin === name);
  const options = item && get(item, 'options.combobox_options', null);
  return options ? getValueByKey(value, options) : 'Значение не найдено';
};

export const getCountryData = async (code) => {
  const url = '/api/v1/catalogs/country/items/search?size=20&showRefs=0';
  try {
    return await fetchFunc({
      url,
      method: 'post',
      data: {code},
    });
  } catch (err) {}
};

export const getAuthorFio = async ({
  uuid = '',
  match: {
    params: {id, item, scope},
  },
}) => {
  let catalog;
  switch (item || scope) {
    case 'org':
    case 'organisations':
    case 'catalog':
      catalog = 'org';
      break;
    case 'services':
    case 'service':
      catalog = 'service';
      break;
    default:
      catalog = item || scope;
      break;
  }

  const url = `/api/v1/catalogs/${catalog}/items/${uuid || id}/author`;
  return await fetchFunc({url});
};

export const convertExpoToString = (n) => {
  const sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  const [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  const num =
    +pow < 0
      ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
  return num;
};

export const digitRankFormatter = (value, typeId = 3) => {
  if (value.length >= 15) value = value.substring(0, 15);
  if (typeId !== 2) {
    const formatValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const numArr = formatValue.split('.');
    if (numArr[1]) return numArr[0] + '.' + numArr[1].replace(/\s/g, '');
    else return formatValue;
  } else return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').split('.')[0];
};
/** Валидация длч поля origin */
export const checkFormat = (_, value) => {
  const systemFieldsArr = [
    'uuid',
    'id',
    'version',
    'versionNumber',
    'authorId',
    'active',
    'dateFrom',
    'dateTo',
    'createDate',
    'status',
    'authorFio',
    'regNumber',
    'catalogId',
    'mainItemUuid',
  ];
  const Reg = new RegExp('^[a-z][a-zA-Z0-9]*$');
  const string = camelToSnake(value);
  if (string?.length > 30) {
    return Promise.reject(`Значение поля с учётом приведения к формату базы данных превышает допустимые 30 символов`);
  }
  if (systemFieldsArr.includes(value) === true) {
    return Promise.reject(
      'Это наименование зарезервировано системой.Пожалуйста, задайте другое значение для наименования'
    );
  }
  if (Reg.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Неверный формат');
};

export const saveTextToFile = (text, flName, res) => {
  const blob = new Blob([text], {type: 'text/plain;charset=utf-8'});
  saveAs(blob, `${flName}.${res}`);
};
