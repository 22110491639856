import {
  ADD_ALGORITHM_DEDUPLICATION,
  ADD_DEDUPLICATION_PROTOCOL,
  ADD_DEGREE_PROXIMITY_DEDUPLICATION,
  ADD_RULES_DEDUPLICATION,
  ADD_START_DEDUPLICATION_PROTOCOL,
  EDIT_JOB_DEDUPLICATION_PROTOCOL,
  RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  RESET_DEDUPLICATION_PROTOCOL_INFO,
  RESET_RULES_DEDUPLICATION,
  SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP,
  SET_DEDUPLICATION_BLOCK_LINK_GROUP,
  SET_DEDUPLICATION_CANCEL_LINK_GROUP,
  SET_DEDUPLICATION_FORM_PROTOCOL,
  SET_DEDUPLICATION_GROUP_PROTOCOL_INFO,
  SET_DEDUPLICATION_JOB_PROTOCOL,
  SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE,
  SET_DEDUPLICATION_PROTOCOL_INFO,
  SET_DEDUPLICATION_PROTOCOLS,
  SET_DEDUPLICATION_PROTOCOLS_DELETE,
  SET_DEDUPLICATION_SET_LINK_GROUP,
  COLOR,
  CAPTION,
  SET_RECORD_ID,
  SET_SUBMIT_REQUEST,
  LOADING_JOBS_DEDUPLICATION,
  LOADING_PROTOCOLS_DEDUPLICATION,
  SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS,
  LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION,
} from '../actions/actionTypes';
import {isEmpty} from 'lodash';

export const deduplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case SET_RECORD_ID:
      return {
        ...state,
        recordId: action.payload,
      };
    case CAPTION:
      return {
        ...state,
        caption: {caption: action.payload.caption, value: action.payload.value},
      };
    case RESET_DEDUPLICATION_PROTOCOL_INFO:
      return {
        ...state,
        deduplicationProtocolInfo: [],
      };
    case SET_SUBMIT_REQUEST:
      return {
        ...state,
        submitRequest: action.payload,
      };
    case RESET_DEDUPLICATION_GROUP_PROTOCOL_INFO:
      return {
        ...state,
        deduplicationGroupProtocolInfo: [],
      };
    case ADD_DEDUPLICATION_PROTOCOL:
      return {
        ...state,
        deduplicationJobProtocol: [action.payload, ...state.deduplicationJobProtocol],
      };
    case ADD_START_DEDUPLICATION_PROTOCOL:
      return {
        ...state,
        deduplicationJobProtocol: [
          ...state.deduplicationJobProtocol.map((item) => {
            if (item.uuid === action.payload.uuid) {
              if (!isEmpty(action.payload.value)) {
                return action.payload.value;
              } else {
                return {...item};
              }
            }
            return item;
          }),
        ],
      };
    case EDIT_JOB_DEDUPLICATION_PROTOCOL:
      return {
        ...state,
        deduplicationJobProtocol: [
          ...state.deduplicationJobProtocol.map((item) => {
            if (item.uuid === action.payload.uuid) {
              return action.payload.value;
            }
            return item;
          }),
        ],
      };
    case SET_DEDUPLICATION_FORM_PROTOCOL:
      return {
        ...state,
        deduplicationFormProtocol: [...action.payload],
      };

    case SET_DEDUPLICATION_JOB_PROTOCOL:
      return {
        ...state,
        deduplicationJobProtocol: [...action.payload],
      };
    case SET_DEDUPLICATION_PROTOCOL_INFO:
      return {
        ...state,
        deduplicationProtocolInfo: [...action.payload.values],
      };
    case SET_DEDUPLICATION_GROUP_PROTOCOL_INFO:
      return {
        ...state,
        deduplicationGroupProtocolInfo: action.payload.value,
      };
    case SET_DEDUPLICATION_BLOCK_LINK_FULL_GROUP:
      return {
        ...state,
        deduplicationProtocolInfo: [
          ...state?.deduplicationProtocolInfo?.map((item) => {
            if (item.groupId === action.payload.groupId) {
              return action.payload.value;
            }
            return item;
          }),
        ],
      };
    case SET_DEDUPLICATION_SET_LINK_GROUP:
      return {
        ...state,
        deduplicationProtocolInfo: [
          ...state?.deduplicationProtocolInfo?.map((item) => {
            if (item.groupId === action.payload.groupId && !isEmpty(item)) {
              return action.payload.value;
            }
            return item;
          }),
        ],
      };
    case SET_DEDUPLICATION_CANCEL_LINK_GROUP:
      return {
        ...state,
        deduplicationProtocolInfo: [
          ...state?.deduplicationProtocolInfo?.map((item) => {
            if (item.groupId === action.payload.groupId) {
              return action.payload.value;
            }
            return item;
          }),
        ],
      };
    case LOADING_JOBS_DEDUPLICATION:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADING_PROTOCOLS_DEDUPLICATION:
      return {
        ...state,
        loadingProtocols: action.payload,
      };
    case LOADING_PROTOCOLS_UNARCHIVED_DEDUPLICATION:
      return {
        ...state,
        loadingUnarchivedProtocols: action.payload,
      };
    case SET_DEDUPLICATION_PROTOCOLS:
      return {
        ...state,
        deduplicationProtocols: [...action.payload],
      };
    case SET_DEDUPLICATION_UNARCHIVED_PROTOCOLS:
      return {
        ...state,
        deduplicationUnarchivedProtocols: [...action.payload],
      };
    case SET_DEDUPLICATION_PROTOCOLS_DELETE:
      return !isEmpty(state.deduplicationProtocols)
        ? {
            ...state,
            deduplicationProtocols: [
              ...state?.deduplicationProtocols?.map((item) => {
                if (item.uuid === action.payload.protocolId) {
                  return action.payload.value;
                }
                return item;
              }),
            ],
          }
        : !isEmpty(state.deduplicationUnarchivedProtocols) && {
            ...state,
            deduplicationUnarchivedProtocols: [
              ...state?.deduplicationUnarchivedProtocols?.filter((item) => {
                return item.uuid !== action.payload.protocolId;
              }),
            ],
          };
    case SET_DEDUPLICATION_BLOCK_LINK_GROUP:
      return {
        ...state,
        deduplicationGroupProtocolInfo: {
          ...state.deduplicationGroupProtocolInfo,
          records: [
            ...state.deduplicationGroupProtocolInfo?.records.filter(
              (record) => record.groupRecordUuid !== action.payload.value.uuid
            ),
          ],
        },
      };
    case SET_DEDUPLICATION_JOB_PROTOCOL_INFO_DELETE:
      return {
        ...state,
        deduplicationJobProtocol: [
          ...state.deduplicationJobProtocol.filter((jobProtocol) => jobProtocol.uuid !== action.payload),
        ],
      };
    case ADD_RULES_DEDUPLICATION:
      return {
        ...state,
        rulesDeduplication: [...action.payload],
      };
    case RESET_RULES_DEDUPLICATION:
      return {
        ...state,
        rulesDeduplication: [],
      };
    case ADD_ALGORITHM_DEDUPLICATION:
      return {
        ...state,
        rulesDeduplication: state.rulesDeduplication.map((item) => {
          if (item.fieldOrigin === action.payload.rulesDeduplication) {
            return {
              ...item,
              algorithmDeduplication: action.payload.comboBoxOptions.key,
              degreeProximity: 0.8,
            };
          }
          return item;
        }),
      };
    case ADD_DEGREE_PROXIMITY_DEDUPLICATION:
      return {
        ...state,
        rulesDeduplication: state?.rulesDeduplication?.map((item) => {
          if (item.fieldOrigin === action.payload.key) {
            return {
              ...item,
              degreeProximity: action.payload.value,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};
