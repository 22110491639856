import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Card, Modal, Table, Tooltip} from 'antd';
import {useHistory, useRouteMatch} from 'react-router';
import _, {isEmpty, isBoolean} from 'lodash';

import {
  setChangedDetailName,
  setChangedDetailField,
  setChangedDetailMode,
  setRecordLoading,
  setCurrentCatalog,
} from '../../redux/actions/actions';
import {SignDocModal} from '../SignDoc';
import {mdm} from '../../Utils/constant';
import RecordReader from './RecordReader';
import RecordEditor from './RecordEditor';
import FormInitiator from './FormInitator';
import Versions from '../Versions';
import {backendDateFormat} from '../Helpers/Utils';
import VersionsByDateForm from './VersionByDateForm';
import SystemInformation from '../SystemInformation';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {metaUnifier} from '../Helpers/RuleConfigurator';
import {warningModalCreate, showDeleteConfirm} from '../Helpers/Modals';
import {getFieldsMeta, normalizeValues, onError} from '../Helpers/Utils';
import {ReactComponent as ExclamationPoint} from '../../assets/images/coolicon.svg';

const RecordRedactor = ({
  catalogUuid,
  isEdit,
  recordUuid,
  versionId,
  type,
  detailUuid,
  detailFieldUuid,
  referenceOrigin,
  referenceUuid,
  mode,
  onCompositeModalSubmit,
  closeModal,
  isUnverified,
  compositeInitialValue,
  additionalMeta,
  additionalFields,
  rootValues,
  rootForm,
  defaultRecord,
  fixedSubmitButton,
  submitButtonPosition,
  renderCustomTable,
  structure,
  maxRefsDepth,
  versionKey,
  setVersionKey,
  onRestore,
  getCatalogItem,
  setIsUnverified,
  initialValidateCatalog,
  isRestored,
}) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const [referenceOriginLocal, setReferenceOrigin] = useState(null);
  const [refDepth, setRefDepth] = useState(maxRefsDepth || 1);
  const [fields, setFields] = useState([]);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState(defaultRecord || []);
  const [showVersionModal, setShowVersionModal] = useState(false);
  const [versionDate, setVersionDate] = useState(null);
  const [editMode, setEditMode] = useState(mode === 'create');
  const [loading, setLoading] = useState(false);
  const [validateCatalog, setValidateCatalog] = useState(initialValidateCatalog);
  const [showSignModal, setShowSignModal] = useState(false);
  const [editSigns, setEditSigns] = useState({});
  const [hiddenFields, setHiddenFields] = useState([]);
  const [ecmDocData, setEcmDocData] = useState({
    templateDocType: '',
    templateDocUuid: '',
    templateDocName: '',
  });
  const [filesForSign, setFilesForSign] = useState({
    modal: false,
    files: [],
  });

  useEffect(() => {
    setLoading(true);
    referenceOrigin && setReferenceOrigin(referenceOrigin);
    catalogUuid && getCatalog();
  }, [catalogUuid, recordUuid]);

  useEffect(() => {
    if (referenceOriginLocal) {
      getCatalogRecordFields().then();
      recordUuid && !defaultRecord && getCatalogRecordItems();
      // if (!isUnverified && !detailUuid && !versionId) recordUuid && getCatalogRecordFiles();
    }
  }, [referenceOriginLocal, defaultRecord, versionId, isUnverified, refDepth, isRestored]);

  const getCatalog = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}`,
        method: 'get',
      });
      if (data.options && data.options.max_refs_depth) {
        setRefDepth(data.options.max_refs_depth);
      }
      setReferenceOrigin(data.origin);
      setEditSigns({
        editAttributes: data.editAttributes,
        editCatalog: data.editCatalog,
        editProperties: data.editProperties,
        inputType: data.inputType,
      });
      if (data.validateRecords) {
        setValidateCatalog(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCatalogRecordFields = async () => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/fields?size=1000`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/fields?showDetails=0&showRefs=0&type=base&size=1000`;
      }
      const data = await fetchFunc({
        url,
        method: 'get',
      });
      if (detailUuid && defaultRecord) {
        data.content.forEach((field) => {
          if (field.fieldType.id === 12) {
            field.fieldType.id = 112;
          }
        });
      }
      if (additionalFields) {
        data.content.push(...additionalFields);
      }
      setFields(additionalMeta ? metaUnifier(data.content, additionalMeta) : data.content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCatalogRecordItems = async () => {
    try {
      if (
        !isEmpty(referenceOriginLocal) ||
        !isEmpty(recordUuid) ||
        !isEmpty(versionId) ||
        !isEmpty(detailFieldUuid) ||
        !isEmpty(versionId)
      ) {
        let url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}?showRefs=${refDepth}`;
        if (versionId) {
          url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}/history/${versionId}?showRefs=${refDepth}&showDetails=1`;
        } else if (detailUuid && mode === 'edit') {
          url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items/${detailFieldUuid}${
            isUnverified ? '/unverified' : ''
          }?showRefs=${refDepth}`;
        } else if (detailUuid && mode === 'create') {
          return false;
        }
        const data = await fetchFunc({
          url,
          method: 'get',
        });
        setData(data);
        setIsUnverified(!!data.sysVerifyInfoDraft);
        const {templateDocName, templateDocType, templateDocUuid} = data;
        setEcmDocData({
          templateDocType,
          templateDocUuid,
          templateDocName,
        });
        data.catalogId && dispatch(setCurrentCatalog(data.catalogId));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // const getCatalogRecordFiles = async () => {
  //   try {
  //     const data = await fetchFunc({
  //       url: `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}/file`,
  //       method: 'get',
  //     });
  //     setFiles(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const saveRecord = async (newData) => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items/${detailFieldUuid}${
          isUnverified ? '/unverified' : ''
        }`;
      }
      const data = await fetchFunc(
        {
          url,
          method: 'put',
          data: {fields: newData},
        },
        onError
      );
      dispatch(setChangedDetailName(detailUuid));
      dispatch(setChangedDetailField(data));
      dispatch(setChangedDetailMode('edit'));
      if (onCompositeModalSubmit) {
        onCompositeModalSubmit(data);
      }
      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status !== 'approved') {
        const recordUuidFromPath = match.params && match.params.id;
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${recordUuidFromPath || recordUuid}/verify`);
        } else {
          history.push(`/catalog/${referenceOriginLocal}/${recordUuidFromPath || recordUuid}/verify`);
        }
      } else {
        setData(data);
        setEditMode(false);
      }
      if (closeModal) closeModal();
      dispatch(setRecordLoading(false));
    } catch (error) {
      dispatch(setRecordLoading(false));
    }
  };

  const createRecord = async (newData) => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/items`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items${
          isUnverified ? '/unverified' : ''
        }`;
      }
      const data = await fetchFunc(
        {
          url,
          method: 'post',
          data: {fields: newData},
        },
        onError
      );
      dispatch(setChangedDetailName(detailUuid));
      dispatch(setChangedDetailField(data));
      dispatch(setChangedDetailMode('create'));
      if (onCompositeModalSubmit) {
        onCompositeModalSubmit(data);
        closeModal();
      }
      if (detailUuid && mode !== 'create') {
        await getCatalogRecordFields();
        await getCatalogRecordItems();
      }
      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status !== 'approved') {
        const recordUuidFromPath = match.params && match.params.id;
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${detailUuid ? recordUuidFromPath || recordUuid : data.uuid}/verify`);
        } else {
          history.push(
            `/catalog/${referenceOriginLocal}/${detailUuid ? recordUuidFromPath || recordUuid : data.uuid}/verify`
          );
        }
      } else {
        getCatalogItem();
      }
      closeModal && closeModal();
      dispatch(setRecordLoading(false));
    } catch (error) {
      dispatch(setRecordLoading(false));
    }
  };

  const deleteRecord = async () => {
    try {
      let url = `/api/v1/catalogs/${referenceOriginLocal}/items/${recordUuid}`;
      if (detailUuid) {
        url = `/api/v1/catalogs/${referenceOriginLocal}/detail/${detailUuid}/${recordUuid}/items/${detailFieldUuid}${
          isUnverified ? '/unverified' : ''
        }`;
      }
      await fetchFunc(
        {
          url,
          method: 'delete',
        },
        onError
      );
      dispatch(setChangedDetailName(detailUuid));
      dispatch(setChangedDetailField(data));
      dispatch(setChangedDetailMode('delete'));
      if (onCompositeModalSubmit) {
        onCompositeModalSubmit(data, 'delete');
        dispatch(setChangedDetailName(detailUuid));
        closeModal();
      }
      if (validateCatalog) {
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${recordUuid}/verify`);
        } else {
          history.push(`/catalog/${referenceOriginLocal}/${recordUuid}/verify`);
        }
      } else if (detailUuid) {
        await getCatalogRecordFields();
        getCatalogItem();
        closeModal();
      } else {
        history.push(`/catalog/${referenceOriginLocal}`);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const restoreRecord = async () => {
    let urlRollBack = `/api/v1/catalogs/${catalogUuid}/item/${recordUuid}/history/${versionId}/undo`;
    try {
      const data = await fetchFunc(
        {
          url:
            type === 'deleted'
              ? `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/history/${versionId}/restore`
              : urlRollBack,
          method: 'post',
        },
        onError,
        'mdm'
      );
      versionKey === 'version' && setData(data);
      !data.sysVerifyInfo && onRestore();
      if (versionKey === 'version') history.push(`/catalog/${catalogUuid}/${recordUuid}`);
      if (data.sysVerifyInfo && data.sysVerifyInfo.status && data.sysVerifyInfo.status === 'draft') {
        if (referenceOriginLocal === 'org') {
          history.push(`/catalog/organisations/${data.uuid}/verify`);
        } else {
          history.push(`/catalog/${referenceOriginLocal}/${data.uuid}/verify`);
        }
      } else history.push(`/catalog/${catalogUuid}/${recordUuid}`);
    } catch (error) {}
  };

  const changeRecord = (values) => {
    if (renderCustomTable) values = {...values, ...ecmDocData};
    values = normalizeValues({values, fields, type: 'recordRedactor'});
    dispatch(setRecordLoading(true));
    if (mode === 'create') {
      createRecord(values).then();
    } else {
      saveRecord(values).then();
    }
  };

  const handleEditMode = () => {
    const editableFlags = fields.map(
      (item) =>
        _.isNull(item.options) ||
        (item.options &&
          (item.options.gui_editable ||
            _.isNull(item.options.gui_editable) ||
            _.isUndefined(item.options.gui_editable))) ||
        item?.accessReadOnly
    );
    if (editableFlags.some((item) => _.isUndefined(item) || _.isNull(item) || item === true)) {
      setEditMode(true);
    } else {
      warningModalCreate('', 'Отсутствуют поля, доступные для редактирования. Обратитесь к администратору');
    }
  };

  const findVersionsByDate = (values) => {
    setVersionDate(values.date && values.date.format(backendDateFormat));
    setShowVersionModal(true);
  };
  return (
    <Card loading={loading} bordered={false}>
      {!detailUuid && mode !== 'create' && data && (
        <SystemInformation
          data={!isEmpty(data) && data}
          referenceOriginLocal={referenceOriginLocal}
          recordUuid={recordUuid}
          validateCatalog={validateCatalog}
        />
      )}
      {!versionId && !detailUuid && mode !== 'create' && referenceOriginLocal !== 'org' && (
        <VersionsByDateForm onFinish={findVersionsByDate} />
      )}
      {!versionId &&
        mode !== 'create' &&
        ((detailUuid && isEdit) || (editSigns && editSigns.editCatalog && editSigns.inputType !== 'external')) && (
          <div className="d-flex my-3">
            {!data?.sysVerifyInfoDraft &&
              (editMode ? (
                <>
                  <Button onClick={() => setEditMode(false)} className="ml-1 ml-auto">
                    Отменить
                  </Button>
                </>
              ) : (
                <Button type="primary" className="ml-auto" onClick={() => handleEditMode()}>
                  Изменить
                </Button>
              ))}
            {!data?.sysVerifyInfoDraft && (
              <>
                {!detailUuid && !editMode && (
                  <Button
                    className="ml-2"
                    onClick={() => {
                      if (files && files.content && files.content.length)
                        setFilesForSign({
                          ...filesForSign,
                          modal: true,
                        });
                      else setShowSignModal(true);
                    }}
                  >
                    Подписать
                  </Button>
                )}
                <Button
                  type="danger"
                  onClick={() =>
                    showDeleteConfirm({
                      content: 'Вы действительно хотите удалить запись справочника?',
                      onOk: () => deleteRecord(),
                      okText: mdm.confirm,
                      cancelText: mdm.cancel,
                    })
                  }
                  className="ml-1"
                >
                  {mdm.delete}
                </Button>
              </>
            )}
          </div>
        )}
      <div className="d-flex my-3 justify-content-end align-items-center">
        {(type === 'deleted' || (versionKey === 'version' && !data.active)) && (
          <>
            {data?.sysVerifyInfoDraft && (
              <Tooltip title="Запись уже находится на верификации">
                <ExclamationPoint style={{cursor: 'pointer'}} className="mr-2" />
              </Tooltip>
            )}
            <Button disabled={data?.sysVerifyInfoDraft} type="primary" onClick={restoreRecord}>
              Восстановить
            </Button>
          </>
        )}
      </div>
      {data && fields && !editMode ? (
        <RecordReader
          fieldsMeta={fields}
          data={!isEmpty(data) && data}
          referenceOrigin={referenceOriginLocal}
          referenceUuid={referenceUuid}
          recordUuid={!isEmpty(recordUuid) && recordUuid}
          detailFieldUuid={detailFieldUuid}
          mode={mode}
          detailUuid={detailUuid}
          rootValues={rootValues}
          maxRefsDepth={refDepth}
          renderCustomTable={renderCustomTable}
          structure={structure}
          isUnverified={isUnverified}
          versionId={versionId}
        />
      ) : (
        <FormInitiator
          formName={'record-form'}
          hiddenFields={hiddenFields}
          fieldsMeta={fields}
          data={mode === 'create' && compositeInitialValue ? compositeInitialValue : data}
          changeRecord={changeRecord}
          fixedSubmitButton={fixedSubmitButton}
          submitButtonPosition={submitButtonPosition}
          children={(form) => (
            <RecordEditor
              form={form}
              validateCatalog={validateCatalog}
              fieldsMeta={getFieldsMeta({
                columns: fields,
                rule: renderCustomTable,
              })}
              data={!isEmpty(data) && data}
              mode={mode}
              isEdit={isBoolean(isEdit) ? isEdit : editMode}
              referenceOrigin={referenceOriginLocal}
              recordUuid={recordUuid}
              detailUuid={detailUuid}
              detailFieldUuid={detailFieldUuid}
              setHiddenFields={setHiddenFields}
              hiddenFields={hiddenFields}
              rootValues={rootValues}
              rootForm={rootForm}
              maxRefsDepth={refDepth}
              renderCustomTable={renderCustomTable}
              ecmDocData={ecmDocData}
              setEcmDocData={setEcmDocData}
              structure={structure}
              isUnverified={isUnverified}
              versionId={versionId}
            />
          )}
        />
      )}
      <Modal
        title={'Просмотр версий на дату'}
        open={showVersionModal}
        footer={null}
        onCancel={() => setShowVersionModal(false)}
        destroyOnClose={true}
        width={800}
      >
        <Versions
          catalogUuid={catalogUuid}
          recordUuid={recordUuid}
          setVersionKey={setVersionKey}
          versionKey={versionKey}
          versionDate={versionDate}
        />
      </Modal>
      <SignDocModal
        showSignModal={showSignModal}
        setShowSignModal={setShowSignModal}
        mainFetchProps={{docUuid: recordUuid, docTypeIdentifier: catalogUuid}}
        filesForSign={filesForSign.files}
      />
      <Modal
        title="Выберите файлы для подписания"
        open={filesForSign.modal}
        width={700}
        onOk={async () => {
          setShowSignModal(true);
          setFilesForSign({
            ...filesForSign,
            modal: false,
          });
        }}
        onCancel={() =>
          setFilesForSign({
            ...filesForSign,
            modal: false,
          })
        }
      >
        <Table
          columns={[
            {
              title: 'Наименование',
              dataIndex: 'name',
              sorter: (a, b) => {
                let nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              },
            },
            {
              title: 'Метки',
              dataIndex: 'labels',
              render: (text) => <span>{text && text.join(', ')}</span>,
            },
            {
              title: 'Дата загрузки',
              dataIndex: 'createDate',
              sorter: (a, b) => a.date > b.date,
            },
          ]}
          dataSource={files.content}
          rowKey={(record) => record.uuid}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setFilesForSign({
                ...filesForSign,
                files: [...selectedRowKeys],
              });
            },
          }}
          pagination={{
            showSizeChanger: false,
            total: files.totalElements,
          }}
        />
      </Modal>
    </Card>
  );
};

export default React.memo(RecordRedactor);
