import React from 'react';
import { renderTableDate } from '../../../components/Helpers/Utils';

export const documentRegistryColumns = [
    {
        title: 'Наименование реестра',
        dataIndex: 'caption',
        render: (text, record) => {
            const { registryTypeIdentifier, uuid } = record;
            const { REACT_APP_ECM_FRONT_URL } = window._env;
            return <a
                href={`${REACT_APP_ECM_FRONT_URL}/registry/${registryTypeIdentifier && registryTypeIdentifier.registryTypeIdentifier}.${uuid}`}
                target={'_blank'}
            >
                {text}
            </a>
        }
    },
    {
        title: 'Дата создания',
        dataIndex: 'createDate',
        render: text => renderTableDate(text)
    },
    {
        title: 'Автор реестра',
        dataIndex: 'authorFio'
    },
    {
        title: 'Статус реестра',
        dataIndex: ['status', 'caption']
    },
];

export const registryFilterFields = [
    {
        caption: "Наименование реестра",
        origin: 'caption',
        fieldType: 'string'
    },
    {
        caption: "Дата создания",
        origin: 'createDate',
        fieldType: 'datePicker'
    },
    {
        caption: "Статус реестра",
        origin: 'status.id',
        fieldType: 'reference',
        fetchOptions: {
            store: 'ecm',
            url: '/api/v1/statuses',
            method: 'get',
            displayedField: 'caption',
            valueField: 'id',
        }
    },
];

export const documentColumns = [
    {
        title: 'Наименование',
        dataIndex: 'caption',
        render: (text, record) => {
            const { documentTypeIdentifier, uuid } = record;
            const { REACT_APP_ECM_FRONT_URL } = window._env;
            return <a
                href={`${REACT_APP_ECM_FRONT_URL}/document/${documentTypeIdentifier.documentTypeIdentifier}.${uuid}`}
                target={'_blank'}
            >
                {text}
            </a>
        }
    },
    {
        title: 'Тип документа',
        dataIndex: ['documentTypeIdentifier', 'caption']
    },
    {
        title: 'Дата создания',
        dataIndex: 'createDate',
        render: text => renderTableDate(text)
    },
    {
        title: 'Автор документа',
        dataIndex: 'authorFio'
    },
    {
        title: 'Статус документа',
        dataIndex: ['status', 'caption']
    },
]

export const documentFilterFields = [
    {
        caption: "Наименование",
        origin: 'caption',
        fieldType: 'string'
    },
    {
        caption: "Типы документов",
        origin: 'documentTypeIdentifier.documentTypeIdentifier',
        fieldType: 'reference',
        fetchOptions: {
            store: 'ecm',
            url: '/api/v1/document-type',
            method: 'get',
            displayedField: 'caption',
            valueField: 'origin',
        }
    },
    {
        caption: "На дату",
        origin: 'createDate',
        fieldType: 'datePicker'
    },
    {
        caption: "Статус документа",
        origin: 'status.id',
        fieldType: 'reference',
        fetchOptions: {
            store: 'ecm',
            url: '/api/v1/statuses',
            method: 'get',
            displayedField: 'caption',
            valueField: 'id',
        }
    },
]

export const serviceColumns = [
    {
        title: 'Наименование процесса',
        dataIndex: 'name',
    },
    {
        title: 'Номер',
        dataIndex: 'number',
    },
    {
        title: 'Текущий статус процесса',
        dataIndex: 'processStatusName',
    },
    {
        title: 'Дата создания',
        dataIndex: 'createDate',
        render: text => renderTableDate(text)
    },
    {
        title: 'Дата изменения',
        dataIndex: 'lastModifyDate',
        render: text => renderTableDate(text)
    },
];

export const serviceFilterFields = [
    {
        caption: "Наименование",
        origin: 'name',
        fieldType: 'string'
    },
    {
        caption: "Номер",
        origin: 'number',
        fieldType: 'string'
    },
]
