import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_TEMPLATE,
  EDIT_NOTIFICATION_TEMPLATE,
  LOADING_NOTIFICATION_LIST,
  LOADING_NOTIFICATION_TEMPLATES,
  SEND_SUCCESS_MESSAGE,
  SET_EMAILS_FOR_TEST,
  SET_FILTERED_SUBSCRIPTION,
  SET_NOTIFICATION_ACTIVATE,
  SET_NOTIFICATION_DEACTIVATE,
  SET_NOTIFICATION_ID,
  SET_NOTIFICATION_LIST,
  SET_NOTIFICATION_SUBSCRIPTION_LIST,
  SET_NOTIFICATION_TEMPLATE,
  SET_NOTIFICATION_TEMPLATE_LIST,
  SET_PHONE_NUMBER_FOR_TEST,
} from '../actions/actionTypes';
import {isEmpty} from 'lodash';

export const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notificationList: [action.payload, ...state.notificationList],
      };
    case SEND_SUCCESS_MESSAGE:
      return {
        ...state,
        notificationTestSuccess: action.payload,
      };
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case SET_NOTIFICATION_ID:
      return {
        ...state,
        notificationId: action.payload,
      };
    case SET_NOTIFICATION_ACTIVATE:
      return {
        ...state,
        notificationList: [
          ...state.notificationList?.map((item) => {
            if (item.id === action.payload.notifyConditionId) {
              return {...item, active: !item.active};
            }
            return item;
          }),
        ],
      };
    case SET_NOTIFICATION_DEACTIVATE:
      return {
        ...state,
        notificationList: [
          ...state.notificationList?.map((item) => {
            if (item.id === action.payload.notifyConditionId) {
              return {...item, active: !item.active};
            }
            return item;
          }),
        ],
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationList: [...state.notificationList?.filter((notification) => notification.id !== action.payload)],
      };
    case LOADING_NOTIFICATION_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADING_NOTIFICATION_TEMPLATES:
      return {
        ...state,
        loadingTemplates: action.payload,
      };
    case SET_NOTIFICATION_TEMPLATE_LIST:
      return {
        ...state,
        notificationTemplateList: action.payload,
      };
    case SET_NOTIFICATION_SUBSCRIPTION_LIST:
      return {
        ...state,
        notificationSubscriptionList: action.payload,
      };
    case DELETE_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplateList: [
          ...state.notificationTemplateList?.filter((template) => template.uuid !== action.payload),
        ],
      };
    case SET_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplate: action.payload,
      };
    case ADD_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplateList: [action.payload, ...state.notificationTemplateList],
      };
    case EDIT_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplateList: !isEmpty(state?.notificationTemplateList) && [
          ...state?.notificationTemplateList?.map((item) => {
            if (item.uuid === action.payload.uuid) {
              return {...action.payload.value};
            }
            return item;
          }),
        ],
      };
    case SET_FILTERED_SUBSCRIPTION:
      return {
        ...state,
        notificationEventList: action.payload,
      };
    case SET_EMAILS_FOR_TEST:
      return {
        ...state,
        notificationEmailsForTest: action.payload,
      };
    case SET_PHONE_NUMBER_FOR_TEST:
      return {
        ...state,
        notificationPhoneNumberForTest: action.payload,
      };
    default:
      return state;
  }
};
