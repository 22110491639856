import React from 'react';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {Router} from 'react-router';
import Keycloak from 'keycloak-js';
import 'antd/dist/antd.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import 'dayjs/locale/ru';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from 'styled-components';

import App from './modules/App';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import configureStore from './redux/store/configureStore';
import {defaultTheme} from './components/Themes/defaultTheme';
import {setUserRoles} from './redux/actions/actions';
import HeaderMdm from './components/Header/HeaderMDM';

import './index.scss';

const history = createBrowserHistory();

dayjs.locale('ru');

window._env = Object.assign(window._env, process.env);

console.log('process.env', process.env);
console.log('window._env', window._env);

export const keycloak = new Keycloak({
  url: `${window._env.REACT_APP_SSO_CONFIG_URL}`,
  realm: `${window._env.REACT_APP_SSO_CONFIG_REALM}`,
  clientId: `${window._env.REACT_APP_CLIENT_ID}`,
});

const store = configureStore;
keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframeInterval: 100,
  })
  .success(() => {
    if (keycloak.authenticated) {
      const {
        idTokenParsed: {sub},
        realmAccess: {roles},
        token,
      } = keycloak;

      window.sessionStorage.setItem('kctoken', `${token}`);

      store.dispatch(setUserRoles(roles));

      render(App, token, sub);
    } else {
      console.error('authenticated=false');
    }
  })
  .error(() => {});

export function keycloakLogout() {
  keycloak.logout().success(() => window.sessionStorage.removeItem('kctoken'));
}
function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConfigProvider locale={ruRU}>
          <Router history={history}>
            <HeaderMdm keycloakLogout={keycloakLogout} />
            <ThemeProvider theme={defaultTheme}>
              <App />
            </ThemeProvider>
          </Router>
        </ConfigProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('react-container')
  );
}
