import {Button, Form, Select} from 'antd';
import React, {useState} from 'react';

import {notifications} from '../../../components/NotificationPage/helpers/constant';
import {notificationPage} from '../helpers/constant';

import './NotificationPageFilters.scss';

/**
 * Фильтр уведомлений
 * @param setFilter - функция сеттер фильтра
 * @param notification - ключ для фильтра уведомлений
 * @param templates - ключ для фильтра шаблона
 * @param captionNotificationFilter - данные для фильтра наименование
 * @param captionCatalogNameFilter - данные для фильтра подписка
 * @param captionTemplateNotificationFilter - данные для фильтра шаблон
 * @param nameTemplateFilter - данные для фильтра название шаблона
 * @param methodsTemplateFilter - данные для фильтра способ отправки
 */
const NotificationPageFilters = ({
  setFilter,
  notification,
  templates,
  captionNotificationFilter,
  captionCatalogNameFilter,
  captionTemplateNotificationFilter,
  nameTemplateFilter,
  methodsTemplateFilter,
}) => {
  /** Получение данных с формы */
  const [form] = Form.useForm();
  /** Значение фильтра */
  const [filterValue, setFilterValue] = useState({
    title: null,
    template: null,
    subscription: null,
    titleTemplate: null,
    methodTemplate: null,
  });
  /** Изменение значений фильтра */
  const onChangeFilter = (field, value) => {
    switch (field) {
      case 'title':
        setFilterValue({...filterValue, title: `${value}`});
        break;
      case 'template':
        setFilterValue({...filterValue, template: `${value}`});
        break;
      case 'subscription':
        setFilterValue({...filterValue, subscription: `${value}`});
        break;
      case 'titleTemplate':
        setFilterValue({...filterValue, titleTemplate: `${value}`});
        break;
      case 'methodTemplate':
        setFilterValue({...filterValue, methodTemplate: `${value}`});
        break;
      default:
        break;
    }
  };
  /** Изменение значений фильтра - Наименование */
  const handleOnChangeName = (event) => onChangeFilter('title', event);
  /** Изменение значений фильтра - Шаблоны */
  const handleOnChangeTypeNotification = (event) => onChangeFilter('template', event);
  /** Изменение значений фильтра - Справочник-подписка */
  const handleOnChangeSubscription = (event) => onChangeFilter('subscription', event);
  /** Изменение значений фильтра - Название шаблона */
  const handleOnChangeTitleTemplate = (event) => onChangeFilter('titleTemplate', event);
  /** Изменение значений фильтра - Способ отправки */
  const handleOnChangeMethodTemplate = (event) => onChangeFilter('methodTemplate', event);
  /** Изменение значений фильтра при подтверждении  */
  const handleSubmit = () => {
    setFilter({
      ...filterValue,
      title: filterValue.title,
      template: filterValue.template,
      subscription: filterValue.subscription,
      titleTemplate: filterValue.titleTemplate,
      methodTemplate: filterValue.methodTemplate,
    });
  };

  return (
    <Form className="notificationFilters" form={form} name="notificationFilters" onFinish={handleSubmit}>
      <Form.Item name="title" className="notificationFilters__name-filter" hidden={templates}>
        <Select
          placeholder={notificationPage.tableName}
          filterOption={true}
          onChange={handleOnChangeName}
          value={filterValue.title}
          options={captionNotificationFilter}
          showSearch
        />
      </Form.Item>
      <Form.Item name="template" className="notificationFilters__type-filter" hidden={templates}>
        <Select
          placeholder={notificationPage.template}
          filterOption={true}
          onChange={handleOnChangeTypeNotification}
          value={filterValue.template}
          options={captionTemplateNotificationFilter}
          showSearch
        />
      </Form.Item>
      <Form.Item name="subscription" className="notificationFilters__subscription-filter" hidden={templates}>
        <Select
          placeholder={notificationPage.subscription}
          onChange={handleOnChangeSubscription}
          filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
          value={filterValue.subscription}
          options={captionCatalogNameFilter}
          showSearch
        />
      </Form.Item>
      <Form.Item name="titleTemplate" className="notificationFilters__subscription-filter" hidden={notification}>
        <Select
          placeholder={notificationPage.tableName}
          onChange={handleOnChangeTitleTemplate}
          filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
          value={filterValue.subscription}
          options={nameTemplateFilter}
          showSearch
        />
      </Form.Item>
      <Form.Item name="methodTemplate" className="notificationFilters__subscription-filter" hidden={notification}>
        <Select
          placeholder={notificationPage.methods}
          onChange={handleOnChangeMethodTemplate}
          filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
          value={filterValue.subscription}
          options={methodsTemplateFilter}
          showSearch
        />
      </Form.Item>
      <div className="notificationFilters__button">
        <Button
          className="deduplicationFilters__button"
          type="danger"
          size="middle"
          onClick={() => {
            form.resetFields();
            setFilterValue({
              title: null,
              template: null,
              subscription: null,
              titleTemplate: null,
              methodTemplate: null,
            });
            setFilter({
              title: null,
              template: null,
              subscription: null,
              titleTemplate: null,
              methodTemplate: null,
            });
          }}
        >
          {notifications.cancel}
        </Button>

        <Button type="primary" size="middle" htmlType="submit" className="deduplicationFilters__button-two">
          {notifications.save}
        </Button>
      </div>
    </Form>
  );
};
export default React.memo(NotificationPageFilters);
