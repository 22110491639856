import React from 'react'
import { Card } from 'antd'
import { CatalogsExtractor } from "mdm-lib";
import security from "../../../../Utils/security";
import { fileDownloadBtn } from "../../../../components/Helpers/FileDownloader";
import {convertDate, optionsExtractor} from "../../../../components/Helpers/Utils";
const { getToken } = security;


const RegProduct = ({ serviceId, data, meta }) => {

    return (
        <Card
            title='Регламент продукта'
            className={'container--white mb-5'}
        >
            <div className='d-flex mt-3 mb-3 align-items-center'>
                <div className='mr-2 font-weight-bold'>Файл с комментариями:</div>
                {data.reglamentFile && fileDownloadBtn({catalogIdentifier: 'service', uuid: serviceId, fileUuid: data.reglamentFile})}
            </div>
            <div className='d-flex mb-3'>
                <div className='mr-2 font-weight-bold'>Сотрудник, выполнивший подготовку:</div>
                <div>
                    <CatalogsExtractor
                        token={getToken()}
                        catalog={'user_profile'}
                        uuid = {data.reglamentUserId && data.reglamentUserId.uuid}
                        render = {
                            (value) => value &&
                                <a
                                    target='_blank'
                                    href={`/catalog/user_profile/${value.uuid}`}
                                >
                                    {`${value && value.lastName} ${value && value.firstName} ${value && value.middleName}`}
                                </a>
                        }
                    />
                </div>
            </div>
            <div className='d-flex mb-3'>
                <div className='mr-2 font-weight-bold'>Результат:</div>
                <div>
                    {data.reglamentResult && optionsExtractor(data.reglamentResult, meta, 'reglamentResult')}
                </div>
            </div>
            <div className='d-flex mb-3'>
                <div className='mr-2 font-weight-bold'>Комментарий:</div>
                <div>
                    {data.reglamentComment}
                </div>
            </div>
            <div className='d-flex mb-3'>
                <div className='mr-2 font-weight-bold'>Дата выполнения:</div>
                <div>{convertDate(data.reglamentStageEndDate)}</div>
            </div>
        </Card>
    )
}

export default RegProduct
