import React from 'react';
import {convertDate} from '../Helpers/Utils';
import {BaseDiv, StyledSpan} from '../Themes/Components';

const HeadCatalog = ({description, authorFio, uuid, createDate, status}) => {
  return (
    <BaseDiv sName="containerWhite" className="container">
      <BaseDiv className="documents__title mb-3">
        <BaseDiv className="d-flex mt-3">
          <BaseDiv className="col-9 documents__info-title">{description}</BaseDiv>
          <BaseDiv className="col-3 documents__info-status text-right">{status}</BaseDiv>
        </BaseDiv>
      </BaseDiv>
      <BaseDiv sName="documentsInfo">
        <BaseDiv className="d-flex mt-3">
          <BaseDiv className="col-8">
            <BaseDiv className="mt-2">
              <StyledSpan sName="documentsInfoLabel" className="mr-2">
                Идентификатор:
              </StyledSpan>
              {uuid}
            </BaseDiv>
            <BaseDiv>
              <StyledSpan sName="documentsInfoLabel" className="mr-2">
                Дата создания:
              </StyledSpan>
              {convertDate(createDate)}
            </BaseDiv>
            <BaseDiv>
              <StyledSpan sName="documentsInfoLabel" className="mr-2">
                Автор:
              </StyledSpan>
              {authorFio}
            </BaseDiv>
          </BaseDiv>
        </BaseDiv>
      </BaseDiv>
    </BaseDiv>
  );
};

export default HeadCatalog;
