import React, {Component} from 'react';
import _, {isEmpty, isString} from 'lodash';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

import {
  BaseDiv,
  StyledButton,
  StyledSelect,
  StyledInput,
  StyledCheckbox,
  StyledSelectOption,
} from '../../Themes/Components';
import {fetchFunc} from '../../../Utils/security/http/mdm';
import {errorModalCreate} from '../../Helpers/Modals';
import {mdm} from '../../../Utils/constant';

import './Attributes.scss';

class Attributes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAttrEdit: false,
      data: [],
      defaultData: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({data: this.props.data});
    }
  }

  editAttributes = async () => {
    try {
      const {uuid, detailUuid} = this.props;
      let data = this.makeBody();
      if (!detailUuid && data.options) {
        data.options = isString(data.options) ? JSON.parse(data.options) : data.options;
      }
      await fetchFunc({
        url: '/api/v1/catalog-draft/' + uuid + (detailUuid ? '/fields/' + detailUuid : ''),
        method: 'put',
        data,
      });
      this.props.onSave();
      this.setState({isAttrEdit: false});
    } catch (error) {
      errorModalCreate('Неверный JSON');
    }
  };

  makeBody = () => {
    const {detailUuid, operation} = this.props;
    const {data} = this.state;
    let body = {};
    data.forEach((item, index) => {
      if (detailUuid) {
        body.fieldOrder = index * 100;
        body.operation = operation;
        if (item.origin.split('.')[0] === 'options' && item.value) {
          body.options = {
            ...body.options,
            [item.origin.split('.')[1]]: item.value,
          };
        } else {
          body[item.origin] = item.value;
        }
      } else {
        if (item.origin === 'options') {
          if (!_.isEmpty(item.value)) body[item.origin] = item.value;
        } else {
          body[item.origin] = item.value;
        }
      }
    });

    return body;
  };

  changeMode = () => {
    const {data} = this.state;
    let defaultData = _.cloneDeep(data);
    this.setState({isAttrEdit: true, defaultData});
  };

  onCancel = () => {
    this.setState({data: this.state.defaultData, isAttrEdit: false});
  };

  handleInputChange = (e, index, type) => {
    const {data} = this.state;
    let newData = data;
    switch (type) {
      case 'input':
        newData[index].value = e.target.value;
        break;
      case 'checkbox':
        newData[index].value = e.target.checked;
        break;
      case 'select':
        newData[index].value = e;
        break;
      default:
        newData[index].value = e.target.value;
    }
    this.setState({data: newData});
  };

  renderAttributes = (data) => {
    const {uniqueFields} = this.props;
    const {isAttrEdit} = this.state;
    return (
      <>
        {data.map((item, index) => (
          <BaseDiv key={index} className="documents__info-item col-12 mb-5">
            <BaseDiv className="documents__info-label">
              <strong>{item.caption}:</strong>
            </BaseDiv>
            {this.renderFields(item, index)}
          </BaseDiv>
        ))}
        {!isEmpty(uniqueFields) && !isAttrEdit && (
          <BaseDiv>
            <strong> {mdm.uniqueFields}</strong>
            <div className="attributes-unique">{this.props.uniqueFields}</div>
          </BaseDiv>
        )}
      </>
    );
  };

  renderFields = (item, index) => {
    switch (item.type) {
      case 'String':
        return this.renderInput(item.value, index);
      case 'Checkbox':
        return this.renderCheckbox(item.value, index, item.disabled);
      case 'Select':
        return this.renderSelect(item.value, index, item.origin);
      default:
        return this.renderInput(item.value, index);
    }
  };

  renderInput = (value, index) => (
    <>
      {this.state.isAttrEdit ? (
        <StyledInput value={value} onChange={(e) => this.handleInputChange(e, index, 'input')} />
      ) : (
        value || '-'
      )}
    </>
  );

  renderCheckbox = (value, index, disabled) => (
    <>
      {this.state.isAttrEdit && !disabled ? (
        <StyledCheckbox checked={value} onChange={(e) => this.handleInputChange(e, index, 'checkbox')} />
      ) : value ? (
        <CheckOutlined />
      ) : (
        <CloseOutlined />
      )}
    </>
  );

  renderSelect = (value, index, origin) => {
    let options = this.props.options.map((item, index) => (
      <StyledSelectOption key={index} value={item.value}>
        {item.label}
      </StyledSelectOption>
    ));
    let valueItem = this.props.options.find((elem) => elem.value === value);

    return (
      <>
        {this.state.isAttrEdit ? (
          <StyledSelect
            disabled={origin === 'fieldTypeId'}
            style={{width: '100%'}}
            value={value}
            onChange={(value) => this.handleInputChange(value, index, 'select')}
          >
            {options}
          </StyledSelect>
        ) : valueItem ? (
          valueItem.label
        ) : (
          '-'
        )}
      </>
    );
  };

  render() {
    const {isAttrEdit, data} = this.state;
    const {loading, status} = this.props;
    return (
      <BaseDiv>
        <h5>{mdm.parameters}</h5>
        <BaseDiv className="d-flex mb-2">
          {isAttrEdit ? (
            <div className="ml-auto">
              <StyledButton sName="dangerBtn" type="danger" onClick={this.onCancel} style={{margin: '5px'}}>
                {mdm.cancel}
              </StyledButton>
              <StyledButton sName="primaryBtn" type="primary" onClick={this.editAttributes}>
                {mdm.save}
              </StyledButton>
            </div>
          ) : status === 'Черновик' ? (
            <StyledButton sName="defaultBtn" className="ml-auto" onClick={this.changeMode} loading={loading}>
              {mdm.change}
            </StyledButton>
          ) : (
            ''
          )}
        </BaseDiv>
        <BaseDiv className="d-flex flex-wrap" style={{width: 600}}>
          {!loading && this.renderAttributes(data)}
        </BaseDiv>
      </BaseDiv>
    );
  }
}

export default Attributes;
