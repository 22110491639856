import {baseStyles} from './Styles/baseStyles';
import {documentCard} from './Styles/documentCard';
import {bpmnModellerStyles} from './Styles/bpmnModeller';
import {filterStyles} from './Styles/filterStyles';

export const defaultTheme = {
  ...baseStyles,
  ...documentCard,
  ...bpmnModellerStyles,
  ...filterStyles,
};
