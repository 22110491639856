import React, { useState, useCallback } from 'react'
import { Form, Input, Button } from 'antd'
import MultiplySelection from '../MultiplyeSelection'
import { get } from 'lodash'

const columns = [
    {
        title: 'Идентификатор',
        dataIndex: 'id',
    },
    {
        title: 'Наименование',
        dataIndex: 'caption',
    },
    {
        title: 'Код',
        dataIndex: 'code',
    },
];

const buttonLayout = {
    wrapperCol: { span: 7, offset: 17 }
};

const validateMessages = {
    required: 'Поле обязательно для заполнения!',
};

const SubsystemForm = ({ onSubmit, editibleItem, onCancel }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedSystem, setSelectedSystem] = useState(editibleItem ? editibleItem.externalSystem : null);
    const [defaultSelected, setDefaultSelected] = useState(get(editibleItem, 'externalSystem.id') ? [editibleItem.externalSystem.id] : []);
    const [form] = Form.useForm();

    const initialValues = {
        caption: editibleItem && editibleItem.caption,
        code: editibleItem && editibleItem.code,
    };

    const onExternalSelect = (value, key) => {
        setModalVisible(false);
        setSelectedSystem(value[0]);
        form.setFieldsValue({ externalSystem: { id: value[0] && value[0].id } })
    };

    const clearSubsystem = () => {
        setSelectedSystem({});
        setDefaultSelected([]);
        form.setFieldsValue({ externalSystem: null })
    }

    return (
        <Form
            form={form}
            onFinish={(values) => onSubmit(values, editibleItem)}
            initialValues={initialValues}
            validateMessages={validateMessages}
        >
            <Form.Item label='Наименование' name='caption' rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label='Код' name='code' rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label='Внешняя система'>
                <Form.Item noStyle name='externalSystem'>
                    <Button onClick={useCallback(() => setModalVisible(true), [])}>Выбрать</Button>
                </Form.Item>
                <p className='ml-1 mb-0'>{selectedSystem && selectedSystem.caption}</p>
                {selectedSystem && <Button type='link' className='pl-1' onClick={clearSubsystem}>Очистить</Button>}
            </Form.Item>
            <Form.Item {...buttonLayout}>
                <Button type='primary' className='mr-1' htmlType='submit'>{editibleItem ? 'Обновить' : 'Создать'}</Button>
                <Button onClick={onCancel}>Отмена</Button>
            </Form.Item>
            <MultiplySelection
                onCancel={useCallback(() => setModalVisible(false), [])}
                onOk={useCallback(onExternalSelect, [])}
                visible={modalVisible}
                columns={columns}
                isSingleSelection={true}
                defaultSelected={defaultSelected}
                rowKey='id'
            />
        </Form>
    )
}

export default SubsystemForm
