import {fetchFunc} from '../../Utils/security/http/mdm';
import {onError} from '../../components/Helpers/Utils';
import {getFieldsTypeAC, getUsersAC, getUsersRolesAC, searchScheduleExecutionAC} from '../actions/actions';

/** Поиск расписаний  планировщика */
export const searchScheduleExecution = (execution) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/scheduler/execution?name_description_like=${execution}`,
        method: 'get',
        body: {name_description_like: execution},
      },
      onError,
      'scheduler'
    );
    dispatch(searchScheduleExecutionAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Поиск расписаний  планировщика */
export const searchScheduleTask = (task) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        url: `/api/v1/scheduler/job?name_description_like=${task}`,
        method: 'get',
        body: {name_description_like: task},
      },
      onError,
      'scheduler'
    );
    dispatch(searchScheduleExecutionAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка пользователей */
export const getUsers = (data) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        method: 'post',
        url: `/api/v1/catalogs/user_profile/items/search/extended?showDetails=0&showRefs=0`,
        data,
      },
      onError,
      'mdm'
    );
    dispatch(getUsersAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка типов полей */
export const getFieldsType = () => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        method: 'get',
        url: `/api/v1/field-type?size=50`,
      },
      onError,
      'apiServiceRo'
    );
    dispatch(getFieldsTypeAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
/** Получение списка ролей */
export const getUsersRoles = (data) => async (dispatch) => {
  try {
    const response = await fetchFunc(
      {
        method: 'post',
        url: `/api/v1/catalogs/user_role/items/search/extended?showDetails=0&showRefs=0`,
        data,
      },
      onError,
      'mdm'
    );
    dispatch(getUsersRolesAC(response.content));
  } catch (error) {
    throw new Error(error.message);
  }
};
