import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
import {Button, Checkbox, Spin} from 'antd';
import {FileZipTwoTone} from '@ant-design/icons';
import cn from 'classnames';

import {deduplication} from '../helpers/constants';
import {deduplicationJobsFilter} from '../helpers/deduplicationFilterUtils';
import {catalogFilter, jobTitleFilter} from '../helpers/DeduplicationUtils';
import {BaseDiv, StyledTable} from '../../../components/Themes/Components';
import DeduplicationFilters from '../DeduplicationFilters/DeduplicationFilters';
import {
  getDeduplicationProtocolDelete,
  getDeduplicationProtocolInfo,
  getDeduplicationProtocols,
  getDeduplicationUnarchivedProtocols,
} from '../../../redux/thunk/deduplicationThunk';

import './DeduplicationProtocols.scss';

/** Протокол дедупликации */
const DeduplicationProtocols = () => {
  /** Маршрутизатор */
  const history = useHistory();
  /** Отправка данных в хранилище */
  const dispatch = useDispatch();
  /** Получаем список всех протоколов */
  const protocolsDeduplication = useSelector((state) => state?.deduplication.deduplicationProtocols);
  /** Получаем список незаархивированных  протоколов */
  const deduplicationUnarchivedProtocols = useSelector(
    (state) => state?.deduplication.deduplicationUnarchivedProtocols
  );
  /** Получаем ключ для лоадера */
  const loading = useSelector((state) => state?.deduplication.loadingProtocols);
  const loadingUnarchivedProtocols = useSelector((state) => state?.deduplication.loadingUnarchivedProtocols);
  /** Начальное состояние фильтра */
  const [filter, setFilter] = useState({catalog: null, name: null, launchData: null});
  /** Количество протоколов для пагинации */
  const [countProtocols, setCountProtocols] = useState('');
  /** Состояние чекбокса */
  const [isChecked, setIsChecked] = useState(false);
  /** Получение списка протоколов дедупликации */
  useEffect(() => {
    isChecked ? dispatch(getDeduplicationProtocols()) : dispatch(getDeduplicationUnarchivedProtocols());
  }, [isChecked]);

  /** Фильтрация завершенных и не заархивированных протоколов */
  const isDataReportUnarchivedProtocols =
    !isEmpty(deduplicationUnarchivedProtocols) &&
    deduplicationUnarchivedProtocols?.filter((item) => (!isEmpty(item.dateReport) ? item : null));
  /** Фильтрация завершенных и  заархивированных протоколов */
  const isDataReportArchivedProtocols =
    !isEmpty(protocolsDeduplication) &&
    protocolsDeduplication?.filter((item) => (!isEmpty(item.dateReport) ? item : null));

  /** Функция для отображения отфильтрованной таблицы  */
  const filteringDeduplicationProtocolsList = deduplicationJobsFilter(
    isChecked ? isDataReportArchivedProtocols : isDataReportUnarchivedProtocols,
    filter
  );
  useEffect(() => {
    !isEmpty(isDataReportArchivedProtocols) && setCountProtocols(isDataReportArchivedProtocols?.length);
    !isEmpty(isDataReportUnarchivedProtocols) && setCountProtocols(isDataReportUnarchivedProtocols?.length);
    !isEmpty(filteringDeduplicationProtocolsList) && setCountProtocols(filteringDeduplicationProtocolsList?.length);
  }, [isDataReportArchivedProtocols, isDataReportUnarchivedProtocols, filteringDeduplicationProtocolsList]);
  /** Функция скрытия.отображения протоколов */
  const isVisibleDeletedProtocols = (event) => {
    setIsChecked(event.target.checked);
  };
  /** Колонки таблицы*/
  const columnsDeduplicationTableList = [
    {
      title: deduplication.nameJob,
      dataIndex: 'name',
      key: 1,
      render: (text) => <p>{text}</p>,
    },
    {
      title: deduplication.dateOfProtocol,
      dataIndex: 'dateReport',
      key: 2,
      render: (text) => <p>{!isEmpty(text) && dayjs(text).format('DD.MM.YYYY HH:mm')}</p>,
    },
    {
      title: deduplication.catalog,
      dataIndex: 'catalogDeduplicationName',
      key: 3,
      render: (text) => <p>{text}</p>,
    },
    {
      title: '',
      dataIndex: '',
      key: 4,
      render: (record) => (
        <Button
          type="link"
          className="deduplicationProtocols__open-protocol-btn"
          onClick={() => {
            dispatch(getDeduplicationProtocolInfo(record.uuid));
            history.push(`/deduplication/protocols/${record.uuid}`);
          }}
        >
          {deduplication.openProtocol}
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <FileZipTwoTone
          hidden={record.isHidden === true}
          className="deduplicationProtocols__open-protocol-btn"
          onClick={() => {
            dispatch(getDeduplicationProtocolDelete(record.uuid));
          }}
        />
      ),
    },
  ];
  return (
    <BaseDiv className="deduplicationProtocols">
      <BaseDiv className="deduplicationProtocols__title">{deduplication.titleForListProtocols}</BaseDiv>
      {loading === true || loadingUnarchivedProtocols === true ? (
        <>
          <DeduplicationFilters
            deduplicationProtocolsFilter={true}
            catalogTitle={catalogFilter(
              isChecked && !isEmpty(protocolsDeduplication)
                ? isDataReportArchivedProtocols
                : isDataReportUnarchivedProtocols
            )}
            jobTitleFilter={jobTitleFilter(isChecked ? isDataReportArchivedProtocols : isDataReportUnarchivedProtocols)}
            setFilter={setFilter}
          />
          <Checkbox className="deduplicationProtocols__checkbox" onChange={isVisibleDeletedProtocols}>
            {deduplication.labelCheckboxForDelete}
          </Checkbox>
          <StyledTable
            columns={columnsDeduplicationTableList}
            dataSource={
              (isDataReportArchivedProtocols || isDataReportUnarchivedProtocols) && filteringDeduplicationProtocolsList
            }
            rowKey={(record) => record.uuid}
            rowClassName={(record) =>
              cn('', {
                deduplicationProtocols__zip: record.isHidden === true,
              })
            }
            pagination={
              countProtocols >= 10 && {
                showSizeChanger: true,
              }
            }
            scroll={{x: true}}
          />
        </>
      ) : (
        <Spin />
      )}
    </BaseDiv>
  );
};

export default React.memo(DeduplicationProtocols);
