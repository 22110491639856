import React, {useEffect, useState} from 'react';
import ListWithAddForm from '../../components/ListWithAddForm/ListWithAddForm';
import useCRUD from '../../hooks/CRUD.hook';
import CreateLinkTypeForm from './CreateLinkTypeForm';

const apiUrl = '/api/v1/link-type';

const LinkTypes = () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'caption',
      sorter: true,
    },
    {
      title: 'Применим для справочников',
      dataIndex: 'availableForCatalogs',
      render: (text) => {
        if (text) {
          return text.map((item, index) => (
            <div>{`${item.catalogId.caption}${index < text.length - 1 ? ',' : ''}`}</div>
          ));
        } else return '';
      },
    },
    {
      title: 'Симметричный тип кросс-связи',
      dataIndex: ['symmetricLinkTypeId', 'caption'],
    },
    {
      title: 'Идентификатор',
      dataIndex: 'id',
    },
  ];
  const [editableItem, setEditableItem] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState('');
  const {getData, changeData, data, loading, total} = useCRUD({
    apiUrl,
    idField: 'id',
    additionalParamString: '/filter',
  });

  useEffect(() => {
    getData({page, size, params: {sort, caption: search}});
  }, [page, size, sort, search]);

  const changeSubsystem = async (values, editableItem) => {
    try {
      await changeData(values, editableItem);
      getData({page, size});
    } catch (error) {}
  };

  const handleTableChange = (paginator, filter, {field, order}) => {
    setPage(--paginator.current);
    setSize(paginator.pageSize);
    if (order) {
      setSort(`${field},${order === 'ascend' ? 'asc' : 'desc'}`);
    } else {
      setSort(null);
    }
  };

  return (
    <ListWithAddForm
      editableItem={editableItem}
      modalWidth={900}
      setEditibleItem={setEditableItem}
      changeData={changeSubsystem}
      handleTableChange={handleTableChange}
      tableConfig={{
        data,
        loading,
        columns,
        total,
      }}
      editCatalog
      isSearch={true}
      onSearch={setSearch}
      addModalTitle="тип связи"
      FormComponent={CreateLinkTypeForm}
    />
  );
};

export default LinkTypes;
