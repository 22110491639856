import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Button, Select, Table, Tooltip, Modal } from 'antd';
import { errorModalCreate, showDeleteConfirm } from '../../Helpers/Modals';
import { fetchFunc } from '../../../Utils/security/http/mdm';
import { DeleteOutlined, UnlockOutlined } from '@ant-design/icons';
import { isString } from 'lodash';
import AccessRights from "../../AccessRights";

const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18, offset: 6 },
};

const StatusMatrixForm = ({ onSubmit, onReset, editibleItem }) => {
    const [form] = Form.useForm();
    const [actionList, setActionList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [sysActionList, setSysActionList] = useState([]);
    const [accessRightsModal, showAccessRightsModal] = useState(false);
    const [editableItemId, setEditableItemId] = useState(null);

    useEffect(() => {
        getActionList();
        getStatusList();
        getSysActionList();
    }, []);

    const [actionTableData, setActionTableData] = useState([]);

    useEffect(() => {
        if (editibleItem && editibleItem.status) {
            setActionTableData(editibleItem.actions);
            delete editibleItem.actions;
            editibleItem.status = editibleItem.status.id;
            editibleItem.params = JSON.stringify(editibleItem.params);
            form.setFieldsValue(editibleItem);
        }
    }, [editibleItem]);

    const getActionList = async () => {
        try {
            const data = await fetchFunc({
                url: `/api/v1/actions`,
                params: {
                    active: true,
                    size: 100
                }
            });
            setActionList(data.content);
        } catch (error) { }
    };

    const getStatusList = async () => {
        try {
            const data = await fetchFunc({
                url: `/api/v1/statuses`,
                params: {
                    active: true,
                    size: 100
                }
            });
            setStatusList(data.content);
        } catch (error) { }
    };

    const getSysActionList = async () => {
        try {
            const data = await fetchFunc({
                url: `/api/v1/status-matrix/sysactions`
            });
            setSysActionList(data.content);
        } catch (error) { }
    };

    const setupListOptions = (store, valStore, nameStore) => {
        return store.map((item, index) => <Option
            key={`${nameStore}-${index}`}
            value={item[valStore]}
        >
            {item.caption}
        </Option>)
    };

    const statusOpt = setupListOptions(statusList, 'id', 'status');

    const actionTableColumns = [
        {
            title: 'Наименование',
            key: 'actionName',
            width: '20%',
            render: (text, record, index) => {
                return <Select
                    value={record.action && record.action.id || ''}
                    name={'action-' + index}
                    onSelect={(value) => handleActions(value, 'action', index)}
                    style={{ 'width': '100%' }}
                >
                    {setupListOptions(actionList, 'id', 'action-id')}
                </Select>
            }
        },
        {
            title: 'Статус',
            key: 'actionStatus',
            width: '20%',
            render: (text, record, index) => {
                return <Select
                    value={record.status && record.status.id || ''}
                    name={'status-' + index}
                    onSelect={(value) => handleActions(value, 'status', index)}
                    style={{ 'width': '100%' }}
                >
                    {statusOpt}
                </Select>
            }
        },
        {
            title: 'Функция на переходе',
            key: 'sysAction',
            width: '20%',
            render: (text, record, index) => {

                return <Select
                    value={record.sysAction || ''}
                    name={'sysAction-' + index}
                    onSelect={(value) => handleActions(value, 'sysAction', index)}
                    style={{ 'width': '100%' }}
                >
                    {setupListOptions(sysActionList, 'code', 'sys-action')}
                </Select>
            }
        },
        {
            title: 'Параметры',
            key: 'params',
            width: '25%',
            render: (text, record, index) => {
                return <Input.TextArea
                    value={isString(record.params) ? record.params : JSON.stringify(record.params)}
                    name={'params-' + index}
                    onChange={(e) => handleActions(e.target.value, 'params', index)}
                    style={{ 'width': '100%' }}
                />
            }
        },
        {
            title: 'Системное',
            key: 'system',
            width: '15%',
            render: (text, record, index) => {
                return <Checkbox
                    name={'system-' + index}
                    checked={record.system || false}
                    onChange={(e) => handleActions(e.target.checked, 'system', index)}
                />
            }
        },
        {
            title: '',
            width: '10%',
            key: 'actions',
            render: (text, record, index) => <div className='d-flex'>
                {record.id && <Tooltip title="Настройка доступа">
                    <UnlockOutlined onClick={() => editAccessRights(record.id)} />
                </Tooltip>}
                <DeleteOutlined
                    className='ml-2 mr-2'
                    onClick={() => showDeleteConfirm({
                        content: 'Вы действительно хотите удалить?',
                        onOk: () => deleteActionRow(index),
                    })}
                />
            </div>
        },
    ];

    const editAccessRights = async (id) => {
        showAccessRightsModal(true);
        setEditableItemId(id);
    }

    const handleActions = (value, store, index) => {
        let newData = [...actionTableData];
        if (!newData[index][store]) newData[index][store] = '';
        switch (store) {
            case 'params':
            case 'sysAction':
            case 'system':
                newData[index][store] = value;
                break;
            default: newData[index][store]['id'] = value;
        }
        setActionTableData(newData);
    };

    const deleteActionRow = (index) => {
        let newData = [...actionTableData];
        newData.splice(index, 1);
        setActionTableData(newData);
    };

    const addAction = () => {
        let newData = [...actionTableData];
        newData.push({
            action: { id: '', caption: '' },
            status: { id: '', caption: '' },
            sysAction: '',
            params: '',
            system: false
        });
        setActionTableData(newData);
    };

    const checkAndConvertActionsParams = (arr) => {
        let correctJson = true;
        let convertedArr = [];
        arr.forEach((item, index) => {
            try {
                convertedArr.push({
                    ...item,
                    params: item.params ? isString(item.params) ? JSON.parse(item.params) : item.params : {}
                })
            } catch (error) {
                correctJson = false;
                errorModalCreate(`Неверный JSON в строке ${index + 1} таблицы функций на переходе`);
            }
        })
        return { correctJson, convertedArr }
    }

    const onFinish = async (values) => {
        const { correctJson, convertedArr } = await checkAndConvertActionsParams(actionTableData);
        if (correctJson) {
            try {
                values.params = values.params ? JSON.parse(values.params) : {};
                values.entityType = "service";
                values.actions = convertedArr;
                values.status = {
                    id: values.status
                };
                onSubmit(values)
            } catch (error) {
                errorModalCreate('Неверный JSON для поля параметры');
            }
        }
    };

    return (<>
        <Form
            {...formItemLayout}
            form={form}
            onFinish={onFinish}
            initialValues={{
                params: ''
            }}
        >
            <Form.Item label="Статус" name='status' rules={[{ required: true }]}>
                <Select >
                    {statusOpt}
                </Select>
            </Form.Item>
            <Form.Item name='readonly' valuePropName='checked' {...formTailLayout}>
                <Checkbox>
                    Нередактируемый
                </Checkbox>
            </Form.Item>
            <Form.Item name='first' valuePropName='checked' {...formTailLayout}>
                <Checkbox name='first'>
                    Начальный
                </Checkbox>
            </Form.Item>
            <Form.Item name='archive' valuePropName='checked' {...formTailLayout}>
                <Checkbox name='archive'>
                    Архивный
                </Checkbox>
            </Form.Item>
            <Form.Item name='acting' valuePropName='checked' {...formTailLayout}>
                <Checkbox name='acting'>
                    Действующий
                </Checkbox>
            </Form.Item>
            <Form.Item label="Параметры" name='params'>
                <Input.TextArea />
            </Form.Item>
            <Table
                scroll={{ x: 1500 }}
                columns={actionTableColumns}
                dataSource={actionTableData}
                pagination={false}
                footer={() => <Button
                    onClick={addAction}
                    className='text-right'
                >
                    Добавить действие
                </Button>}
            />
            <Form.Item {...formTailLayout} className='text-right'>
                <Button className='mr-3' type="primary" htmlType='submit'>
                    Сохранить
                </Button>
                <Button type="danger" onClick={onReset}>
                    Отмена
                    </Button>
            </Form.Item>
        </Form>
        <Modal
            title={'Права доступа'}
            visible={accessRightsModal}
            footer={null}
            width={700}
            onCancel={() => showAccessRightsModal(false)}
            destroyOnClose
        >
            <AccessRights
                store='status-matrix-action'
                rulesRecordUuid={editableItemId}
            />
        </Modal>
    </>
    )
};

export default StatusMatrixForm;
