import React, { useState } from 'react';
import { Form, Input, InputNumber, Select, Checkbox, Button, Table } from 'antd';
import DynamicSelect from '../../components/FormElements/DynamicSelect';
import { forOwn, debounce } from "lodash";
import { renderTableSelect } from '../../components/Helpers/Utils';
import { fetchFunc } from '../../Utils/security/http/mdm';
import useCRUD from '../../hooks/CRUD.hook';
import { confirmModalCreate } from '../../components/Helpers/Modals';
import { useHistory } from 'react-router';

const renderBool = (text) => text ? 'Да' : 'Нет'

const recTypeOptions = [
    { key: 'individual', value: 'Индивидуальный предприниматель, физическое лицо' },
    { key: 'comm_org', value: 'Коммерческая организация' },
];

const CatalogsOrgForm = ({ onSubmit, onCancel }) => {

    const columns = [
        {
            title: 'Есть связанный контрагент',
            dataIndex: 'hasOrgLink',
            render: renderBool
        },
        {
            title: 'Нерезидент РФ',
            dataIndex: 'nonResidentRf',
            render: renderBool
        },
        {
            title: 'Страна',
            dataIndex: ['regCountry', 'caption'],
        },
        {
            title: 'Тип организации',
            dataIndex: 'recType',
            render: (text) => renderTableSelect(text, recTypeOptions)
        },
        {
            title: 'Полное наименование',
            dataIndex: 'description',
        },
        {
            title: 'Сокращенное наименование',
            dataIndex: 'caption',
        },
        {
            title: 'ОГРН',
            dataIndex: 'ogrn',
        },
        {
            title: 'ИНН',
            dataIndex: 'inn',
        },
        {
            key: 'button',
            fixed: 'right',
            render: (text, record) => <Button onClick={() => {formOrg(record)}}>Сформировать контрагента</Button>,
        },
    ]

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    const [form] = Form.useForm();
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const { getData, data, loading, total } = useCRUD({
        apiUrl: '/api/v1/search-org',
        service: 'org'
    });
    const history = useHistory();

    const requiredRule = {
        required: true,
        message: 'Поле обязательно для заполнения'
    };

    const getOrgs = async (changedValues, allValues) => {
        try {
            const { nonResidentRf, recType, ogrn, inn, regCountry, description } = allValues;
            await getData({
                page,
                size,
                params: {
                    nonResidentRf,
                    recType,
                    ogrn_like: ogrn,
                    inn_like: inn,
                    regCountry,
                    caption_like: description,
                }
            });
        } catch (error) { }
    };

    const formOrg = async (record) => {
        try {
            if (record.hasOrgLink) {
                confirmModalCreate({
                    title: 'Внимание!',
                    content: 'Уже существует контрагент, связанный с выбранной организацией. Открыть карточку контрагента?',
                    okText: 'Да',
                    onOk: () => history.push(`/organisations/${record.uuid}`),
                    cancelText: 'Отмена'
                });
            } else {
                const data = await fetchFunc({
                    url: `/api/v1/org-from-legal/${record.uuid}`,
                    method: 'post',
                    data: {},
                    params: {
                        uuid: record.uuid
                    }
                }, null, 'org');
                history.push(`/organisations/${data.uuid}`);
            }
        } catch (error) { }
    };

    const prepareOrgValue = values => {
        forOwn(values, (value, key) => {
            if (key === 'caption' || key === 'codeOgrnAnalog') {
                values[key] = values[key].trim();
            }
            if (key === 'regCountry') {
                values[key] = { uuid: values[key] };
            }
        });

        if (data && data.length) {
            confirmModalCreate({
                title: 'Внимание!',
                content: 'В реестре организаций найдены записи, удовлетворяющие введённым реквизитам. Рекомендуется сформировать контрагента на основе записи реестра организаций',
                okText: 'Продолжить',
                onOk: () => onSubmit(values),
                cancelText: 'Вернуться к реестру'
            });
        } else {
            onSubmit(values);
        }
    };

    const initialValues = {
        nonResidentRf: false,
        recType: 'comm_org',
    };

    const handleTableChange = ({ current, pageSize }) => {
        setPage(current - 1);
        setSize(pageSize);
        const values = form.getFieldsValue();
        getOrgs(null, values);
    };

    const fieldTextFormatter = (text) => text.replace(/ {1,}/g, " ");

    const renderFormFields = (commercial) => {
        return <>
            <Form.Item
                noStyle
                shouldUpdate={(prevState, nextState) => prevState.nonResidentRf !== nextState.nonResidentRf}
            >
                {({ getFieldValue }) => {
                    if (!getFieldValue('nonResidentRf')) {
                        return <div className='d-flex'>
                            <div className='col-6'>
                                <Form.Item
                                    label={commercial ? 'ОГРН' : 'ОГРНИП'}
                                    name='ogrn'
                                    rules={[
                                        requiredRule,
                                        {
                                            pattern: commercial ? /\b\d{13}\b/ : /\b\d{15}\b/,
                                            message: `Количество символов должно быть ${commercial ? '13' : '15'}`
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </div>
                            <div className='col-6'>
                                <Form.Item
                                    label="ИНН"
                                    name='inn'
                                    rules={[{
                                        pattern: commercial ? /\b\d{10}\b/ : /\b\d{12}\b/,
                                        message: `Количество символов должно быть ${commercial ? '10' : '12'}`
                                    }]}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    } else {
                        return <div className='d-flex'>
                            <div className='col-6'>
                                <Form.Item
                                    label="Страна"
                                    name='regCountry'
                                    rules={[requiredRule]}
                                >
                                    <DynamicSelect
                                        style={{ width: '100%' }}
                                        placeholder='Выбрать'
                                        notfoundcontentplaceholder='Введите запрос'
                                        feachoptions={{
                                            referenceStore: 'mdm',
                                            url: '/api/v1/catalogs/country/items/search',
                                            method: 'post',
                                            catalogOrigin: 'country',
                                            displayedField: 'caption',
                                            valueField: 'uuid',
                                            valueSearchName: 'search',
                                            defaultSize: 20
                                        }}
                                        handleSelectChange={(value) => {
                                            if (value === '21b35792-6d8e-4e04-a5d2-a73b9efa8b91') {
                                                form.setFieldsValue({ regCountry: null });
                                            }
                                            else {
                                                form.setFieldsValue({ regCountry: value });
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className='col-6'>
                                <Form.Item
                                    label="КОД (аналог ОГРН)"
                                    name='codeOgrnAnalog'
                                    rules={[requiredRule]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            form.setFieldsValue({ codeOgrnAnalog: fieldTextFormatter(value) });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    }
                }}
            </Form.Item>
            <Form.Item
                label={commercial ? 'Полное наименование' : 'Фамилия, Имя, Отчество'}
                name='description'
                rules={[requiredRule]}
            >
                <Input.TextArea
                    onChange={(e) => {
                        const { value } = e.target;
                        form.setFieldsValue({ description: fieldTextFormatter(value) });
                    }}
                />
            </Form.Item>
        </>
    };

    return (
        <Form
            {...layout}
            form={form}
            name="organisation-create"
            onFinish={prepareOrgValue}
            initialValues={initialValues}
            onValuesChange={debounce(getOrgs, 1000)}
        >
            <Form.Item
                name='nonResidentRf'
                valuePropName="checked"
            >
                <Checkbox>
                    Нерезидент РФ
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Тип контрагента"
                name="recType"
            >
                <Select>
                    {recTypeOptions.map(item => <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevState, nextState) => prevState.recType !== nextState.recType}
            >
                {({ getFieldValue }) => {
                    return renderFormFields(getFieldValue('recType') === 'comm_org')
                }}
            </Form.Item>
            <Form.Item {...tailLayout} className='text-right'>
                <Button
                    className='mr-3'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                >
                    Создать
                </Button>
            </Form.Item>
            <Table
                dataSource={[...data]}
                columns={columns}
                onChange={handleTableChange}
                loading={loading}
                rowKey={record => record.uuid}
                pagination={{
                    current: page + 1,
                    total: total,
                    showSizeChanger: true
                }}
                scroll={{
                    x: true,
                }}
            />
        </Form>
    );
};

export default CatalogsOrgForm;
