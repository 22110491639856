import React from 'react';
import {Docs} from 'ecm-docs';
import moment from 'moment';
import { BaseDiv } from "../Themes/Components";
import {isArray, isEmpty} from 'lodash';
import security from "../../Utils/security";
const { getToken } = security;


const ButtonWithDocumentSelection = ({ form, recordUuid }) => {

    const onDocumentSelect = (selectedRows) => {
        if (!isEmpty(selectedRows)) {
            const {caption, documentTypeIdentifier, uuid } = isArray(selectedRows) ? selectedRows[0] : selectedRows;
            form.setFieldsValue({
                docCaption: caption,
                docOrigin: documentTypeIdentifier.documentTypeIdentifier,
                docUuid: uuid,
            });
        }
    };

    const { REACT_APP_ECM_URL: ecmApiUrl, REACT_APP_ECM_FRONT_URL: ecmFrontUrl} = window._env;

    return (
        <BaseDiv>
            <Docs
                multiply={false}
                ecmApiUrl={ecmApiUrl}
                ecmFrontUrl={ecmFrontUrl}
                token={getToken()}
                fetch={{
                    docStoreUrl: `/api/v1/document/search`,
                    method: 'POST',
                    extraParams: '',
                    service: 'ecm'
                }}
                initValues={{
                    'docKind': '2',
                    'source': 'МДМ',
                    'isEndData': false,
                    'regDate': moment(),
                    'orgId': recordUuid
                }}
                onDocumentsAdd={onDocumentSelect}
            />
        </BaseDiv>
    );
};

export default ButtonWithDocumentSelection;
