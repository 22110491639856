import React, {Component} from 'react';
import {connect} from 'react-redux';
import {saveAs} from 'file-saver';
import {Button, Tooltip} from 'antd';
import {get, isEmpty, isObject} from 'lodash';
import moment from 'moment';

import HeadCatalog from '../../components/CardWidgets/HeadCatalog';
import Fields from '../../components/CardWidgets/Fields';
import Attributes from '../../components/CardWidgets/Attributes/Attributes';
import {fetchFunc} from '../../Utils/security/http/mdm';
import {errorModalCreate} from '../../components/Helpers/Modals';

import './CatalogDraftCard.scss';

class CatalogDraftCard extends Component {
  static applyOrRemoveAccessRoles = ['mdm_admin', 'catalog_admin', 'ROLE_SYSTEM', 'ROLE_ROLE_SYSTEM'];

  constructor(props) {
    super(props);

    this.state = {
      hasApplyOrRemoveAccess: false,
      options: [
        {value: 'manual', label: 'Вручную'},
        {value: 'external', label: 'Во внешних источниках данных'},
        {value: 'mixed', label: 'В смешанном режиме'},
      ],
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
        uuid: this.props.match.params.draftId,
        uniqueFields: [],
        hasApplyOrRemoveAccess: this.defineHasApplyOrRemoveAccess(this.props.userRoles),
      },
      () => {
        this.getDraftItem().then();
      }
    );
  }

  defineHasApplyOrRemoveAccess(userRoles = []) {
    return CatalogDraftCard.applyOrRemoveAccessRoles.some((role) => userRoles.includes(role));
  }

  makeAction = async (type) => {
    const {uuid} = this.state;
    let url = '/api/v1/catalog-draft/' + uuid;
    let method = 'delete';
    let body = {};
    if (type === 'apply') {
      url = '/api/v1/catalog-draft/' + uuid + '/actions';
      method = 'post';
      body.action = 'Применить';
    }
    try {
      const response = await fetchFunc({url, method, data: body}, (error) =>
        errorModalCreate(get(error, 'data.message') || error.message)
      );
      const isErrorResponse =
        isObject(response) && response.objects && response.objects.action && response.objects.action.errorMessage;
      if (isErrorResponse) {
        errorModalCreate(isErrorResponse);
      } else {
        this.props.history.push('/catalog-draft');
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };

  getDraftItem = async () => {
    try {
      const {uuid} = this.state;
      const data = await fetchFunc({
        url: `/api/v1/catalog-draft/${uuid || this.props.match.params.draftId}`,
        method: 'get',
      });
      const uniqueFields = !isEmpty(data?.options?.unique_fields) && data?.options?.unique_fields.slice(',').join(', ');
      this.setState({data, uniqueFields, loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onExportError = async (response) => {
    const text = await response.data.text();
    errorModalCreate(JSON.parse(text).message);
  };

  exportDraft = async () => {
    const {uuid} = this.state;
    try {
      const data = await fetchFunc(
        {
          url: `/api/v1/draft-order/${uuid}/metadata`,
          method: 'get',
          responseType: 'blob',
        },
        this.onExportError
      );
      saveAs(data, `${uuid}-metadata-${moment().format('DD-MM-YYYY-h-mm')}`);
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onAttributesSave = () => {
    this.setState({loading: true}, () => this.getDraftItem());
  };

  render() {
    const {data, uniqueFields, options, loading, hasApplyOrRemoveAccess} = this.state;
    const {location, history, match, referenceCatalogPatternStore} = this.props;
    return (
      <>
        <HeadCatalog
          description={data && data.description}
          status={data && data.status && data.status.caption}
          uuid={data && data.uuid}
          createDate={data && data.createDate}
          authorFio={data && data.authorFio}
        ></HeadCatalog>
        <div className="container container--white">
          <div className="d-flex mb-2">
            <div className="ml-auto">
              <Tooltip title="Экспортировать структуру заявки в файл">
                <Button className="mr-1" onClick={() => this.exportDraft()}>
                  Экспортировать
                </Button>
              </Tooltip>
              {data && data.status && data.status.caption === 'Черновик' && hasApplyOrRemoveAccess && (
                <>
                  <Button className="mr-1" onClick={() => this.makeAction('apply')}>
                    Применить заявку
                  </Button>
                  <Button onClick={() => this.makeAction('delete')} className="mr-1">
                    Удалить
                  </Button>
                </>
              )}
              <Button onClick={() => this.props.history.push('/catalog-draft/')}>Закрыть</Button>
            </div>
          </div>
          <Attributes
            uuid={data && data.uuid}
            status={data && data.status && data.status.caption}
            loading={loading}
            options={options}
            uniqueFields={uniqueFields}
            data={[
              {caption: 'Наименование справочника', value: data && data.caption, type: 'String', origin: 'caption'},
              {caption: 'Наименование в БД', value: data && data.origin, type: 'String', origin: 'origin'},
              {
                caption: 'Требуется верификация записей справочника',
                value: data && data.validateRecords,
                type: 'Checkbox',
                origin: 'validateRecords',
              },
              {
                caption: 'Включен механизм приоритетности источников данных',
                value: data && data.prioritySupport,
                type: 'Checkbox',
                origin: 'prioritySupport',
              },
              {caption: 'Способ ведения', value: data && data.inputType, type: 'Select', origin: 'inputType'},
            ]}
            onSave={this.onAttributesSave}
          />
          <Fields
            draftAction={data && data.draftAction}
            status={data && data.status && data.status.caption}
            catalogOptions={data && data.options}
            options={options}
            loading={loading}
            referenceCatalogPatternStore={referenceCatalogPatternStore}
            uuid={data && data.uuid}
            catalogIdentifier={data && data.catalogType && data.catalogType.catalogIdentifier}
            location={location}
            history={history}
            match={match}
            onSave={this.onAttributesSave}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    breadcrumbNameMap: store.navigate.breadcrumbNames,
    referenceCatalogPatternStore: store.detail.referenceCatalogPatternStore,
    userRoles: store.user.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBreadcrumbMap: (obj) => {
      dispatch({
        type: 'UPDATE_BREADCRUMB_MAP',
        data: obj,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDraftCard);
