import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import cn from 'classnames';

import './DeduplicationGroupModalValue.scss';

const DeduplicationGroupModalValue = ({
  recordsValue,
  caption,
  copyValueToGoldRecordHandler,
  index,
  status,
  goldRecord,
}) => {
  /** Индекс активной записи */
  const [activeIndex, setActiveIndex] = useState(null);
  /** Состояние для изменения класса элемента */
  const [active, setActive] = useState(false);
  /** Uuid выбранного элемента  */
  const [uuidSelected, setSelected] = useState('');
  /** Используем хранилище */
  const color = useSelector((state) => state?.deduplication.color);
  const recordId = useSelector((state) => state?.deduplication.recordId);
  /** Условие для измененния активного класса */
  useEffect(() => {
    (isEmpty(goldRecord) || (caption?.caption === recordsValue?.caption && uuidSelected !== recordId)) &&
      setActive(false);
  }, [recordId, recordsValue, goldRecord]);
  /** Условие для выделения выбранного элемента */
  const checkingForHighlighting =
    (color === 'red' &&
      activeIndex === index &&
      goldRecord?.caption === caption?.caption &&
      uuidSelected === recordId) ||
    (color === 'gray' && activeIndex === index && goldRecord?.caption === caption?.caption && uuidSelected === recordId)
      ? !active
      : active;
  return (
    <div
      key={index}
      id={recordsValue.recordUuid}
      className={cn('deduplicationGroupModalValue__item', {
        'deduplicationGroupModalValue__item--active': checkingForHighlighting,
      })}
      onClick={
        status !== 'LINK_SET'
          ? (event) => {
              copyValueToGoldRecordHandler(
                event,
                recordsValue.value,
                recordsValue.caption,
                recordsValue.recordUuid,
                recordsValue.origin,
                active
              );
              setActiveIndex(index);
              setSelected(recordsValue.recordUuid);
              setActive((prevState) => !prevState);
            }
          : null
      }
    >
      {recordsValue.value}
    </div>
  );
};

export default React.memo(DeduplicationGroupModalValue);
