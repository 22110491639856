import {SET_CATALOG_FIELDS, SET_LOADING, SET_SOURCE_PRIORITY} from './actionTypes';

const initialState = {
  catalogFields: {
    content: [],
  },
  sourcePriority: {
    content: [],
  },
  loadings: {
    catalogFields: false,
    sourcePriority: false,
  },
};

export const cardWidgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATALOG_FIELDS:
      return {
        ...state,
        catalogFields: action.payload,
      };
    case SET_SOURCE_PRIORITY:
      return {
        ...state,
        sourcePriority: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loadings: {
          ...initialState.loadings,
          [action.store]: action.payload,
        },
      };
    default:
      return state;
  }
};
