export const getCustomizedDocDetailColumns = ({
  columns = [],
  itemsForAdd = [],
  itemsForRemove = [],
  addToBegin = false
}) => {
  const filteredArray = columns.filter((col) => {
    return !itemsForRemove.includes(col?.key || col?.origin);
  });
  if (!addToBegin) return filteredArray.concat(itemsForAdd);
  return itemsForAdd.concat(filteredArray);
}
