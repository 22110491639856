import React from 'react';
import {Collapse} from 'antd';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';

import {StyledSpan} from '../../../../../components/Themes/Components';
import {deduplication} from '../../../helpers/constants';

import './DeduplicationProtocolSystemInfo.scss';

/**
 * @param deduplicationProtocolInfo - данные для отображения системной информации по протоколу
 * Системная информация протокола дедупликации */
const DeduplicationProtocolSystemInfo = ({deduplicationProtocolInfo}) => {
  const {Panel} = Collapse;

  return (
    <div className="deduplicationProtocolSystemInfo">
      <Collapse className="deduplicationProtocolSystemInfo__collapse">
        <Panel header={deduplication.systemInfoHeader} key="1">
          <div className="deduplicationProtocolSystemInfo__row-box">
            <div className="deduplicationProtocolSystemInfo__row-box--column">
              <div className="deduplicationProtocolSystemInfo__text">
                <StyledSpan className="deduplicationProtocolSystemInfo__label">{deduplication.uuid}</StyledSpan>
                {deduplicationProtocolInfo?.protocolUuid}
              </div>
              <div>
                <StyledSpan className="deduplicationProtocolSystemInfo__label">{deduplication.createDate}</StyledSpan>
                {dayjs(deduplicationProtocolInfo?.protocolCreateDate).format('DD.MM.YYYY HH:mm')}
              </div>
              <div>
                <StyledSpan className="deduplicationProtocolSystemInfo__label">{deduplication.finishedDate}</StyledSpan>
                {!isEmpty(deduplicationProtocolInfo?.protocolDateReport) &&
                  dayjs(deduplicationProtocolInfo?.protocolDateReport).format('DD.MM.YYYY HH:mm')}
              </div>
            </div>

            <div className="deduplicationProtocolSystemInfo__row-box--column">
              <div>
                <StyledSpan className="deduplicationProtocolSystemInfo__label">{deduplication.version}</StyledSpan>
                {deduplicationProtocolInfo?.protocolVersion}
              </div>
              <div>
                <StyledSpan className="deduplicationProtocolSystemInfo__label">{deduplication.changeDate}</StyledSpan>
                {dayjs(deduplicationProtocolInfo?.protocolDateFrom).format('DD.MM.YYYY HH:mm')}
              </div>
              <div>
                <StyledSpan className="deduplicationProtocolSystemInfo__label">{deduplication.identifier}</StyledSpan>
                {deduplicationProtocolInfo?.protocolId}
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};
export default React.memo(DeduplicationProtocolSystemInfo);
