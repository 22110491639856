import React, {useReducer, useEffect} from 'react';
import {fetchFunc} from '../../Utils/security/http/mdm';
import moment from 'moment';
import {Table, Modal} from 'antd';
import RecordRedactor from '../RecordRedactor';
import {isBoolean, isEmpty} from 'lodash';
import {renderStatus} from '../Helpers/Utils';
import {Link} from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'setData':
      return {...state, data: action.payload};
    case 'setLoading':
      return {...state, loading: action.payload};
    case 'setModalVisible':
      return {...state, modalVisible: action.payload};
    case 'setVersionId':
      return {...state, versionId: action.payload};
    case 'setRecordUuid':
      return {...state, recordUuidLocal: action.payload};
    case 'setTotal':
      return {...state, totalElements: action.payload};
    case 'setUnverified':
      return {...state, unverified: action.payload};
    default:
      return state;
  }
};

const Versions = ({
  type,
  recordUuid,
  catalogUuid,
  versionDate,
  versionModal,
  includeDeprecatedHistory,
  versionKey,
  setVersionKey,
  setIsRestored,
  isUnverified,
}) => {
  const versionColumns = [
    {
      title: 'Идентификатор',
      dataIndex: 'id',
      render: (text, record) =>
        isBoolean(record.active) ? (
          <span
            className="link"
            onClick={() => {
              type !== 'deleted' && setVersionKey('version');
              showModal(record.id, record.uuid);
            }}
          >
            {text}
          </span>
        ) : (
          <Link to={`/catalog/${catalogUuid}/${recordUuid}/verify`}>{record.version}</Link>
        ),
    },
    {
      title: 'Автор',
      dataIndex: 'authorFio',
    },
    {
      title: 'Действует с',
      dataIndex: 'dateFrom',
      render: (text) => renderTableDate(text),
    },
    {
      title: 'Действует по',
      dataIndex: 'dateTo',
      render: (text) => renderTableDate(text),
    },
  ];

  if (type !== 'deleted') {
    versionColumns.push({
      title: 'Статус',
      dataIndex: 'active',
      render: (active, record) => {
        const isWithoutVerif = isBoolean(active);
        const isUnverified = !isEmpty(record.sysVerifyInfoDraft);
        if (isUnverified) {
          const currentVerson = record.sysVerifyInfoDraft.sourceId.id;
          return currentVerson === record.id ? 'Действующая' : 'Архив';
        } else {
          return isWithoutVerif ? (active ? 'Действующая' : 'Архив') : renderStatus(record, 'sysVerifyInfo.status');
        }
      },
    });
  }

  const initialState = {
    columns: versionColumns,
    data: [],
    unverified: {},
    loading: false,
    modalVisible: false,
    versionId: null,
    recordUuidLocal: null,
    totalElements: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const {columns, data, loading, modalVisible, versionId, recordUuidLocal, unverified} = state;

  if (type === 'deleted')
    versionColumns.push({
      title: 'Дата создания записи',
      dataIndex: 'createDate',
      render: (text) => renderTableDate(text),
    });

  const renderTableDate = (date) => (date ? moment(date).format('DD-MM-YYYY') : '');

  useEffect(() => {
    dispatch({type: 'setLoading', payload: true});
    getVersions().then(() => {
      if (type !== 'deleted' && isUnverified) getRecord().then();
    });
  }, [type]);

  useEffect(() => {
    dispatch({type: 'setData', payload: [unverified, ...state.data]});
  }, [unverified]);

  const getVersions = async () => {
    try {
      let url = `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/history`;
      if (type === 'deleted') {
        url = `/api/v1/catalogs/${catalogUuid}/items/history/deleted`;
      }
      const data = await fetchFunc({
        url,
        method: 'get',
        params: {
          filterDate: versionDate,
          includeDeprecatedHistory,
        },
      });
      dispatch({type: 'setData', payload: data.content});
      dispatch({type: 'setTotal', payload: data.totalElements});
      if (type === 'deleted' || !isUnverified) dispatch({type: 'setLoading', payload: false});
    } catch (error) {
      dispatch({type: 'setLoading', payload: false});
    }
  };

  const getRecord = async () => {
    try {
      const data = await fetchFunc({
        url: `/api/v1/catalogs/${catalogUuid}/items/${recordUuid}/unverified`,
      });
      dispatch({type: 'setUnverified', payload: {...data, sysVerifyInfo: data.sysVerifyInfo}});
      dispatch({type: 'setLoading', payload: false});
    } catch (error) {}
  };

  const showModal = (id, uuid) => {
    dispatch({type: 'setVersionId', payload: id});
    dispatch({type: 'setRecordUuid', payload: uuid});
    dispatch({type: 'setModalVisible', payload: true});
  };

  const closeModal = () => {
    dispatch({type: 'setModalVisible', payload: false});
  };

  const onRestore = () => {
    getVersions().then();
    setIsRestored && setIsRestored((isRestored) => !isRestored);
    closeModal();
    setVersionKey('');
  };

  const sortedData = data.sort((a, b) => b.version - a.version);

  return (
    <>
      {data && data.length ? (
        <Table dataSource={sortedData} loading={loading} columns={columns} rowKey={(record) => record.id} bordered />
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100">Не найдено ни одной версии</div>
      )}
      <Modal visible={modalVisible} onCancel={closeModal} destroyOnClose width={1000} footer={null}>
        {(versionModal && versionModal(versionId)) || (
          <RecordRedactor
            recordUuid={recordUuid || recordUuidLocal}
            catalogUuid={catalogUuid}
            versionId={versionId || true}
            type={type}
            versionKey={versionKey}
            onRestore={onRestore}
          />
        )}
      </Modal>
    </>
  );
};

export default React.memo(Versions);
